import { track } from '@amplitude/analytics-browser';

// episodeIds included for historical tracking purposes
type ReportCmeEvaluationSubmittedParams = {
  creditIds: number[];
  episodeIds: number[];
};

export function reportCmeEvaluationSubmittedAnalytics({
  creditIds,
  episodeIds
}: ReportCmeEvaluationSubmittedParams) {
  track('CME_Evaluation_Submitted', {
    credit_ids: creditIds,
    episode_ids: episodeIds,
    path: window.location.pathname
  });
}
