import { z } from 'zod';
import { cmeTagSchema } from './cmeTag';

export const cmeChapterSchema = z.object({
  id: z.number(),
  title: z.string(),
  tags: z.array(cmeTagSchema)
});

export type CmeChapter = z.infer<typeof cmeChapterSchema>;
