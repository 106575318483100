import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import config from '../../config';
import { useDispatch, useElasticsearch, useSelector } from '../../lib/hooks';
import { Wrapper } from '../Wrapper';
import { EpisodeFeed } from '../EpisodeFeed/EpisodeFeed';
import { EpisodeCard } from '../EpisodeCard/EpisodeCard';
import Loader from '../Loader';
import { LoadingError } from '../LoadingError';
import { UC_MAX } from '../../lib/episodeTypes';
import { reportError } from '../../lib/reportError';
import { loadAllC3Playlists } from '../../actions/c3Playlists';
import { ESEmrapGuideEpisodeDoc } from '../../lib/types';
import { ESC3Playlist } from '../../schema/playlist/ESC3Playlist';

import './AudioEpisodeSection.scss';

const { episodeArtUrl } = config;

const audioEpisodeQuery = {
  query: {
    bool: {
      must: [
        {
          term: {
            'episode.episode_type.id': UC_MAX
          }
        },
        {
          term: {
            type: 'episode'
          }
        }
      ]
    }
  },
  sort: [
    {
      'episode.publish_date': {
        order: 'desc'
      }
    }
  ],
  size: 60
};

export interface AudioEpisodeSectionProps {
  className?: string;
}

export function AudioEpisodeSection({ className }: AudioEpisodeSectionProps) {
  const dispatch = useDispatch();

  const { modernizePlayerPage } = useFlags();

  const {
    data: audioEpisodesData,
    loading: isAudioEpisodesLoading,
    error: audioEpisodesError
  } = useElasticsearch<ESEmrapGuideEpisodeDoc>(
    'emrap.guide.episode',
    audioEpisodeQuery
  );

  useEffect(() => {
    dispatch(loadAllC3Playlists());
  }, [loadAllC3Playlists, dispatch]);

  const c3Playlists = useSelector(state => state.c3Playlists.allPlaylists);

  const c3Playlist = useMemo(() => {
    const c3AllPlaylistsData: ESC3Playlist[] | null = c3Playlists.data;
    return c3AllPlaylistsData
      ? c3AllPlaylistsData.find(playlist => playlist.url_path === 'C3-UC')
      : null;
  }, [c3Playlists.data]);

  return (
    <>
      <Wrapper className={cn('uc-audio-page-latest', className)}>
        <h3 className="uc-audio-page__title--latest">Latest Episodes</h3>
        <p className="uc-audio-page__description--latest">
          Check back monthly for new content!
        </p>
        {isAudioEpisodesLoading ? (
          <Loader className="uc-audio-page__playlist-loader" />
        ) : (
          <div className="uc-audio-page__playlist">
            {audioEpisodesError ? (
              <LoadingError className="uc-audio-page__playlist-error" />
            ) : (
              <div className="uc-audio-page__episode-feed">
                <EpisodeFeed page="uc-audio" episodeType="uc">
                  {audioEpisodesData &&
                    audioEpisodesData.map(({ episode }) => {
                      const episodeResourceUrl = episode.resource.replace(
                        /^http(s?):\/\/www.emrap.org/,
                        ''
                      );

                      if (
                        !episode.title ||
                        !episode.duration ||
                        !episode.chapter_count
                      ) {
                        reportError('Missing UC Episode feed data');
                        return undefined;
                      }

                      return (
                        <EpisodeCard
                          key={episode.id}
                          href={
                            modernizePlayerPage
                              ? `/episode/${episode.shortname}` || ''
                              : episodeResourceUrl
                          }
                          imageUrl={`${episodeArtUrl}/${episode.artwork.id}.jpg`}
                          title={episode.title}
                          duration={episode.duration}
                          itemCount={episode.chapter_count}
                          page="uc-audio"
                        />
                      );
                    })}
                </EpisodeFeed>
              </div>
            )}
          </div>
        )}
      </Wrapper>
      <Wrapper className={cn('uc-audio-page-relevant', className)}>
        <h3 className="uc-audio-page__title--recommended">
          You may also like these
        </h3>
        <p className="uc-audio-page__description--recommended">
          Content from the EM:RAP universe customized for UC subscribers.
        </p>
        {c3Playlists.isLoading ? (
          <Loader className="uc-audio-page__playlist-loader" />
        ) : (
          <div className="uc-audio-page__playlist">
            {c3Playlists.error ? (
              <LoadingError className="uc-audio-page__playlist-error" />
            ) : (
              <div className="uc-audio-page__episode-feed">
                <EpisodeFeed page="uc-audio" episodeType="uc">
                  {c3Playlist &&
                    c3Playlist.items.map(item => (
                      <EpisodeCard
                        key={item.url_path}
                        href={item.url_path}
                        imageUrl={
                          item.thumbnail
                            ? `${process.env.REACT_APP_CDN_BASE}${item.thumbnail['episode-card'].path}`
                            : ''
                        }
                        title={item.title}
                        duration={item.duration}
                        itemCount={item.item_count}
                        page="uc-audio"
                      />
                    ))}
                </EpisodeFeed>
              </div>
            )}
          </div>
        )}
      </Wrapper>
    </>
  );
}
