import React, { ReactNode } from 'react';
import { FormLabel, Tag as LabelTag } from './FormLabel';
import { FormDetails } from './FormDetails';
import { FormHint } from './FormHint';
import { FeedbackBar } from './FeedbackBar';

import './_style/lib/components/Question.scss';

interface QuestionProps {
  labelTag?: LabelTag;
  question: string;
  children: ReactNode;
  htmlFor?: string;
  details?: string;
  hintText?: string;
  isFlexLayout?: boolean;
  errorMessage?: string;
}

export function Question({
  labelTag,
  question,
  details,
  hintText,
  isFlexLayout = false,
  htmlFor,
  children,
  errorMessage
}: QuestionProps) {
  const ContainerTag = labelTag === 'legend' ? 'fieldset' : 'div';

  return (
    <ContainerTag className="global-question">
      <FormLabel
        variant="question"
        labelText={question}
        htmlFor={htmlFor}
        Tag={labelTag}
      />
      <div className="global-question__details-container">
        {details !== undefined && <FormDetails text={details} />}
        {hintText !== undefined && (
          <FormHint className="global-question__hint" text={hintText} />
        )}
      </div>
      <div
        className="global-question__input-container"
        data-is-flex-layout={isFlexLayout}
      >
        {children}
      </div>
      {errorMessage !== undefined && (
        <FeedbackBar variant="negative" format="form" message={errorMessage} />
      )}
    </ContainerTag>
  );
}
