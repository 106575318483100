import { z } from 'zod';
import { editionSchema } from '../account/edition';
import { specialtySchema } from '../account/specialty';
import { subscriptionRenewalStatusTypeSchema } from '../account/subscription';
import { metaSchema } from './meta';

const accountApiSchema = z.object({
  id: z.number(),
  avatarURI: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  suffix: z.string().nullable(),
  specialty: specialtySchema,
  edition: editionSchema.optional(),
  subscriptionType: z.string().nullable(),
  isCurator: z.boolean()
});

const eventApiSchema = z.object({
  shortname: z.string(),
  has_in_person: z.boolean(),
  has_streaming: z.boolean()
});

export type EventApi = z.infer<typeof eventApiSchema>;

const subscriptionApiSchema = z.object({
  id: z.number(),
  title: z.string(),
  type: z.string(),
  startDate: z.string(),
  expiryDate: z.string().optional(),
  automaticRenewalStatus: subscriptionRenewalStatusTypeSchema,
  cardExpiryDate: z.string().nullable().optional()
});

export const getAccountSchema = z.object({
  accounts: z.array(accountApiSchema),
  events: z.array(eventApiSchema),
  subscriptions: z.array(subscriptionApiSchema),
  meta: metaSchema
});

export type GetAccount = z.infer<typeof getAccountSchema>;
