import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  AsyncThunkPayloadCreator
} from '@reduxjs/toolkit';
import {
  CmeGenericDisclosurePayload,
  genericDisclosureSchema
} from '../schema/cme/cmeGenericDisclosure';
import { CmeGenericDisclosureState } from '../lib/stateTypes';
import { reportError } from '../lib/reportError';
import { fetchApi } from '../lib/fetchApi';
import { ApiError } from '../lib/ApiError';

const initialState: CmeGenericDisclosureState = {
  disclosure: null,
  providerId: null,
  isLoading: false
};

export const payloadCreator: AsyncThunkPayloadCreator<
  CmeGenericDisclosurePayload | null,
  number,
  { state: CmeGenericDisclosureState; rejectValue: null }
> = async (
  providerId: number,
  thunkAPI
): Promise<CmeGenericDisclosurePayload | null> => {
  const state = thunkAPI.getState();
  const { disclosure, providerId: stateProviderId } = state;

  try {
    if (disclosure && stateProviderId === providerId) {
      return { disclosure, providerId: stateProviderId };
    }

    const response = await fetchApi(`cme_generic_disclosures/${providerId}`);
    const data = await response.json();
    const parsedData = genericDisclosureSchema.parse(data.data);

    return { disclosure: parsedData, providerId };
  } catch (error) {
    const errorContext: { extra: { [key: string]: unknown } } = {
      extra: {
        url: `cme_generic_disclosures/${providerId}`,
        providerId
      }
    };
    if (error instanceof ApiError) {
      errorContext.extra.status = error.status;
    }

    reportError(error, errorContext);
    return null;
  }
};

// TODO In the future, we need to add another thunk
// to load all disclosures in an array.
// Perhaps rename this one loadSpecificCmeGenericDisclosure.
export const loadCmeGenericDisclosures = createAsyncThunk<
  CmeGenericDisclosurePayload | null,
  number,
  { state: CmeGenericDisclosureState; rejectValue: null }
>('cmeGenericDisclosures/loadCmeGenericDisclosures', payloadCreator);

export const cmeGenericDisclosureSlice = createSlice({
  name: 'cmeGenericDisclosure',
  initialState,
  reducers: {
    setCmeGenericDisclosures: (
      state,
      action: PayloadAction<CmeGenericDisclosurePayload>
    ) => {
      state.disclosure = action.payload.disclosure;
      state.providerId = action.payload.providerId;
      state.lastUpdate = new Date().getTime();
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadCmeGenericDisclosures.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadCmeGenericDisclosures.fulfilled, (state, action) => {
        if (action.payload) {
          state.disclosure = action.payload.disclosure;
          state.providerId = action.meta.arg;
          state.isLoading = false;
          state.lastUpdate = new Date().getTime();
        }
      })
      .addCase(loadCmeGenericDisclosures.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const { reducer, actions } = cmeGenericDisclosureSlice;
export const { setCmeGenericDisclosures } = actions;
