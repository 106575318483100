import React from 'react';
import classNames from 'classnames';
import TextLink from '../TextLink/TextLink';

import './QuickLinks.scss';

interface Link {
  name: string;
  to: string;
  onClick?: () => void;
}

interface QuickLinksProps {
  className?: string;
  links: Link[];
}

export const QuickLinks = ({ className, links }: QuickLinksProps) => (
  <ul className={classNames(className, 'quick-links')}>
    {links.map(link => (
      <li key={link.name}>
        <TextLink className="quick-link" href={link.to} onClick={link.onClick}>
          {link.name}
        </TextLink>
      </li>
    ))}
  </ul>
);

export default QuickLinks;
