import React from 'react';

interface DomNode {
  type: string;
  name?: string;
  children?: DomNode[];
  data?: string;
}

export function passMarks(node: DomNode) {
  if (
    node?.type === 'tag' &&
    node?.name === 'mark' &&
    node?.children?.[0]?.type === 'text' &&
    typeof node?.children?.[0]?.data === 'string'
  ) {
    return <mark>{node.children[0].data}</mark>;
  }
  return null;
}
