import { z } from 'zod';

export const cmeCreditStatusValues = [
  'Available',
  'Claimed',
  'Expiring Soon',
  'Expired'
] as const;

export const cmeCreditStatusSchema = z.enum(cmeCreditStatusValues);

export type CmeCreditStatusType = z.infer<typeof cmeCreditStatusSchema>;
