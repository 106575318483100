import momentTimezone from 'moment-timezone';

export default function formatDayWithTimeZone(
  time: number,
  timeZone: string,
  showTimeZone: boolean,
  withSuperscript?: boolean
) {
  let format;
  if (withSuperscript) {
    format = showTimeZone ? 'MMMM Do, YYYY - zz' : 'MMMM Do, YYYY';
  } else {
    format = showTimeZone ? 'MMMM D, YYYY - zz' : 'MMMM D, YYYY';
  }
  return momentTimezone(time * 1000)
    .tz(timeZone)
    .format(format);
}
