import { z } from 'zod';

export const episodeEmbeddedQuizSchema = z.object({
  title: z.string(),
  shortname: z.string(),
  displayOrder: z.number(),
  isFree: z.boolean(),
  uri: z.string()
});

export type EpisodeEmbeddedQuiz = z.infer<typeof episodeEmbeddedQuizSchema>;
