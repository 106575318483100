import React, { useEffect, useState, useMemo } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from '../../lib/hooks';
import { loadAllC3Playlists } from '../../actions/c3Playlists';
import Loader from '../Loader';
import EpisodeSearchBar from './EpisodeSearchBar';
import EpisodeHomeLink from './EpisodeHomeLink';
import SketchyButton from '../LandingPage/SketchyButton';
import EpisodeTypeSelect from './EpisodeTypeSelect';
import Icon from '../Icons/Icon';
import { C3 } from '../../lib/episodeTypes';
import { getEpisodeTypePath } from '../../lib/getEpisodeTypePath';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import AllTopicsSection from './AllTopicsSection';
import { reportPlaylistOpenedAnalytics } from '../../analytics/reportPlaylistOpenedAnalytics';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import { ESC3PlaylistItem } from '../../schema/playlist/ESC3PlaylistItem';

import './AllTopicsMenu.scss';

const animationDurationMs = 250;

// TODO: Replace the parent selector with an element
// that allows us to still show the banner/nav
const MODAL_PARENT_SELECTOR = '.react-app'; // this can be passed as a prop later if we need more than c3 page modal

interface AllTopicsMenuProps {
  defaultState?: 'open' | 'closed';
  displayLogo?: boolean;
  displayDropDown?: boolean;
}

function isPlaylistItem(item: ESC3PlaylistItem) {
  return item.item_count > 0;
}

function AllTopicsMenu({
  defaultState = 'closed',
  displayLogo = true,
  displayDropDown = true
}: AllTopicsMenuProps) {
  const [isOpen, setIsOpen] = useState(defaultState === 'open');

  const closeMenu = () => {
    setIsOpen(false);
  };

  const c3PlaylistsState = useSelector(state => state.c3Playlists.allPlaylists);
  const isLoading = c3PlaylistsState?.isLoading;
  const playlists = c3PlaylistsState?.data;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(loadAllC3Playlists());
    }
  }, [dispatch, loadAllC3Playlists, isOpen]);

  const sortedPlaylists = useMemo(
    () =>
      playlists
        ? playlists
            // Remove video items from children.
            .map(playlist => ({
              ...playlist,
              items: playlist.items.filter(isPlaylistItem)
            }))

            // Remove playlists that do not contain child playlists.
            .filter(playlist => playlist.items.length > 0)

            // Sort by longest playlist in reverse order.
            .sort((a, b) => b.items.length - a.items.length)
        : [],

    [playlists]
  );

  return (
    <>
      <SketchyButton
        className="c3-playlists-menu__toggle c3-header-bar__button"
        hasIcon
        iconName="playlist"
        isFilled
        color="c3-green"
        onClick={() => {
          reportButtonClickedAnalytics({
            buttonType: 'open menu',
            buttonText: 'all topics',
            pageSpecialty: 'C3'
          });
          setIsOpen(!isOpen);
        }}
      >
        <span>
          <span className="c3-playlists-menu__open-all">All</span> Topics
        </span>
        <Icon
          name="arrow-right"
          className="c3-playlists-menu__secondary-icon"
        />
      </SketchyButton>
      <Modal
        overlayClassName="c3-playlists-menu__overlay"
        className="c3-playlists-menu"
        isOpen={isOpen}
        onRequestClose={closeMenu}
        closeTimeoutMS={animationDurationMs}
        bodyOpenClassName="c3-playlists-menu__body--open"
        parentSelector={() =>
          document.body.querySelector(MODAL_PARENT_SELECTOR) as HTMLElement
        }
        onAfterOpen={() => {
          // adjust top offset to accommodate any page scroll
          const modalParent = document.body.querySelector(
            MODAL_PARENT_SELECTOR
          );
          if (modalParent instanceof HTMLElement) {
            modalParent.style.setProperty(
              '--top-offset',
              `${modalParent.getBoundingClientRect().y}px`
            );
          }
        }}
      >
        <div className="c3-playlists-menu__content">
          <div className="c3-playlists-menu__header">
            <div className="c3-playlists-menu__search">
              {displayDropDown && (
                <div className="c3-playlists-menu__dropdown">
                  <EpisodeTypeSelect episodeType={C3} />
                </div>
              )}
              {displayLogo && (
                <EpisodeHomeLink
                  episodeType={C3}
                  pathTo={getEpisodeTypePath(C3)}
                  className="c3-playlists-menu__home-link"
                />
              )}
              <EpisodeSearchBar episodeType={C3} />
            </div>
            <SketchyButton
              className="c3-playlists-menu__close"
              hasIcon
              iconName="playlist"
              isFilled
              color="c3-green"
              onClick={() => {
                reportButtonClickedAnalytics({
                  buttonType: 'close menu',
                  buttonText: 'all topics',
                  pageSpecialty: 'C3'
                });
                closeMenu();
              }}
            >
              <span>
                <span className="c3-playlists-menu__close-all">All</span> Topics
              </span>
              <Icon
                name="close"
                className="c3-playlists-menu__secondary-icon"
              />
            </SketchyButton>
          </div>
          {isLoading && <Loader />}
          {!isLoading && playlists && (
            <ul className="c3-playlists-menu__playlists">
              {sortedPlaylists.map(playlist => (
                <li
                  key={playlist.url_path}
                  className="c3-playlists-menu__playlist"
                >
                  <AllTopicsSection
                    sectionTitle={playlist.title}
                    sectionLink={`/c3/playlist/${playlist.url_path}`}
                    closeMenu={closeMenu}
                    onClick={() => {
                      reportPlaylistOpenedAnalytics({
                        playlist: playlist.title,
                        itemCount: playlist.item_count,
                        pageSpecialty: 'C3'
                      });
                    }}
                  >
                    <ul className="c3-playlists-menu__playlist-items">
                      {playlist.items.map(item => (
                        <li
                          key={item.url_path}
                          className="c3-playlists-menu__playlist-item"
                        >
                          <a
                            className="c3-playlists-menu__playlist-item-title"
                            href={getPhpSitePath(
                              `/c3/playlist/${playlist.url_path}/${item.url_path}`
                            )}
                          >
                            <span className="c3-playlists-menu__item-title">
                              {item.title}
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </AllTopicsSection>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Modal>
    </>
  );
}

export default AllTopicsMenu;
