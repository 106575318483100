import React from 'react';
import ROPCallout from './ROPCallout';

import './EpisodeGuideSidebar.scss';

export default function CrunchTimeFmSidebar() {
  return (
    <nav className="episode-guide-sidebar filters-container">
      <ROPCallout />
    </nav>
  );
}
