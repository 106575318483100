import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { setCurrentEpisodeType, setCurrentSearch } from '../actions/filters';
import { useDispatch } from '../lib/hooks';

interface SearchOptions {
  episodeType?: number;
}

export function useSearch(opts?: SearchOptions) {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>('');

  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    dispatch(setCurrentSearch(searchValue));
    if (opts?.episodeType) {
      dispatch(setCurrentEpisodeType([opts.episodeType]));
    }
    push(`/search?emrap-search-bar=${searchValue}`);
  };

  return { searchValue, onSearchChange, onSearchSubmit };
}
