import React from 'react';
import { SidebarLink } from './SidebarLink';
import Icon from '../Icons/Icon';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { ROP } from '../../lib/episodeTypes';

const ROPCallout = () => (
  <SidebarLink
    to="rop"
    buttonText="Listen to Right on Prime"
    title="Want more PC Content?"
    text="Check out Right on Prime for more content for Primary Care clinicians."
    episodeType={ROP}
    isInverted
    icon={<Icon name="rop" />}
    onClick={() =>
      reportLinkClickedAnalytics({
        to: 'rop',
        linkText: 'Listen to Right on Prime',
        eventSource: 'ROP Callout'
      })
    }
  />
);

export default ROPCallout;
