import React from 'react';
import { VideoIntroduction } from './VideoIntroduction';
import { FeaturedVideo } from './FeaturedVideo';
import { ESVideoPlaylistChapterItem } from '../../schema/playlist/ESVideoPlaylistItem';

import './VideoHero.scss';

interface VideoHeroProps {
  isUrgentCare: boolean;
  featuredChapter: ESVideoPlaylistChapterItem | null;
}

export function VideoHero({ isUrgentCare, featuredChapter }: VideoHeroProps) {
  return (
    <div className="video-hero">
      <VideoIntroduction />
      <FeaturedVideo chapter={featuredChapter} isUrgentCare={isUrgentCare} />
    </div>
  );
}
