import { sortBy } from 'lodash';
import {
  WebChapterBlock,
  WebChapterBlocks
} from '../schema/webEpisode/webChapterBlocks';

export default function sortBlocksByDisplayOrder(blocks: WebChapterBlocks) {
  const chapterBlocksArray: WebChapterBlock[] = [
    ...blocks.attachment,
    ...blocks.image,
    ...blocks.text,
    ...blocks.media
  ];

  return sortBy(chapterBlocksArray, 'displayOrder');
}
