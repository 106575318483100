import React from 'react';
import classNames from 'classnames';
import DropdownButton from '../DropdownButton';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import '../RadioList.scss';
import './SortSelect.scss';

export interface SortOption {
  key: string;
  label: string;
}

interface SortSelectProps {
  className: string;
  currentSort: SortOption;
  options: SortOption[];
  setSort: (sort: SortOption) => void;
  sortOpen: boolean;
  toggleSort: () => void;
  setMenusClosed: () => void;
}

const SortSelect = ({
  className,
  currentSort,
  options,
  setSort,
  sortOpen,
  toggleSort,
  setMenusClosed
}: SortSelectProps) => {
  const setSortAndClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    reportButtonClickedAnalytics({
      buttonType: 'episode sort',
      buttonText: event.currentTarget.innerText
    });
    const key = event.currentTarget.dataset.option;
    const matchedOption = options.find(option => option.key === key);
    if (matchedOption) {
      setSort(matchedOption);
      toggleSort();
    }
  };

  const handleButtonClick = () => {
    if (!sortOpen) {
      setMenusClosed();
    }
    toggleSort();
  };

  const renderOptions = () =>
    options.map(option => (
      <li
        key={`sort-select-${option.key}`}
        className={classNames('radio-list__option', {
          active: currentSort.key === option.key
        })}
      >
        <button
          type="button"
          className="radio-list__button"
          data-option={option.key}
          onClick={setSortAndClose}
        >
          {option.label}
        </button>
      </li>
    ));

  return (
    <div className={classNames(className, 'sort-select')}>
      <DropdownButton
        className="sort-button"
        handleClick={handleButtonClick}
        text={currentSort.label}
        isOpen={sortOpen}
      />
      <ul
        className={classNames('sort-options radio-list', {
          open: sortOpen
        })}
      >
        {renderOptions()}
      </ul>
    </div>
  );
};

export default SortSelect;
