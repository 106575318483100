import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const startDate = new Date('2021-09-01T14:55:00Z');
export const endDate = new Date('2021-09-02T14:55:00Z');

const now = moment.utc().valueOf();

let currentStatus = 'during';
if (now < startDate.getTime()) {
  currentStatus = 'before';
} else if (now > endDate.getTime()) {
  currentStatus = 'after';
}

export const status = currentStatus;

const today = momentTimezone().tz('America/Los_Angeles').format('M/D/YYYY');

const id =
  today === '9/1/2021'
    ? {
        id: '588550729',
        extra: '7fb594a5ba'
      }
    : {
        id: '588554566',
        extra: '7865476156'
      };

export const vimeoId = id;
