import { z } from 'zod';
import { thumbnailSchema } from './thumbnail';

const RelatedContentBaseItemSchema = z.object({
  id: z.number(),
  shortname: z.string(),
  title: z.string(),
  uploaded_at: z.string(),
  url_path: z.string()
});

export const RelatedContentChapterItemSchema =
  RelatedContentBaseItemSchema.extend({
    type: z.literal('chapter'),
    thumbnail: thumbnailSchema.optional(),
    duration: z.number()
  });

export type RelatedContentChapterItem = z.infer<
  typeof RelatedContentChapterItemSchema
>;

export const RelatedContentEpisodeItemSchema =
  RelatedContentBaseItemSchema.extend({
    type: z.literal('episode'),
    video_count: z.number()
  });

export type RelatedContentEpisodeItem = z.infer<
  typeof RelatedContentEpisodeItemSchema
>;

export const RelatedContentPlaylistItemSchema =
  RelatedContentBaseItemSchema.extend({
    type: z.literal('playlist'),
    video_count: z.number(),
    graphic: z.string().optional()
  });

export type RelatedContentPlaylistItem = z.infer<
  typeof RelatedContentPlaylistItemSchema
>;

export const RelatedContentItemSchema = z.discriminatedUnion('type', [
  RelatedContentChapterItemSchema,
  RelatedContentEpisodeItemSchema
]);

export type RelatedContentItem = z.infer<typeof RelatedContentItemSchema>;
