import React from 'react';
import classnames from 'classnames';
import { getPhpSitePath } from '../../../lib/getPhpSitePath';
import cmeLogo from '../../../images/conferences/cme/cme-logo.png';
import { reportLinkClickedAnalytics } from '../../../analytics/reportLinkClickedAnalytics';

import './LiveConferenceCme.scss';

interface Props {
  className?: string;
  episodePath: string | null;
}

const LiveConferenceCme = ({ className, episodePath }: Props) => (
  <div className={classnames('live-conference-cme', className)}>
    <div className="live-conference-cme__container">
      <div className="live-conference-cme__content">
        <h3 className="live-conference-cme__title">
          Continuing Medical Education
        </h3>
        <p className="live-conference-cme__description">
          <img
            src={cmeLogo}
            alt="Jointly Accredited Provider logo"
            className="live-conference-cme__description-logo"
          />
          <strong>Joint Accreditation Statement:</strong> In support of
          improving patient care, this activity has been planned and implemented
          by the Postgraduate Institute for Medicine and EM:RAP. Postgraduate
          Institute for Medicine is jointly accredited by the Accreditation
          Council for Continuing Medical Education (ACCME), the Accreditation
          Council for Pharmacy Education (ACPE), and the American Nurses
          Credentialing Center (ANCC), to provide continuing education for the
          healthcare team.
        </p>
        <p className="live-conference-cme__description">
          <strong>Physician Continuing Medical Education:</strong> PIM
          designates this enduring material for a maximum of 14.5{' '}
          <em>AMA PRA Category 1 Credit(s)</em>™. Physicians should claim only
          the credit commensurate with the extent of their participation in the
          activity.
        </p>
        {episodePath !== null && (
          <p className="live-conference-cme__description">
            <a
              href={getPhpSitePath(`/episode/${episodePath}`)}
              className="live-conference-cme__link"
              onClick={() =>
                reportLinkClickedAnalytics({
                  to: `episode/${episodePath}`,
                  linkText: 'additional cme information'
                })
              }
            >
              Additional CME Information
            </a>
          </p>
        )}
      </div>
    </div>
  </div>
);

export default LiveConferenceCme;

LiveConferenceCme.defaultProps = {
  className: undefined
};
