import {
  EMRAP,
  C3,
  EMA,
  HD,
  CRUNCH_TIME_EM,
  LLSA,
  ROP,
  CRUNCH_TIME_FM,
  UC_MAX,
  NONE
} from './episodeTypes';

export function getEpisodeTypeDisplay(episodeType: number | string = NONE) {
  switch (episodeType) {
    case EMRAP:
    case 'full':
      return 'EM:RAP';
    case EMA:
    case 'ema':
      return 'EMA';
    case C3:
    case 'c3':
      return 'C3';
    case HD:
    case 'hd':
      return 'HD';
    case CRUNCH_TIME_EM:
    case 'crunchtime':
      return 'Crunch Time EM';
    case LLSA:
    case 'llsa':
      return 'LLSA';
    case ROP:
    case 'rop':
      return 'Right on Prime';
    case CRUNCH_TIME_FM:
    case 'crunchtimefm':
      return 'Crunch Time FM';
    case UC_MAX:
    case 'ucmaximus':
      return 'UC Max';
    case 'none':
    default:
      return '';
  }
}
