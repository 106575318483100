import { useLayoutEffect } from 'react';

/*
 * Using method outlined here: https://reactrouter.com/web/guides/scroll-restoration
 */
export default function ScrollToTopOnMount() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return null;
}
