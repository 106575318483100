import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';

import './ChapterPageBreadcrumb.scss';

export interface ChapterPageBreadcrumbLink {
  title: string;
  href?: string;
  publishedDate?: string;
}

interface ChapterPageBreadcrumbProps {
  indexLinks: ChapterPageBreadcrumbLink[];
  episodeType: EpisodeTypeShortname;
  isEpisodeTitle?: boolean;
}

export const ChapterPageBreadcrumb = ({
  indexLinks,
  episodeType,
  isEpisodeTitle
}: ChapterPageBreadcrumbProps) => {
  const lastIndex = indexLinks.length - 1;
  return (
    <ol
      aria-label="breadcrumb"
      className={classnames('breadcrumb', `breadcrumb--${episodeType}`)}
    >
      {indexLinks.map((link, index) =>
        link.href === undefined || isEpisodeTitle ? (
          <li
            key={link.title}
            className={classnames('chapter-page-breadcrumb__link--last', {
              'chapter-page__episode-title': isEpisodeTitle
            })}
          >
            {episodeType === 'full' ? link.publishedDate : link.title}
          </li>
        ) : (
          <li key={link.href}>
            <Link
              to={link.href}
              className={classnames('chapter-page-breadcrumb__link', {
                'chapter-page-breadcrumb__link--last': lastIndex === index
              })}
            >
              {link.title}
            </Link>
            {lastIndex > index && (
              <span
                aria-hidden="true"
                className="chapter-page-breadcrumb__separator"
              >
                /
              </span>
            )}
          </li>
        )
      )}
    </ol>
  );
};
