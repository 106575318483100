import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { EpisodeList } from './EpisodeList';
import EpisodeHeaderBar from '../EpisodeHeaderBar/EpisodeHeaderBar';
import { AllEpisodesHeader } from '../PageHeader/AllEpisodesHeader';
import EpisodeMobileDropdown from './EpisodeMobileDropdown';
import DefaultSidebar from '../Sidebar/DefaultSidebar';
import theme from '../../theme';
import { setSearchParams } from '../../lib/setSearchParams';
import getEpisodeQuery from '../../lib/getEpisodeQuery';
import { EpisodeSortSelect } from '../SortSelect';
import AudienceSelect from '../AudienceSelect';

import config from '../../config';

import './EpisodeGuide.scss';

const { episodeSortOptions } = config;
const episodeConfig = config.episodes.find(
  episode => episode.key === 'episode'
);

export function AllEpisodes({ audience, media, sort }) {
  const query = getEpisodeQuery(audience.value, sort);

  return (
    <section className="episode-guide">
      <Helmet>
        <title>All Episodes | EM:RAP</title>
        <meta property="og:title" content="EM:RAP All Episodes" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.emrap.org/episodes" />
        <meta
          property="og:image"
          content="https://www.emrap.org/images/elements/emrap-header-logo.png"
        />
      </Helmet>
      <div className="app-container">
        <div className="results-container">
          <ReactiveBase
            app={config.episodeGuideApp}
            url={config.url}
            theme={theme}
            setSearchParams={setSearchParams}
          >
            <EpisodeHeaderBar displayLogo={false} />
            <AllEpisodesHeader />
            {media.xs && (
              <EpisodeMobileDropdown sortOptions={episodeSortOptions} />
            )}

            <div className="episode-list-container">
              <div className="episode-list-content">
                <EpisodeList
                  episodeTypes={audience.value}
                  media={media}
                  query={query}
                  filters={
                    media.sm && (
                      <div className="filter-dropdown-container">
                        <AudienceSelect />
                        <EpisodeSortSelect options={episodeSortOptions} />
                      </div>
                    )
                  }
                />
                <DefaultSidebar media={media} episodeConfig={episodeConfig} />
              </div>
            </div>
          </ReactiveBase>
        </div>
      </div>
    </section>
  );
}

AllEpisodes.propTypes = {
  audience: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    dataField: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    sortBy: PropTypes.string,
    dataField: PropTypes.string
  }).isRequired,
  media: PropTypes.shape({
    xs: PropTypes.bool,
    sm: PropTypes.bool
  }).isRequired
};

const mapStatetoProps = state => ({
  sort: state.filters.episodeSort,
  audience: state.filters.audience
});

export default connect(mapStatetoProps)(AllEpisodes);
