const collections = {
  episodes: 'webEpisodes',
  publicEpisodes: 'publicWebEpisodes',
  playlists: 'episodePlaylists',
  playbacks: 'playbacks',
  webMarketingSpots: 'webMarketingSpots',
  woopWoopEvents: 'woopWoopEvents',
  woopWoopSimpleEvents: 'woopWoopSimpleEvents',
  webConfig: 'webConfig'
};

export default collections;
