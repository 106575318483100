import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatDuration from '../../lib/formatDuration';

import './EpisodeDuration.scss';

export function EpisodeDuration({ className, duration }) {
  return (
    <div className={classNames(className, 'episode-duration')}>
      {formatDuration(duration)}
    </div>
  );
}

EpisodeDuration.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number
};

EpisodeDuration.defaultProps = {
  className: null,
  duration: 0
};

export default EpisodeDuration;
