import { track } from '@amplitude/analytics-browser';

type ModalType =
  | 'cme generic disclosure'
  | 'cme faculty disclosure'
  | 'cme planning committee disclosure'
  | 'marketing spot';

interface ReportModalOpenedAnalyticsParams {
  modalType: ModalType;
  triggerText?: string;
  pageSpecialty?: string;
  eventSource?: string;
}

export function reportModalOpenedAnalytics({
  modalType,
  triggerText,
  pageSpecialty,
  eventSource
}: ReportModalOpenedAnalyticsParams) {
  track('Modal_Opened', {
    modal_type: modalType,
    trigger_text: triggerText?.toLowerCase(),
    page_specialty: pageSpecialty,
    event_source: eventSource,
    path: window.location.pathname
  });
}
