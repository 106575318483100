import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from '../lib/hooks';
import DropdownButton from './DropdownButton';
import {
  setAudience as setAudienceAction,
  setMenusClosed as setMenusClosedAction,
  toggleAudienceSelect as toggleAudienceSelectAction
} from '../actions/filters';
import config from '../config';
import { reportButtonClickedAnalytics } from '../analytics/reportButtonClickedAnalytics';

import './RadioList.scss';
import './SortSelect/SortSelect.scss';

const { audienceOptions } = config;

const AudienceSelect = () => {
  const dispatch = useDispatch();
  const currentAudience = useSelector(state => state.filters.audience);
  const audienceSelectOpen = useSelector(
    state => state.filters.audienceSelectOpen
  );

  const setAudienceAndClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    reportButtonClickedAnalytics({
      buttonType: 'audience sort',
      buttonText: event.currentTarget.innerText
    });
    const key = event.currentTarget.dataset.option;
    const matchedOption = audienceOptions.find(option => option.key === key);
    if (matchedOption) {
      dispatch(setAudienceAction(matchedOption));
      dispatch(toggleAudienceSelectAction());
    }
  };

  const handleButtonClick = () => {
    if (!audienceSelectOpen) {
      dispatch(setMenusClosedAction());
    }
    dispatch(toggleAudienceSelectAction());
  };

  return (
    <div className="sort-select">
      <DropdownButton
        className="sort-button"
        handleClick={handleButtonClick}
        text={currentAudience.label}
        isOpen={audienceSelectOpen}
      />
      <ul
        className={classnames('sort-options radio-list', {
          open: audienceSelectOpen
        })}
      >
        {audienceOptions.map(option => (
          <li
            key={`audience-select-${option.key}`}
            className={classnames('radio-list__option', {
              active: currentAudience.key === option.key
            })}
          >
            <button
              type="button"
              className="radio-list__button"
              data-option={option.key}
              onClick={setAudienceAndClose}
            >
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AudienceSelect;
