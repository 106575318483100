import React from 'react';
import { Link } from 'react-router-dom';
import { useUrgentCare } from '../../hooks/useUrgentCare';
import Icon from '../Icons/Icon';
import { getEpisodePath } from '../../lib/getEpisodePath';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';

import './SkipEpisode.scss';

interface SkipEpisodeProps {
  icon: 'next' | 'previous';
  episodeShortname?: string;
  chapterShortname?: string;
  episodeType: EpisodeTypeShortname;
  onSkip?: (nextChapterShortname: string, icon: 'next' | 'previous') => void;
}

const SkipEpisode = ({
  icon,
  episodeShortname,
  chapterShortname,
  episodeType,
  onSkip
}: SkipEpisodeProps) => {
  const isUrgentCare = useUrgentCare() || episodeType === 'ucmaximus';
  const labelText = `Skip to ${icon} chapter`;

  return episodeShortname && chapterShortname ? (
    <Link
      to={getEpisodePath(
        isUrgentCare,
        `/${episodeShortname}/${chapterShortname}`
      )}
      onClick={() => (onSkip ? onSkip(chapterShortname, icon) : undefined)}
      className={`skip-episode skip-episode--${episodeType}`}
    >
      <Icon
        className={`skip-episode__icon skip-episode__icon--${icon}`}
        isInteractive
        name="skip"
        customLabel={labelText}
      />
    </Link>
  ) : (
    <div className="skip-episode skip-episode--disabled">
      <Icon
        className={`skip-episode__icon skip-episode__icon--${icon}`}
        name="skip"
      />
    </div>
  );
};

export default SkipEpisode;
