import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState, CmeEpisodesCreditState } from '../lib/stateTypes';
import { reportError } from '../lib/reportError';
import { fetchApi } from '../lib/fetchApi';
import {
  CmeEpisodeCredit,
  cmeEpisodeCreditResponseSchema
} from '../schema/cme/cmeEpisodeCredit';

const initialState: CmeEpisodesCreditState = {
  credits: null,
  isLoading: false
};

export const loadCmeEpisodeCredit = createAsyncThunk<
  { episodeId: number; credits: CmeEpisodeCredit | null },
  { episodeId: number; isSignedIn: boolean },
  { state: AppState; rejectValue: null }
>(
  'cmeEpisodeCredit/loadCmeEpisodeCredit',
  async ({ episodeId, isSignedIn }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      if (
        state.cmeEpisodesCredit.credits !== null &&
        episodeId in state.cmeEpisodesCredit.credits
      ) {
        return {
          episodeId,
          credits: state.cmeEpisodesCredit.credits[episodeId]
        };
      }
      const url = isSignedIn
        ? `cme_episode_credit/${episodeId}`
        : `cme_episode_credit_public/${episodeId}`;
      const response = await fetchApi(url);
      const data = await response.json();

      const parsedResponse = cmeEpisodeCreditResponseSchema.parse(data);
      return { episodeId, credits: parsedResponse.data.credit };
    } catch (error) {
      reportError(error);
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const cmeEpisodesCreditSlice = createSlice({
  name: 'cmeEpisodesCredit',
  initialState,
  reducers: {
    markCreditAsClaimed: (
      state,
      action: PayloadAction<{ episodeId: number }>
    ) => {
      if (
        state.credits === null ||
        !(action.payload.episodeId in state.credits)
      ) {
        reportError(
          'Episode credit is missing from store when trying to claim'
        );
        return;
      }
      state.credits[action.payload.episodeId].status = 'Claimed';
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadCmeEpisodeCredit.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadCmeEpisodeCredit.fulfilled, (state, action) => {
        if (action.payload && action.payload.credits !== null) {
          state.credits = {
            ...state.credits,
            [action.payload.episodeId]: action.payload.credits
          };
        }
        state.isLoading = false;
      })
      .addCase(loadCmeEpisodeCredit.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const {
  reducer,
  actions: { markCreditAsClaimed }
} = cmeEpisodesCreditSlice;
