import { z } from 'zod';

export const episodeTypeShortnameSchema = z.union([
  z.literal('full'), // these come from EM:RAP episodes
  z.literal('hd'),
  z.literal('c3'),
  z.literal('ema'),
  z.literal('crunchtime'),
  z.literal('llsa'),
  z.literal('rop'),
  z.literal('crunchtimefm'),
  z.literal('ucmaximus'),
  z.literal('none')
]);

export type EpisodeTypeShortname = z.infer<typeof episodeTypeShortnameSchema>;
