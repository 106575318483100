import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import ConferenceMenuIcon from './ConferenceMenuIcon';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './ConferenceMenuItem.scss';

interface Props {
  url: string;
  name: string;
  title: string;
  className?: string;
}

export const ConferenceMenuItem = ({ url, name, title, className }: Props) => (
  <li className="conference-menu__list-item">
    <NavLink
      to={url}
      activeClassName="conference-menu__item--active"
      className={classNames(
        className,
        `conference-menu__item conference-menu__item--${name}`
      )}
      onClick={() =>
        reportLinkClickedAnalytics({
          to: url,
          linkText: name
        })
      }
    >
      <span className="conference-menu__item-title">{title}</span>
      <ConferenceMenuIcon name={name} />
    </NavLink>
  </li>
);

ConferenceMenuItem.defaultProps = {
  className: undefined
};

export default ConferenceMenuItem;
