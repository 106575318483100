import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import Icon from '../Icons/Icon';
import TextLink from '../TextLink/TextLink';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import { reportCarouselSwipedAnalytics } from '../../analytics/reportCarouselSwipedAnalytics';

import 'slick-carousel/slick/slick.css';
import './EpisodeFeed.scss';

const SLIDER_BREAKPOINTS = [
  {
    breakpoint: 1380,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 4
    }
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4
    }
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 2
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2.4,
      slidesToScroll: 2,
      arrows: false
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.9,
      slidesToScroll: 1,
      arrows: false
    }
  },
  {
    breakpoint: 320,
    settings: {
      slidesToShow: 1.6,
      slidesToScroll: 1,
      arrows: false
    }
  }
];

interface ArrowProps {
  direction: 'left' | 'right';
  onClick: () => void;
  className?: string;
  episodeType: string;
}

function Arrow({
  direction,
  className = '',
  onClick,
  episodeType
}: ArrowProps) {
  return (
    <button
      type="button"
      className={classNames(
        `sketchy-button--arrow-${direction}`,
        'episode-feed__button',
        `${episodeType}-feed__button`,
        className
      )}
      onClick={() => {
        reportButtonClickedAnalytics({
          buttonType: 'carousel arrow'
        });
        onClick();
      }}
      disabled={className.includes('slick-disabled')}
    >
      <Icon name="triangle-right" />
    </button>
  );
}

interface EpisodeFeedProps {
  title?: string;
  children: React.ReactNode;
  count?: number;
  link?: string;
  episodeType: string;
  to?: string;
  onClick?: () => void;
  page: string;
}

export const EpisodeFeed = ({
  episodeType,
  title,
  count,
  link,
  children,
  to,
  onClick = () => null,
  page
}: EpisodeFeedProps) => (
  <div className={classNames('episode-feed', `${episodeType}-feed`)}>
    {page === 'c3' && (
      <div className={`${episodeType}-feed__header episode-feed__header`}>
        <h2 className={`${episodeType}-feed__title episode-feed__title`}>
          {title}
        </h2>
        <div className={`${episodeType}-feed__count episode-feed__count`}>
          {count} {count === 1 ? 'Episode' : 'Episodes'}
        </div>
        <TextLink
          className={`${episodeType}-feed__link episode-feed__link sketchy-link--arrow-right`}
          iconName="triangle-right"
          href={to}
          onClick={onClick}
        >
          {link}
        </TextLink>
      </div>
    )}
    <Slider
      className={`${episodeType}-feed__slider episode-feed__slider`}
      dots={false}
      infinite={false}
      lazyLoad="ondemand"
      speed={500}
      slidesToShow={6}
      slidesToScroll={4}
      nextArrow={
        <Arrow direction="right" onClick={onClick} episodeType={episodeType} />
      }
      prevArrow={
        <Arrow direction="left" onClick={onClick} episodeType={episodeType} />
      }
      responsive={SLIDER_BREAKPOINTS}
      onSwipe={() => reportCarouselSwipedAnalytics({})}
    >
      {children}
    </Slider>
  </div>
);
