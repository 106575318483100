import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { getEpisodeTypeClass } from '../../lib/getEpisodeTypeClass';
import { getEpisodeTypeDisplay } from '../../lib/getEpisodeTypeDisplay';
import Icon from '../Icons/Icon';
import {
  setCurrentSearch as setCurrentSearchAction,
  setCurrentEpisodeType as setCurrentEpisodeTypeAction
} from '../../actions/filters';
import './EpisodeSearchBar.scss';
import { NONE } from '../../lib/episodeTypes';

export function EpisodeSearchBar({
  setCurrentSearch,
  setCurrentEpisodeType,
  episodeType
}) {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const labelText = `Search ${
    episodeType === NONE ? 'All' : getEpisodeTypeDisplay(episodeType)
  } Episodes`;

  const searchEpisodes = event => {
    event.preventDefault();
    setCurrentSearch(searchText);
    setCurrentEpisodeType(episodeType === NONE ? [] : [episodeType]);
    history.push('/search');
  };

  return (
    <form
      className={classnames(
        'search-bar',
        getEpisodeTypeClass(episodeType, 'search-bar')
      )}
      onSubmit={searchEpisodes}
    >
      <label htmlFor="search-input" className="visually-hidden">
        {labelText}
      </label>
      <input
        id="search-input"
        className={classnames(
          getEpisodeTypeClass(episodeType, 'search-bar__input'),
          'search-bar__input'
        )}
        placeholder={labelText}
        type="text"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
      />
      <button
        className={classnames(
          getEpisodeTypeClass(episodeType, 'search-bar__button'),
          'search-bar__button'
        )}
        type="submit"
      >
        <Icon
          isInteractive
          className={getEpisodeTypeClass(episodeType, 'search-bar__icon')}
          name="search"
        />
      </button>
    </form>
  );
}

EpisodeSearchBar.propTypes = {
  setCurrentSearch: PropTypes.func,
  setCurrentEpisodeType: PropTypes.func,
  episodeType: PropTypes.number.isRequired
};

EpisodeSearchBar.defaultProps = {
  setCurrentSearch: () => {},
  setCurrentEpisodeType: () => {}
};

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setCurrentSearch: setCurrentSearchAction,
      setCurrentEpisodeType: setCurrentEpisodeTypeAction
    },
    dispatch
  );

export default connect(null, mapDispatchtoProps)(EpisodeSearchBar);
