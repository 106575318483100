import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WoopWoopAllEvents } from '../schema/woopWoopAllEvents/woopWoopAllEvents';

export interface WoopWoopAllEventsState {
  isLoading: boolean;
  events: WoopWoopAllEvents | undefined;
  hasError: boolean;
}

const initialState: WoopWoopAllEventsState = {
  isLoading: false,
  events: undefined,
  hasError: false
};

export const allEventsSlice = createSlice({
  name: 'firebase/woop-woop-all-events',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    update: (state, action: PayloadAction<WoopWoopAllEvents>) => {
      state.events = action.payload;
    },
    remove: state => {
      state.events = undefined;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    }
  }
});
