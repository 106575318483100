import { track } from '@amplitude/analytics-browser';

interface ReportOpenEpisodeAnalyticsParams {
  episode: number;
  chapterCount: number;
  pageSpecialty?: string;
}

export function reportOpenEpisodeAnalytics({
  episode,
  chapterCount,
  pageSpecialty
}: ReportOpenEpisodeAnalyticsParams) {
  track('Open_Episode', {
    episode,
    chapter_count: chapterCount,
    page_specialty: pageSpecialty,
    path: window.location.pathname
  });
}
