import React from 'react';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from '../../lib/hooks';
import { FeatureBubble } from './FeatureBubble';
import { EmrapFeature } from './EmrapFeature';
import { PrimaryLinkButton } from './PrimaryLinkButton';
import { IconName } from './Icon';
import { MediaModal } from './MediaModal';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

import './FeaturesSection.scss';

export interface FeatureDetails {
  id: number;
  icon: IconName;
  img: string;
  artwork: string;
  bubbleFlipped: boolean;
  bubbleButtonText: string;
  bubbleText: string;
  mediaModalTitle: string;
  mediaModalSubtitle: string;
  mediaUrl: string;
  isVideo: boolean;
}

interface FeaturesSectionProps {
  isOpen: boolean;
  onModalClose: () => void;
  features: FeatureDetails[];
  featureSelected: number;
  featureOnClick: (index: number) => void;
  className?: string;
}

export function FeaturesSection({
  isOpen,
  onModalClose,
  features,
  featureSelected,
  featureOnClick,
  className
}: FeaturesSectionProps) {
  const account = useSelector(state => state.account.account);

  const { trialSubscriptionMessaging } = useFlags();

  const joinUrl = trialSubscriptionMessaging
    ? '/account/sign-up?specialty=uc'
    : 'urgent-care/subscribe';

  return (
    <section className={cn('uc-features-section', className)}>
      <div className="uc-features-section__header">
        <h2 className="uc-features-section__header-title">Lifelong Learning</h2>
        <p className="uc-features-section__header-text">
          Expand your Urgent Care practice with UC Max. Elevate patient care for
          years to come with a comprehensive suite of UC educational products.
          <br />
          One price, full access, always.
        </p>
      </div>
      <ul
        className="uc-features-section__feature-bubbles-container"
        aria-label="urgent care features"
      >
        {features.map(
          (
            { id, img, icon, bubbleFlipped, bubbleButtonText, bubbleText },
            index
          ) => (
            <FeatureBubble
              key={id}
              img={img}
              icon={icon}
              flipped={bubbleFlipped}
              buttonText={bubbleButtonText}
              text={bubbleText}
              onClick={() => featureOnClick(index)}
              className="uc-features-section__feature-bubble"
            />
          )
        )}
      </ul>
      <div className="uc-features-section__emrap-callout-container">
        <h2 className="uc-features-section__emrap-callout-title">
          What you get by joining
        </h2>
        <p className="uc-features-section__emrap-callout-text">
          You don’t have to choose: when you join UC Max, you get access to all
          of the educational services we’ve ever made. Customize your education
          with all of our offerings.
        </p>
        <ul
          className="uc-features-section__emrap-features-container"
          aria-label="emrap features"
        >
          <EmrapFeature
            icon="features-audio"
            title="Audio"
            text="Turn any space into your classroom with the most up-to-date clinical content, right in your ears."
            className="uc-features-section__emrap-feature"
          />
          <EmrapFeature
            icon="features-video"
            title="Video"
            text="Procedure videos, fundamentals courses, and live events that change your practice."
            className="uc-features-section__emrap-feature"
          />
          <EmrapFeature
            icon="features-textbook"
            title="Clinical Reference"
            text="CorePendium is the most trusted and reviewed clinical reference tool that is a must have on shift."
            className="uc-features-section__emrap-feature"
          />
          <EmrapFeature
            icon="features-literature-review"
            title="Literature Review"
            text="We’ve read all the most impactful journals so you don’t have to: get only the best, practice-changing information."
            className="uc-features-section__emrap-feature"
          />
        </ul>
        {account ? null : (
          <PrimaryLinkButton
            icon="arrow-right"
            text="Subscribe Now"
            href={getPhpSitePath(joinUrl)}
            className="uc-features-section__primary-button"
            onClick={() =>
              reportLinkClickedAnalytics({
                to: getPhpSitePath(joinUrl),
                linkText: 'subscribe now',
                pageSpecialty: 'UC'
              })
            }
          />
        )}
      </div>
      <MediaModal
        isOpen={isOpen}
        closeOnClick={onModalClose}
        featureOnClick={index => featureOnClick(index)}
        icons={features.map(feature => feature.icon)}
        activeIcon={features[featureSelected].icon}
        artwork={features[featureSelected].artwork}
        title={features[featureSelected].mediaModalTitle}
        subtitle={features[featureSelected].mediaModalSubtitle}
        mediaUrl={features[featureSelected].mediaUrl}
        prevOnClick={() => featureOnClick(featureSelected - 1)}
        nextOnClick={() => featureOnClick(featureSelected + 1)}
        isVideo={features[featureSelected].isVideo}
      />
    </section>
  );
}
