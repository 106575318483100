import { z } from 'zod';

const episodeTypeSchema = z.object({
  episode_type: z.number(),
  shortname: z.string()
});

const topicSchema = z.object({
  id: z.union([z.number(), z.string()]), // TODO: Restrict to z.string() only after removing the modernizedTags flag and we are using the new table for tags
  title: z.string(),
  isDisabled: z.boolean()
});

export const cmeFiltersOptionsSchema = z.object({
  program: z.array(episodeTypeSchema),
  topics: z.array(topicSchema)
});

export type CmeFiltersOptions = z.infer<typeof cmeFiltersOptionsSchema>;
