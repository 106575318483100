import React from 'react';
import cn from 'classnames';

import './FacultyHeadshot.scss';

interface FacultyHeadshotProps {
  imgSrc: string;
  imgAlt: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

export function FacultyHeadshot({
  imgSrc,
  imgAlt,
  onClick,
  disabled,
  className
}: FacultyHeadshotProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cn('uc-faculty-headshot', className)}
    >
      <div className="uc-faculty-headshot__img-container">
        <img src={imgSrc} alt={imgAlt} className="uc-faculty-headshot__img" />
      </div>
    </button>
  );
}
