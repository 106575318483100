import React, { ReactNode } from 'react';

import './MobileDropdownContainer.scss';

interface MobileDropdownContainerProps {
  children: ReactNode;
}

const MobileDropdownContainer = ({
  children
}: MobileDropdownContainerProps) => (
  <div className="mobile-dropdown-container">{children}</div>
);

export default MobileDropdownContainer;
