import { z } from 'zod';
import { accountCmeCreditSchema } from './accountCmeCredit';
import { metaSchema } from '../api/meta';

export const cmeCreditsResponseSchema = z.object({
  status: z.literal('success'),
  data: z.object({
    foundHoursPerTopic: z.record(z.string(), z.number()).optional(),
    results: z.array(accountCmeCreditSchema),
    totalRows: z.number(),
    totalCreditHours: z.number()
  }),
  meta: metaSchema.merge(z.object({ page: z.number(), pageSize: z.number() }))
});
