import { track } from '@amplitude/analytics-browser';
import { CarouselEventSource } from './reportCarouselSwipedAnalytics';

export type ButtonType =
  | 'confirm'
  | 'faculty'
  | 'view pricing'
  | 'view video'
  | 'carousel arrow'
  | 'carousel dot'
  | 'open menu'
  | 'close menu'
  | 'audience sort'
  | 'episode sort'
  | 'print citations'
  | 'view day'
  | 'clear all'
  | 'select'
  | 'select all'
  | 'unselect'
  | 'unselect all'
  | 'end session'
  | 'feature'
  | 'add comment'
  | 'claim cme'
  | 'get certificate';

interface ReportButtonClickedAnalyticsParams {
  buttonType: ButtonType;
  buttonText?: string;
  pageSpecialty?: string;
  eventSource?: string | CarouselEventSource;
}

export function reportButtonClickedAnalytics({
  buttonType,
  buttonText,
  pageSpecialty,
  eventSource
}: ReportButtonClickedAnalyticsParams) {
  track('Button_Clicked', {
    button_type: buttonType,
    button_text: buttonText?.toLowerCase(),
    page_specialty: pageSpecialty,
    event_source: eventSource,
    path: window.location.pathname
  });
}
