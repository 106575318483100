import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PrintWindow from './PrintWindow';
import CitationPrint from './CitationPrint';
import { ReactComponent as PrintIcon } from '../../../images/icons/icon-print.svg';
import {
  toggleSelectAll as toggleSelectAllAction,
  clearSelectedCitations as clearSelectedCitationsAction
} from '../../../actions/print';
import { reportButtonClickedAnalytics } from '../../../analytics/reportButtonClickedAnalytics';

import './PrintButtons.scss';

export function PrintButtons({
  toggleSelectAll,
  buttonText,
  elementsToPrint,
  searchString,
  clearSelectedCitations,
  selectAll
}) {
  const [print, setPrint] = useState(false);
  const searchRef = useRef(searchString);

  // clear the selected citations and select all toggle if a new search is performed
  const clearSelections = () => {
    if (searchRef.current !== searchString) {
      clearSelectedCitations();
      if (selectAll) {
        toggleSelectAll();
      }
    }
  };
  useEffect(clearSelections, [searchString]);

  return (
    <div className="print-buttons">
      {print && (
        <PrintWindow handleClose={() => setPrint(false)}>
          <h2 style={{ fontFamily: 'Arial', fontWeight: 'normal' }}>
            Search Citations for: {searchString}
          </h2>
          {elementsToPrint.reverse().map(data => (
            <CitationPrint key={data._id} data={data} /> // eslint-disable-line no-underscore-dangle
          ))}
        </PrintWindow>
      )}
      <div className="print-all">
        <label htmlFor="select_all_citations" className="select-all">
          <input
            onChange={() => {
              reportButtonClickedAnalytics({
                buttonType: selectAll ? 'unselect all' : 'select all'
              });
              toggleSelectAll();
            }}
            id="select_all_citations"
            type="checkbox"
            checked={selectAll}
          />
          <span className="circle-checkbox" />
          Select All
        </label>
      </div>
      <button
        type="button"
        onClick={() => {
          reportButtonClickedAnalytics({
            buttonType: 'print citations',
            buttonText: 'print selected citations'
          });
          setPrint(true);
        }}
        className="print-selected-button"
      >
        <PrintIcon className="print-selected-button__icon" />
        <span className="print-selected-button__text">{buttonText}</span>
      </button>
    </div>
  );
}

const mapStatetoProps = state => ({
  elementsToPrint: Object.values(state.print.selectedCitations),
  searchString: state.filters.currentSearch,
  selectAll: state.print.selectAll
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      toggleSelectAll: toggleSelectAllAction,
      clearSelectedCitations: clearSelectedCitationsAction
    },
    dispatch
  );

const PrintButtonsContainer = connect(
  mapStatetoProps,
  mapDispatchtoProps
)(PrintButtons);

PrintButtons.propTypes = {
  toggleSelectAll: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  elementsToPrint: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  searchString: PropTypes.string.isRequired,
  clearSelectedCitations: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired
};

export default PrintButtonsContainer;
