import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ stroke, strokeWidth, ...rest }) => (
  <svg viewBox="-1 -1 25 25" width="1em" height="1em" {...rest}>
    <g stroke={stroke} strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
      <path d="M15.533 15.34l-.334.371 5.848 5.266c.141-.014.217-.046.247-.079.036-.04.067-.138.071-.307l-5.832-5.251z" />
      <circle cx={9} cy={9} r={8} />
    </g>
  </svg>
);

SearchIcon.propTypes = {
  stroke: PropTypes.string.isRequired,
  strokeWidth: PropTypes.string.isRequired
};

export default SearchIcon;
