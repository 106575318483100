import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { clearValues } from '@appbaseio/reactivecore/lib/actions';
import { connect } from '@appbaseio/reactivesearch/lib/utils';
import { RootState } from '../../store';
import { ReactComponent as CloseIcon } from '../../images/icons/close-filters.svg';

import './ClearFiltersButton.scss';

interface ClearFiltersButtonProps {
  clearFilters: () => void;
}

export const ClearFiltersButton = ({
  clearFilters
}: ClearFiltersButtonProps) => (
  <button type="button" className="clear-filters-button" onClick={clearFilters}>
    <span className="clear-filters-button__text">Clear Filters</span>
    <CloseIcon className="clear-filters-button__icon" />
  </button>
);

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
) => ({
  clearFilters: () => dispatch(clearValues())
});

export default connect(undefined, mapDispatchToProps)(ClearFiltersButton);
