import React, { useState } from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import AttachmentResult from './AttachmentResult';
import { ChapterResult } from './ChapterResult';
import CitationResult from './CitationResult';
import { EpisodeResult } from './EpisodeResult';
import { CommentResult } from './CommentResult';
import Loader from '../Loader';
import { reportSearchSubmittedAnalytics } from '../../analytics/reportSearchSubmittedAnalytics';

import './Results.scss';

function Results({ children, sort, sortOpen, media, currentSearch }) {
  const [amplitudeSearchQuery, setAmplitudeSearchQuery] = useState('');

  const highlightedFields = {
    'episode.title': 'title',
    'episode.description': 'description',
    'chapter.title': 'title',
    'chapter.description': 'description',
    'citation.citation': 'title',
    'citation.abstract_xhtml': 'description',
    'attachment.title': 'title',
    'attachment.bodytext': 'description',
    'comment.bodytext': 'description'
  };

  const getHighlightedMatch = result => {
    Object.keys(highlightedFields).forEach(key => {
      if (key.match(new RegExp(result.type, 'i'))) {
        if (!result.highlight) {
          result.highlight = {}; // eslint-disable-line no-param-reassign
        }
        if (result[key]) {
          result.highlight[highlightedFields[key]] = result[key]; // eslint-disable-line no-param-reassign
        }
      }
    });
    return result;
  };

  return (
    <div className="result-list">
      {children}
      <ReactiveList
        componentId="results"
        className="result-list-container"
        dataField={sort.dataField}
        sortBy={sort.sortBy}
        scrollOnChange={false}
        renderNoResults={() => 'No results found.'}
        loader={<Loader />}
        onData={data => {
          // prevents event firing if search is empty
          // or filters are added to existing search
          if (currentSearch === '' || currentSearch === amplitudeSearchQuery)
            return;
          reportSearchSubmittedAnalytics({
            query: currentSearch,
            isCitationSearch: window.location.pathname === '/search/citations',
            results: data.resultStats.numberOfResults
          });
          setAmplitudeSearchQuery(currentSearch);
        }}
        react={{
          and: [
            'emrap-search-bar',
            'tag-select',
            'doc-type-select',
            'episode-type-select',
            'publish-date',
            'faculty-select',
            'citation-type',
            'availability',
            'exclude-comments',
            'citation-audience-filter'
          ],
          or: ['episode-date-query']
        }}
        pagination={false}
        innerClass={{
          list: 'result-list-container',
          pagination: 'result-list-pagination',
          resultsInfo: classnames('result-list-info', {
            closed: !sortOpen
          }),
          poweredBy: 'powered-by',
          sortOptions: 'sort-options',
          noResults: 'no-results'
        }}
        showResultStats={false}
        size={10}
        renderItem={result => {
          const key = result._id; // eslint-disable-line no-underscore-dangle
          const highlightedResult = getHighlightedMatch(result);
          // highlighted fields will be present in the form 'chapter.title': [highlighted text]
          switch (highlightedResult.type) {
            case 'episode':
              return (
                <EpisodeResult
                  key={key}
                  data={highlightedResult}
                  media={media}
                />
              );
            case 'chapter':
              return (
                <ChapterResult
                  key={key}
                  data={highlightedResult}
                  media={media}
                />
              );
            case 'citation':
              return (
                <CitationResult
                  key={key}
                  data={highlightedResult}
                  media={media}
                />
              );
            case 'attachment':
              return (
                <AttachmentResult
                  key={key}
                  data={highlightedResult}
                  media={media}
                />
              );
            case 'comment':
              return (
                <CommentResult
                  key={key}
                  data={highlightedResult}
                  media={media}
                />
              );
            default:
              return (
                <div className="result-item" key={key}>
                  <h2>{highlightedResult.type}</h2>
                </div>
              );
          }
        }}
      />
    </div>
  );
}

Results.propTypes = {
  media: PropTypes.shape({
    mobile: PropTypes.bool,
    desktop: PropTypes.bool,
    sm: PropTypes.bool,
    xs: PropTypes.bool
  }).isRequired,
  sortOpen: PropTypes.bool,
  sort: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    sortBy: PropTypes.string,
    dataField: PropTypes.string
  }).isRequired,
  children: PropTypes.node,
  currentSearch: PropTypes.string.isRequired
};

Results.defaultProps = {
  sortOpen: false,
  children: null
};

const mapStatetoProps = state => ({
  sortOpen: state.filters.searchSortOpen,
  sort: state.filters.searchSort,
  currentSearch: state.filters.currentSearch
});

export default connect(mapStatetoProps)(Results);
