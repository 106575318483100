import {
  CmeEvaluation,
  CmeEvaluationQuestion
} from '../schema/cme/cmeEvaluations';
import { CmeEvaluationResponse } from '../schema/cme/cmeEvaluationResponse';

export const hasAnsweredEvaluationQuestions = (
  evaluation: CmeEvaluation,
  evaluationResponse: CmeEvaluationResponse
) => {
  const chosenOptionIds = Object.values(evaluationResponse.questionResponse)
    .map(response => response.evaluation_question_option_id)
    .flat();

  return evaluation.questions.every((question: CmeEvaluationQuestion) => {
    const response = evaluationResponse.questionResponse[question.id];

    // question is optional, considered "answered"
    if (question.required === false) {
      return true;
    }

    // question not displayed, considered "answered"
    if (
      question.dependent_question_option_id !== null &&
      !chosenOptionIds.includes(question.dependent_question_option_id)
    ) {
      return true;
    }

    if (question.question_type === 'TYPE_TEXT') {
      return response.text_value !== null && response.text_value.trim() !== '';
    }

    if (
      question.question_type === 'TYPE_CHECKBOX' ||
      question.question_type === 'TYPE_RADIO'
    ) {
      return response.evaluation_question_option_id.length > 0;
    }

    // other questions types considered "answered" by default
    return true;
  });
};
