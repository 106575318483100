import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import DropdownButton from '../DropdownButton';

import config from '../../config';

import {
  setEpisodeSort as setSortAction,
  toggleEpisodeSort as toggleSortAction,
  setEpisodeSortOpen as setSortOpenAction,
  setAudience as setAudienceAction,
  toggleAudienceSelect as toggleAudienceSelectAction,
  setAudienceOpen as setAudienceOpenAction
} from '../../actions/filters';

import './EpisodeMobileDropdown.scss';
import '../RadioList.scss';

const { audienceOptions } = config;

const EpisodeMobileDropdown = ({
  currentSort,
  sortOptions,
  sortOpen,
  toggleSort,
  setSort,
  setSortOpen,
  audienceSelectOpen,
  setAudience,
  currentAudience,
  toggleAudienceSelect,
  setAudienceOpen,
  match
}) => {
  const height = `${document.body.scrollHeight}px`;

  const closeModal = () => {
    setSortOpen(false);
    setAudienceOpen(false);
  };
  return (
    <div className="episode-mobile-dropdown">
      {match.url === '/episode' && (
        <DropdownButton
          handleClick={toggleAudienceSelect}
          text={currentAudience.label}
          isOpen={audienceSelectOpen}
        />
      )}
      <div className="episode-mobile-dropdown__right">
        {sortOptions && (
          <DropdownButton
            className="sort-button"
            handleClick={toggleSort}
            text={currentSort.label}
            isOpen={sortOpen}
          />
        )}
      </div>
      <div
        className={classnames('episode-mobile-dropdown__modal-fader', {
          open: sortOpen || audienceSelectOpen
        })}
        style={{ height }}
        onClick={closeModal}
        role="presentation"
      >
        {/* Audience Select */}
        {audienceSelectOpen && (
          <AudienceSelect
            options={audienceOptions}
            currentAudience={currentAudience}
            setAudience={setAudience}
          />
        )}
        {/* Sort Select */}
        {sortOpen && sortOptions && (
          <SortList
            options={sortOptions}
            currentSort={currentSort}
            setSort={setSort}
          />
        )}
      </div>
    </div>
  );
};

const SortList = ({ options, currentSort, setSort }) => (
  <div className="episode-mobile-dropdown__list">
    <ul className="sort-options radio-list">
      {options.map(option => (
        <li
          key={`sort-select-${option.key}`}
          className={classnames('radio-list__option', {
            active: currentSort.key === option.key
          })}
        >
          <button
            type="button"
            className="radio-list__button"
            onClick={() => setSort(option)}
          >
            {option.label}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

const AudienceSelect = ({ options, setAudience, currentAudience }) => (
  <div className="episode-mobile-dropdown__list">
    <ul className="sort-options radio-list">
      {options.map(option => (
        <li
          key={`sort-select-${option.key}`}
          className={classnames('radio-list__option', {
            active: currentAudience.key === option.key
          })}
        >
          <button
            type="button"
            className="radio-list__button"
            onClick={() => setAudience(option)}
          >
            {option.label}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

EpisodeMobileDropdown.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string
    })
  ),
  currentSort: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  sortOpen: PropTypes.bool,
  toggleSort: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setSortOpen: PropTypes.func.isRequired,
  audienceSelectOpen: PropTypes.bool.isRequired,
  setAudience: PropTypes.func.isRequired,
  currentAudience: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  setAudienceOpen: PropTypes.func.isRequired,
  toggleAudienceSelect: PropTypes.func.isRequired
};

EpisodeMobileDropdown.defaultProps = {
  sortOptions: null,
  sortOpen: false
};

AudienceSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string
    })
  ).isRequired,
  setAudience: PropTypes.func.isRequired,
  currentAudience: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }).isRequired
};

SortList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string
    })
  ),
  currentSort: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  setSort: PropTypes.func.isRequired
};

SortList.defaultProps = {
  options: null
};

const mapStatetoProps = state => ({
  currentSort: state.filters.episodeSort,
  sortOpen: state.filters.episodeSortOpen,
  currentAudience: state.filters.audience,
  audienceSelectOpen: state.filters.audienceSelectOpen
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setSort: setSortAction,
      toggleSort: toggleSortAction,
      setSortOpen: setSortOpenAction,
      setAudience: setAudienceAction,
      toggleAudienceSelect: toggleAudienceSelectAction,
      setAudienceOpen: setAudienceOpenAction
    },
    dispatch
  );

export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(EpisodeMobileDropdown)
);
