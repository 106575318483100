import React from 'react';
import classnames from 'classnames';
import { CmeCredit } from '../../schema/cme/episodeCmeCreditResponse';
import { ChapterCmeLink } from './ChapterCmeLink';
import SubscriptionStatus from '../ChapterPage/SubscriptionStatus';
import activeQuizIcon from '../../images/icons/cme/quiz-icon--active.png';
import inactiveQuizIcon from '../../images/icons/cme/quiz-icon--inactive.png';
import activeEvaluationIcon from '../../images/icons/cme/evaluation-icon--active.png';
import inactiveEvaluationIcon from '../../images/icons/cme/evaluation-icon--inactive.png';
import activePrinterIcon from '../../images/icons/cme/printer-icon--active.png';
import inactivePrinterIcon from '../../images/icons/cme/printer-icon--inactive.png';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';

import './ChapterCme.scss';

interface ChapterCmeProps {
  cmeCredit: CmeCredit;
  episodeShortname: string;
  isPaidAndActive: boolean;
  isSignedIn: boolean;
  episodeType: EpisodeTypeShortname;
  shouldShowCmeLinks: boolean;
}

function getQuizStatus(hasQuiz: boolean, isComplete = false) {
  if (!hasQuiz) {
    return 'Quiz Not Required';
  }
  if (isComplete) {
    return 'Quiz Completed';
  }
  return 'Complete Quiz';
}

export const ChapterCme = ({
  cmeCredit,
  episodeShortname,
  isPaidAndActive,
  isSignedIn,
  episodeType,
  shouldShowCmeLinks
}: ChapterCmeProps) => {
  const links = [
    {
      id: 0,
      href: getPhpSitePath(
        `/episode/${episodeShortname}/cme/quiz/${cmeCredit.credit.id}`
      ),
      isActive:
        isPaidAndActive && cmeCredit.has_quiz && !cmeCredit.is_quiz_complete,
      activeImg: activeQuizIcon,
      inactiveImg: inactiveQuizIcon,
      alt: 'CME Quiz Icon',
      text: getQuizStatus(cmeCredit.has_quiz, cmeCredit.is_quiz_complete)
    },
    {
      id: 1,
      href: getPhpSitePath(
        `/episode/${episodeShortname}/cme/evaluation/${cmeCredit.credit.id}`
      ),
      isActive:
        isPaidAndActive &&
        (cmeCredit.has_quiz ? cmeCredit.is_quiz_complete : true) &&
        !cmeCredit.is_evaluation_complete,
      activeImg: activeEvaluationIcon,
      inactiveImg: inactiveEvaluationIcon,
      alt: 'CME Evaluation Icon',
      text: cmeCredit.is_evaluation_complete
        ? 'Evaluation Completed'
        : 'Complete Evaluation'
    },
    {
      id: 2,
      href: getPhpSitePath(
        `/account/certificate?selected[]=${cmeCredit.credit.id}`
      ),
      isActive: cmeCredit.is_credit_earned,
      activeImg: activePrinterIcon,
      inactiveImg: inactivePrinterIcon,
      alt: 'Printer Icon',
      text: 'Print Certificate'
    }
  ];

  return (
    <section
      className={classnames('chapter-cme', `chapter-cme--${episodeType}`)}
    >
      <SubscriptionStatus
        isSignedIn={isSignedIn}
        isPaidAndActive={isPaidAndActive}
        tabName="cme"
        episodeType={episodeType}
      />
      <p className="chapter-cme__cme-credits">
        {cmeCredit.credit.hours}{' '}
        <em>
          {cmeCredit.credit.hours === 1
            ? cmeCredit.providers[0].credit_title
            : cmeCredit.providers[0].credit_title_plural}
        </em>{' '}
        certified by {cmeCredit.providers[0].title}
      </p>
      {shouldShowCmeLinks && (
        <ul className="chapter-cme__links" data-testid="chapter-cme-links">
          {links.map(link => (
            <ChapterCmeLink
              key={link.id}
              href={link.href}
              isActive={link.isActive}
              activeImg={link.activeImg}
              inactiveImg={link.inactiveImg}
              alt={link.alt}
              text={link.text}
              episodeType={episodeType}
            />
          ))}
        </ul>
      )}
    </section>
  );
};
