import React, { useRef, useState, useEffect } from 'react';
import FilePlayer from 'react-player';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { WebEpisode } from '../../schema/webEpisode/webEpisode';
import { WebChapter } from '../../schema/webEpisode/webChapter';
import PlayerControlBar from './PlayerControlBar';
import Icon from '../Icons/Icon';
import arrayToList from '../../lib/arrayToList';
import SubscribeContent from './SubscribeContent';
import { useFirebasePlayback } from '../../hooks/useFirebasePlayback';
import {
  debouncedHandleSeekStartAnalytics,
  debouncedHandleSeekEndAnalytics
} from '../../lib/handleSeekingAnalytics';
import { reportPlaybackBeginAnalytics } from '../../analytics/reportPlaybackBeginAnalytics';
import { reportPlaybackSpeedChangedAnalytics } from '../../analytics/reportPlaybackSpeedChangedAnalytics';
import { reportPlayerPausedAnalytics } from '../../analytics/reportPlayerPausedAnalytics';
import { reportPlaybackResumedAnalytics } from '../../analytics/reportPlaybackResumedAnalytics';
import { reportEndPlayerAnalytics } from '../../analytics/reportEndPlayerAnalytics';
import { reportPlaybackSkippedAnalytics } from '../../analytics/reportPlaybackSkippedAnalytics';
import { reportPreviousContentOpenedAnalytics } from '../../analytics/reportPreviousContentOpenedAnalytics';
import { MediaReport } from '../../lib/types';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './EpisodePlayer.scss';

function getAudioPlayerSpeedPreferenceCookie(): number | undefined {
  const audioPlayerSpeedPreferenceCookie = Cookies.get(
    'audioPlaybackSpeedPreference'
  );
  if (audioPlayerSpeedPreferenceCookie === undefined) {
    return undefined;
  }
  return Number(audioPlayerSpeedPreferenceCookie);
}

interface EpisodePlayerProps {
  episode: WebEpisode;
  chapter: WebChapter;
  autoPlay: boolean;
  nursesEdition?: boolean;
  isEnabled?: boolean;
  isSignedIn?: boolean;
  accountId?: number | null;
}

function getChapterMedia(chapter: WebChapter, nursesEdition?: boolean) {
  const media = nursesEdition
    ? chapter.media.find(item => item.edition === 'rn')
    : chapter.media.find(item => item.isPrimary);

  return media || chapter.media[0];
}

function getChapterMediaByChapterShortname(
  episode: WebEpisode,
  chapterShortname: string,
  nursesEdition?: boolean
): number | undefined {
  const chapter = episode.chapters.find(
    currentChapter => currentChapter.shortname === chapterShortname
  );
  return chapter ? getChapterMedia(chapter, nursesEdition).id : undefined;
}

const EpisodePlayer = ({
  episode,
  chapter,
  autoPlay,
  nursesEdition,
  isEnabled = true,
  isSignedIn = false,
  accountId
}: EpisodePlayerProps) => {
  const history = useHistory();
  const [playing, setPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [previousPlaybackSpeed, setPreviousPlaybackSpeed] = useState(1);
  const [hasStartedPlayback, setHasStartedPlayback] = useState(false);

  const playerRef = useRef<FilePlayer>(null);
  const playPauseButtonRef = useRef<HTMLButtonElement>(null);
  const getCurrentPoint = () =>
    Math.round(playerRef.current?.getCurrentTime() ?? 0);

  const media = getChapterMedia(chapter, nursesEdition);
  const pageSpecialty = getPageSpecialty(episode.type);

  const {
    synchronizeFirebasePlayback,
    prepareMediaPlaybackReset,
    secondsPlayed,
    setSecondsPlayed,
    lastSavedPlaybackSeconds
  } = useFirebasePlayback(media, accountId, playerRef.current?.seekTo);

  const [duration, setDuration] = useState(media.duration || 0);

  const chapterIndex = episode.chapters.findIndex(
    item => item.id === chapter.id
  );
  const previousChapter =
    chapterIndex > 0 ? episode.chapters[chapterIndex - 1].shortname : undefined;
  const nextChapter =
    chapterIndex < episode.chapters.length - 1
      ? episode.chapters[chapterIndex + 1].shortname
      : undefined;

  const mediaReport: MediaReport = {
    id: media.id,
    chapterTitle: chapter.title,
    episodeTitle: episode.title,
    currentPoint: Math.round(secondsPlayed),
    duration: Math.round(duration),
    mediaType: 'audio'
  };

  const handlePlaybackSpeedAnalytics = () => {
    if (previousPlaybackSpeed !== playbackSpeed) {
      reportPlaybackSpeedChangedAnalytics({
        ...mediaReport,
        currentPoint: getCurrentPoint(),
        previousSpeed: previousPlaybackSpeed,
        selectedSpeed: playbackSpeed,
        pageSpecialty
      });

      // prepare for next speed change
      setPreviousPlaybackSpeed(playbackSpeed);
    }
  };

  function onPlay() {
    setPlaying(true);
    setHasStartedPlayback(true);
    reportPlaybackBeginAnalytics({
      ...mediaReport,
      currentPoint: getCurrentPoint(),
      pageSpecialty,
      isNursesEdition: nursesEdition
    });
  }

  function onPause() {
    setPlaying(false);
    reportPlayerPausedAnalytics({
      ...mediaReport,
      currentPoint: getCurrentPoint(),
      pageSpecialty
    });
  }

  function onResume() {
    setPlaying(true);
    reportPlaybackResumedAnalytics({
      ...mediaReport,
      currentPoint: getCurrentPoint(),
      pageSpecialty
    });
  }

  const onEnded = () => {
    reportEndPlayerAnalytics({
      ...mediaReport,
      currentPoint: getCurrentPoint(),
      pageSpecialty
    });
    synchronizeFirebasePlayback(0, true, true, false);
    if (autoPlay && nextChapter) {
      prepareMediaPlaybackReset(
        getChapterMediaByChapterShortname(episode, nextChapter, nursesEdition)
      );
      history.push(`/episode/${episode.shortname}/${nextChapter}`);
    } else {
      setPlaying(false);
    }
  };

  useEffect(() => {
    if (playerRef.current?.props.playing) {
      reportPlaybackBeginAnalytics({
        ...mediaReport,
        duration: media.duration,
        currentPoint: getCurrentPoint(),
        pageSpecialty,
        isAutoplay: true
      });
    } else {
      setHasStartedPlayback(false);
    }
  }, [chapter]);

  useEffect(() => {
    const audioPlayerSpeedPreference = getAudioPlayerSpeedPreferenceCookie();
    if (audioPlayerSpeedPreference === undefined) {
      return;
    }
    setPlaybackSpeed(audioPlayerSpeedPreference);
  }, []);

  return (
    <div
      className={classnames(
        'episode-player',
        `episode-player--${episode.type}`,
        {
          'episode-player--mini': nursesEdition
        }
      )}
    >
      {chapter.image !== null && Boolean(chapter.image) && (
        <>
          <div className="episode-player__background">
            <img src={chapter.image} alt="" />
          </div>
          <img
            className={classnames('episode-player__artwork', {
              'episode-player__artwork--disabled': !isEnabled
            })}
            src={chapter.image}
            alt="chapter-artwork"
          />
        </>
      )}
      {isEnabled ? (
        <button
          ref={playPauseButtonRef}
          type="button"
          className={classnames('episode-player__button', {
            'episode-player__play-button': !playing,
            'episode-player__pause-button': playing
          })}
          onClick={() => {
            playPauseButtonRef.current?.focus();
            if (!hasStartedPlayback) {
              onPlay();
            } else if (playing) {
              onPause();
            } else {
              onResume();
            }
          }}
        >
          {playing ? (
            <Icon isInteractive name="pause" />
          ) : (
            <Icon isInteractive name="play" />
          )}
        </button>
      ) : (
        <>
          {/* play button is hidden from interaction here since there is no on-page action that could change the button's state (user would have to sign up/sign in first) */}
          {/* alternative feedback is also already given through subscribe message */}
          <div
            data-testid="play-button--disabled"
            className="episode-player__button episode-player__play-button episode-player__play-button--disabled"
          >
            <Icon name="play" />
          </div>
          {!nursesEdition && (
            <div className="episode-player__subscribe-container">
              <div className="episode-player__subscribe-message-arrow" />
              <div className="episode-player__subscribe-message">
                <SubscribeContent
                  isSignedIn={isSignedIn}
                  isHDVideo={false}
                  className="episode-player__subscribe-message-links"
                  path={window.location.pathname}
                />
              </div>
            </div>
          )}
        </>
      )}
      {nursesEdition && (
        <div
          className="episode-player__edition-details"
          data-testid="edition-details"
        >
          <span className="episode-player__edition-title">
            {media.editionTitle} Commentary
          </span>
          <span className="episode-player__authors">
            {arrayToList(media.authors.map(author => author.fullname))}
          </span>
        </div>
      )}
      <FilePlayer
        url={media.uri}
        ref={playerRef}
        className="episode-player__player"
        playing={playing}
        onProgress={({ playedSeconds }) => {
          setSecondsPlayed(playedSeconds);
          synchronizeFirebasePlayback(playedSeconds, false, false, true);
        }}
        onPause={() =>
          synchronizeFirebasePlayback(secondsPlayed, false, true, false)
        }
        onDuration={secs => {
          setDuration(secs);
        }}
        onEnded={onEnded}
        playbackRate={playbackSpeed}
      />
      <PlayerControlBar
        duration={duration}
        episodeShortname={episode.shortname}
        episodeType={episode.type}
        nextChapterShortname={nextChapter}
        prevChapterShortname={previousChapter}
        secondsPlayed={secondsPlayed}
        onSkip={(nextChapterShortname, icon) => {
          prepareMediaPlaybackReset(
            getChapterMediaByChapterShortname(
              episode,
              nextChapterShortname,
              nursesEdition
            )
          );
          synchronizeFirebasePlayback(secondsPlayed, false, true, false);

          (icon === 'next'
            ? reportPlaybackSkippedAnalytics
            : reportPreviousContentOpenedAnalytics)({
            ...mediaReport,
            pageSpecialty
          });
        }}
        setSecondsPlayed={playedSeconds => {
          if (playerRef.current) {
            playerRef.current.seekTo(playedSeconds);
          }
          setSecondsPlayed(playedSeconds);
          synchronizeFirebasePlayback(playedSeconds, false, true, true);
          debouncedHandleSeekStartAnalytics({
            currentPoint: lastSavedPlaybackSeconds.current.seconds,
            episodeType: episode.type,
            mediaReport
          });
          debouncedHandleSeekEndAnalytics({
            currentPoint: playedSeconds,
            episodeType: episode.type,
            mediaReport
          });
        }}
        setSpeed={setPlaybackSpeed}
        handlePlaybackSpeedAnalytics={handlePlaybackSpeedAnalytics}
        speed={playbackSpeed}
        isEnabled={isEnabled}
      />
    </div>
  );
};

export default EpisodePlayer;
