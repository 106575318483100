import { z } from 'zod';
import { marketingSlotIdSchema } from './marketingSlotId';

export const MarketingSpotSchema = z.object({
  id: z.string(),
  headline: z.string().optional(),
  message: z.string(),
  ctaText: z.string(),
  link: z.string(),
  campaignColor: z.string(),
  imageSource: z.string(),
  slotIds: z.array(marketingSlotIdSchema),
  order: z.number().optional()
});

export type MarketingSpot = z.infer<typeof MarketingSpotSchema>;
