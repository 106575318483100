import React from 'react';
import classnames from 'classnames';
import { HDHeroLogo } from './HDHeroLogo';
import { ESVideoPlaylistChapterItem } from '../../schema/playlist/ESVideoPlaylistItem';
import { HDUCMaximusLogo } from './HDUCMaximusLogo';

import './HDHeroFeatureContent.scss';

interface Props {
  isUrgentCare?: boolean;
  chapter: ESVideoPlaylistChapterItem;
}

export function HDHeroFeatureContent({ isUrgentCare = false, chapter }: Props) {
  return (
    <div
      className={classnames('hd-hero-feature-content__container', {
        'hd-hero-feature-content__container--uc': isUrgentCare
      })}
    >
      <HDHeroLogo isUrgentCare={isUrgentCare} />
      {isUrgentCare && (
        <HDUCMaximusLogo
          to="/uc-dashboard"
          className="hd-hero-feature-content__uc-logo"
        />
      )}
      <strong
        className={classnames('hd-hero-feature-content__title-label', {
          'hd-hero-feature-content__title-label--uc': isUrgentCare
        })}
      >
        Featured
      </strong>
      <h1
        id="skip_to_content"
        className={classnames('hd-hero-feature-content__title', {
          'hd-hero-feature-content__title--short': chapter.title.length < 20,
          'hd-hero-feature-content__title--long': chapter.title.length > 50,
          'hd-hero-feature-content__title--uc': isUrgentCare
        })}
      >
        {chapter.title}
      </h1>
    </div>
  );
}
