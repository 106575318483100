import React from 'react';
import classnames from 'classnames';
import { AudienceOption } from '../../lib/types';

import './MobileDropdown.scss';

interface MobileDropdownListProps {
  options: AudienceOption[];
  setOption: (option: AudienceOption) => void;
  currentOption: AudienceOption;
}

export const MobileDropdownList = ({
  options,
  currentOption,
  setOption
}: MobileDropdownListProps) => (
  <div className="mobile-dropdown__list">
    <ul className="sort-options radio-list">
      {options.map(option => (
        <li
          key={`sort-select-${option.key}`}
          className={classnames('radio-list__option', {
            active: currentOption.key === option.key
          })}
        >
          <button
            type="button"
            className="radio-list__button"
            onClick={() => setOption(option)}
          >
            {option.label}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

export default MobileDropdownList;
