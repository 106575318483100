import { WebComment } from '../schema/webComment/webComment';

export interface WebCommentWithReplies extends WebComment {
  replies: WebCommentWithReplies[];
}

export default function organizeComments(comments: WebComment[]) {
  const commentsWithReplies: WebCommentWithReplies[] = comments
    .map(comment => ({
      ...comment,
      replies: []
    }))
    .sort(
      (commentA, commentB) =>
        new Date(commentA.createDate).getTime() -
        new Date(commentB.createDate).getTime()
    );

  // Add replies references
  commentsWithReplies.forEach(comment => {
    comment.replies = commentsWithReplies.filter(
      otherComment => otherComment.parent === comment.id
    );
  });

  // Return root commments
  return commentsWithReplies.filter(comment => comment.parent === null);
}
