import React from 'react';
import classnames from 'classnames';
import { HDHeaderBar } from '../HDNav/HDHeaderBar';
import { HDHeroBackground } from './HDHeroBackground';
import { HDHeroFeatureContent } from './HDHeroFeatureContent';
import { HDHeroFeatureVideo } from './HDHeroFeatureVideo';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { getHDPath } from '../../lib/getHDPath';
import Loader from '../Loader';
import { ESVideoPlaylistChapterItem } from '../../schema/playlist/ESVideoPlaylistItem';
import { reportFeaturedContentClickedAnalytics } from '../../analytics/reportFeaturedContentClickedAnalytics';

import './HDHero.scss';

interface Props {
  isLoading?: boolean;
  isUrgentCare?: boolean;
  defaultMenuState?: 'open' | 'closed';
  chapter?: ESVideoPlaylistChapterItem | null;
  className?: string;
}

export function HDHero({
  isLoading = true,
  isUrgentCare = false,
  chapter = null,
  defaultMenuState = 'closed',
  className
}: Props) {
  return (
    <div
      className={classnames('hd-hero__container', className, {
        'hd-hero__container--uc': isUrgentCare
      })}
    >
      <section className="hd-hero">
        <HDHeroBackground isUrgentCare={isUrgentCare} />
        <HDHeaderBar
          displayHDLogo={false}
          defaultMenuState={defaultMenuState}
          isUrgentCare={isUrgentCare}
        />
        {chapter && !isLoading ? (
          <div className="hd-hero__content">
            <HDHeroFeatureContent
              isUrgentCare={isUrgentCare}
              chapter={chapter}
            />
            <HDHeroFeatureVideo
              href={getPhpSitePath(
                isUrgentCare
                  ? getHDPath(isUrgentCare, chapter.url_path)
                  : `/episode/${chapter.url_path.slice('chapter/'.length)}`
              )}
              posterFrame={`${process.env.REACT_APP_CDN_BASE}${chapter.thumbnail['720'].path}`}
              duration={chapter.duration}
              onClick={() =>
                reportFeaturedContentClickedAnalytics({
                  episodeTitle: chapter.title,
                  pageSpecialty: isUrgentCare ? 'UC' : 'HD'
                })
              }
            />
          </div>
        ) : (
          <Loader className="hd-hero__loader" />
        )}
      </section>
    </div>
  );
}
