// temporary fallback page for when users try to access generated playlists on the web, which currently only exist on the apps
// TODO: remove this component once generated playlists are supported on the web
import React from 'react';
import { osName } from 'react-device-detect';
import { FeedbackBar } from './FeedbackBar';
import { getEmrapAppLinkForNonWebContent } from '../lib/getEmrapAppLinkForNonWebContent';

export function FeedbackPlaylistErrorPage() {
  const href = getEmrapAppLinkForNonWebContent(osName);

  // if the app store link is undefined (for OS other than Mac OS, iOS or Android), we don't want to show the CTA, but still inform the user of the app
  return href !== undefined ? (
    <FeedbackBar
      variant="neutral"
      format="page"
      headline="This playlist does not exist on web"
      message="If you want to listen to the playlist, check it out on the EM:RAP app available on iOS and Android."
      actionText="Get the App!"
      href={href}
    />
  ) : (
    <FeedbackBar
      variant="neutral"
      format="page"
      headline="This playlist does not exist on web"
      message="If you want to listen to the playlist, check it out on the EM:RAP app available on iOS and Android."
    />
  );
}
