import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useFirstChapterThumbnail } from '../../hooks/useFirstChapterThumbnail';
import { formatDateMonthYear } from '../../lib/formatDateMonthYear';
import Icon from '../Icons/Icon';

import './HDPlaylistThumbnail.scss';

interface Props {
  className?: string;
  title?: string;
  prefix?: string;
  imageUrl?: string | null;
  uploadDate?: string;
  to?: string;
  videoCount?: number;
  isChapterPage?: boolean;
  isRelatedPlaylist?: boolean;
  isSuggestionList?: boolean;
  isVideoReskin?: boolean;
  onClick: () => void;
}

export function HDPlaylistThumbnail({
  className,
  title = '',
  prefix = 'Playlist',
  videoCount = 0,
  to = '/',
  imageUrl,
  uploadDate,
  isChapterPage = false,
  isRelatedPlaylist = false,
  isSuggestionList = false,
  isVideoReskin = false,
  onClick
}: Props) {
  const [isDragging, setIsDragging] = useState(false);

  const thumbnail = imageUrl ?? useFirstChapterThumbnail(to);

  return (
    <Link
      to={to}
      className={classNames(className, 'hd-playlist-thumbnail', {
        'hd-playlist-thumbnail--chapter-page': isChapterPage,
        'hd-playlist-thumbnail--related-playlist': isRelatedPlaylist,
        'hd-playlist-thumbnail--suggestion-list': isSuggestionList
      })}
      onMouseDown={() => {
        setIsDragging(false);
      }}
      onDragStart={e => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onClick={e => {
        if (isDragging) {
          e.preventDefault();
          return;
        }
        onClick();
      }}
    >
      <div className="hd-playlist-thumbnail__container">
        <div className="hd-playlist-thumbnail__poster-frame">
          <div className="hd-playlist-thumbnail__image">
            <div className="hd-playlist-thumbnail__overlay">
              <div className="hd-playlist-thumbnail__overlay-text">
                <div className="hd-playlist-thumbnail__overlay-video-count-container">
                  <div className="hd-playlist-thumbnail__overlay-video-count">
                    <strong className="hd-playlist-thumbnail__overlay-video-count-number">
                      {videoCount}
                    </strong>{' '}
                    <span className="hd-playlist-thumbnail__overlay-video-count-text">
                      {videoCount === 1 ? 'Video' : 'Videos'}
                    </span>
                  </div>
                </div>
                <div className="hd-playlist-thumbnail__overlay-description">
                  <h2
                    className={classNames(
                      'hd-playlist-thumbnail__overlay-title',
                      {
                        'hd-playlist-thumbnail__overlay-title--long':
                          title.length > 9,
                        'hd-playlist-thumbnail__overlay-title--longer':
                          title.length > 20
                      }
                    )}
                  >
                    {title}
                  </h2>
                  <div className="hd-playlist-thumbnail__overlay-action">
                    <span className="hd-playlist-thumbnail__overlay-action-text sketchy-link--arrow-right">
                      See All <Icon name="triangle-right" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {isVideoReskin ? (
              <img
                width="16"
                height="9"
                loading="lazy"
                src={
                  thumbnail ||
                  'https://d140vvwqovffrf.cloudfront.net/app-resources/graphics/thumb-default.svg'
                }
                alt=""
                className="hd-playlist-thumbnail__image-element"
                style={thumbnail ? { opacity: 1 } : { opacity: 0.2 }}
              />
            ) : (
              <div
                className="hd-playlist-thumbnail__image-shim"
                style={
                  thumbnail
                    ? {
                        backgroundImage: `url(${thumbnail})`,
                        opacity: 1
                      }
                    : {}
                }
              />
            )}
          </div>
        </div>
        <div className="hd-playlist-thumbnail__description">
          <h3 className="hd-playlist-thumbnail__title">
            <span>
              <strong className="hd-playlist-thumbnail__title-label">
                {prefix}:&nbsp;
              </strong>
              {title}
            </span>
          </h3>
          {uploadDate && (
            <h4 className="hd-playlist-thumbnail__upload-date">
              Uploaded {formatDateMonthYear(uploadDate)}
            </h4>
          )}
        </div>
      </div>
    </Link>
  );
}
