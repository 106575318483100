export function isEmrapUrl(url: string) {
  const validPrefixes = [
    '/',
    'emrap.org',
    'www.emrap.org',
    'http://emrap.org',
    'https://emrap.org',
    'http://www.emrap.org',
    'https://www.emrap.org'
  ];

  return validPrefixes.some(prefix => url.startsWith(prefix));
}
