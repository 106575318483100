import {
  CmeEvaluationQuestionResponse,
  CmeEvaluationResponse
} from '../schema/cme/cmeEvaluationResponse';
import {
  CmeEvaluation,
  CmeEvaluationQuestion
} from '../schema/cme/cmeEvaluations';

export function getInitialEvaluationResponse(
  evaluation: CmeEvaluation
): CmeEvaluationResponse {
  return {
    providerId: evaluation.provider.id,
    questionResponse: evaluation.questions.reduce(
      (
        formAnswers: {
          [questionId: number]: CmeEvaluationQuestionResponse;
        },
        question: CmeEvaluationQuestion
      ) => {
        formAnswers[question.id] = {
          numeric_value: null,
          text_value: null,
          evaluation_question_option_id: []
        };
        return formAnswers;
      },
      {}
    )
  };
}
