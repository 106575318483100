import React from 'react';
import CrunchyQCallout from '../CrunchyQCallout';

import './EpisodeGuideSidebar.scss';

export default function CrunchTimeEmSidebar() {
  return (
    <nav className="episode-guide-sidebar filters-container">
      <CrunchyQCallout />
    </nav>
  );
}
