import React from 'react';
import classnames from 'classnames';
import { WebChapter } from '../../schema/webEpisode/webChapter';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import formatDuration from '../../lib/formatDuration';

import './PlaylistItem.scss';

interface PlaylistItemProps {
  chapter: WebChapter;
  chapterNumber: number;
  isSelected?: boolean;
  showThumbnail?: boolean;
  episodeType: EpisodeTypeShortname;
  isAbstract: boolean;
}

const PlaylistItem = ({
  chapter,
  chapterNumber,
  isSelected,
  showThumbnail,
  episodeType,
  isAbstract
}: PlaylistItemProps) => {
  const { duration } =
    chapter.media.find(audio => audio.isPrimary) || chapter.media[0];

  return (
    <div
      className={classnames(`playlist-item playlist-item--${episodeType}`, {
        'playlist-item--selected': isSelected
      })}
    >
      {chapter.thumbnail && showThumbnail ? (
        <div className="playlist-item__thumbnail">
          <img
            className="playlist-item__thumbnail-image"
            src={chapter.thumbnail}
            alt=""
          />
          {!isSelected && (
            <div className="playlist-item__thumbnail-number">
              {chapterNumber}
            </div>
          )}
        </div>
      ) : (
        <div
          className={classnames('playlist-item__chapter-number', {
            'playlist-item__chapter-number--abstract': isAbstract
          })}
        >
          {chapterNumber}
        </div>
      )}
      <div className="playlist-item__text">
        <div className="playlist-item__title-container">
          {chapter.isFree && (
            <span className="playlist-item__free-badge">Free audio</span>
          )}
          <span className="playlist-item__title">{chapter.title}</span>
        </div>
        <span className="playlist-item__duration">
          {formatDuration(duration)}
        </span>
      </div>
    </div>
  );
};

export default PlaylistItem;
