import React from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { resultTitle, resultDescription } from '../../lib/highlightedElements';
import { getEpisodeTypeClass } from '../../lib/getEpisodeTypeClass';
import config from '../../config';

import './EpisodeResult.scss';
import './PreviewResult.scss';

interface PreviewResultsEpisodeData {
  episode: {
    title: string;
    description: string;
    episode_type: {
      id: number;
      display: string;
    };
  };
}

interface PreviewResultProps {
  data: PreviewResultsEpisodeData;
  media: { xs: boolean };
}

const PreviewResult = ({ data, media }: PreviewResultProps) => {
  const {
    episode: { title, description, episode_type: episodeType }
  } = data;

  const artId = get(data, 'episode.artwork.id', null);
  const art = artId ? `${config.episodeArtUrl}/${artId}.jpg` : '';

  const classes = classnames(
    'result-item episode preview',
    getEpisodeTypeClass(episodeType.id)
  );

  return (
    <div className={classes}>
      <img src={art} alt="Episode Cover Art" />
      <div className="content">
        {resultTitle(title, { title: '', description: '' }, false)}
        {resultDescription(description, null, media.xs, 100)}
        <div className="preview-footer">Coming Soon!</div>
      </div>
    </div>
  );
};

export default PreviewResult;
