import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Modal } from '../Modal';
import { Heading } from '../Heading';
import { reportModalOpenedAnalytics } from '../../analytics/reportModalOpenedAnalytics';
import { LinkButton } from '../LinkButton';

import './MarketingSpotModal.scss';

type MarketingSpotModalProps = {
  modalId: string;
  report: () => void;
  campaignColor: string;
  headline?: string;
  message: string;
  ctaText: string;
  href: string;
  imgSrc: string;
};

const COOKIE_DURATION_IN_DAYS = 365;
export const VIEWED_MARKETING_MODALS_COOKIE_NAME = 'viewedMarketingModals';

function updateViewedMarketingModalsCookie(modalId: string) {
  const previousCookieValue = Cookies.get(VIEWED_MARKETING_MODALS_COOKIE_NAME);
  if (previousCookieValue === undefined) {
    Cookies.set(
      VIEWED_MARKETING_MODALS_COOKIE_NAME,
      JSON.stringify([modalId]),
      {
        expires: COOKIE_DURATION_IN_DAYS
      }
    );
    return;
  }
  Cookies.set(
    VIEWED_MARKETING_MODALS_COOKIE_NAME,
    JSON.stringify([...JSON.parse(previousCookieValue), modalId]),
    {
      expires: COOKIE_DURATION_IN_DAYS
    }
  );
}

export function MarketingSpotModal({
  campaignColor,
  headline,
  message,
  imgSrc,
  modalId,
  report,
  ctaText,
  href
}: MarketingSpotModalProps) {
  const [isModalOpen, setModalOpen] = useState(() => {
    const viewedMarketingModalsCookie = Cookies.get(
      VIEWED_MARKETING_MODALS_COOKIE_NAME
    );

    return viewedMarketingModalsCookie
      ? !JSON.parse(viewedMarketingModalsCookie).includes(modalId)
      : true;
  });

  const closeModalCallback = () => {
    updateViewedMarketingModalsCookie(modalId);
    setModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen === true) {
      reportModalOpenedAnalytics({
        modalType: 'marketing spot'
      });
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      closeButtonTheme="light"
      handleClose={closeModalCallback}
      modalClassName="marketing-spot-modal"
      sectionClassName="marketing-spot-modal__content"
      style={{
        content: {
          '--firestore--background--color': campaignColor
        }
      }}
    >
      <div className="marketing-spot-modal__text-content-container global-is-stack">
        {headline && (
          <Heading
            Tag="h2"
            className="marketing-spot-modal__headline"
            variant="xl"
          >
            {headline}
          </Heading>
        )}
        <p className="marketing-spot-modal__message">{message}</p>
        <LinkButton
          href={href}
          className="marketing-spot-modal__cta"
          variant="tertiary"
          onClick={() => {
            report();
            closeModalCallback();
          }}
          label={ctaText}
        />
      </div>

      <img src={imgSrc} alt="" className="marketing-spot-modal__image" />
    </Modal>
  );
}
