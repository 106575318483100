import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { ReactComponent as C3Logo } from '../../images/c3/hero/c3-logo-hero.svg';
import { ReactComponent as CPIcon } from '../../images/icons/icon-corependium.svg';
import FeaturedEpisodeCard from '../EpisodeCard/FeaturedEpisodeCard';
import EpisodeHeaderBar from '../EpisodeHeaderBar/EpisodeHeaderBar';
import Loader from '../Loader';
import { C3 } from '../../lib/episodeTypes';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { reportFeaturedContentClickedAnalytics } from '../../analytics/reportFeaturedContentClickedAnalytics';
import { ESC3PlaylistItem } from '../../schema/playlist/ESC3PlaylistItem';

import './C3Hero.scss';

interface C3HeroProps {
  isLoading: boolean;
  episode: ESC3PlaylistItem | null;
}

export function C3Hero({ isLoading, episode }: C3HeroProps) {
  return (
    <div className="c3-hero__container">
      <div className="c3-hero__container-bg">
        <section className="c3-hero">
          <Switch>
            <Route path="/c3/all-topics">
              <EpisodeHeaderBar
                episodeType={C3}
                displayLogo={false}
                defaultMenuState="open"
              />
            </Route>
            <Route path="*">
              <EpisodeHeaderBar episodeType={C3} displayLogo={false} />
            </Route>
          </Switch>
          <div className="c3-hero__content">
            <div className="c3-hero__details">
              <div className="c3-hero__details-top">
                <div className="c3-hero__logo-container">
                  <C3Logo className="c3-hero__logo" />
                </div>
                <div className="c3-hero__details-content">
                  <h1 className="c3-hero__title" id="skip_to_content">
                    <div className="c3-hero__title-letter">Comprehensive </div>
                    <div className="c3-hero__title-letter">Core </div>
                    <div className="c3-hero__title-letter">Curriculum</div>
                  </h1>
                  <p className="c3-hero__description">
                    An in-depth review of Emergency Medicine’s core knowledge
                    base
                  </p>
                </div>
              </div>
              <div className="c3-hero__details-bottom">
                <CPIcon className="c3-hero__icon-corependium" />
                <p className="c3-hero__bottom-description-text">
                  CorePendium chapters form the outline of each episode, with
                  real patient case presentations
                </p>
              </div>
            </div>
            {episode && !isLoading ? (
              <FeaturedEpisodeCard
                href={getPhpSitePath(`/${episode.url_path}`)}
                title={episode.title}
                imageUrl={
                  episode.thumbnail
                    ? `${process.env.REACT_APP_CDN_BASE}${episode.thumbnail['featured-episode-card'].path}`
                    : ''
                }
                itemCount={episode.item_count}
                duration={episode.duration}
                onClick={() =>
                  reportFeaturedContentClickedAnalytics({
                    episodeTitle: episode.title,
                    pageSpecialty: 'C3'
                  })
                }
              />
            ) : (
              <Loader className="c3-hero__loader" />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

C3Hero.propTypes = {
  isLoading: PropTypes.bool,
  episode: PropTypes.shape({
    title: PropTypes.string,
    url_path: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
      'featured-episode-card': PropTypes.shape({
        path: PropTypes.string
      })
    }),
    item_count: PropTypes.number,
    duration: PropTypes.number
  })
};

C3Hero.defaultProps = {
  isLoading: true,
  episode: null
};

export default C3Hero;
