import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Textarea from '../Textarea';
import useSendComment from '../../hooks/useSendComment';
import ConversationAvatar from './ConversationAvatar';
import SketchyButton from '../LandingPage/SketchyButton';
import formFieldErrorIcon from '../../images/icons/form-field-error.png';
import { ReplyingToLabel } from './ReplyingToLabel';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import { doScroll } from '../../hooks/useScrollToHash';
import { CommentStatus } from './CommentStatus';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './ConversationCommentForm.scss';

interface ConversationCommentFormProps {
  chapterId: number;
  episodeId: number;
  gravatarEmail: string | null;
  isReply: boolean;
  parentCommentId: number | null;
  replyingToName?: string;
  episodeType: EpisodeTypeShortname;
  isNewCommentSuccessful?: boolean;
}

export default function ConversationCommentForm({
  chapterId,
  episodeId,
  gravatarEmail,
  isReply,
  parentCommentId,
  replyingToName,
  episodeType,
  isNewCommentSuccessful
}: ConversationCommentFormProps) {
  const [commentMessage, setCommentMessage] = useState('');
  const [invalidComment, setInvalidComment] = useState(false);
  const { sendStatus, setSendStatus, sendComment, newCommentId } =
    useSendComment();
  const [isCommentOpen, setIsCommentOpen] = useState(!isReply);
  useEffect(() => {
    if (sendStatus === 'success' && newCommentId !== undefined) {
      doScroll(`${newCommentId}-comment`);
      setCommentMessage('');
    }
  }, [newCommentId, sendStatus]);

  if (!isCommentOpen || (sendStatus === 'success' && isReply)) {
    return (
      <div className="comment-form__form-button-reply-container">
        {isNewCommentSuccessful && (
          <CommentStatus message="Comment posted!" status="success" />
        )}
        <SketchyButton
          type="text"
          className="comment-form__form-button comment-form__form-button--reply"
          color="gray-inverse"
          onClick={() => {
            setIsCommentOpen(true);
            setSendStatus(undefined);
          }}
        >
          REPLY
        </SketchyButton>
      </div>
    );
  }

  return (
    <form
      className={classnames('comment-form', `comment-form--${episodeType}`, {
        'comment-form--no-reply': !isReply,
        'comment-form--reply-form': isReply
      })}
      id="comment-form"
      onSubmit={e => {
        e.preventDefault();
        if (commentMessage.trim() === '') {
          setInvalidComment(true);
        } else {
          sendComment({
            chapterId,
            bodyText: commentMessage,
            parentCommentId,
            episodeId
          });
        }
      }}
    >
      <ConversationAvatar gravatarEmail={gravatarEmail} />
      <div className="comment-form__textarea-container">
        <div className="comment-form__textarea-top-container">
          {replyingToName && <ReplyingToLabel name={replyingToName} />}
          {isReply && (
            <button
              type="button"
              className={classnames(
                'comment-form__form-button-close',
                episodeType &&
                  `comment-form__form-button-close--${[episodeType]}`
              )}
              onClick={() => setIsCommentOpen(false)}
            >
              Cancel Reply
            </button>
          )}
        </div>
        <Textarea
          id="comment"
          name="comment"
          form="comment-form"
          placeholder={`Type your ${isReply ? 'reply' : 'comment'}…`}
          disabled={sendStatus === 'processing'}
          onChange={e => {
            if (invalidComment) {
              setInvalidComment(false);
            }
            setCommentMessage(e.currentTarget.value);
          }}
          value={commentMessage}
          className={invalidComment ? 'textarea--error' : ''}
        />
        {invalidComment && (
          <div className="comment-form__error-message-container">
            <img src={formFieldErrorIcon} alt="error-form" />
            <p className="comment-form_error-message">
              Please enter your comment above.
            </p>
          </div>
        )}
        <div
          className={`comment-form__bottom-container comment-form__bottom-container--${episodeType}`}
        >
          {sendStatus === 'fail' && (
            <CommentStatus
              message="Posting Comment Failed. Please try again."
              status={sendStatus}
            />
          )}
          <SketchyButton
            type="submit"
            className="comment-form__form-button"
            color="gray-inverse"
            disabled={sendStatus === 'processing'}
            onClick={event => {
              reportButtonClickedAnalytics({
                buttonType: 'add comment',
                buttonText: event.target.innerText,
                pageSpecialty: getPageSpecialty(episodeType)
              });
            }}
          >
            ADD COMMENT
          </SketchyButton>
        </div>
      </div>
    </form>
  );
}
