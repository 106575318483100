import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Link as ReactLink } from 'react-router-dom';

type LinkProps = {
  className?: string;
  children: ReactNode;
  isMonoColor: boolean;
} & ({ to: string; href?: never } | { href: string; to?: never });

export function Link({
  to,
  href,
  children,
  className,
  isMonoColor = false
}: LinkProps) {
  return to !== undefined ? (
    <ReactLink
      to={to}
      className={classnames('global-link', className)}
      data-is-mono-color={isMonoColor}
    >
      {children}
    </ReactLink>
  ) : (
    <a
      href={href}
      className={classnames('global-link', className)}
      data-is-mono-color={isMonoColor}
    >
      {children}
    </a>
  );
}
