import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const startDate = new Date('2021-04-14T14:55:00Z');
// a few hours after the conference ends
export const endDate = new Date('2021-04-16T03:00:00Z');

const now = moment.utc().valueOf();

let currentStatus = 'during';
if (now < startDate.getTime()) {
  currentStatus = 'before';
} else if (now > endDate.getTime()) {
  currentStatus = 'after';
}

export const status = currentStatus;

const today = momentTimezone().tz('America/Los_Angeles').format('M/D/YYYY');

let link = '#register';
if (today === '4/14/2021') {
  link = 'https://youtu.be/L4zaxGs_p6Y';
} else if (today === '4/15/2021') {
  link = 'https://youtu.be/iAaZuVoTRtU';
}

export const streamingLink = link;
