import { useState, useEffect } from 'react';
import { useLoadVideoPlaylistQuery } from '../store/videoPlaylists';
import config from '../config';
import { isRelativeURL } from '../lib/isRelativeUrl';
import {
  ESVideoPlaylistChapterItem,
  ESVideoPlaylistItem
} from '../schema/playlist/ESVideoPlaylistItem';

const { cdnUrl } = config;

function getPlaylistShortname(urlPath: string) {
  const segments = urlPath.split('/');
  const [secondLastSegment = '', lastSegment = ''] = segments.slice(-2);

  if (secondLastSegment === 'episode') {
    return `${secondLastSegment}/${lastSegment}`;
  }

  return lastSegment;
}

function getThumbnailFromItem(item: ESVideoPlaylistChapterItem) {
  const path = item.thumbnail?.['480'].path;
  return isRelativeURL(path) ? `${cdnUrl}/${path}` : path;
}

export const useFirstChapterThumbnail = (urlPath: string) => {
  const [firstChapterThumbnail, setFirstChapterThumbnail] = useState<
    string | null
  >(null);
  const [nestedPlaylistShortname, setNestedPlaylistShortname] = useState<
    string | null
  >(null);
  const topLevelPlaylistShortname = getPlaylistShortname(urlPath);
  const { data: topLevelPlaylist } = useLoadVideoPlaylistQuery(
    topLevelPlaylistShortname
  );
  const { data: nestedPlaylist } = useLoadVideoPlaylistQuery(
    nestedPlaylistShortname ?? topLevelPlaylistShortname
  );

  function findFirstChapterThumbnail(item: ESVideoPlaylistItem) {
    if (item.type === 'chapter') {
      return getThumbnailFromItem(item);
    }
    // item is a playlist or episode
    setNestedPlaylistShortname(item.url_path);
    return null;
  }

  useEffect(() => {
    if (topLevelPlaylist !== undefined) {
      const firstItem = topLevelPlaylist?.items[0];
      if (firstItem === undefined) {
        return;
      }

      const thumbnail = findFirstChapterThumbnail(firstItem);
      if (thumbnail !== undefined) {
        setFirstChapterThumbnail(thumbnail);
      }
    }
  }, [topLevelPlaylist, topLevelPlaylistShortname]);

  useEffect(() => {
    if (nestedPlaylist !== null) {
      const nestedFirstItem = nestedPlaylist?.items[0];
      if (nestedFirstItem === undefined) {
        return;
      }

      const thumbnail = findFirstChapterThumbnail(nestedFirstItem);
      if (thumbnail) {
        setFirstChapterThumbnail(thumbnail);
      }
    }
  }, [nestedPlaylist]);

  return firstChapterThumbnail;
};
