import React from 'react';
import moment from 'moment-timezone';
import listify from 'listify';
import { getEpisodeTypeDisplay } from '../../lib/getEpisodeTypeDisplay';
import { useSelector } from '../../lib/hooks';

import './EpisodeFilterSummary.scss';

interface EpisodeFilterSummary {
  episodeTypes: number[];
  dateRange: {
    start: number;
    end: number;
  };
}

export function StatelessEpisodeFilterSummary({
  episodeTypes,
  dateRange
}: EpisodeFilterSummary) {
  const types = episodeTypes.map(id => getEpisodeTypeDisplay(id));
  const after = moment(dateRange.start).format('YYYY');
  const before = moment(dateRange.end).format('YYYY');
  return (
    <span className="episode-filter-summary">
      <span>
        {' '}
        in <strong>{listify(types)}</strong>
      </span>
      <span>
        {' '}
        from <strong>{after}</strong>
      </span>
      <span>
        {' '}
        to <strong>{before}</strong>
      </span>
    </span>
  );
}

export function EpisodeFilterSummary({
  episodeTypes
}: Pick<EpisodeFilterSummary, 'episodeTypes'>) {
  const dateRange = useSelector(state => state.page.dateRange);
  return (
    <StatelessEpisodeFilterSummary
      episodeTypes={episodeTypes}
      dateRange={dateRange}
    />
  );
}
