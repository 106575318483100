import React from 'react';
import cn from 'classnames';
import { reportFeaturedContentClickedAnalytics } from '../../analytics/reportFeaturedContentClickedAnalytics';
import { LinkModernizer } from '../LinkModernizer';

import './FeaturedContentCard.scss';

export interface FeaturedContentCardProps {
  className?: string;
  posterFrame: string;
  chapters?: number;
  category?: string;
  title: string;
  format: 'video' | 'audio';
  episodeUrl: string;
}

export function FeaturedContentCard({
  className,
  posterFrame,
  chapters,
  category,
  title,
  format,
  episodeUrl
}: FeaturedContentCardProps) {
  return (
    <LinkModernizer
      className={cn('uc-featured-content-card', className)}
      to={episodeUrl}
      href={episodeUrl}
      onClick={() => {
        reportFeaturedContentClickedAnalytics({
          episodeTitle: title,
          pageSpecialty: 'UC'
        });
      }}
    >
      <img
        className="uc-featured-content-card__posterframe"
        src={posterFrame}
        alt=""
        width="400"
        height="240"
      />
      <div className="uc-featured-content-card__details">
        {category && (
          <p className="uc-featured-content-card__category">{category}</p>
        )}
        <p
          className="uc-featured-content-card__title"
          data-content-has-no-chapters={
            format !== 'audio' || chapters === undefined
          }
        >
          {title}
        </p>
        {format === 'audio' && chapters && (
          <p className="uc-featured-content-card__chapters">
            {chapters} {chapters > 1 ? 'Chapters' : 'Chapter'}
          </p>
        )}
        <span className="uc-featured-content-card__format">{format}</span>
      </div>
    </LinkModernizer>
  );
}
