import { z } from 'zod';
import { episodeEmbeddedQuizSchema } from './episodeEmbeddedQuiz';
import { episodeTypeShortnameSchema } from './episodeType';
import { relatedEpisodeSchema } from './relatedEpisode';
import { timestampSchema } from '../timestamp';
import { webChapterSchema } from './webChapter';
import { webChapterListItemSchema } from './webChapterListItem';

export const webEpisodeSchema = z.object({
  id: z.number(),
  shortname: z.string(),
  title: z.string(),
  chapters: z.array(webChapterSchema),
  chapterList: z.array(webChapterListItemSchema),
  thumbnail: z.string().nullable(),
  description: z.string().optional(),
  type: episodeTypeShortnameSchema,
  modifiedDate: timestampSchema,
  publishedDate: timestampSchema,
  format: z.union([z.literal('audio'), z.literal('video')]),
  relatedEpisodes: z.array(relatedEpisodeSchema),
  embeddedQuizzes: z.array(episodeEmbeddedQuizSchema)
});

export type WebEpisode = z.infer<typeof webEpisodeSchema>;
