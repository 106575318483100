import React from 'react';
import cn from 'classnames';
import { Icon, IconName } from './Icon';
import './EmrapFeature.scss';

interface Props {
  icon: IconName;
  title: string;
  text: string;
  className?: string;
}

export function EmrapFeature({ icon, title, text, className }: Props) {
  return (
    <li className={cn('emrap-feature__container', className)}>
      <Icon name={icon} className="emrap-feature__icon" />
      <div className="emrap-feature__content">
        <h3 className="emrap-feature__title">{title}</h3>
        <p className="emrap-feature__text">{text}</p>
      </div>
    </li>
  );
}
