// This schema mirrors the one in the emrap-next project,
// so any changes made here should also be reflected in https://github.com/silverorange/emrap-next/blob/master/src/schema/cmeEvaluationQuestionEmrapClient.ts
import { z } from 'zod';

const cmeEvaluationQuestionTypeSchema = z.enum([
  'TYPE_RADIO',
  'TYPE_FLYDOWN',
  'TYPE_TEXT',
  'TYPE_CHECKBOX'
]);

const cmeEvaluationQuestionOptionsSchema = z.object({
  id: z.number(),
  question_id: z.number(),
  title: z.string().nullable(),
  display_order: z.number(),
  include_text: z.boolean()
});

export type CmeEvaluationQuestionOption = z.infer<
  typeof cmeEvaluationQuestionOptionsSchema
>;

const cmeEvaluationQuestionSchema = z.object({
  id: z.number(),
  body_text: z.string().nullable(),
  question_type: cmeEvaluationQuestionTypeSchema,
  display_order: z.number(),
  required: z.boolean(),
  dependent_question_option_id: z.number().nullable(),
  question_options: z.array(cmeEvaluationQuestionOptionsSchema)
});

export type CmeEvaluationQuestion = z.infer<typeof cmeEvaluationQuestionSchema>;

export const cmeEvaluationSchema = z.object({
  provider: z.object({ shortname: z.string(), id: z.number() }),
  questions: z.array(cmeEvaluationQuestionSchema)
});

export type CmeEvaluation = z.infer<typeof cmeEvaluationSchema>;

export const cmeEvaluationsSchema = z.array(cmeEvaluationSchema);
