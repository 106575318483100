import { z } from 'zod';
import { timestampSchema } from '../timestamp';

export const playbackSchema = z.object({
  account: z.number(),
  currentpoint: z.number(),
  highpoint: z.number(),
  media: z.number(),
  last_played: timestampSchema.optional()
});

export type Playback = z.infer<typeof playbackSchema>;
