import momentTimezone from 'moment-timezone';
import formatTimeRange from './formatTimeRange';

function formatDayDiff(day: number) {
  if (day > 0) {
    return `+${day}`;
  }
  if (day < 0) {
    return `${day}`;
  }
  return '';
}

export default function formatRelativeTimeRange(
  start: number,
  end: number | null,
  origTimeZone: string,
  relaTimeZone: string,
  showPeriod?: boolean
) {
  const time = end || start;
  const origTime = momentTimezone(time * 1000).tz(origTimeZone);
  const relaTime = momentTimezone(time * 1000).tz(relaTimeZone);

  const origStart = origTime.clone().utc(true).startOf('day');

  const relaStart = relaTime.clone().utc(true).startOf('day');

  // 'moment-timezone' will not always show the abbreviated time zone name,
  // instead, will show the time offsets for each region https://momentjs.com/timezone/docs/
  const isAbbreviationAnOffset = /^[+-][0-9]{2,4}$/.test(relaTime.format('z'));
  const suffix = `${
    isAbbreviationAnOffset
      ? relaTime.format('[GMT]Z').replace(/([+-])0/, '$1')
      : relaTime.format('z')
  } ${formatDayDiff(relaStart.diff(origStart, 'days'))}`.trim();

  return `${formatTimeRange(start, end, relaTimeZone, showPeriod)} ${suffix}`;
}
