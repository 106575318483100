import React from 'react';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import { ReactComponent as InformationIcon } from '../images/icons/icon-information.svg';
import { ReactComponent as ExclamationIcon } from '../images/icons/icon-warning.svg';
import { ReactComponent as CheckmarkIcon } from '../images/icons/icon-checkmark.svg';
import { Link } from './Link';
import { ButtonLink } from './ButtonLink';

import './_style/lib/components/FeedbackBar.scss';

// there should never be more than one overlay feedback component on a page
const modalParentSelector = 'feedback-bar';

export const ICON_MAP = {
  neutral: InformationIcon,
  positive: CheckmarkIcon,
  warning: ExclamationIcon,
  negative: ExclamationIcon
};

type Variant = 'negative' | 'warning' | 'positive' | 'neutral';
type Format = 'page' | 'overlay' | 'section' | 'inline' | 'form';

type FeedbackOptionalActionProps = {
  actionText?: string;
  onClick?: () => void;
  to?: string;
  href?: string;
};

type FeedbackProps = FeedbackOptionalActionProps & {
  className?: string;
  message: string | null;
  headline?: string;
  variant?: Variant;
  format?: Format;
};

type FeedbackNoActionProps = FeedbackProps & {
  actionText?: never;
  onClick?: never;
  to?: never;
  href?: never;
};

export type FeedbackActionButtonProps = FeedbackProps & {
  actionText: string;
  onClick: () => void;
};

export type FeedbackActionLinkProps = FeedbackProps & { actionText: string } & (
    | { to: string; href?: never }
    | { href: string; to?: never }
  );

function FeedbackOptionalAction({
  actionText,
  to,
  href,
  onClick
}: FeedbackOptionalActionProps): JSX.Element | null {
  if (actionText !== undefined) {
    if (to !== undefined) {
      return (
        <Link to={to} isMonoColor>
          {actionText}
        </Link>
      );
    }
    if (href !== undefined) {
      return (
        <Link href={href} isMonoColor>
          {actionText}
        </Link>
      );
    }
    if (onClick !== undefined) {
      return (
        <ButtonLink onClick={onClick} isMonoColor>
          {actionText}
        </ButtonLink>
      );
    }
  }
  return null;
}

export function FeedbackBar(props: FeedbackNoActionProps): JSX.Element;
export function FeedbackBar(props: FeedbackActionButtonProps): JSX.Element;
export function FeedbackBar(props: FeedbackActionLinkProps): JSX.Element;
export function FeedbackBar({
  className,
  message,
  headline,
  variant = 'neutral',
  format = 'inline',
  actionText,
  onClick,
  to,
  href
}: FeedbackProps) {
  const Icon = ICON_MAP[variant];
  const hasHeadline =
    format === 'page' || format === 'section' || format === 'overlay';

  if (format === 'overlay') {
    return (
      <div
        data-format={format}
        data-variant={variant}
        className="global-feedback-bar"
        data-testid="feedback-bar"
        id={modalParentSelector}
      >
        <ReactModal
          isOpen={message !== null}
          overlayClassName=".global-feedback-bar__modal-overlay"
          className="global-feedback-bar__content-container"
          parentSelector={() =>
            // required to inherit the css properies from feedback parent
            document.getElementById(modalParentSelector) as HTMLElement
          }
        >
          <h2 className="global-feedback-bar__title">
            <Icon
              aria-hidden
              data-testid="feedback-bar__icon"
              className="global-feedback-bar__icon"
            />
            {headline}
          </h2>
          <p className="global-feedback-bar__message">{message}</p>
          <FeedbackOptionalAction
            actionText={actionText}
            onClick={onClick}
            href={href}
            to={to}
          />
        </ReactModal>
      </div>
    );
  }

  return (
    <div
      data-format={format}
      data-variant={variant}
      className={classnames('global-feedback-bar', className, {
        'global-background-gradient': format === 'page'
      })}
      data-testid="feedback-bar"
    >
      {message !== null && (
        <div className="global-feedback-bar__content-container">
          {hasHeadline ? (
            <>
              <h2 className="global-feedback-bar__title">
                <Icon
                  aria-hidden
                  data-testid="feedback-bar__icon"
                  className="global-feedback-bar__icon"
                />
                {headline}
              </h2>
              <p className="global-feedback-bar__message">{message}</p>
            </>
          ) : (
            <p className="global-feedback-bar__message">
              <Icon
                aria-hidden
                data-testid="feedback-bar__icon"
                className="global-feedback-bar__icon"
              />
              {message}
            </p>
          )}
          <FeedbackOptionalAction
            actionText={actionText}
            onClick={onClick}
            href={href}
            to={to}
          />
        </div>
      )}
    </div>
  );
}
