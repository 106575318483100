import { Timestamp } from 'firebase/firestore';
import formatChapterBlocks from './formatChapterBlocks';
import { ChapterCommentStatus } from '../schema/webEpisode/webChapter';
import { WebEpisode } from '../schema/webEpisode/webEpisode';

function addChapterStatus(data: WebEpisode) {
  // TODO remove once Firebase is updated with commentStatus for all episodes
  data.chapters.forEach(chapter => {
    chapter.commentStatus = chapter.commentStatus || ChapterCommentStatus.OPEN;
  });

  return data;
}

function addUploadedAt(data: WebEpisode) {
  // TODO remove once Firebase is updated with chapter.uploaded_at for all episodes
  data.chapters.forEach(chapter => {
    chapter.uploaded_at = chapter.uploaded_at || new Timestamp(1234567, 12345);
  });

  return data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function formatWebEpisode(data: any) {
  if (!data.chapterList) {
    data.chapterList = [];
  }
  const preparedData = addChapterStatus(
    formatChapterBlocks(addUploadedAt(data))
  );

  return preparedData;
}
