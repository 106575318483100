import React from 'react';
import { SidebarLink } from './SidebarLink';
import Icon from '../Icons/Icon';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { EMA } from '../../lib/episodeTypes';

const EMACallout = () => (
  <SidebarLink
    to="/search/citations"
    buttonText="Go to Citation Search"
    icon={<Icon name="ema" />}
    text="Search thousands of emergency and primary care medical citations and abstract recordings."
    episodeType={EMA}
    onClick={() =>
      reportLinkClickedAnalytics({
        to: 'search/citations',
        linkText: 'go to citation search'
      })
    }
  />
);

export default EMACallout;
