import bytes from 'bytes';

const BYTES_IN_GB = 1024 * 1024 * 1024;

export default function formatBytes(numberOfBytes: number): string {
  return bytes.format(numberOfBytes, {
    decimalPlaces: numberOfBytes > BYTES_IN_GB ? 1 : 0,
    unitSeparator: ' '
  });
}
