import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StateProvider } from '@appbaseio/reactivesearch';
import { checkPropChange } from '@appbaseio/reactivecore/lib/utils/helper';
import get from 'lodash/get';
import MultiList from './MultiList';
import DropdownButton from '../../DropdownButton';

import './ShowMoreList.scss';

export function ShowMoreList(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { componentId, dataField } = props;
  const showDropdown = options.length !== 0;
  const size = open ? 100 : 6;

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="show-more-list">
      <StateProvider
        onChange={(prev, next) => {
          const prevAggs = get(prev, `[${componentId}].aggregations`);
          const nextAggs = get(next, `[${componentId}].aggregations`);
          checkPropChange(prevAggs, nextAggs, () => {
            const data = nextAggs[dataField];
            const buckets = typeof data === 'object' ? data.buckets : [];
            setOptions(buckets);
          });
        }}
      />
      <MultiList
        size={size}
        customQuery={() => ({
          aggs: {
            [dataField]: {
              terms: { field: dataField, size, order: { _count: 'desc' } }
            }
          }
        })}
        {...props}
      />
      {showDropdown && (
        <DropdownButton
          handleClick={toggleOpen}
          isOpen={open}
          text={open ? 'Show Fewer' : 'Show More'}
        />
      )}
    </div>
  );
}

ShowMoreList.propTypes = {
  listOptions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  dataField: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired
};

ShowMoreList.defaultProps = {
  listOptions: {}
};

export default ShowMoreList;
