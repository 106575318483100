import { z } from 'zod';

export const providerSchema = z.object({
  id: z.number(),
  shortname: z.string(),
  title: z.string(),
  credit_title: z.string(),
  credit_title_plural: z.string(),
  total_credits: z.number(),
  joint_accreditation: z.string().nullable(),
  active: z.boolean()
});

export type Provider = z.infer<typeof providerSchema>;
