import React from 'react';
import classnames from 'classnames';
import { useDismissAfterTime } from '../../hooks/useDismissAfterTime';

import './CommentStatus.scss';

interface CommentStatusProps {
  message: string;
  status?: 'fail' | 'success' | 'processing';
}

export function CommentStatus({ message, status }: CommentStatusProps) {
  const isDismissed = useDismissAfterTime(
    status !== undefined && status !== 'processing',
    7000
  );

  if (status === undefined || status === 'processing') {
    return null;
  }

  return (
    <div
      className={classnames('comment-status', {
        'comment-status--failed': status === 'fail',
        'comment-status--success': status === 'success',
        'comment-status--hidden': isDismissed && status !== 'fail'
      })}
    >
      <span className="comment-status__text">{message}</span>
    </div>
  );
}
