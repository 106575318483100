import React from 'react';
import classnames from 'classnames';

import './ColumnHeader.scss';

interface ColumnHeaderProps {
  className?: string;
  title: string;
  description?: string;
  logo?: string;
  doctors?: string;
  children?: React.ReactNode;
}

const ColumnHeader = ({
  className = '',
  title,
  description = '',
  logo,
  doctors,
  children
}: ColumnHeaderProps) => (
  <div className={classnames('page-header episode-header', className)}>
    <div className="page-header-contents">
      <div className="page-copy">
        <div className="page-copy-container">
          {logo && (
            <img
              src={logo}
              alt="" // text appears in the h1 below, so logo is purely decorative
              className={`page-logo ${className}-logo`}
            />
          )}
          <h1 id="skip_to_content" className="page-title">
            {title}
          </h1>
          <p className="page-intro">{description}</p>
        </div>
      </div>
      {doctors && (
        <img
          src={doctors}
          alt=""
          className={`page-image-doctors ${className}-doctors`}
        />
      )}
    </div>
    {children}
  </div>
);

export default ColumnHeader;
