import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as CameraIcon } from '../../images/icons/camera.svg';
import { getHDPath } from '../../lib/getHDPath';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './VideoHomeLink.scss';

interface Props {
  className?: string;
  isUrgentCare: boolean;
}

export function VideoHomeLink({ className = '', isUrgentCare }: Props) {
  return (
    <div
      className={classnames('video-home-link__container', className)}
      data-theme={isUrgentCare ? 'uc-max' : ''}
    >
      <Link
        className="video-home-link"
        to={getHDPath(isUrgentCare, '')}
        onClick={() =>
          reportLinkClickedAnalytics({
            to: getHDPath(isUrgentCare, ''),
            linkText: 'hd',
            pageSpecialty: isUrgentCare ? 'UC' : 'HD'
          })
        }
        aria-label={isUrgentCare ? 'Urgent Care Video' : 'Video'}
      >
        <CameraIcon width="47" height="32" aria-hidden="true" />
      </Link>
    </div>
  );
}
