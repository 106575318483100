import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { FacultyTooltip } from './FacultyTooltip';
import { PrimaryButton } from './PrimaryButton';
import { Faculty, FacultyModal } from './FacultyModal';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import './FacultySection.scss';

interface Props {
  isOpen: boolean;
  onModalClose: () => void;
  faculty: Faculty[];
  onClick: (faculty: number) => void;
  facultySelected: number;
  facultyOnClick: (faculty: number) => void;
  className?: string;
}

const FLIPPED_INDEX_TABLET = [0, 3, 4, 7, 9, 12, 14, 16, 17, 20, 23];
const FLIPPED_INDEX_DESKTOP = [0, 3, 7, 10, 12, 13, 16, 19, 23];

export function FacultySection({
  isOpen,
  onModalClose,
  faculty,
  onClick,
  facultySelected,
  facultyOnClick,
  className
}: Props) {
  const tablet = useMediaQuery({ query: '(max-width: 1023px)' });

  return (
    <section className={cn('uc-faculty-section', className)}>
      <div className="uc-faculty-section__header">
        <div className="uc-faculty-section__header-content">
          <h2 className="uc-faculty-section__title">UC Faculty</h2>
          <h3 className="uc-faculty-section__subtitle">Leaders in the Field</h3>
          <p className="uc-faculty-section__text">
            Physician-founded and run for over 20 years, we’ve assembled the
            best and brightest community of clinicians who are just like you:
            they care about patients.
          </p>
          <PrimaryButton
            icon="arrow-right"
            text="Read Profiles"
            onClick={() => {
              reportButtonClickedAnalytics({
                buttonType: 'faculty',
                buttonText: 'Read Profiles',
                pageSpecialty: 'UC'
              });
              onClick(facultySelected);
            }}
            className="uc-faculty-section__button"
          />
        </div>
      </div>
      <div className="uc-faculty-section__faculty">
        <ul
          className="uc-faculty-section__faculty-content"
          aria-label="urgent care faculty"
        >
          {faculty.map(({ id, title, text, imgSrc }, index) => (
            <FacultyTooltip
              key={id}
              title={title}
              text={text}
              onClick={() => facultyOnClick(index)}
              facultyOnClick={() => facultyOnClick(index)}
              imgSrc={imgSrc}
              flipped={
                tablet
                  ? FLIPPED_INDEX_TABLET.includes(index)
                  : FLIPPED_INDEX_DESKTOP.includes(index)
              }
              className="uc-faculty-section__tooltip"
            />
          ))}
        </ul>
      </div>
      <FacultyModal
        isOpen={isOpen}
        onModalClose={onModalClose}
        faculty={faculty}
        facultySelected={facultySelected}
        onFacultyChange={facultyOnClick}
      />
    </section>
  );
}
