import { z } from 'zod';

export const webTextBlockSchema = z.object({
  displayOrder: z.number(),
  bodyText: z.string(),
  type: z.literal('text')
});

export type WebTextBlock = z.infer<typeof webTextBlockSchema>;

export const webAttachmentBlockSchema = z.object({
  displayOrder: z.number(),
  title: z.string(),
  mimeType: z.string(),
  fileSize: z.number(),
  extension: z.string(),
  folder: z.string(),
  uri: z.string(),
  isSmartCard: z.boolean(),
  attachmentId: z.number(),
  type: z.literal('attachment')
});

export type WebAttachmentBlock = z.infer<typeof webAttachmentBlockSchema>;

export const webMediaBlockSchema = z.object({
  displayOrder: z.number(),
  title: z.string().nullable(),
  description: z.string(),
  imageUris: z.array(z.string()),
  videoKey: z.string(),
  type: z.literal('media')
});

export type WebMediaBlock = z.infer<typeof webMediaBlockSchema>;

export const webImageBlockSchema = z.object({
  displayOrder: z.number(),
  title: z.string(),
  description: z.string().nullable(),
  imageUris: z.array(z.string()),
  type: z.literal('image')
});

export type WebImageBlock = z.infer<typeof webImageBlockSchema>;

export const webChapterBlockSchema = z.discriminatedUnion('type', [
  webTextBlockSchema,
  webAttachmentBlockSchema,
  webMediaBlockSchema,
  webImageBlockSchema
]);

export type WebChapterBlock = z.infer<typeof webChapterBlockSchema>;

export const webChapterBlocksSchema = z.object({
  citation: z.string(),
  text: z.array(webTextBlockSchema),
  attachment: z.array(webAttachmentBlockSchema),
  media: z.array(webMediaBlockSchema),
  image: z.array(webImageBlockSchema)
});

export type WebChapterBlocks = z.infer<typeof webChapterBlocksSchema>;
