import React from 'react';
import classnames from 'classnames';
import Icon from '../Icons/Icon';
import { WebAttachmentBlock } from '../../schema/webEpisode/webChapterBlocks';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import formatBytes from '../../lib/formatBytes';
import config from '../../config';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './ChapterDownloadItem.scss';

const { cdnUrl } = config;

interface ChapterDownloadItemProps {
  downloadItem: WebAttachmentBlock;
  canDownload: boolean;
  episodeType: EpisodeTypeShortname;
  isFromSummary?: boolean;
}

function getIconName(extension: string) {
  const lowerCaseExtension = extension.toLowerCase();
  switch (lowerCaseExtension) {
    case 'm4a':
    case 'mp4':
    case 'mp3':
    case 'zip':
    case 'jpg':
    case 'png':
    case 'doc':
    case 'html':
    case 'pdf':
      return lowerCaseExtension;
    default:
      return 'default-extension';
  }
}

function getSrc(downloadItem: WebAttachmentBlock) {
  if (downloadItem.uri) {
    // is attachment from blocks section
    return `${cdnUrl}/${downloadItem.uri}`;
  }
  return `${cdnUrl}/attachments/${downloadItem.folder}/${downloadItem.attachmentId}.${downloadItem.extension}`;
}

export const ChapterDownloadItemBody = ({
  downloadItem,
  isFromSummary
}: {
  downloadItem: WebAttachmentBlock;
  isFromSummary?: boolean;
}) => (
  <>
    <Icon
      className="chapter-download-item__icon"
      name={getIconName(downloadItem.extension)}
    />
    <span>
      <span className="chapter-download-item__file-title">
        {downloadItem.title}
      </span>
      <span className="chapter-download-item__file-description">
        <span className="chapter-download-item__file-details">
          {formatBytes(downloadItem.fileSize)} -{' '}
          {downloadItem.extension === 'html'
            ? 'Web Page'
            : downloadItem.extension.toUpperCase()}
        </span>
        {isFromSummary && (
          <span className="chapter-download-item__file-download-text">
            download
          </span>
        )}
      </span>
    </span>
  </>
);

const ChapterDownloadItem = ({
  downloadItem,
  canDownload,
  episodeType,
  isFromSummary
}: ChapterDownloadItemProps) => {
  const episodeColorClassName = `chapter-download-item--${episodeType}`;
  const href = getSrc(downloadItem);

  if (!canDownload) {
    return (
      <div className="chapter-download-item--disabled">
        <ChapterDownloadItemBody downloadItem={downloadItem} />
      </div>
    );
  }

  return (
    <a
      className={classnames('chapter-download-item', episodeColorClassName)}
      href={href}
      onClick={() =>
        reportLinkClickedAnalytics({
          to: href,
          linkText: downloadItem.title,
          eventSource: 'download item',
          pageSpecialty: getPageSpecialty(episodeType)
        })
      }
    >
      <ChapterDownloadItemBody
        downloadItem={downloadItem}
        isFromSummary={isFromSummary}
      />
    </a>
  );
};

export default ChapterDownloadItem;
