import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './TableBody.scss';

interface TableBodyProps {
  children: ReactNode;
  className?: string;
  hasBorderRadius?: boolean;
}

export function TableBody({
  children,
  className,
  hasBorderRadius = false
}: TableBodyProps) {
  return (
    <tbody
      data-has-border-radius={hasBorderRadius}
      className={classnames('table-body', className)}
    >
      {children}
    </tbody>
  );
}
