/**
 * Generate a string containing random characters.
 * Radix 2 will generate a string of 0s and 1s.
 * Radix 10 will generate a string of numbers 0-9.
 * Radix 16 will generate a string of numbers 0-9 and letters a-f.
 * Radix 36 will generate a string of numbers 0-9 and letters a-z.
 * @param radix 2 for binary, 10 for decimal, 16 for hex, 36 for alphanumeric
 * @param length the number of characters in the sequence
 */
export function getRandomSequence(
  radix: 2 | 10 | 16 | 36 = 36,
  length: number
): string {
  let seq = '';
  while (seq.length < length) {
    seq += Math.random().toString(radix).substring(2, 15);
  }
  return seq.substring(0, length);
}

/**
 * Generate a string of 0s and 1s.
 * @param length specifies the number of characters in the sequence
 */
export function getRandomBinary(length = 8) {
  return getRandomSequence(2, length);
}

/**
 * Generate a string of numbers 0-9.
 * @param length specifies the number of characters in the sequence
 */
export function getRandomDecimal(length = 8) {
  return getRandomSequence(10, length);
}

/**
 * Generate a string of numbers 0-9 and letters a-f.
 * @param length specifies the number of characters in the sequence
 */
export function getRandomHex(length = 8) {
  return getRandomSequence(16, length);
}

/**
 * Generate a string of numbers 0-9 and letters a-z.
 * @param length specifies the number of characters in the sequence
 */
export function getRandomAlphanumeric(length = 8) {
  return getRandomSequence(36, length);
}
