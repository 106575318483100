import {
  SET_DATERANGE,
  SET_CURENT_SEARCH,
  SET_CURRENT_TAGS,
  SET_CURRENT_FACULTY,
  SET_CURRENT_EPISODE_TYPE,
  SET_CURRENT_DOCUMENT_TYPE,
  SET_CURRENT_IS_FREE_ONLY,
  SET_CITATION_TYPE,
  SET_EPISODE_SORT,
  SET_SEARCH_SORT,
  SET_EPISODE_SORT_OPEN,
  SET_SEARCH_SORT_OPEN,
  SET_AUDIENCE,
  TOGGLE_FILTERS,
  TOGGLE_EPISODE_SORT,
  TOGGLE_SEARCH_SORT,
  TOGGLE_AUDIENCE_SELECT,
  SET_AUDIENCE_OPEN,
  SET_MENUS_CLOSED
} from '../actions/filters';

import config from '../config';

const { dateSliderStart, dateSliderEnd } = config;

export const initialState = {
  documentType: [],
  dateRange: {
    start: dateSliderStart,
    end: dateSliderEnd
  },
  episodeType: [],
  isFreeOnly: null,
  currentSearch: '',
  tags: [],
  faculty: [],
  open: false,
  episodeSortOpen: false,
  searchSortOpen: false,
  citationType: null,
  episodeSort: config.episodeSortOptions.find(
    opt => opt.key === 'sort-by-newest'
  ),
  searchSort: config.searchSortOptions.find(
    opt => opt.key === 'sort-by-relevance'
  ),
  audience: config.audienceOptions.find(opt => opt.key === 'all-clinicians'),
  audienceSelectOpen: false
};

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATERANGE:
      return {
        ...state,
        dateRange: {
          start: action.start || dateSliderStart,
          end: action.end || dateSliderEnd
        }
      };
    case SET_CURENT_SEARCH:
      return {
        ...state,
        currentSearch: action.currentSearch
      };
    case SET_CURRENT_TAGS:
      return {
        ...state,
        tags: action.tags
      };
    case SET_CURRENT_FACULTY:
      return {
        ...state,
        faculty: action.faculty
      };
    case SET_CURRENT_EPISODE_TYPE:
      return {
        ...state,
        episodeType: action.episodeType
      };
    case SET_CURRENT_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.documentType
      };
    case SET_CURRENT_IS_FREE_ONLY:
      return {
        ...state,
        isFreeOnly: action.isFreeOnly
      };
    case SET_CITATION_TYPE:
      return {
        ...state,
        citationType: action.citationType
      };
    case SET_EPISODE_SORT:
      return {
        ...state,
        episodeSort: action.episodeSort
      };
    case SET_SEARCH_SORT:
      return {
        ...state,
        searchSort: action.searchSort
      };
    case SET_EPISODE_SORT_OPEN:
      return {
        ...state,
        episodeSortOpen: action.episodeSortOpen
      };
    case SET_SEARCH_SORT_OPEN:
      return {
        ...state,
        searchSortOpen: action.searchSortOpen
      };
    case SET_AUDIENCE:
      return {
        ...state,
        audience: action.audience
      };
    case TOGGLE_FILTERS:
      return {
        ...state,
        open: !state.open
      };
    case TOGGLE_EPISODE_SORT:
      return {
        ...state,
        episodeSortOpen: !state.episodeSortOpen
      };
    case TOGGLE_SEARCH_SORT:
      return {
        ...state,
        searchSortOpen: !state.searchSortOpen
      };
    case TOGGLE_AUDIENCE_SELECT:
      return {
        ...state,
        audienceSelectOpen: !state.audienceSelectOpen
      };
    case SET_AUDIENCE_OPEN:
      return {
        ...state,
        audienceSelectOpen: action.audienceSelectOpen
      };
    case SET_MENUS_CLOSED:
      return {
        ...state,
        audienceSelectOpen: action.audienceSelectOpen,
        episodeSortOpen: action.episodeSortOpen,
        searchSortOpen: action.searchSortOpen
      };
    default:
      return state;
  }
}
