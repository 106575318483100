import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import SketchyButton from './SketchyButton';
import { ReactComponent as IconFacebook } from '../../images/icons/social-icons/facebook.svg';
import { ReactComponent as IconX } from '../../images/icons/social-icons/X.svg';
import { ReactComponent as IconInstagram } from '../../images/icons/social-icons/instagram.svg';
import { ReactComponent as IconYouTube } from '../../images/icons/social-icons/youtube.svg';
import { ReactComponent as IconTikTok } from '../../images/icons/social-icons/tiktok.svg';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { reportNewsletterSubscribedAnalytics } from '../../analytics/reportNewsletterSubscribedAnalytics';
import { reportOpenPrivacyPolicyAnalytics } from '../../analytics/reportOpenPrivacyPolicyAnalytics';
import { reportOpenTermsAnalytics } from '../../analytics/reportOpenTermsAnalytics';

import './Footer.scss';

interface Props {
  className?: string;
}

const PRODUCTS = [
  { to: '/emrap', name: 'EM:RAP', php: false },
  { to: '/corependium', name: 'CorePendium', php: true },
  {
    to: '/uc-dashboard',
    name: 'UC Max',
    php: false
  },
  { to: '/c3', name: 'C3', php: false },
  { to: '/ema', name: 'EMA', php: false },
  { to: '/hd', name: 'Video', php: false },
  { to: '/hd/playlist/dailydose', name: 'Daily Dose', php: false },
  { to: '/event/grand-rounds', name: 'Grand Rounds', php: false },
  { to: '/crunchtime', name: 'Crunch Time EM', php: false },
  { to: '/events', name: 'Events', php: false }
];

const LINKS = [
  { to: '/about', name: 'About Us' },
  { to: '/contact', name: 'Contact Us' },
  { to: '/merch', name: 'Merch Store' },
  { to: '/coins', name: 'Challenge Coins' },
  { to: '/invite', name: 'Invite Friends' },
  { to: '/podcast', name: 'Podcast & App Setup' },
  { to: '/beta', name: 'Beta Signup' },
  { to: '/privacy', name: 'Privacy' },
  { to: '/terms', name: 'Terms' }
];

const SOCIALS = [
  {
    to: 'https://www.facebook.com/EmergencyMedicine/',
    icon: <IconFacebook className="landing-page-footer__social-icon" />,
    name: 'Facebook'
  },
  {
    to: 'https://www.instagram.com/emrapshow/',
    icon: <IconInstagram className="landing-page-footer__social-icon" />,
    name: 'Instagram'
  },
  {
    to: 'https://mobile.twitter.com/emrap_tweets',
    icon: <IconX className="landing-page-footer__social-icon" />,

    name: 'X'
  },
  {
    to: 'https://www.youtube.com/user/EMRAPMEDICAL',
    icon: <IconYouTube className="landing-page-footer__social-icon" />,
    name: 'YouTube'
  },
  {
    to: 'https://www.tiktok.com/@emrap',
    icon: <IconTikTok className="landing-page-footer__social-icon" />,
    name: 'TikTok'
  }
];

export function Footer({ className }: Props) {
  const isDesktop = useMediaQuery({ minWidth: '768px' });

  return (
    <footer className={cn('landing-page-footer', className)}>
      <div className="container">
        <div className="landing-page-footer__main-columns">
          <div className="landing-page-footer__primary">
            <div className="landing-page-footer__links">
              <div className="landing-page-footer__link-group">
                <h3 className="landing-page-footer__heading">Products</h3>
                <ul className="landing-page-footer__list">
                  {PRODUCTS.map(({ to, name, php }) => (
                    <li key={to} className="landing-page-footer__list-item">
                      <Link
                        className="landing-page-footer__link"
                        to={php ? getPhpSitePath(to) : to}
                        onClick={() => {
                          reportLinkClickedAnalytics({
                            to,
                            linkText: name
                          });
                        }}
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="landing-page-footer__link-group">
                <h3 className="landing-page-footer__heading">Links</h3>
                <ul className="landing-page-footer__list">
                  {LINKS.map(({ to, name }, index) => {
                    if (index === LINKS.length - 1) return null;
                    return (
                      <li key={to} className="landing-page-footer__list-item">
                        <a
                          className="landing-page-footer__link"
                          href={getPhpSitePath(to)}
                          onClick={() => {
                            if (name === 'Privacy') {
                              reportOpenPrivacyPolicyAnalytics();
                            } else {
                              reportLinkClickedAnalytics({
                                to,
                                linkText: name
                              });
                            }
                          }}
                        >
                          {name}
                        </a>
                        {index === LINKS.length - 2 && (
                          <>
                            {' / '}
                            <a
                              className="landing-page-footer__link"
                              href={getPhpSitePath(LINKS[LINKS.length - 1].to)}
                              onClick={() => reportOpenTermsAnalytics()}
                            >
                              {LINKS[LINKS.length - 1].name}
                            </a>
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {isDesktop && (
              <ul className="landing-page-footer__social-links">
                {SOCIALS.map(({ to, icon, name }) => (
                  <li
                    key={to}
                    className="landing-page-footer__social-links-item"
                  >
                    <a
                      href={to}
                      className="landing-page-footer__social-link"
                      onClick={() => {
                        reportLinkClickedAnalytics({
                          to,
                          linkText: name,
                          isExternal: true
                        });
                      }}
                      rel="nofollow"
                    >
                      {icon}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="landing-page-footer__secondary">
            <div>
              <form
                method="post"
                acceptCharset="utf-8"
                action="newsletter"
                className="landing-page-footer__newsletter-form"
                onSubmit={() => reportNewsletterSubscribedAnalytics()}
              >
                <label
                  htmlFor="email"
                  className="landing-page-footer__newsletter-email-label"
                >
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    className="landing-page-footer__newsletter-email"
                    placeholder="Email Address"
                  />
                </label>
                <input
                  id="newsletter_submit"
                  name="newsletter_submit"
                  type="hidden"
                  value="Subscribe"
                />
                <input
                  id="_swat_form_process"
                  name="_swat_form_process"
                  type="hidden"
                  value="newsletter_form"
                />
                <SketchyButton
                  color="teal-light"
                  type="submit"
                  className="landing-page-footer__newsletter-submit"
                >
                  Subscribe
                </SketchyButton>
              </form>
              {!isDesktop && (
                <ul className="landing-page-footer__social-links">
                  {SOCIALS.map(({ to, icon, name }) => (
                    <li
                      key={to}
                      className="landing-page-footer__social-links-item"
                    >
                      <a
                        href={to}
                        className="landing-page-footer__social-link"
                        onClick={() => {
                          reportLinkClickedAnalytics({
                            to,
                            linkText: name,
                            isExternal: true
                          });
                        }}
                        rel="nofollow"
                      >
                        {icon}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="landing-page-footer__slogan-mel-container">
              <p className="landing-page-footer__slogan">
                What you do matters.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
