import moment from 'moment';

export function getDaysToExpiry(expiryDate: string): number {
  const momentExpiryDate = moment(expiryDate);
  const currentDate = moment.utc().valueOf();

  return Math.max(
    0,
    Math.ceil(momentExpiryDate.diff(currentDate, 'days', true))
  );
}
