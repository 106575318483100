import { EMRAP, C3, EMA, LLSA, NONE } from './episodeTypes';
import { getEpisodeTypeDisplay } from './getEpisodeTypeDisplay';

export function getEpisodeTypeLongDisplay(episodeType = NONE) {
  switch (episodeType) {
    case EMRAP:
      return 'Emergency Medicine: Reviews and Perspectives';
    case EMA:
      return 'Emergency Medical Abstracts';
    case C3:
      return 'Comprehensive Core Curriculum';
    case LLSA:
      return 'Lifelong Learning and Self-Assessment';
    default:
      return getEpisodeTypeDisplay(episodeType);
  }
}
