import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './ConferenceButton.scss';

interface Props {
  children: ReactNode;
  className?: string;
  onClick: () => void;
}

const ConferenceButton = ({ children, className, onClick }: Props) => (
  <button
    type="button"
    className={classnames('conference-button', className)}
    onClick={onClick}
  >
    <span className="conference-button__text">{children}</span>
  </button>
);

ConferenceButton.defaultProps = {
  className: undefined
};

export default ConferenceButton;
