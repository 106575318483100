import React, { useState, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { PimFrontMatter } from './PimFrontMatter';
import { FeedbackBar } from '../FeedbackBar';
import { FrontMatter } from '../../schema/cme/frontMatter';
import { getFrontMatterContent } from './getFrontMatterContent';

import './FrontMatterModal.scss';

interface Props {
  isOpen: boolean;
  handleAttestCme: () => void;
  episodeId: number;
  isHDEpisode?: boolean;
  isUrgentCare?: boolean;
  hasCmeError: boolean;
}

export function FrontMatterModal({
  isOpen,
  handleAttestCme,
  episodeId,
  isHDEpisode,
  isUrgentCare,
  hasCmeError
}: Props) {
  const [frontMatterContent, setFrontMatterContent] = useState<FrontMatter>();
  const [hasError, setHasError] = useState(false);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getFrontMatterContent(episodeId, setFrontMatterContent, setHasError);
  }, []);

  useEffect(() => {
    if (hasCmeError) {
      setHasError(true);
    }
  }, [hasCmeError]);

  let frontMatter;
  switch (frontMatterContent?.providers[0]) {
    case 'pim-2022':
      frontMatter = (
        <PimFrontMatter
          episodeTitle={frontMatterContent.episode_title}
          releaseDate={frontMatterContent.release_date}
          expiryDate={frontMatterContent.expiry_date}
          contentXhtml={frontMatterContent.content_xhtml}
        />
      );
      break;
    default:
      frontMatter = null;
  }

  let returnLink;
  if (!isHDEpisode) {
    returnLink = '/episode';
  } else {
    returnLink = isUrgentCare ? '/urgent-care/hd' : '/hd';
  }

  return (
    <ReactModal
      shouldFocusAfterRender={false}
      onAfterOpen={() => {
        if (scrollableContainerRef.current) {
          scrollableContainerRef.current.focus();
        }
      }}
      isOpen={isOpen}
      overlayClassName="front-matter-modal__overlay"
      className={{
        base: 'front-matter-modal',
        afterOpen: 'front-matter-modal--after-open',
        beforeClose: 'front-matter-modal--before-close'
      }}
    >
      <p className="front-matter-modal__header">
        Before you view {frontMatterContent?.episode_title}, please attest to
        reading the following:
      </p>
      <div className="front-matter-modal__front-matter-container">
        {hasError ? (
          <div className="front-matter-modal__error">
            <FeedbackBar
              variant="negative"
              message="There seems to be an error loading data."
              actionText="Try refreshing again."
              onClick={() => window.location.reload()}
            />
          </div>
        ) : (
          <div
            ref={scrollableContainerRef}
            tabIndex={-1}
            className="front-matter-modal__content"
          >
            {frontMatter}
          </div>
        )}
      </div>
      <div className="front-matter-modal__buttons">
        {frontMatterContent && (
          <button
            type="submit"
            className="front-matter-modal__continue-button"
            onClick={handleAttestCme}
          >
            I Have Read the CME Information / Continue
          </button>
        )}
        <Link to={returnLink} className="front-matter-modal__cancel-button">
          Cancel and Return
        </Link>
      </div>
    </ReactModal>
  );
}
