import { track } from '@amplitude/analytics-browser';

export function reportPlaylistOpenedAnalytics({
  playlist,
  itemCount,
  pageSpecialty,
  eventSource
}: {
  playlist: string;
  itemCount: number;
  pageSpecialty?: string;
  eventSource?: string;
}) {
  track('Playlist_Opened', {
    playlist,
    item_count: itemCount,
    page_specialty: pageSpecialty,
    event_source: eventSource,
    path: window.location.pathname,
    // is_custom value is passed for web + app event parity
    is_custom: false
  });
}
