import React, { forwardRef } from 'react';
import classnames from 'classnames';

import './_style/lib/components/Input.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  type?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  variant?: 'default' | 'dropdown-search';
  className?: string;
  hasError?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      type = 'text',
      inputMode = 'text',
      value,
      onChange,
      placeholder,
      variant = 'default',
      className,
      hasError = false,
      ...ariaProps
    },
    ref
  ) => (
    <input
      ref={ref}
      id={id}
      type={type}
      inputMode={inputMode}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={classnames('global-input', className)}
      data-variant={variant}
      data-has-error={hasError}
      {...ariaProps}
    />
  )
);
