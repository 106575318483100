import React from 'react';
import classnames from 'classnames';
import { ReactComponent as HD } from '../../images/hd/logo.svg';
import { ReactComponent as UCHD } from '../../images/urgent-care/hd/uc-hd__hero-logo.svg';

import './HDHeroLogo.scss';

interface Props {
  isUrgentCare?: boolean;
  className?: string;
}

export function HDHeroLogo({ isUrgentCare = false, className }: Props) {
  return isUrgentCare ? (
    <>
      <span className="visually-hidden">Urgent Care HD</span>
      <UCHD
        aria-hidden="true"
        className={classnames('hd-hero-logo', className)}
      />
    </>
  ) : (
    <>
      <span className="visually-hidden">HD</span>
      <HD
        aria-hidden="true"
        className={classnames('hd-hero-logo', className)}
      />
    </>
  );
}
