import moment from 'moment';

/**
 * Formats a date as a month and year
 *
 * @param {string} date the date to format.
 *
 * @returns {string} the formatted date.
 */
export function formatDateMonthYear(date: string): string {
  const m = moment(date);
  return m.format('MMMM YYYY');
}
