import { useEffect, useRef } from 'react';
import scrollToSection from '../lib/scrollToSection';

export function doScroll(id: string, retryCount = 1) {
  const element = document.getElementById(id);
  // used to scroll so that the section is right below the nav
  if (element) {
    scrollToSection(element);
  } else if (retryCount < 10) {
    setTimeout(() => doScroll(id, retryCount + 1), 200 * retryCount);
  }
}

export default function useScrollToHash() {
  const { hash } = window.location;
  const initialRender = useRef(true);
  useEffect(() => {
    if (hash && initialRender.current) {
      initialRender.current = false;
      doScroll(hash.substring(1));
    }
  }, [hash]);
}
