import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { CmeGenericDisclosureModal } from '../CmeCreditsPage/CmeGenericDisclosureModal';
import { fetchApi } from '../../lib/fetchApi';
import {
  CmeGenericDisclosure,
  genericDisclosureSchema,
  CmeTemporaryDisclosure,
  temporaryDisclosureSchema
} from '../../schema/cme/cmeGenericDisclosure';
import NotFound from '../NotFound';
import Loader from '../Loader';

export function PreviewDisclosurePage() {
  const { id, idType } = useParams<{
    id: string;
    idType: 'providerId' | 'disclosurePreviewId';
  }>();
  const location = useLocation();

  const [disclosure, setDisclosure] = useState<
    CmeGenericDisclosure | CmeTemporaryDisclosure | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previewError, setPreviewError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (id) {
      setIsLoading(true);
      fetchApi(`preview_generic_disclosure/${idType}/${id}`)
        .then(response => response.json())
        .then(json => {
          const parsedData =
            idType === 'providerId'
              ? genericDisclosureSchema.parse(json.data)
              : temporaryDisclosureSchema.parse(json.data);
          if (isMounted) {
            setDisclosure(parsedData);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setPreviewError(true);
          if (isMounted) {
            setIsLoading(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  if (previewError) {
    return (
      <NotFound
        sourcePath={location.pathname}
        component="Preview Disclosure"
        reason="Couldn't find resouce"
      />
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <header>
      <CmeGenericDisclosureModal
        isPreview
        isModalOpen
        handleClose={() => undefined}
        isLoading={isLoading}
        disclosureContent={disclosure !== null ? disclosure.content_html : ''}
      />
    </header>
  );
}
