import React from 'react';
import './PageHeader.scss';

const CitationSearchHeader = () => (
  <div className="citation-search-header">
    <div className="page-header-contents">
      <h1 className="page-title">Citation Search</h1>
    </div>
  </div>
);

export default CitationSearchHeader;
