import React from 'react';
import cn from 'classnames';
import { Icon, IconName } from './Icon';

import './ModalButton.scss';

interface Props {
  icon: IconName;
  size?: 'large' | 'small';
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  report: () => void;
}

export function ModalButton({
  icon,
  size = 'large',
  onClick,
  className = '',
  disabled = false,
  report
}: Props) {
  return (
    <button
      type="button"
      onClick={() => {
        onClick();
        report();
      }}
      className={cn('uc-modal-button', `uc-modal-button--${size}`, className, {
        'uc-modal-button--disabled': disabled
      })}
      disabled={disabled}
    >
      <Icon name={icon} className="uc-modal-button__icon" />
    </button>
  );
}
