import React from 'react';
import { LinkButton } from '../LinkButton';

import './Launchpad.scss';

export interface LaunchpadLink {
  id: number;
  to: string;
  label: string;
}

interface LaunchpadProps {
  links: LaunchpadLink[];
}

export function Launchpad({ links }: LaunchpadProps) {
  return (
    <div className="launchpad__container">
      {links.map(link => (
        <LinkButton
          key={link.id}
          to={link.to}
          variant="primary"
          label={link.label}
        />
      ))}
    </div>
  );
}
