import { AccountCmeCredit } from '../schema/cme/accountCmeCredit';
import { cmeCreditStatusSchema } from '../schema/cme/cmeCreditStatus';
import { CmeEpisodeCredit } from '../schema/cme/cmeEpisodeCredit';

/*
User-facing statuses ultimately resolve to AVAILABLE or CLAIMED credits
- only CLAIMED credits can be printed on certificates
- "Claimed" status is for CLAIMED credits that are still active (termination date is in future)
- "Expired" status is for CLAIMED credits that are inactive (termination date is in past)
*/
export function isCmeCreditClaimed(
  credit: AccountCmeCredit | CmeEpisodeCredit
) {
  return (
    credit.status === cmeCreditStatusSchema.enum.Claimed ||
    credit.status === cmeCreditStatusSchema.enum.Expired
  );
}
