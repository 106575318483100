import { Middleware, Dispatch, MiddlewareAPI } from 'redux';
import logdown from 'logdown';
import { collection, query, onSnapshot, where } from 'firebase/firestore';
import { auth, db } from '../firebase';
import collections from '../firebase/collections';
import { IdTokenResultSubset } from '../lib/stateTypes';
import { episodesSlice } from '../store/episodes';
import { firebaseSlice } from '../store/firebase';
import { hasPaidAndActiveSubscription } from '../lib/hasPaidAndActiveSubscription';
import { reportError } from '../lib/reportError';

const logger = logdown('EM:RAP Client: Auth Middleware');

export function middleware(): Middleware {
  const firebaseMiddleware: Middleware = ({
    dispatch,
    getState
  }: MiddlewareAPI) => {
    auth.onAuthStateChanged(
      authUser => {
        if (authUser === null) {
          // unsubscribe if user signs out
          const { unsubscribe } = getState().firebase;
          if (unsubscribe) {
            unsubscribe();
            dispatch(firebaseSlice.actions.setUnsubscribe(undefined));
          }
          logger.info('user is logged out');
          dispatch(firebaseSlice.actions.authSuccess({ token: null }));
          dispatch(firebaseSlice.actions.setIsCurator(false));
        } else {
          // subscribe to snapshot updates
          const unsubscribe = onSnapshot(
            query(
              collection(
                db,
                hasPaidAndActiveSubscription(getState().account)
                  ? collections.episodes
                  : collections.publicEpisodes
              ),
              where('modifiedDate', '>', Date.now())
            ),
            snapshot => {
              snapshot.docChanges().forEach(change => {
                const { type, doc } = change;
                if (type === 'removed') {
                  dispatch(episodesSlice.actions.removeEpisode(doc.id));
                }
                if (type === 'modified') {
                  const data = doc.data();
                  dispatch(
                    episodesSlice.actions.updateEpisode({
                      episodeId: doc.id,
                      data
                    })
                  );
                }
              });
            }
          );

          dispatch(firebaseSlice.actions.setUnsubscribe(unsubscribe));
          logger.info('fetching user id token');
          authUser
            .getIdTokenResult()
            .then((idTokenResult: IdTokenResultSubset) => {
              logger.info('user id token received');
              dispatch(
                firebaseSlice.actions.setIsCurator(
                  idTokenResult.claims.isCurator
                )
              );
            })
            .catch(error => {
              logger.error('failed to retrieve the user id token', error);
            });
        }
      },
      error => {
        const state = getState();
        reportError(error, {
          tags: {
            onAuthStateChanged: true,
            account: state.account?.account?.id,
            subscriptionType: state.account?.account?.subscription?.type,
            hasCustomToken: !!state.firebase?.token,
            hasSnapshotListener: !!state.firebase?.unsubscribe
          }
        });
        firebaseSlice.actions.authFailure(error);
        logger.error(error.message);
      }
    );

    return (next: Dispatch) => action => next(action);
  };

  return firebaseMiddleware;
}

export default middleware;
