import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import {
  WebImageBlock,
  WebMediaBlock
} from '../../schema/webEpisode/webChapterBlocks';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import config from '../../config';
import findImageByType from '../../lib/findImageByType';
import { ReactComponent as LinkArrowIcon } from '../../images/icons/icon-link-arrow.svg';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './RelatedMedia.scss';

const { cdnUrl } = config;

interface RelatedMediaProps {
  block: WebImageBlock | WebMediaBlock;
  episodeType: EpisodeTypeShortname;
}

const RelatedMedia = ({ block, episodeType }: RelatedMediaProps) => {
  const pathname = getPhpSitePath(useLocation().pathname);
  const src =
    'videoKey' in block
      ? `${cdnUrl}/images/${findImageByType(block.imageUris, 'thumb')}`
      : `${cdnUrl}/${findImageByType(block.imageUris, 'thumb')}`;
  return (
    <li
      className={classnames('related-media', `related-media--${episodeType}`)}
    >
      <a
        // Link was not used in this case, because it doesn't work correctly with a hashed link.
        // For context, see https://github.com/remix-run/react-router/issues/394#issuecomment-220221604
        className="related-media__link"
        href={`${pathname}#${block.imageUris[0]}`}
        onClick={event => {
          reportLinkClickedAnalytics({
            to: `${pathname}#${block.imageUris[0]}`,
            linkText: event.currentTarget.text,
            eventSource: 'related media',
            pageSpecialty: getPageSpecialty(episodeType)
          });
        }}
      >
        <span className="related-media__link-title">
          {'videoKey' in block ? 'Video' : 'Image'}
          <LinkArrowIcon className="related-media__link-arrow" />
        </span>
        <div className="related-media__image-wrapper">
          <img
            className="related-media__image"
            src={src}
            alt={block.title || block.description || ''}
          />
        </div>
      </a>
    </li>
  );
};

export default RelatedMedia;
