import React from 'react';
import { Modal } from '../Modal';
import { Heading } from '../Heading';
import { CmeFaculty } from '../../schema/cme/cmeFaculty';

import './ContributorModal.scss';

interface ContributorModalProps {
  faculty: CmeFaculty;
  handleClose: () => void;
}

export function ContributorModal({
  faculty,
  handleClose
}: ContributorModalProps) {
  return (
    <Modal
      isOpen
      handleClose={handleClose}
      title="Contributor"
      sectionClassName="contributor-modal__container global-is-stack"
    >
      <div className="contributor-modal__section-container global-is-stack global-is-width-wrapper">
        <Heading Tag="h3" variant="lg" className="contributor-modal__name">
          {faculty.fullname}
        </Heading>
        {faculty.biography !== null && (
          <p
            className="contributor-modal__designations"
            data-testid="contributor-biography"
          >
            {faculty.biography}
          </p>
        )}
      </div>
      <div className="contributor-modal__section-container global-is-stack global-is-width-wrapper">
        <Heading
          Tag="h4"
          variant="md"
          className="contributor-modal__disclosure-title"
        >
          Disclosures
        </Heading>
        <p
          className="contributor-modal__disclosure"
          data-testid="contributor-disclosure"
        >
          {faculty.disclosure ??
            'This contributor does not have any relevant financial relationships to disclose.'}
        </p>
      </div>
    </Modal>
  );
}
