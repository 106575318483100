import {
  TOGGLE_CITATION_TO_PRINT,
  TOGGLE_SELECT_ALL,
  CLEAR_CITATIONS
} from '../actions/print';

export const initialState = {
  selectedCitations: {},
  selectAll: false
};

export default function printReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CITATION_TO_PRINT: {
      const { id, data, selectAll } = action;
      const citations = { ...state.selectedCitations };
      // if selectAll is specified, force the citation to be
      // added or deleted, otherwise toggle
      if (typeof selectAll !== 'undefined') {
        if (selectAll) {
          citations[id] = data;
        } else {
          delete citations[id];
        }
      } else if (citations[id]) {
        delete citations[id];
      } else {
        citations[id] = data;
      }

      return {
        ...state,
        selectedCitations: citations
      };
    }
    case TOGGLE_SELECT_ALL:
      return {
        ...state,
        selectAll: !state.selectAll
      };
    case CLEAR_CITATIONS:
      return {
        ...state,
        selectedCitations: {}
      };
    default:
      return state;
  }
}
