import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../lib/fetchApi';
import { useSelector } from '../lib/hooks';
import { commentsSlice } from '../store/comments';
import { WebComment } from '../schema/webComment/webComment';
import { postCommentApiResponseSchema } from '../schema/api/postComment';
import { reportError } from '../lib/reportError';

type SendStatus = 'success' | 'fail' | 'processing' | undefined;

interface Comment {
  bodyText: string;
  chapterId: number;
  parentCommentId: number | null;
  episodeId: number;
}

export default function useSendComment(): {
  sendStatus: SendStatus;
  setSendStatus: React.Dispatch<React.SetStateAction<SendStatus>>;
  sendComment: (comment: Comment) => Promise<void>;
  newCommentId: number | undefined;
} {
  const [sendStatus, setSendStatus] = useState<SendStatus>(undefined);
  const [newCommentId, setNewCommentId] = useState<number>();
  const account = useSelector(state => state.account.account);
  const dispatch = useDispatch();

  const sendComment = async (comment: Comment) => {
    setSendStatus('processing');

    try {
      if (!comment.bodyText || comment.bodyText.trim() === '') {
        throw new Error('Comment message is required');
      }

      const requestBody = {
        commentBody: comment.bodyText,
        parent: comment.parentCommentId ?? undefined
      };

      const response = await fetchApi(
        `chapters/${comment.chapterId}/comments`,
        {
          method: 'POST',
          body: JSON.stringify(requestBody)
        }
      );

      if (account) {
        const data = postCommentApiResponseSchema.parse(await response.json());

        const formattedComment: WebComment = {
          ...data.comment,
          bodyText: data.comment.bodytext,
          createDate: data.comment.createdate,
          episode: comment.episodeId,
          firstName: account.firstName,
          lastInitial: account.lastName.charAt(0),
          gravatarEmail: account.avatarUri,
          suffix: account.suffix
        };

        dispatch(commentsSlice.actions.add(formattedComment));
        setNewCommentId(formattedComment.id);
        setSendStatus('success');
      }
    } catch (error) {
      setSendStatus('fail');
      reportError(error);
    }
  };

  return {
    sendStatus,
    setSendStatus,
    sendComment,
    newCommentId
  };
}
