import React from 'react';

import './ChapterBlockMediaDetails.scss';

interface ChapterBlockMediaDetailsProps {
  title: string | null;
  description: string | null;
}

const ChapterBlockMediaDetails = ({
  title,
  description
}: ChapterBlockMediaDetailsProps) => (
  <span className="chapter-block-media-details">
    {title && (
      <span className="chapter-block-media-details__title">{title}</span>
    )}
    {!!description && (
      <span className="chapter-block-media-details__description">
        {description}
      </span>
    )}
  </span>
);

export default ChapterBlockMediaDetails;
