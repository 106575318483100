/**
 * Formats a duration in second as hours, minutes, or seconds
 *
 * @param duration the duration in seconds.
 *
 * @returns the formatted duration.
 */

export default function formatDuration(duration: number) {
  const sign = duration < 0 ? `-` : '';
  const totalSeconds = Math.abs(Math.round(duration));

  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;

  const hours = Math.floor(totalSeconds / 3600);

  if (hours === 0) {
    return `${sign}${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  return `${sign}${hours}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}
