import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { MenuItem } from './MenuItem';
import { SearchBar } from './SearchBar';
import { Wrapper } from '../Wrapper';
import { ReactComponent as UCLogo } from '../../images/urgent-care/logo/uc-logo--icon-v2.svg';
import { ReactComponent as UCLogoHorizontal } from '../../images/urgent-care/logo/uc-logo--horizontal-v2.svg';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { reportUCSearchSubmittedAnalytics } from '../../analytics/reportUCSearchSubmittedAnalytics';

import './NavBar.scss';

interface Props {
  searchValue: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: React.FormEvent) => void;
  isFancy?: boolean; // If enabled will use a curved SVG background
  className?: string;
}

export function NavBar({
  searchValue,
  onSearchChange,
  onSearchSubmit,
  isFancy = true,
  className = ''
}: Props) {
  return (
    <nav
      className={cn('uc-navbar', className, { 'uc-navbar--fancy': isFancy })}
      aria-label="urgent care navbar"
    >
      <Wrapper className="uc-navbar__inner">
        <NavLink
          to="/uc-dashboard"
          aria-label="UC Max"
          className="uc-navbar__logo-link"
          onClick={() => {
            reportLinkClickedAnalytics({
              to: 'uc-dashboard',
              linkText: 'UC',
              pageSpecialty: 'UC'
            });
          }}
        >
          <UCLogo aria-hidden="true" className="uc-navbar__logo-link-icon" />
          <UCLogoHorizontal
            aria-hidden="true"
            className="uc-navbar__logo-link-icon--horizontal"
          />
        </NavLink>
        <SearchBar
          value={searchValue}
          placeholder="Search Urgent Care"
          onSubmit={e => {
            reportUCSearchSubmittedAnalytics();
            onSearchSubmit(e);
          }}
          onChange={onSearchChange}
          className="uc-navbar__search-bar"
        />
        <ul className="uc-navbar__menu-item-container">
          <MenuItem
            url="/urgent-care/hd/playlist/uc-courses/"
            iconName="content-type-foundations"
            title="Courses"
            className="uc-navbar__menu-item"
            onClick={() => {
              reportLinkClickedAnalytics({
                to: 'urgent-care/hd/playlist/uc-courses/',
                linkText: 'Courses',
                pageSpecialty: 'UC'
              });
            }}
          />
          <MenuItem
            url="/uc-audio"
            iconName="content-type-audio"
            title="Audio"
            className="uc-navbar__menu-item"
            onClick={() => {
              reportLinkClickedAnalytics({
                to: 'uc-audio',
                linkText: 'Audio',
                pageSpecialty: 'UC'
              });
            }}
          />
          <MenuItem
            url="/urgent-care/hd"
            iconName="content-type-video"
            title="Video"
            className="uc-navbar__menu-item"
            onClick={() => {
              reportLinkClickedAnalytics({
                to: 'urgent-care/hd',
                linkText: 'Video',
                pageSpecialty: 'UC'
              });
            }}
          />
          <MenuItem
            url="/event/urgent-care"
            iconName="content-type-conferences"
            title="Events"
            className="uc-navbar__menu-item"
            onClick={() => {
              reportLinkClickedAnalytics({
                to: 'event/urgent-care',
                linkText: 'Events',
                pageSpecialty: 'UC'
              });
            }}
          />
        </ul>
      </Wrapper>
    </nav>
  );
}
