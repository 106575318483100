import React, { useState } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import ConferenceFacultyAvatar from './ConferenceFacultyAvatar';
import formatDayWithTimeZone from '../../lib/formatDayWithTimeZone';
import formatRelativeTimeRange from '../../lib/formatRelativeTimeRange';
import { ReactComponent as ArrowIcon } from '../../images/conferences/workshops/arrow.svg';
import ConferenceLink from './ConferenceLink';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './ConferenceWorkshopCard.scss';

interface Props {
  title: string;
  description: JSX.Element | string | null;
  educator: string;
  suffix: string;
  photo: string;
  price: number;
  eventTimeZone: string;
  eventDates: {
    startDate: number;
    endDate: number;
  }[];
  disable: boolean;
  className?: string;
}

const ConferenceWorkshopCard = ({
  title,
  description,
  educator,
  suffix,
  photo,
  price,
  eventTimeZone,
  eventDates,
  disable,
  className
}: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
  const firstDate = eventDates.length > 0 ? eventDates[0] : undefined;
  const secondDate = eventDates.length > 1 ? eventDates[1] : undefined;

  return (
    <div
      className={classnames('conference-workshop-card__container', className)}
    >
      <h5 className="conference-workshop-card__date">
        {firstDate &&
          formatDayWithTimeZone(firstDate.startDate, eventTimeZone, false)}{' '}
        |{' '}
        {firstDate &&
          formatRelativeTimeRange(
            firstDate.startDate,
            firstDate.endDate,
            eventTimeZone,
            eventTimeZone,
            true
          )}
        {secondDate && ' or '}
        {secondDate &&
          formatRelativeTimeRange(
            secondDate.startDate,
            secondDate.endDate,
            eventTimeZone,
            eventTimeZone,
            true
          )}
      </h5>
      {isMobile && <h3 className="conference-workshop-card__title">{title}</h3>}
      <div className="conference-workshop-card__content">
        <div>
          {isMobile ? (
            <>
              <p
                className={classnames('conference-workshop-card__description', {
                  'conference-workshop-card__description--truncate':
                    !isReadMoreOpen
                })}
              >
                {description}
              </p>
              {isReadMoreOpen ? (
                <button
                  type="button"
                  className="conference-workshop-card__read-more"
                  onClick={() => setIsReadMoreOpen(!isReadMoreOpen)}
                >
                  read less{' '}
                  <ArrowIcon className="conference-workshop-card__icon--open" />
                </button>
              ) : (
                <button
                  type="button"
                  className="conference-workshop-card__read-more"
                  onClick={() => setIsReadMoreOpen(!isReadMoreOpen)}
                >
                  read more <ArrowIcon />
                </button>
              )}
            </>
          ) : (
            <>
              <h3 className="conference-workshop-card__title">{title}</h3>
              <p className="conference-workshop-card__description">
                {description}
              </p>
            </>
          )}
        </div>
        <div className="conference-workshop-card__faculty">
          <ConferenceFacultyAvatar name={educator} img={photo} />
          <h3 className="conference-workshop-card__name">{educator}</h3>
          <h4 className="conference-workshop-card__faculty-title">{suffix}</h4>
        </div>
      </div>
      <div className="conference-workshop-card__footer">
        <span className="conference-workshop-card__price">${price}</span>
        <ConferenceLink
          className={
            disable
              ? 'conference-workshop-card__button conference-workshop-card__button--disable'
              : 'conference-workshop-card__button'
          }
          href={getPhpSitePath('/subscribe/conference')}
          onClick={() =>
            reportLinkClickedAnalytics({
              to: 'subscribe/conference',
              linkText: disable ? 'Sold Out' : 'Sign up for workshop',
              eventSource: educator
            })
          }
        >
          {disable ? 'Sold Out' : 'Sign up for workshop'}
        </ConferenceLink>
      </div>
    </div>
  );
};

export default ConferenceWorkshopCard;

ConferenceWorkshopCard.defaultProps = {
  className: undefined
};
