import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ZodError } from 'zod';
import { AppState, CmeEvaluationsState } from '../lib/stateTypes';
import { reportError } from '../lib/reportError';
import {
  CmeEvaluation,
  cmeEvaluationsSchema
} from '../schema/cme/cmeEvaluations';
import { getInitialEvaluationResponse } from '../lib/getInitialEvaluationResponse';
import { fetchApi } from '../lib/fetchApi';
import { updateCmeEvaluationResponse } from '../lib/updateCmeEvaluationResponse';
import { ApiError } from '../lib/ApiError';

const initialState: CmeEvaluationsState = {
  cmeEvaluations: null,
  cmeEvaluationsResponses: [],
  isLoading: false
};

export const payloadCreator = async (cmeCredits: number[]) => {
  try {
    if (cmeCredits.length === 0) {
      return null;
    }

    const response = await fetchApi('cme_evaluations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ creditsIds: cmeCredits })
    });

    const data = await response.json();
    const evaluations = cmeEvaluationsSchema.parse(data.evaluations);

    // Sort questions and options by displayOrder
    evaluations.forEach(evaluation => {
      evaluation.questions = evaluation.questions.sort(
        (a, b) => a.display_order - b.display_order
      );
      evaluation.questions.forEach(question => {
        question.question_options.sort(
          (a, b) => a.display_order - b.display_order
        );
      });
    });

    return evaluations;
  } catch (error) {
    const errorContext: { extra: { [key: string]: unknown } } = {
      extra: {
        url: `cme_evaluations`
      }
    };
    if (error instanceof ApiError) {
      errorContext.extra.status = error.status;
    } else if (error instanceof ZodError) {
      errorContext.extra.creditIds = cmeCredits;
    }
    reportError(error, errorContext);
    return null;
  }
};

export const loadEvaluations = createAsyncThunk<
  CmeEvaluation[] | null,
  number[],
  { state: AppState; rejectValue: null }
>('cmeEvaluations/loadEvaluations', payloadCreator);

export const cmeEvaluationsSlice = createSlice({
  name: 'cmeEvaluations',
  initialState,
  reducers: {
    resetEvaluationResponses: state => {
      if (state.cmeEvaluations !== null) {
        state.cmeEvaluationsResponses[0] = getInitialEvaluationResponse(
          state.cmeEvaluations[0]
        );
      }
    },
    updateCmeEvaluationQuestionResponse: (
      state,
      action: PayloadAction<{
        questionId: number;
        newOptionIds: number[];
        textValue: string | null;
      }>
    ) => {
      if (state.cmeEvaluations === null || state.cmeEvaluations.length === 0) {
        return;
      }
      updateCmeEvaluationResponse(
        state.cmeEvaluations[0],
        state.cmeEvaluationsResponses[0],
        action.payload
      );
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadEvaluations.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadEvaluations.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          if (state.cmeEvaluations === null) {
            state.cmeEvaluations = [];
          }
          const existingEvaluationsProviderIds = state.cmeEvaluations.map(
            evaluation => evaluation.provider.id
          );
          // Only add CmeEvaluations and response of evaluations we don't have already
          action.payload
            .filter(
              cmeEvaluation =>
                !existingEvaluationsProviderIds.includes(
                  cmeEvaluation.provider.id
                )
            )
            .forEach(newCmeEvaluation => {
              state.cmeEvaluations?.push(newCmeEvaluation);
              state.cmeEvaluationsResponses.push(
                getInitialEvaluationResponse(newCmeEvaluation)
              );
            });
        }
        state.isLoading = false;
        state.lastUpdate = new Date().getTime();
      })
      .addCase(loadEvaluations.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const {
  reducer,
  actions: { updateCmeEvaluationQuestionResponse, resetEvaluationResponses }
} = cmeEvaluationsSlice;
