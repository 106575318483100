/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react';
import { StateProvider } from '@appbaseio/reactivesearch';
import get from 'lodash/get';

interface ResetStateProps {
  watchValue?: string | number | any[];
  watchState?: string;
  onClearFilter: () => void;
  searchState: Record<string, any>;
}

interface SetInitialStateProps {
  onInit: (searchState: Record<string, any>) => void;
  searchState: Record<string, any>;
}

interface SearchStateHelperProps {
  onInit?: (searchState: Record<string, any>) => void;
  onClearFilter?: () => void;
  watchValue?: string | number | any[];
  watchState?: string;
}

export const ResetState = ({
  watchValue,
  watchState,
  onClearFilter,
  searchState
}: ResetStateProps) => {
  let watch = watchValue;
  if (watchState) {
    watch = get(searchState, `[${watchState}].value`);
  }
  const runOnClearFilter = () => {
    if (watch === null) {
      onClearFilter();
    }
  };
  useEffect(runOnClearFilter, [watch]);
  return null;
};

export const SetInitialState = ({
  onInit,
  searchState
}: SetInitialStateProps) => {
  const setStateOnInit = () => {
    onInit(searchState);
  };
  useEffect(setStateOnInit, []);
  return null;
};

/**
 * A helper for use with ReactiveSearch state updates. Allows you to
 * specify a function to run on init and one to run when filters are cleared
 * Watches a specified state value
 */
export const SearchStateHelper = ({
  onInit,
  watchValue,
  watchState,
  onClearFilter
}: SearchStateHelperProps) => (
  <StateProvider
    render={({ searchState }) => (
      <>
        {typeof onInit === 'function' && (
          <SetInitialState onInit={onInit} searchState={searchState} />
        )}
        {typeof onClearFilter === 'function' && (
          <ResetState
            watchValue={watchValue}
            watchState={watchState}
            onClearFilter={onClearFilter}
            searchState={searchState}
          />
        )}
      </>
    )}
  />
);
