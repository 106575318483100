import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ConferenceFacultyAvatar from './ConferenceFacultyAvatar';
import './ConferenceFacultyCard.scss';

const ConferenceFacultyCard = props => {
  const { name, title, img, description, className, onClick } = props;

  return (
    <div
      className={classnames('conference-faculty-card__container', className)}
    >
      <div className="conference-faculty-card__avatar">
        <ConferenceFacultyAvatar name={name} img={img} className="test" />
      </div>
      <div className="conference-faculty-card__content">
        <h3 className="conference-faculty-card__name">{name}</h3>
        <h4 className="conference-faculty-card__title">{title}</h4>
        <p className="conference-faculty-card__description">{description}</p>
        <button
          type="button"
          className="conference-faculty-card__read-more"
          onClick={onClick}
        >
          read more
        </button>
      </div>
    </div>
  );
};

export default ConferenceFacultyCard;

ConferenceFacultyCard.defaultProps = {
  name: null,
  title: null,
  img: null,
  description: null,
  className: null,
  onClick: null
};

ConferenceFacultyCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  img: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};
