import { z } from 'zod';
import { ESC3PlaylistItemSchema } from './ESC3PlaylistItem';

// ES transformer https://github.com/silverorange/emrap-search-tools/blob/master/src/transforms/c3PlaylistTransform.js
// psql query https://github.com/silverorange/emrap-search-tools/blob/master/src/db/getC3Playlists.js

export const ESC3PlaylistSchema = z.object({
  title: z.string(),
  item_count: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  url_path: z.string(),
  graphic: z.string().optional(),
  display_order: z.number(),
  items: z.array(ESC3PlaylistItemSchema)
});

export type ESC3Playlist = z.infer<typeof ESC3PlaylistSchema>;
