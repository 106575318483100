import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../Icons/Icon';

import './SketchyLink.scss';

const SketchyButton = props => {
  const {
    children,
    className,
    color,
    size,
    onClick,
    type,
    isFilled,
    hasIcon,
    iconName,
    disabled
  } = props;

  const colorClass = classNames(className, 'sketchy-link', {
    'sketchy-link--black': color === 'black',
    'sketchy-link--teal-dark': color === 'teal-dark',
    'sketchy-link--teal-light': color === 'teal-light',
    'sketchy-link--orange': color === 'orange',
    'sketchy-link--ema-red': color === 'ema-red',
    'sketchy-link--crunch-red': color === 'crunch-red',
    'sketchy-link--llsa-blue': color === 'llsa-blue',
    'sketchy-link--c3-green': color === 'c3-green',
    'sketchy-link--hd-purple': color === 'hd-purple',
    'sketchy-link--gray': color === 'gray',
    'sketchy-link--gray-inverse': color === 'gray-inverse',
    'sketchy-link--small': size === 'small',
    'sketchy-link--filled': isFilled,
    'sketchy-link--has-icon': hasIcon
  });

  /* eslint-disable react/button-has-type */
  /* See https://github.com/yannickcr/eslint-plugin-react/issues/1555 */
  return (
    <button
      type={type}
      className={colorClass}
      onClick={onClick}
      disabled={disabled}
    >
      {hasIcon && <Icon name={iconName} />}
      <span className="sketchy-link__text">{children}</span>
    </button>
  );
  /* eslint-enable react/button-has-type */
};

SketchyButton.defaultProps = {
  children: null,
  className: null,
  color: 'default',
  size: 'default',
  onClick: null,
  type: 'button',
  isFilled: false,
  hasIcon: false,
  iconName: null,
  disabled: false
};

SketchyButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'black',
    'orange',
    'teal-dark',
    'c3-green',
    'teal-light',
    'hd-purple',
    'gray',
    'gray-inverse'
  ]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small']),
  isFilled: PropTypes.bool,
  hasIcon: PropTypes.bool,
  iconName: PropTypes.string,
  disabled: PropTypes.bool
};

export default SketchyButton;
