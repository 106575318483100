import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import DropdownButton from '../DropdownButton';
import SearchBarGroup from './SearchBarGroup';
import SidebarExtraSection from './SidebarExtraSection';
import config from '../../config';
import {
  setCurrentSearch as setCurrentSearchAction,
  setCitationType as setCitationTypeAction,
  setAudience as setAudienceAction,
  ALL_CITATIONS,
  NON_ABSTRACTED_CITATIONS,
  ABSTRACT_CITATIONS
} from '../../actions/filters';
import { reportFilterAppliedAnalytics } from '../../analytics/reportFilterAppliedAnalytics';

import './SidebarCitationSearch.scss';
import '../RadioList.scss';

const getAudience = episodeKey => {
  switch (episodeKey) {
    case 'ema':
      return config.audienceOptions.find(aud => aud.key === 'em-clinicians');
    case 'rop':
      return config.audienceOptions.find(aud => aud.key === 'fm-clinicians');
    default:
      throw new Error(`Unknown key ${episodeKey}`);
  }
};

export class SidebarCitationSearch extends Component {
  constructor(props) {
    super(props);
    this.searchInput = null;
    this.state = {
      currentCitation: { value: ALL_CITATIONS, label: 'All Citations' },
      citationOptions: [
        { value: ALL_CITATIONS, label: 'All Citations' },
        { value: ABSTRACT_CITATIONS, label: 'Citations with Abstracts' },
        { value: NON_ABSTRACTED_CITATIONS, label: 'Non-Abstracted Citations' }
      ],
      selectOpen: false
    };
  }

  setInputRef = elem => {
    this.searchInput = elem;
  };

  setSearchAndNavigate = () => {
    const { setCurrentSearch, setCitationType, setAudience, episode, history } =
      this.props;
    const { currentCitation } = this.state;
    setCurrentSearch(this.searchInput.value);
    setCitationType(currentCitation.value);
    setAudience(getAudience(episode.key));
    history.push('/search/citations');
  };

  setCitationAndClose = event => {
    const { citationOptions } = this.state;
    const value = event.currentTarget.dataset.option;
    const matchedOption = citationOptions.find(
      option => option.value === value
    );
    if (matchedOption) {
      reportFilterAppliedAnalytics({
        filterType: 'citation type',
        filterValue: matchedOption.label
      });
      this.setState(() => ({
        currentCitation: matchedOption,
        selectOpen: false
      }));
    }
  };

  toggleSelect = () =>
    this.setState(prevState => ({
      selectOpen: !prevState.selectOpen
    }));

  renderOptions() {
    const { citationOptions, currentCitation } = this.state;
    return citationOptions.map(option => (
      <li
        key={`citation-select-${option.value}`}
        className={classnames('radio-list__option', {
          active: currentCitation.value === option.value
        })}
      >
        <button
          type="button"
          className="radio-list__button"
          data-option={option.value}
          onClick={this.setCitationAndClose}
        >
          {option.label}
        </button>
      </li>
    ));
  }

  render() {
    const { selectOpen, currentCitation } = this.state;
    const { episode } = this.props;
    return (
      <SidebarExtraSection className="sidebar-citation-search">
        <h4 className="sidebar-citation-search__header">
          Citation Advanced Search
        </h4>
        <p className="sidebar-citation-search__content">
          Search through thousands of citations and abstract {episode.linkText}{' '}
          recordings.
        </p>
        <div className="sidebar-citation-search__citation-select">
          <DropdownButton
            className="sidebar-citation-search__citation-select-button"
            handleClick={this.toggleSelect}
            isOpen={selectOpen}
            text={currentCitation.label}
          />
          <ul
            className={classnames(
              'sidebar-citation-search__citation-select-options',
              'radio-list',
              {
                'sidebar-citation-search__citation-select-options--open':
                  selectOpen
              }
            )}
          >
            {this.renderOptions()}
          </ul>
        </div>
        <SearchBarGroup
          handleSubmit={this.setSearchAndNavigate}
          setInputRef={this.setInputRef}
        />
      </SidebarExtraSection>
    );
  }
}

SidebarCitationSearch.propTypes = {
  setCurrentSearch: PropTypes.func.isRequired,
  setCitationType: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  episode: PropTypes.shape({
    key: PropTypes.string,
    linkText: PropTypes.string
  }).isRequired,
  setAudience: PropTypes.func.isRequired
};

const mapStatetoProps = state => ({
  currentSearch: state.filters.currentSearch
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setCurrentSearch: setCurrentSearchAction,
      setCitationType: setCitationTypeAction,
      setAudience: setAudienceAction
    },
    dispatch
  );

export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(SidebarCitationSearch)
);
