import React from 'react';
import { SidebarLink } from './Sidebar/SidebarLink';
import Icon from './Icons/Icon';
import { reportLinkClickedAnalytics } from '../analytics/reportLinkClickedAnalytics';
import { getPhpSitePath } from '../lib/getPhpSitePath';
import { CRUNCH_TIME_EM } from '../lib/episodeTypes';

const CrunchyQCallout = () => (
  <SidebarLink
    href={getPhpSitePath('crunchtime/quiz')}
    buttonText="Go to Crunchy Qs"
    icon={<Icon name="crunchy-q" />}
    isInverted
    episodeType={CRUNCH_TIME_EM}
    text="Think you’re ready for the boards? Test your knowledge with Crunchy Qs, our interactive board review question bank."
    onClick={() =>
      reportLinkClickedAnalytics({
        to: 'crunchtime/quiz',
        linkText: 'Go to Crunchy Qs',
        eventSource: 'Crunchy Qs Callout'
      })
    }
  />
);

export default CrunchyQCallout;
