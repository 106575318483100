import React from 'react';
import { ReactComponent as HDHPolygon } from '../../images/hd/header/feature-background-polygon.svg';
import { ReactComponent as UCHDPolygon } from '../../images/urgent-care/hd/uc-hd__hero-background--polygon.svg';

import './HDHeroBackground.scss';

interface Props {
  isUrgentCare?: boolean;
}

export function HDHeroBackground({ isUrgentCare = false }: Props) {
  return isUrgentCare ? (
    <UCHDPolygon className="hd-hero-background--uc" />
  ) : (
    <HDHPolygon className="hd-hero-background" />
  );
}
