import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from '../lib/hooks';
import { accountSlice } from '../store/account';

const ConnectedHelmet = () => {
  const account = useSelector(state => state[accountSlice.name]);
  const isSignedIn = !!account.account;

  return !isSignedIn ? null : (
    <Helmet>
      <meta
        name="apple-itunes-app"
        content="app-id=804248260"
        // eslint-disable-next-line react/no-unknown-property
        app-argument={window.location.href}
      />
    </Helmet>
  );
};

export default ConnectedHelmet;
