import React from 'react';
import classnames from 'classnames';

import './_style/lib/components/Checkbox.scss';

interface CheckboxProps {
  id: string;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  isLabelVisuallyHidden?: boolean;
  isDisabled?: boolean;
}

export const Checkbox = ({
  id,
  label,
  onChange,
  isChecked,
  isLabelVisuallyHidden = false,
  isDisabled = false
}: CheckboxProps) => (
  <div className="global-checkbox" data-is-label-hidden={isLabelVisuallyHidden}>
    <input
      className="global-checkbox__input"
      type="checkbox"
      name={id}
      id={id}
      onChange={onChange}
      checked={isChecked}
      disabled={isDisabled}
    />
    <label
      htmlFor={id}
      className={classnames('global-checkbox__label', {
        'global-is-visually-hidden': isLabelVisuallyHidden
      })}
    >
      {label}
    </label>
  </div>
);
