import React from 'react';
import { Link } from 'react-router-dom';
import { reportLinkClickedAnalytics } from '../../../analytics/reportLinkClickedAnalytics';

import './MainSearchLink.scss';

export default function MainSearchLink() {
  return (
    <Link
      to="/search"
      className="main-search-link"
      onClick={() =>
        reportLinkClickedAnalytics({
          to: 'search',
          linkText: 'return to main search'
        })
      }
    >
      Return to Main Search
    </Link>
  );
}
