export default function findImageByType(arrayOfUris: string[], type: string) {
  const uriPosition = arrayOfUris.findIndex(element => {
    if (element.includes(type)) {
      return true;
    }
    return false;
  });

  if (uriPosition !== -1) {
    return arrayOfUris[uriPosition];
  }

  return arrayOfUris[0];
}
