export default function getPlaylistItemURL(
  itemUrlPath: string,
  isChapterPage: boolean,
  isRelatedPlaylistOrSuggestionItem: boolean,
  isUrgentCare: boolean,
  basePath: string
) {
  if (isRelatedPlaylistOrSuggestionItem) {
    if (itemUrlPath.startsWith('/hd') && isUrgentCare) {
      return `/urgent-care${itemUrlPath}`;
    }
    return itemUrlPath;
  }
  if (isChapterPage) {
    if (basePath.includes('/episode')) {
      // remove the last section of the path because it is the chapter id. Episodes only
      // contains chapters and we will concatenate the new chapter id at the end
      basePath = basePath.substring(0, basePath.lastIndexOf('/'));
    } else {
      // This is for chapters that are part of a playlist. We just want to keep the URI
      // until the playlist shortnames
      basePath = basePath.replace(/\/chapter\/.*/, '');
    }
  }
  if (basePath.endsWith('/')) {
    // Remove trailing slashes
    basePath = basePath.replace(/[/]+$/, '');
  }
  return `${basePath}/${itemUrlPath}`;
}
