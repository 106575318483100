import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './_style/lib/components/Link.scss';

export interface ButtonProps {
  size?: 'default' | 'small';
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  isMonoColor?: boolean;
  className?: string;
}

export const ButtonLink = ({
  size = 'default',
  disabled = false,
  onClick,
  children,
  className,
  isMonoColor = false
}: ButtonProps) => (
  <button
    type="button"
    className={classnames('global-link', className)}
    data-size={size}
    data-is-mono-color={isMonoColor}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);
