import React from 'react';
import cn from 'classnames';
import { osName } from 'react-device-detect';
import { Wrapper } from '../Wrapper';
import { SecondaryLinkButton } from './SecondaryLinkButton';
import { IconName } from './Icon';
import { FeaturedContentCard } from './FeaturedContentCard';
import Loader from '../Loader';
import { LoadingError } from '../LoadingError';
import { getEmrapAppLink } from '../../lib/getEmrapAppLink';
import { getCorependiumAppLink } from '../../lib/getCorependiumAppLink';
import { useElasticsearch } from '../../lib/hooks';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getHDPath } from '../../lib/getHDPath';
import { ESVideoPlaylist } from '../../schema/playlist/ESVideoPlaylist';
import config from '../../config';

import './DashboardHeroSection.scss';

const { episodeArtUrl } = config;

export interface DashboardHeroSectionProps {
  accountName: string;
  className?: string;
}

export interface NavButton {
  id: number;
  icon: IconName;
  href: string;
  text: string;
  onClick?: () => void;
}

export const NAV_BUTTONS: NavButton[] = [
  {
    id: 0,
    icon: 'arrow-right',
    href: getEmrapAppLink(osName),
    text: 'Listen on the App',
    onClick: () => {
      reportLinkClickedAnalytics({
        to: 'podcast',
        linkText: 'Listen on the App',
        pageSpecialty: 'UC'
      });
    }
  },
  {
    id: 1,
    icon: 'arrow-right',
    href: getCorependiumAppLink(osName),
    text: 'Download the CorePendium App',
    onClick: () => {
      reportLinkClickedAnalytics({
        to: 'podcast',
        linkText: 'Download the CorePendium App',
        pageSpecialty: 'UC'
      });
    }
  }
];

const featuredVideoPlaylist = {
  query: {
    term: {
      url_path: 'featured-uc'
    }
  }
};

export function DashboardHeroSection({
  accountName,
  className
}: DashboardHeroSectionProps) {
  const {
    data: featuredVideoPlaylistData,
    loading: isFeaturedVideoPlaylistLoading,
    error: featuredVideoPlaylistError
  } = useElasticsearch<ESVideoPlaylist>(
    'emrap.hd.video-playlist',
    featuredVideoPlaylist
  );

  const featuredVideo = featuredVideoPlaylistData?.[0]?.items?.filter(item =>
    ['chapter', 'episode'].includes(item.type)
  )[0];

  let featuredVideoPosterFrame = '';

  if (featuredVideo) {
    if (featuredVideo.type === 'chapter') {
      featuredVideoPosterFrame = `${process.env.REACT_APP_CDN_BASE}${featuredVideo.thumbnail?.['480'].path}`;
    } else if (featuredVideo.type === 'episode') {
      featuredVideoPosterFrame = `${episodeArtUrl}/${featuredVideo.artwork?.id}.jpg`;
    }
  }

  return (
    <Wrapper className={cn('uc-dashboard-hero', className)}>
      <div className="uc-dashboard-hero__section-header">
        <h2 className="uc-dashboard-hero__title">Welcome {accountName}!</h2>
        <p className="uc-dashboard-hero__title-text">
          Your membership unlocks exclusive access to a comprehensive Urgent
          Care educational program, plus everything else EM:RAP offers!
        </p>
        <ul aria-label="hero links" className="uc-dashboard-hero__links">
          {NAV_BUTTONS.map(({ id, icon, href, text, onClick }) => (
            <li key={id} className="uc-dashboard-hero__link">
              <SecondaryLinkButton
                icon={icon}
                href={href}
                text={text}
                className="uc-dashboard-hero__nav-button"
                onClick={onClick}
              />
            </li>
          ))}
        </ul>
      </div>
      {isFeaturedVideoPlaylistLoading ? (
        <Loader className="uc-dashboard-hero__featured-content-card-loader" />
      ) : (
        <div className="uc-dashboard-hero__featured-content-card-container">
          {featuredVideoPlaylistError ? (
            <div className="uc-dashboard-hero__featured-content-card-error">
              <LoadingError />
            </div>
          ) : (
            featuredVideo && (
              <FeaturedContentCard
                posterFrame={featuredVideoPosterFrame}
                title={featuredVideo.title}
                format="video"
                episodeUrl={getHDPath(
                  true,
                  `/playlist/${featuredVideoPlaylist.query.term.url_path}/${featuredVideo.url_path}`
                )}
              />
            )
          )}
        </div>
      )}
    </Wrapper>
  );
}
