import { fetchApi } from '../lib/fetchApi';
import { reportError } from '../lib/reportError';

export const CONFERENCE_LOADING = 'CONFERENCE_LOADING';
export const CONFERENCE_SUCCESS = 'CONFERENCE_SUCCESS';
export const CONFERENCE_FAILURE = 'CONFERENCE_FAILURE';

function setLoading() {
  return {
    type: CONFERENCE_LOADING
  };
}

function setSuccess(isRegistered) {
  return {
    type: CONFERENCE_SUCCESS,
    isRegistered
  };
}

function setFailure() {
  return {
    type: CONFERENCE_FAILURE
  };
}

export function loadRegistration() {
  return async dispatch => {
    dispatch(setLoading());
    try {
      const response = await fetchApi('woop_woop_conference', {
        baseUrl: process.env.REACT_APP_API_URI
      });
      const data = await response.json();

      if (data.registered) {
        dispatch(setSuccess(data.registered));
      } else {
        dispatch(setFailure());
      }
    } catch (e) {
      reportError(e);
      dispatch(setFailure());
    }
  };
}

export function setRegistration(value) {
  return async dispatch => {
    dispatch(setLoading());
    try {
      const response = await fetchApi('woop_woop_conference', {
        baseUrl: process.env.REACT_APP_API_URI,
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify({
          registered: value
        })
      });

      const data = await response.json();

      if (data.registered) {
        dispatch(setSuccess(data.registered));
      } else {
        dispatch(setFailure());
      }
    } catch (e) {
      reportError(e);
      dispatch(setFailure());
    }
  };
}
