import React from 'react';
import './Loader.scss';

interface LoaderProps {
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ className = 'loader__container' }) => (
  <div className={className} data-testid="loader">
    <div className="loader" />
  </div>
);

export default Loader;
