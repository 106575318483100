import { EpisodeTypeShortname } from '../schema/webEpisode/episodeType';

export function getPageSpecialty(episodeType: EpisodeTypeShortname) {
  let pageSpecialty;

  switch (episodeType) {
    case 'c3':
      pageSpecialty = 'C3';
      break;
    case 'ucmaximus':
      pageSpecialty = 'UC';
      break;
    default:
      pageSpecialty = undefined;
  }

  return pageSpecialty;
}
