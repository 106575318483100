import React from 'react';

import './_style/lib/components/RadioOption.scss';

interface RadioOptionProps {
  id: number;
  name: string;
  value: string | number;
  title: string | null;
  isChecked: boolean;
  onChange: (id: number) => void;
  isDisabled?: boolean;
  hasError?: boolean;
}

export function RadioOption({
  id,
  name,
  value,
  title,
  isChecked,
  onChange,
  isDisabled,
  hasError
}: RadioOptionProps) {
  return (
    <label className="global-radio-option" data-variant={hasError && 'error'}>
      <input
        disabled={isDisabled}
        type="radio"
        name={name}
        value={value}
        checked={isChecked}
        onChange={() => onChange(id)}
      />{' '}
      {title}
    </label>
  );
}
