import React from 'react';
import ColumnHeader from './ColumnHeader';
import ROPDoctors from '../../images/episode-guide/doctors-rop.svg';
import ROPLogo from '../../images/episode-guide/logo-rop.svg';

import './PageHeader.scss';
import './RopHeader.scss';

export const RopHeader = () => {
  const title = 'EM:RAP’s Right on Prime';
  const description = `An exciting monthly audio series for the generalist. Primary care, urgent care, low-risk obstetrics, pediatrics, rural, remote and international medicine, and much more!`;

  return (
    <ColumnHeader
      className="rop-header"
      title={title}
      description={description}
      doctors={ROPDoctors}
      logo={ROPLogo}
    />
  );
};

export default RopHeader;
