import React from 'react';
import classnames from 'classnames';

import './_style/lib/components/FormHint.scss';

type FormHintProps = {
  text: string;
  className?: string;
};

export function FormHint({ text, className }: FormHintProps) {
  return (
    <span className={classnames('global-form-hint', className)}>{text}</span>
  );
}
