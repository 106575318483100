import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import get from 'lodash/get';
import { setCurrentIsFreeOnly as setCurrentIsFreeOnlyAction } from '../../../actions/filters';
import { SearchStateHelper } from '../../SearchStateHelper';
import RadioList from '../../RadioList';
import { reportFilterAppliedAnalytics } from '../../../analytics/reportFilterAppliedAnalytics';

import './FreeContentToggle.scss';

const ALL = 'all';
const ONLY_FREE = 'only-free';
const ONLY_FREE_VALUE = 'Only free content';
const FreeContentToggleOptions = [
  { title: 'Search all content', value: ALL },
  { title: 'Search only free content', value: ONLY_FREE }
];

const getQuery = isFreeOnly => {
  if (isFreeOnly) {
    return {
      query: {
        bool: {
          should: [
            {
              term: {
                'episode.is_free': true
              }
            },
            {
              term: {
                'chapter.is_free': true
              }
            }
          ]
        }
      },

      // Value is used in both URLParams and in the SelectedFilters component.
      value: ONLY_FREE_VALUE
    };
  }

  return {
    query: {
      match_all: {}
    },

    // Set value to false to hide filter when in default state.
    value: false
  };
};

export function FreeContentToggle({ isFreeOnly, setCurrentIsFreeOnly }) {
  return (
    <ReactiveComponent
      componentId="availability"
      filterLabel="Free"
      defaultValue={isFreeOnly}
      URLParams
      render={({ setQuery }) => {
        const defaultStringValue = isFreeOnly ? ONLY_FREE : ALL;
        return (
          <div className="free-content-toggle">
            <h2 className="free-content-toggle__header">Availability:</h2>
            <RadioList
              options={FreeContentToggleOptions}
              defaultValue={defaultStringValue}
              onChange={changedValue => {
                reportFilterAppliedAnalytics({
                  filterType: 'availability',
                  filterValue:
                    changedValue === 'all' ? 'all content' : 'only free'
                });
                setQuery(getQuery(changedValue === ONLY_FREE));
                setCurrentIsFreeOnly(changedValue === ONLY_FREE);
              }}
            />
            <SearchStateHelper
              onInit={searchState => {
                if (isFreeOnly === null) {
                  const freeValue = get(
                    searchState,
                    '[only-free].value',
                    false
                  );
                  setCurrentIsFreeOnly(freeValue);
                  setQuery(getQuery(freeValue));
                }
              }}
              onClearFilter={() => {
                setQuery(getQuery(false));
                setCurrentIsFreeOnly(false);
              }}
            />
          </div>
        );
      }}
    />
  );
}

FreeContentToggle.propTypes = {
  isFreeOnly: PropTypes.bool,
  setCurrentIsFreeOnly: PropTypes.func.isRequired
};

FreeContentToggle.defaultProps = {
  isFreeOnly: null
};

const mapStatetoProps = state => ({
  isFreeOnly: state.filters.isFreeOnly
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setCurrentIsFreeOnly: setCurrentIsFreeOnlyAction
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchtoProps)(FreeContentToggle);
