import { track } from '@amplitude/analytics-browser';

export function reportFeaturedContentClickedAnalytics({
  episodeTitle,
  pageSpecialty
}: {
  episodeTitle: string;
  pageSpecialty?: string;
}) {
  track('Featured_Content_Clicked', {
    episode_title: episodeTitle,
    page_specialty: pageSpecialty,
    path: window.location.pathname
  });
}
