import React, { useMemo } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Icon from '../Icons/Icon';
import TextLink from '../TextLink/TextLink';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import { reportCarouselSwipedAnalytics } from '../../analytics/reportCarouselSwipedAnalytics';

import 'slick-carousel/slick/slick.css';
import './HDVideoFeed.scss';

interface ArrowProps {
  className?: string;
  onClick: () => void;
  isUrgentCare: boolean;
}

function ArrowNext({ className = '', onClick, isUrgentCare }: ArrowProps) {
  return (
    <button
      type="button"
      className={classNames(
        className,
        'hd-video-feed__button sketchy-button--arrow-right'
      )}
      onClick={() => {
        reportButtonClickedAnalytics({
          buttonType: 'carousel arrow',
          pageSpecialty: isUrgentCare ? 'UC' : 'HD'
        });
        onClick();
      }}
      disabled={className.includes('slick-disabled')}
    >
      <Icon name="triangle-right" />
    </button>
  );
}

function ArrowPrev({ className = '', onClick, isUrgentCare }: ArrowProps) {
  return (
    <button
      type="button"
      className={classNames(
        className,
        'hd-video-feed__button sketchy-button--arrow-left'
      )}
      onClick={() => {
        reportButtonClickedAnalytics({
          buttonType: 'carousel arrow',
          pageSpecialty: isUrgentCare ? 'UC' : 'HD'
        });
        onClick();
      }}
      disabled={className.includes('slick-disabled')}
    >
      <Icon name="triangle-right" />
    </button>
  );
}

interface Props {
  title?: string;
  children?: React.ReactNode;
  count?: number;
  link?: string;
  to?: string;
  className?: string;
  isUrgentCare?: boolean;
  onClick: () => void;
}

export function HDVideoFeed({
  className,
  title = 'Latest Feed',
  count = 0,
  link = 'See All',
  children,
  to = '/',
  isUrgentCare = false,
  onClick
}: Props) {
  const { reskinVideoPage } = useFlags();

  const sliderBreakpoints = [
    {
      breakpoint: 1380,
      settings: {
        slidesToShow: 4.4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.6,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3.1,
        slidesToScroll: 3,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 2,
        arrows: false
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
        arrows: false
      }
    }
  ];

  const newTitle = useMemo(
    () =>
      isUrgentCare && title.split(' ')[0] === 'UC'
        ? title.split(' ')[1]
        : title,
    [title]
  );

  return (
    <div className={classNames(className, 'hd-video-feed')}>
      <div className="hd-video-feed__header">
        <h2 className="hd-video-feed__title">
          {!reskinVideoPage && isUrgentCare && (
            <span className="hd-video-feed__title-prefix">UC</span>
          )}{' '}
          {newTitle}
        </h2>
        <div className="hd-video-feed__count">{count} videos</div>
        <TextLink
          className="hd-video-feed__link sketchy-link--arrow-right"
          iconName="triangle-right"
          href={to}
          onClick={onClick}
        >
          {link}
        </TextLink>
      </div>
      <Slider
        className="hd-video-feed__slider"
        dots={false}
        infinite={false}
        lazyLoad="ondemand"
        speed={500}
        slidesToShow={5}
        slidesToScroll={4}
        nextArrow={<ArrowNext onClick={onClick} isUrgentCare={isUrgentCare} />}
        prevArrow={<ArrowPrev onClick={onClick} isUrgentCare={isUrgentCare} />}
        responsive={sliderBreakpoints}
        onSwipe={() =>
          reportCarouselSwipedAnalytics({
            pageSpecialty: isUrgentCare ? 'UC' : 'HD'
          })
        }
      >
        {children}
      </Slider>
    </div>
  );
}
