import React from 'react';
import classNames from 'classnames';
import PlaylistTitle from './PlaylistTitle';
import EpisodeHeaderBar from '../EpisodeHeaderBar/EpisodeHeaderBar';
import { C3 } from '../../lib/episodeTypes';

import './EpisodePlaylistHeader.scss';

interface EpisodePlaylistHeaderProps {
  title: string;
  itemCount?: number;
  graphic?: string;
}

export function EpisodePlaylistHeader({
  graphic,
  title,
  itemCount
}: EpisodePlaylistHeaderProps) {
  return (
    <div
      className={classNames(
        {
          [`playlist-header__container--${graphic}`]: !!graphic
        },
        'playlist-header__container'
      )}
    >
      <div className="playlist-header__container-bg">
        <section className="playlist-header">
          <EpisodeHeaderBar
            episodeType={C3}
            displayDropDown={false}
            className="playlist-header__header-bar"
          />
          {title && itemCount && (
            <div className="playlist-header__content">
              <PlaylistTitle title={title} itemCount={itemCount} />
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default EpisodePlaylistHeader;
