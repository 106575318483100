import React from 'react';
import { useDispatch, useSelector } from '../../lib/hooks';
import { toggleFilters as toggleFiltersAction } from '../../actions/filters';
import DropdownButton from '../DropdownButton';

const MobileFilterToggle = () => {
  const dispatch = useDispatch();
  const filtersOpen = useSelector(state => state.filters.open);
  const toggleFilters = () => dispatch(toggleFiltersAction());

  return (
    <DropdownButton
      handleClick={toggleFilters}
      text="Filters"
      isOpen={filtersOpen}
    />
  );
};

export default MobileFilterToggle;
