function isCacheValid(
  // TODO: only use one kind of serialization for the lastUpdate property across all the different states for consistency
  lastUpdate: Date | number | string | null,
  cacheLifetimeMs: number
) {
  if (!lastUpdate) {
    return false;
  }

  let lastUpdateMs;

  if (lastUpdate instanceof Date) {
    lastUpdateMs = lastUpdate.getTime();
  } else if (typeof lastUpdate === 'string') {
    const date = new Date(lastUpdate);
    lastUpdateMs = date.getTime();
  } else {
    lastUpdateMs = lastUpdate;
  }
  const threshold = Date.now() - cacheLifetimeMs;

  if (Number.isNaN(lastUpdateMs)) {
    return false;
  }

  return lastUpdateMs >= threshold;
}

export default isCacheValid;
