// Deprecated - prefer ToggleButtonLink for new code
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as ArrowDownIcon } from '../images/icons/icon-arrow--down.svg';

import './DropdownButton.scss';

const DropdownButton = ({
  className,
  handleClick,
  isOpen,
  text,
  displayCollapseLabel
}) => (
  <button
    type="button"
    className={classnames('dropdown-button', className, {
      'dropdown-button--open': isOpen
    })}
    onClick={handleClick}
  >
    <span className="dropdown-button__text">{text}</span>
    {displayCollapseLabel && (
      <span className="collapse-toggle__text">
        {isOpen ? 'Hide all' : 'Show all'}
      </span>
    )}
    <ArrowDownIcon className="dropdown-button__icon" />
  </button>
);

DropdownButton.defaultProps = {
  className: '',
  handleClick: () => null,
  isOpen: false,
  text: '',
  displayCollapseLabel: false
};

DropdownButton.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  displayCollapseLabel: PropTypes.bool
};

export default DropdownButton;
