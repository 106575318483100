import React from 'react';
import ColumnHeader from './ColumnHeader';
import FeedDoctor from '../../images/episode-guide/doctors-episode-feed.svg';

import './PageHeader.scss';
import './AllEpisodesHeader.scss';

export function AllEpisodesHeader() {
  return (
    <ColumnHeader
      className="feed-header"
      title="EM:RAP Audio Programming"
      description="All of our audio content now lives in one place. Emergency Medicine, Family Medicine, Urgent Care, board review—we’ve got it all! From the #1 EM audio program to the latest LLSA review and everything in-between, we’ve worked hard to create the world’s most comprehensive audio library for medical clinicians."
      doctors={FeedDoctor}
    />
  );
}
