import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './TableCell.scss';

interface TableCellProps {
  children: ReactNode;
  className?: string;
  id?: string;
  scope?: 'row' | 'col' | undefined;
  testId?: string;
}

export function TableCell({
  children,
  className,
  id,
  scope,
  testId
}: TableCellProps) {
  // heading cells must define scope
  const Tag = scope !== undefined ? 'th' : 'td';
  return (
    <Tag
      data-testid={testId}
      id={id}
      scope={scope}
      className={classnames('table-cell', className)}
    >
      {children}
    </Tag>
  );
}
