import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

export function FreeSubscription() {
  const { trialSubscriptionMessaging } = useFlags();
  return (
    <div className="status-bar">
      <div className="status-bar__container">
        <p className="status-bar__text">
          Want{' '}
          <a
            href={getPhpSitePath(
              trialSubscriptionMessaging ? '/account/payment' : '/subscribe'
            )}
          >
            access to all episodes
          </a>
          , discussions, and up to 3.5 hours of new CME each month?
        </p>
        <a
          href={getPhpSitePath(
            trialSubscriptionMessaging ? '/account/payment' : '/subscribe'
          )}
          className="status-bar__action-button"
        >
          Upgrade For Full Access
        </a>
      </div>
    </div>
  );
}
