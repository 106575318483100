import { z } from 'zod';
import { metaSchema } from '../api/meta';

const cmeFacultySchema = z.object({
  disclosure: z.string().nullable(),
  fullname: z.string(),
  biography: z.string().nullable()
});

export const cmeFacultyResponseSchema = z.object({
  status: z.literal('success'),
  data: z.object({
    faculties: z.array(cmeFacultySchema)
  }),
  meta: metaSchema
});

export type CmeFaculty = z.infer<typeof cmeFacultySchema>;
