import { track, flush } from '@amplitude/analytics-browser';

export function reportLinkClickedAnalytics({
  to,
  linkText,
  isExternal = false,
  pageSpecialty,
  eventSource
}: {
  to: string;
  linkText: string;
  isExternal?: boolean;
  pageSpecialty?: string;
  eventSource?: string;
}) {
  track('Link_Clicked', {
    to: to.replace(/^\/(.+)/, '$1').replace('https://www.emrap.org/', ''),
    link_text: linkText.toLowerCase(),
    is_external: isExternal,
    page_specialty: pageSpecialty,
    event_source: eventSource,
    path: window.location.pathname
  });
  flush();
}
