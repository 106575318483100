import React, { ReactNode } from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch';
import classnames from 'classnames';
import { useSelector } from '../../lib/hooks';
import { EpisodeResult, PreviewResult } from '../Results';
import Loader from '../Loader';
import { EpisodeFilterSummary } from './EpisodeFilterSummary';

import './EpisodeList.scss';

const resultListComponentId = 'episode-list';
const resultSize = 8;

interface EpisodeListResultData {
  _id: string;
  episode: {
    title: string;
    resource: string;
    description: string;
    chapter_count: string;
    episode_type: {
      id: number;
      display: string;
    };
    duration: string | number;
    shortname: string;
    publish_date: string;
    cme: {
      hours: number;
    };
    preview: boolean;
  };
}

interface EpisodeListProps {
  episodeTypes: number[];
  query: (
    episodeTypes: number[],
    sort: {
      dataField: string;
      sortBy: string;
    }
  ) => void;
  media: {
    xs: boolean;
    sm: boolean;
  };
  filters?: ReactNode;
  callout?: ReactNode;
}

export function EpisodeList({
  episodeTypes,
  query,
  media,
  filters,
  callout
}: EpisodeListProps) {
  const sortOpen = useSelector(state => state.filters.episodeSortOpen);

  return (
    <div className="episode-list">
      {filters}
      {callout}

      <ReactiveList
        componentId={resultListComponentId}
        dataField=""
        defaultQuery={query}
        size={resultSize}
        pagination={false}
        showResultStats={false}
        scrollOnChange={false}
        loader={<Loader />}
        renderNoResults={() => (
          <>
            <span>No results found.</span>
            <EpisodeFilterSummary episodeTypes={episodeTypes} />
          </>
        )}
        innerClass={{
          sortOptions: 'sort-options',
          resultsInfo: classnames('result-list-info', {
            closed: !sortOpen
          }),
          pagination: 'pagination-controls',
          noResults: 'no-results'
        }}
        react={{
          and: ['all-episodes-query', 'episode-guide-publish-date']
        }}
      >
        {({ data }) => (
          <div className="result-list-container">
            {data.map((item: EpisodeListResultData) => {
              if (item.episode.preview) {
                return (
                  // eslint-disable-next-line no-underscore-dangle
                  <PreviewResult key={item._id} data={item} media={media} />
                );
              }
              // eslint-disable-next-line no-underscore-dangle
              return <EpisodeResult key={item._id} data={item} media={media} />;
            })}
          </div>
        )}
      </ReactiveList>
    </div>
  );
}
