import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import striptags from 'striptags';
import formatDuration from '../../lib/formatDuration';
import { LinkModernizer } from '../LinkModernizer';

import './MediaThumbnail.scss';

export interface MediaThumbnailProps {
  className?: string;
  uploadDate?: Date;
  posterFrame: string;
  title: string;
  description?: string;
  duration: number;
  chapters?: number;
  episodeUrl: string;
  format: 'audio' | 'video';
  onClick?: () => void;
}

export function MediaThumbnail({
  className,
  posterFrame,
  uploadDate,
  title,
  description = '',
  duration,
  chapters,
  episodeUrl,
  format,
  onClick
}: MediaThumbnailProps) {
  return (
    <LinkModernizer
      className={cn('uc-media-thumbnail', className)}
      to={episodeUrl}
      href={episodeUrl}
      onClick={onClick}
    >
      <div className="uc-media-thumbnail__content">
        <img
          className={cn({
            'uc-media-thumbnail__audio-posterframe': format === 'audio',
            'uc-media-thumbnail__video-posterframe': format === 'video'
          })}
          src={posterFrame}
          alt=""
        />
        <div className="uc-media-thumbnail__details">
          {uploadDate && (
            <p className="uc-media-thumbnail__upload-date">
              {moment(uploadDate).format('MMMM YYYY')}
            </p>
          )}
          <p className="uc-media-thumbnail__title">{title}</p>
          <p className="uc-media-thumbnail__description">
            {striptags(description)}
          </p>
          <div>
            <span className="uc-media-thumbnail__duration">
              {formatDuration(duration)}
            </span>
            {chapters ? (
              <span className="uc-media-thumbnail__chapters">
                {chapters} {chapters > 1 ? 'Chapters' : 'Chapter'}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </LinkModernizer>
  );
}
