import React from 'react';
import ReactModal from 'react-modal';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { FacultyModalHeadshot } from './FacultyModalHeadshot';
import { ModalButton } from './ModalButton';
import { reportCarouselSwipedAnalytics } from '../../analytics/reportCarouselSwipedAnalytics';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import 'slick-carousel/slick/slick.css';
import './FacultyModal.scss';

export interface Faculty {
  id: number;
  title: string;
  text: string;
  imgSrc: string;
}

interface Props {
  isOpen: boolean;
  onModalClose: () => void;
  faculty: Faculty[];
  facultySelected: number;
  onFacultyChange: (index: number) => void;
}

export function FacultyModal({
  isOpen,
  onModalClose,
  faculty,
  facultySelected,
  onFacultyChange
}: Props) {
  const tablet = useMediaQuery({ query: '(min-width: 768px)' });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: facultySelected,
    afterChange: (index: number) => onFacultyChange(index),
    prevArrow: (
      <ModalButton
        icon="arrow-left"
        size={tablet ? 'large' : 'small'}
        onClick={() => null}
        report={() =>
          reportButtonClickedAnalytics({
            buttonType: 'carousel arrow',
            eventSource: 'faculty slider'
          })
        }
      />
    ),
    nextArrow: (
      <ModalButton
        icon="arrow-right"
        size={tablet ? 'large' : 'small'}
        onClick={() => null}
        report={() =>
          reportButtonClickedAnalytics({
            buttonType: 'carousel arrow',
            eventSource: 'faculty slider'
          })
        }
      />
    )
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onModalClose}
      overlayClassName="uc-faculty-modal__container"
      className="uc-faculty-modal"
    >
      <ModalButton
        icon="close"
        size={tablet ? 'large' : 'small'}
        onClick={onModalClose}
        report={() =>
          reportButtonClickedAnalytics({
            buttonType: 'close menu',
            eventSource: 'faculty slider'
          })
        }
        className="uc-faculty-modal__close-button"
      />
      <Slider
        {...settings}
        className="uc-faculty-modal__slider"
        onSwipe={() => reportCarouselSwipedAnalytics({ pageSpecialty: 'UC' })}
      >
        {faculty.map(({ id, title, text, imgSrc }) => (
          <div key={id} className="uc-faculty-modal__content">
            <FacultyModalHeadshot
              imgSrc={imgSrc}
              imgAlt={faculty[facultySelected].imgSrc}
              className="uc-faculty-modal__headshot"
            />
            <h3 className="uc-faculty-modal__title">{title}</h3>
            <p className="uc-faculty-modal__text">{text}</p>
          </div>
        ))}
      </Slider>
    </ReactModal>
  );
}
