import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resultTitle, resultAbstract } from '../../lib/highlightedElements';
import { toggleCitationToPrint as toggleCitationToPrintAction } from '../../actions/print';
import AudienceTag from './AudienceTag';
import { ROP } from '../../lib/episodeTypes';

import './CitationResult.scss';

const citationAbstractTitle = title => {
  if (title) {
    const matched = title.match(/(abstract)([0-9]+)/);
    if (matched) {
      const [, , second] = matched;
      return <span className="abstract-title"> / Abstract {second}</span>;
    }
  }
  return null;
};

const CitationResult = ({
  media,
  selectAll,
  toggleCitationToPrint,
  selectedCitations,
  data
}) => {
  const { id } = data.citation;

  // check selected citations when transitioning back from main search
  const [checked, setChecked] = useState(
    typeof selectedCitations[id] !== 'undefined'
  );

  const toggleCheckbox = (citationId, citationData, toggleAll) => {
    toggleCitationToPrint(citationId, citationData, toggleAll);
    setChecked(typeof toggleAll === 'undefined' ? !checked : toggleAll);
  };

  // toggle the checkbox if select all has been updated
  const selectRef = useRef(selectAll);
  const toggleAllCheckboxes = () => {
    if (selectRef.current !== selectAll) {
      toggleCheckbox(id, data, selectAll);
    }
  };
  useEffect(toggleAllCheckboxes, [selectAll]);

  const {
    highlight,
    episode: {
      title,
      resource: episodeResource,
      episode_type: { id: episodeType }
    },
    chapter: { shortname: abstractTitle, resource: chapterResource },
    citation: { citation, abstract_xhtml: abstractXhtml }
  } = data;
  return (
    <div className="result-item citation">
      <div className="check-container">
        <input
          id={`abstract-check-${id}`}
          type="checkbox"
          className="abstract-check"
          checked={checked}
          readOnly
        />
        <button
          type="button"
          className="circle-checkbox"
          onClick={() => toggleCheckbox(id, data)}
        />
      </div>
      <div className="content">
        <h2 className="episode-title">
          <a href={episodeResource}>{title}</a>{' '}
          {citationAbstractTitle(abstractTitle)}
          {episodeType === ROP && <AudienceTag episodeType={episodeType} />}
        </h2>
        {resultTitle(citation, highlight, media.xs)}
        {chapterResource ? (
          <a className="btn btn-info go-to-chapter" href={chapterResource}>
            Listen to Abstract
          </a>
        ) : null}
        {resultAbstract(abstractXhtml, highlight, media.xs)}
      </div>
    </div>
  );
};

CitationResult.propTypes = {
  media: PropTypes.shape({
    mobile: PropTypes.bool,
    xs: PropTypes.bool
  }).isRequired,
  selectAll: PropTypes.bool,
  toggleCitationToPrint: PropTypes.func.isRequired,
  selectedCitations: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.shape({
    highlight: PropTypes.shape({
      title: PropTypes.string,
      'citation.abstract_xhtml': PropTypes.arrayOf(PropTypes.string)
    }),
    episode: PropTypes.shape({
      title: PropTypes.string,
      resource: PropTypes.string,
      episode_type: PropTypes.shape({
        id: PropTypes.number
      })
    }),
    chapter: PropTypes.shape({
      shortname: PropTypes.string,
      resource: PropTypes.string
    }),
    citation: PropTypes.shape({
      id: PropTypes.number,
      citation: PropTypes.string,
      abstract_xhtml: PropTypes.string
    })
  }).isRequired
};

CitationResult.defaultProps = {
  selectAll: false
};

const mapStatetoProps = state => ({
  selectAll: state.print.selectAll,
  selectedCitations: state.print.selectedCitations
});

const mapDispatchtoProps = dispatch => ({
  toggleCitationToPrint: (id, data, selectAll) =>
    dispatch(toggleCitationToPrintAction(id, data, selectAll))
});

export default connect(mapStatetoProps, mapDispatchtoProps)(CitationResult);
