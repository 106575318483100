import React from 'react';
import { useDispatch, useSelector } from '../../lib/hooks';
import {
  setSearchSort as setSearchSortAction,
  setMenusClosed as setMenusClosedAction,
  toggleSearchSort as toggleSortAction
} from '../../actions/filters';
import SortSelect, { SortOption } from './SortSelect';

interface SearchSortSelectProps {
  className?: string;
  options: SortOption[];
}

const SearchSortSelect = ({ className, options }: SearchSortSelectProps) => {
  const dispatch = useDispatch();
  const currentSort = useSelector(state => state.filters.searchSort);
  const sortOpen = useSelector(state => state.filters.searchSortOpen);

  const setSort = (sort: SortOption) => dispatch(setSearchSortAction(sort));
  const toggleSort = () => dispatch(toggleSortAction());
  const setMenusClosed = () => dispatch(setMenusClosedAction());

  return (
    <SortSelect
      className={className ?? 'search-sort-select'}
      options={options}
      currentSort={currentSort}
      sortOpen={sortOpen}
      setSort={setSort}
      toggleSort={toggleSort}
      setMenusClosed={setMenusClosed}
    />
  );
};

export default SearchSortSelect;
