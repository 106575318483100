import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './TableHeadingRow.scss';

interface TableHeadingRowProps {
  children: ReactNode;
  className?: string;
}

export function TableHeadingRow({ children, className }: TableHeadingRowProps) {
  return (
    <tr className={classnames('table-heading-row', className)}>{children}</tr>
  );
}
