import { z } from 'zod';

export const subscriptionRenewalStatusTypeSchema = z.enum([
  'manual',
  'past-due',
  'expired',
  'okay',
  'cancelled'
]);

export type SubscriptionRenewalStatusType = z.infer<
  typeof subscriptionRenewalStatusTypeSchema
>;

export const subscriptionSchema = z.object({
  id: z.number(),
  title: z.string(),
  type: z.string(),
  startDate: z.string(),
  expiryDate: z.string().optional(),
  automaticRenewalStatus: subscriptionRenewalStatusTypeSchema,
  cardExpiryDate: z.string().optional()
});

export type Subscription = z.infer<typeof subscriptionSchema>;
