import React from 'react';
import { useSelector } from '../../lib/hooks';
import { accountSlice } from '../../store/account';

import './SpoofMessage.scss';

function getFullname(first: string, last: string, suffix?: string | null) {
  return suffix ? `${first} ${last}, ${suffix}` : `${first} ${last}`;
}

export default function SpoofMessage() {
  const account = useSelector(state => state[accountSlice.name].account);

  return account && account.isSpoofed ? (
    <section className="spoof-message">
      Watch out! You are currently signed in as{' '}
      {getFullname(account.firstName, account.lastName, account.suffix)}.
    </section>
  ) : null;
}
