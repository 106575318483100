import { relatedContentApiResponseSchema } from '../schema/api/relatedContent';
import { fetchApi } from './fetchApi';
import { reportError } from './reportError';

const MAX_RELATED_ITEMS = 5;

export async function fetchRelatedContent(chapterId: number) {
  try {
    const response = await fetchApi(`chapters/${chapterId}/related_content`, {
      baseUrl: process.env.REACT_APP_API_URI
    });
    const { chapter_related_content: chapterContent } =
      relatedContentApiResponseSchema.parse(await response.json());

    return {
      relatedContent: chapterContent.related_content.slice(
        0,
        MAX_RELATED_ITEMS
      ),
      relatedPlaylist: chapterContent.in_playlists.slice(0, MAX_RELATED_ITEMS)
    };
  } catch (error) {
    reportError(error);
    return { relatedContent: [], relatedPlaylist: [] };
  }
}
