import { z } from 'zod';

const Providers = z.enum(['pim-2022']);
export type Providers = z.infer<typeof Providers>;

export const frontMatterSchema = z.object({
  providers: z.array(Providers),
  release_date: z.string(),
  expiry_date: z.string(),
  episode_title: z.string(),
  content_xhtml: z.string()
});

export type FrontMatter = z.infer<typeof frontMatterSchema>;
