import React from 'react';
import { HDThumbnailDuration } from '../HDThumbnails/HDThumbnailDuration';

import './HDHeroFeatureVideo.scss';

interface Props {
  href: string;
  posterFrame: string;
  duration?: number;
  onClick: () => void;
}

export function HDHeroFeatureVideo({
  href,
  posterFrame,
  duration = 0,
  onClick
}: Props) {
  return (
    <a className="hd-hero-feature-video__link" href={href} onClick={onClick}>
      <div className="hd-hero-feature-video__video">
        <div>
          <img
            src={posterFrame}
            alt="HD Hero Feature Video"
            className="hd-hero-feature-video__posterframe"
          />
          <HDThumbnailDuration
            className="hd-hero-feature-video__duration"
            duration={duration}
          />
        </div>
      </div>
    </a>
  );
}
