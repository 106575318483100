import moment from 'moment-timezone';

const FIRST = 'h:mm';
const LAST = 'h:mm a';

export default function formatTimeRange(
  start: number,
  end: number | null,
  timeZone: string,
  showPeriod?: boolean
) {
  return end !== null
    ? `${moment(start * 1000)
        .tz(timeZone)
        .format(showPeriod ? LAST : FIRST)} - ${moment(end * 1000)
        .tz(timeZone)
        .format(LAST)}`
    : moment(start * 1000)
        .tz(timeZone)
        .format(LAST);
}
