import React from 'react';

import './_style/lib/components/FormDetails.scss';

type FormDetailsProps = {
  text: string;
  size?: 'default' | 'small';
};

export function FormDetails({ text, size = 'default' }: FormDetailsProps) {
  return (
    <span className="global-form-details" data-size={size}>
      {text}
    </span>
  );
}
