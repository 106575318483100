import { track } from '@amplitude/analytics-browser';
import { ChapterTab } from '../lib/types';

interface ReportPlayerTabOpenedAnalyticsParams {
  tab: ChapterTab;
  pageSpecialty?: string;
}

export function reportPlayerTabOpenedAnalytics({
  tab,
  pageSpecialty
}: ReportPlayerTabOpenedAnalyticsParams) {
  let eventName;
  switch (tab) {
    case 'CME Credits':
      eventName = 'Credits_Tab';
      break;
    case 'Smart Cards':
      eventName = 'Smart Card_Tab';
      break;
    default:
      eventName = `${tab}_Tab`;
      break;
  }

  track(eventName, {
    page_specialty: pageSpecialty,
    path: window.location.pathname
  });
}
