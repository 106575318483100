import React from 'react';
import { RelatedContentPlaylistItem } from '../../schema/playlist/RelatedContentItem';
import { HDPlaylistItems } from '../HDPlaylistPage/HDPlaylistItems';

import './RelatedPlaylist.scss';

interface RelatedPlaylistProps {
  relatedPlaylist: RelatedContentPlaylistItem[];
  isUrgentCare: boolean;
}

export const RelatedPlaylist = ({
  relatedPlaylist,
  isUrgentCare
}: RelatedPlaylistProps) => (
  <div className="related-playlist">
    <span className="related-playlist__title">
      {`THIS VIDEO ALSO APPEARS IN ${
        relatedPlaylist.length === 1 ? 'THIS PLAYLIST' : 'THESE PLAYLISTS'
      }`}
    </span>
    <div className="related-playlist__list-container">
      <HDPlaylistItems
        items={relatedPlaylist}
        isChapterPage
        className="related-playlist__list"
        isUrgentCare={isUrgentCare}
        trimmedUrl=""
        isRelatedPlaylist
      />
    </div>
  </div>
);
