import { WebAttachmentBlock } from '../schema/webEpisode/webChapterBlocks';
import { WebEpisode } from '../schema/webEpisode/webEpisode';

function getSmartCards(episode: WebEpisode | undefined) {
  if (!episode) {
    return [];
  }
  const blockAttachments: WebAttachmentBlock[] = [];
  episode.chapters.forEach(episodeChapter =>
    episodeChapter.blocks.attachment.forEach(attachment =>
      blockAttachments.push(attachment)
    )
  );
  const smartCardAttatchments = blockAttachments.filter(
    attachment => attachment.isSmartCard === true
  );

  // "Duplicate" smartCards from episode and chapter attachments may contain different IDs
  // This removes cards based on the same originalFileName and fileSize
  const uniqueSmartCards: Array<WebAttachmentBlock> = [];
  smartCardAttatchments.forEach(smartCard => {
    if (!smartCard.attachmentId) {
      // This smart card has a missing id, ignore it
      // eslint-disable-next-line no-console
      console.error('Unexpected smart card without id: ', smartCard);
      return;
    }
    if (
      uniqueSmartCards.some(
        card =>
          card.attachmentId === smartCard.attachmentId &&
          card.fileSize === smartCard.fileSize
      )
    ) {
      // We already added this smart card, ignore duplicates
      return;
    }
    uniqueSmartCards.push(smartCard);
  });
  return uniqueSmartCards;
}

export default getSmartCards;
