import React from 'react';
import cn from 'classnames';
import ReactPlayer from 'react-player';
import { IconButton } from './IconButton';

import './VideoPlayer.scss';

interface Props {
  url: string;
  playing: boolean;
  onPlayClick: () => void;
  onStart?: () => void;
  posterFrame?: string;
  className?: string;
}

export function VideoPlayer({
  url,
  playing,
  onPlayClick,
  onStart,
  posterFrame,
  className
}: Props) {
  return (
    <div className={cn('uc-video-player__container', className)}>
      <ReactPlayer
        aria-label="video player"
        url={url}
        className="uc-video-player"
        width="100%"
        height="100%"
        controls
        playing={playing}
        light={posterFrame}
        onReady={onPlayClick}
        onStart={onStart}
        playIcon={
          <IconButton
            icon="media-controls-play"
            onClick={onPlayClick}
            className="uc-video-player__button"
            iconClassName="uc-video-player__button-icon"
          />
        }
      />
    </div>
  );
}
