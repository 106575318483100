import React from 'react';
import { EpisodeCard } from '../EpisodeCard/EpisodeCard';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { ESC3PlaylistItem } from '../../schema/playlist/ESC3PlaylistItem';

interface EpisodePlaylistItemsProps {
  items: ESC3PlaylistItem[];
  trimmedUrl: string;
}

export function EpisodePlaylistItems({
  items,
  trimmedUrl
}: EpisodePlaylistItemsProps) {
  return (
    <div className="playlist-items">
      {items.map(item => (
        <EpisodeCard
          key={item.url_path}
          imageUrl={
            item.thumbnail
              ? `${process.env.REACT_APP_CDN_BASE}${item.thumbnail['episode-card'].path}`
              : ''
          }
          itemCount={item.item_count ?? 0}
          title={item.title}
          href={getPhpSitePath(`${trimmedUrl}/${item.url_path}`)}
          duration={item.duration ?? 0}
          headingLevel={2}
          page="c3"
        />
      ))}
    </div>
  );
}

export default EpisodePlaylistItems;
