import React from 'react';
import { Nav } from '@silverorange/emrap-nav';
import { NavLink, useLocation } from 'react-router-dom';
import logdown from 'logdown';
import config from '../../config';
import { useSelector } from '../../lib/hooks';
import { useGetMarketingSpots } from '../../hooks/useGetMarketingSpots';
import { isEmrapUrl } from '../../lib/isEmrapUrl';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { reportOpenPrivacyPolicyAnalytics } from '../../analytics/reportOpenPrivacyPolicyAnalytics';
import { reportOpenTermsAnalytics } from '../../analytics/reportOpenTermsAnalytics';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import { reportMarketingSpotClickedAnalytics } from '../../analytics/reportMarketingSpotClickedAnalytics';
import { reportError } from '../../lib/reportError';

const episodeRoutes = config.episodes.map(data => data.key).join('|');
const routableExp = new RegExp(
  `^(/|/search|/search/citations|/(${episodeRoutes})|/(${episodeRoutes})/archive/[\\w-]+|/hd/playlist/[\\w-]+)$`
);

const logger = logdown('EM:RAP Client: Nav');

async function handleSignOutError(response: Response) {
  const body = await response.text();
  const message = `Error signing out: ${body}`;
  logger.log(message);
  reportError(new Error(message));
}

export function ConnectedNav() {
  const reduxAccount = useSelector(state => state.account.account);
  const loadingAccount = useSelector(state => state.account.isLoading);
  const location = useLocation();
  const isRoutable = routableExp.test(location.pathname);
  const account:
    | {
        avatarUri: string;
        firstName: string;
        lastName: string;
        suffix?: string;
        email: string;
      }
    | undefined = reduxAccount
    ? {
        avatarUri: reduxAccount.avatarUri,
        firstName: reduxAccount.firstName,
        lastName: reduxAccount.lastName,
        suffix: reduxAccount.suffix ?? undefined,
        email: reduxAccount.email
      }
    : undefined;

  const miniBannerSpots = useGetMarketingSpots('emrapMiniBanner').flatMap(
    spot => ({
      id: spot.id,
      message: spot.message,
      link: isEmrapUrl(spot.link) ? getPhpSitePath(spot.link) : spot.link,
      ctaText: spot.ctaText,
      campaignColor: spot.campaignColor
    })
  );

  return (
    <Nav
      account={account}
      loadingAccount={loadingAccount}
      signOutEndpoint={process.env.REACT_APP_SIGN_OUT_URL ?? ''}
      handleSignOutError={handleSignOutError}
      isRoutable={isRoutable}
      navLinkComponent={NavLink}
      isStickyNav
      miniBannerSpots={miniBannerSpots}
      handleMiniBannerClickedAnalyticsEvent={(
        marketingSpotType: 'mini-banner',
        buttonText: string,
        to: string
      ) =>
        reportMarketingSpotClickedAnalytics({
          marketingSpotType,
          buttonText,
          to
        })
      }
      handleLinkClickedAnalyticsEvent={(
        to: string,
        linkText: string,
        eventSource: string
      ) => reportLinkClickedAnalytics({ to, linkText, eventSource })}
      handleButtonClickedAnalyticsEvent={(
        buttonType: 'open menu' | 'close menu' | 'end session',
        eventSource: string,
        buttonText?: string
      ) => {
        reportButtonClickedAnalytics({ buttonType, eventSource, buttonText });
      }}
      handleOpenPrivacyPolicyAnalyticsEvent={() =>
        reportOpenPrivacyPolicyAnalytics()
      }
      handleOpenTermsAnalyticsEvent={() => reportOpenTermsAnalytics()}
    />
  );
}
