import { z } from 'zod';
import { webCommentSchema } from '../webComment/webComment';

export const postCommentApiResponseSchema = z.object({
  comment: webCommentSchema
    .pick({
      id: true,
      account: true,
      chapter: true,
      parent: true,
      status: true
    })
    .merge(z.object({ bodytext: z.string(), createdate: z.string() })),
  isEditor: z.boolean()
});
