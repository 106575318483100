import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ESVideoPlaylistApiResponseSchema } from '../schema/playlist/ESVideoPlaylist';
import config from '../config';
import { reportError } from '../lib/reportError';

const { url: elasticsearchBase, videoPlaylistApp } = config;

export const videoPlaylistsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: elasticsearchBase }),
  reducerPath: 'videoPlaylists',
  endpoints: builder => ({
    loadVideoPlaylist: builder.query({
      query: (urlPath: string) => ({
        url: `/${videoPlaylistApp}/_search`,
        method: 'POST',
        body: {
          query: {
            term: {
              url_path: urlPath
            }
          },
          size: 1
        }
      }),
      transformResponse: (response, _, arg) => {
        try {
          const parsedResponse =
            // eslint-disable-next-line no-underscore-dangle
            ESVideoPlaylistApiResponseSchema.parse(response).hits.hits[0]
              ?._source;

          return parsedResponse;
        } catch (error) {
          reportError(`HD Playlist with URL path “${arg}” not found.`);
          throw error;
        }
      }
    }),
    loadTopLevelVideoPlaylists: builder.query({
      query: (ordering: string) => ({
        url: `/${videoPlaylistApp}/_search`,
        method: 'POST',
        body: {
          sort: [
            ordering === 'urgent-care'
              ? { display_order_uc: 'asc' }
              : { display_order: 'asc' }
          ],
          query:
            ordering === 'urgent-care'
              ? {
                  bool: {
                    should: [
                      { term: { is_top_level: true } },
                      { term: { url_path: 'featured-uc' } }
                    ]
                  }
                }
              : { term: { is_top_level: true } },
          size: 100
        }
      }),
      transformResponse: response => {
        try {
          const parsedResponse = ESVideoPlaylistApiResponseSchema.parse(
            response
            // eslint-disable-next-line no-underscore-dangle
          ).hits.hits.map(hit => hit._source);

          return parsedResponse;
        } catch (error) {
          reportError('Top level HD playlists not found.');
          throw error;
        }
      }
    })
  })
});

export const { useLoadVideoPlaylistQuery, useLoadTopLevelVideoPlaylistsQuery } =
  videoPlaylistsApi;
