import moment from 'moment';

export const MONTHS = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sept',
  'oct',
  'nov',
  'dec',
  'january',
  'february',
  'march',
  'april',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const yearRegex = /(?:^|\s)(19|20)?([0-9][0-9])(?:\s|$)/;
export const monthRegex = new RegExp(`(?:^|\\s)(${MONTHS.join('|')})(?:$|\\s)`);

export function matchYear(text: string) {
  const yearMatch = text.match(yearRegex);

  if (yearMatch && yearMatch[1] && yearMatch[2]) {
    return `${yearMatch[1]}${yearMatch[2]}`;
  }
  if (yearMatch && yearMatch[2]) {
    return `20${yearMatch[2]}`;
  }

  return null;
}

export function matchMonth(text: string) {
  const monthMatch = text.match(monthRegex);

  if (monthMatch && monthMatch[1]) {
    return monthMatch[1].charAt(0).toUpperCase() + monthMatch[1].slice(1);
  }

  return null;
}

export function getDateRange(currentSearch: string) {
  const year = matchYear(currentSearch);
  const month = matchMonth(currentSearch);

  const date = moment();
  if (year && month) {
    date.year(+year);
    date.month(month);
    date.date(1);
    return {
      gte: date.format('YYYY-MM-DD'),
      lt: date.add(1, 'month').format('YYYY-MM-DD'),
      format: 'yyyy-MM-dd',
      boost: 20
    };
  }
  if (year) {
    date.year(+year);
    date.month('Jan');
    date.date(1);
    return {
      gte: date.format('YYYY-MM-DD'),
      lt: date.add(1, 'year').format('YYYY-MM-DD'),
      format: 'yyyy-MM-dd',
      boost: 20
    };
  }
  if (month) {
    date.month(month);
    date.date(1);
    return {
      gte: date.format('YYYY-MM-DD'),
      lt: date.add(1, 'month').format('YYYY-MM-DD'),
      format: 'yyyy-MM-dd',
      boost: 20
    };
  }

  return null;
}

export function stripDate(text: string) {
  const yearMatch = text.match(yearRegex);
  const monthMatch = text.match(monthRegex);

  let newText = text;
  if (yearMatch) {
    newText = newText.replace(yearMatch[0], ' ');
  }
  if (monthMatch) {
    newText = newText.replace(monthMatch[0], ' ');
  }

  return newText;
}

export function getDateQuery(
  currentSearch: string,
  currentDateRange: {
    start: number;
    end: number;
  }
) {
  const range = getDateRange(currentSearch.toLowerCase());

  const useRange =
    range &&
    new Date(range.gte).getTime() >= currentDateRange.start &&
    new Date(range.lt).getTime() <= currentDateRange.end;

  if (useRange) {
    const remainingTerms = stripDate(currentSearch.toLowerCase());

    const multiMatch = [];
    // if there are no other terms than the date, favor the introduction
    if (!remainingTerms.trim()) {
      multiMatch.push({
        multi_match: {
          query: 'introduction',
          fields: ['chapter.title^7', 'chapter.description^2'],
          type: 'best_fields',
          operator: 'or'
        }
      });
    } else {
      multiMatch.push({
        multi_match: {
          query: remainingTerms,
          fields: ['chapter.title^7', 'chapter.description^2'],
          type: 'best_fields',
          operator: 'or'
        }
      });
    }

    return {
      query: {
        bool: {
          should: [...multiMatch],

          must: {
            range: {
              'episode.publish_date': range
            }
          }
        }
      }
    };
  }

  return {
    query: {
      match_all: {}
    }
  };
}
