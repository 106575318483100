import { z } from 'zod';
import { simpleFacultySchema } from './simpleFaculty';

export const webMediaSchema = z.object({
  id: z.number(),
  uri: z.string(),
  edition: z.string(),
  editionTitle: z.string(),
  authors: z.array(simpleFacultySchema),
  fileSize: z.number(),
  duration: z.number(),
  isPrimary: z.boolean(),
  format: z.union([z.literal('audio'), z.literal('video')]),
  image: z.string().optional(), // Only for video content, for audio the artwork of the chapter that comes in webChapterSchema.image is used
  verticalVideo: z
    .object({
      uri: z.string(),
      image: z.string(),
      filesize: z.number(),
      vertical_only: z.boolean()
    })
    .optional(),
  downloadUrl: z.string().optional()
});

export type WebMedia = z.infer<typeof webMediaSchema>;
