import React from 'react';
import md5 from 'md5';
import config from '../../config';
import gravatarImg from '../../images/icons/gravatar80.png';

import './ConversationAvatar.scss';

interface ConversationAvatarProps {
  gravatarEmail: string | null;
}

const escapedDefaultGravatar = encodeURIComponent(config.defaultGravatar);

const ConversationAvatar = ({ gravatarEmail }: ConversationAvatarProps) => {
  const gravatarUri = gravatarEmail
    ? `https://secure.gravatar.com/avatar/${md5(
        gravatarEmail
      )}?s=80&d=${escapedDefaultGravatar}`
    : gravatarImg;

  return (
    <div className="conversation-avatar">
      <img src={gravatarUri} alt="avatar" />
    </div>
  );
};

export default ConversationAvatar;
