import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { FlagState } from '../lib/stateTypes';

export const initialState: FlagState = {
  flags: {},
  initialized: false
};

export const flagsSlice = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    setFlags: (state, action: PayloadAction<LDFlagSet>) => {
      state.flags = { ...state.flags, ...action.payload };
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    }
  }
});
