import { useEffect, useState } from 'react';
import {
  TypedUseSelectorHook,
  useDispatch as useD,
  useSelector as useS
} from 'react-redux';
import config from '../config';
import { AppDispatch, RootState } from '../store';

const { url: elasticsearchBase } = config;

export const useDispatch = () => useD<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useS;

interface ElasticsearchHit<T> {
  _source: T;
  [key: string]: unknown;
}

interface ElasticsearchResponse<T> {
  hits?: {
    hits?: ElasticsearchHit<T>[];
  };
}

export function useElasticsearch<T>(esIndex: string, body: unknown) {
  const [data, setData] = useState<T[]>();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${elasticsearchBase}/${esIndex}/_search`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`ElasticSearch error: ${response.status}`);
        }
        return response.json() as Promise<ElasticsearchResponse<T>>;
      })
      .then(json =>
        // eslint-disable-next-line no-underscore-dangle
        setData(json.hits?.hits?.map(hit => hit._source as T))
      )
      .catch(setError)
      .finally(() => setLoading(false));
  }, [esIndex, body]);

  return { data, error, loading };
}
