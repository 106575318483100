import React from 'react';
import { FeedbackBar } from './FeedbackBar';

// NOTE: this component needs to render as a direct child of App to reach full height
export function FeedbackErrorPage() {
  return (
    <FeedbackBar
      variant="negative"
      format="page"
      headline="This page has an error"
      message="There seems to be an error loading data."
      actionText="Try Refreshing"
      href={window.location.href}
    />
  );
}
