import React from 'react';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Wrapper } from '../Wrapper';
import { PrimaryLinkButton } from './PrimaryLinkButton';
import { MediaThumbnail } from './MediaThumbnail';
import Loader from '../Loader';
import { LoadingError } from '../LoadingError';
import { useElasticsearch } from '../../lib/hooks';
import { UC_MAX } from '../../lib/episodeTypes';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getEpisodePath } from '../../lib/getEpisodePath';
import { getHDPath } from '../../lib/getHDPath';
import { ESEmrapGuideChapterDoc } from '../../lib/types';
import { ESVideoPlaylist } from '../../schema/playlist/ESVideoPlaylist';
import { ESVideoPlaylistChapterItem } from '../../schema/playlist/ESVideoPlaylistItem';
import config from '../../config';

import './DashboardLatestSection.scss';

const { chapterArtUrl } = config;

export interface DashboardLatestSectionProps {
  className?: string;
}

const audioChapterQuery = {
  query: {
    bool: {
      must: [
        { term: { 'episode.episode_type.id': UC_MAX } },
        { term: { type: 'chapter' } }
      ]
    }
  },
  sort: [
    { 'episode.publish_date': { order: 'desc' } },
    { 'chapter.displayorder': { order: 'asc' } }
  ],
  size: 2
};

const latestVideoPlaylistQuery = {
  query: {
    term: {
      url_path: 'latest-uc'
    }
  }
};

export function DashboardLatestSection({
  className
}: DashboardLatestSectionProps) {
  const {
    data: audioEpisodesData,
    loading: isAudioEpisodesDataLoading,
    error: audioEpisodesError
  } = useElasticsearch<ESEmrapGuideChapterDoc>(
    'emrap.guide.episode',
    audioChapterQuery
  );

  const {
    data: latestVideoPlaylistData,
    loading: isLatestVideoPlaylistLoading,
    error: latestVideoPlaylistError
  } = useElasticsearch<ESVideoPlaylist>(
    'emrap.hd.video-playlist',
    latestVideoPlaylistQuery
  );

  const { modernizePlayerPage } = useFlags();

  const latestVideos = latestVideoPlaylistData?.[0]?.items
    .filter(
      (item): item is ESVideoPlaylistChapterItem => item.type === 'chapter'
    )
    .slice(0, 2);

  return (
    <Wrapper className={cn('uc-dashboard-latest', className)}>
      <div className="uc-dashboard-latest__episodes-container">
        <div className="uc-dashboard-latest__subtitle-container">
          <h3 className="uc-dashboard-latest__subtitle">LATEST AUDIO</h3>
          <p className="uc-dashboard-latest__subtitle-text">
            Expert CME from leaders in the field, delivered to you every week.
          </p>
        </div>
        {isAudioEpisodesDataLoading ? (
          <Loader className="uc-dashboard-latest__audio-thumbnails-loader" />
        ) : (
          <ul
            aria-label="latest audio"
            className="uc-dashboard-latest__audio-thumbnails-container"
          >
            {audioEpisodesError ? (
              <LoadingError />
            ) : (
              audioEpisodesData &&
              audioEpisodesData.map(({ chapter, episode }) => {
                const chapterResourceUrl = chapter.resource.replace(
                  /^http(s?):\/\/www.emrap.org/,
                  ''
                );
                return (
                  <li
                    className="uc-dashboard-latest__audio-thumbnail"
                    key={chapter.id}
                  >
                    <MediaThumbnail
                      uploadDate={new Date(episode.publish_date ?? '')}
                      posterFrame={`${chapterArtUrl}/${chapter.artwork.id}.jpg`}
                      title={chapter.title}
                      description={chapter.description}
                      duration={chapter.media?.md?.duration ?? 0}
                      episodeUrl={
                        modernizePlayerPage
                          ? getEpisodePath(
                              true,
                              `/${episode.shortname}/${chapter.shortname}`
                            )
                          : chapterResourceUrl
                      }
                      format="audio"
                      onClick={() => {
                        reportLinkClickedAnalytics({
                          to: `${episode.shortname}/${chapter.shortname}`,
                          linkText: chapter.title,
                          eventSource: 'Latest Audio',
                          pageSpecialty: 'UC'
                        });
                      }}
                    />
                  </li>
                );
              })
            )}
          </ul>
        )}
        <PrimaryLinkButton
          icon="arrow-right"
          text="See All"
          to="/uc-audio"
          className="uc-dashboard-latest__see-all-button"
          onClick={() =>
            reportLinkClickedAnalytics({
              to: 'uc-audio',
              linkText: 'Latest Audio See All',
              pageSpecialty: 'UC'
            })
          }
        />
      </div>
      <div className="uc-dashboard-latest__courses-container">
        <div className="uc-dashboard-latest__subtitle-container">
          <h3 className="uc-dashboard-latest__subtitle">LATEST VIDEO</h3>
          <p className="uc-dashboard-latest__subtitle-text">
            Expert CME from leaders in the field, delivered to you every week.
          </p>
        </div>
        {isLatestVideoPlaylistLoading ? (
          <Loader className="uc-dashboard-latest__video-thumbnails-loader" />
        ) : (
          <ul
            aria-label="latest videos"
            className="uc-dashboard-latest__video-thumbnails-container"
          >
            {latestVideoPlaylistError ? (
              <LoadingError />
            ) : (
              latestVideos &&
              latestVideos.map(item => (
                <li
                  className="uc-dashboard-latest__video-thumbnail"
                  key={item.url_path}
                >
                  <MediaThumbnail
                    uploadDate={new Date(item.uploaded_at ?? '')}
                    posterFrame={`${process.env.REACT_APP_CDN_BASE}${item.thumbnail?.['480'].path}`}
                    title={item.title}
                    duration={item.duration ?? 0}
                    episodeUrl={getHDPath(
                      true,
                      `/playlist/${latestVideoPlaylistQuery.query.term.url_path}/${item.url_path}`
                    )}
                    format="video"
                    onClick={() => {
                      reportLinkClickedAnalytics({
                        to: item.url_path,
                        linkText: item.title,
                        eventSource: 'Latest Video',
                        pageSpecialty: 'UC'
                      });
                    }}
                  />
                </li>
              ))
            )}
          </ul>
        )}
        <PrimaryLinkButton
          icon="arrow-right"
          text="See All"
          to="/urgent-care/hd"
          className="uc-dashboard-latest__see-all-button"
          onClick={() =>
            reportLinkClickedAnalytics({
              to: 'urgent-care/hd',
              linkText: 'Latest Video See All',
              pageSpecialty: 'UC'
            })
          }
        />
      </div>
    </Wrapper>
  );
}
