import React, { ReactNode, forwardRef } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import { LinkProps } from 'react-router-dom';
import { ReactComponent as IconX } from '../images/icons/icon-x.svg';
import { Heading } from './Heading';

import './_style/lib/components/Modal.scss';

type CloseButtonTheme = 'light' | 'dark';

interface ModalProps {
  modalClassName?: string;
  sectionClassName?: string;
  closeButtonTheme?: CloseButtonTheme;
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  footer?: ReactNode;
  title?: string;
  isPreview?: boolean;
  style?: {
    content: {
      '--firestore--background--color': string;
    };
  };
}

type ModalStickyFooterAction =
  | JSX.IntrinsicElements['button']
  | JSX.IntrinsicElements['a']
  | LinkProps;

interface ModalStickyFooterProps {
  primaryAction: ModalStickyFooterAction;
  secondaryAction?: ModalStickyFooterAction;
}

export function ModalStickyFooter({
  primaryAction,
  secondaryAction
}: ModalStickyFooterProps) {
  return (
    <footer className="global-modal__sticky-footer">
      {primaryAction}
      {secondaryAction !== undefined && secondaryAction}
    </footer>
  );
}

export const Modal = forwardRef<HTMLElement, ModalProps>(
  (
    {
      modalClassName,
      sectionClassName,
      closeButtonTheme = 'dark',
      style,
      isOpen,
      handleClose,
      title,
      children,
      footer,
      isPreview = false
    },
    ref
  ) => (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      portalClassName="global-modal"
      overlayClassName="global-modal__overlay"
      className={classnames('global-modal__content', modalClassName)}
      style={style}
    >
      <header className="global-modal__header">
        {title !== undefined && (
          <Heading Tag="h2" className="global-modal__header-title" variant="lg">
            {title}
          </Heading>
        )}
        {!isPreview && (
          <button
            aria-label="close"
            type="button"
            onClick={handleClose}
            className="global-modal__close-button"
            data-theme={closeButtonTheme}
          >
            <IconX />
          </button>
        )}
      </header>
      <section
        className={classnames('global-modal__section', sectionClassName)}
        data-testid="global-modal__section"
        ref={ref}
      >
        {children}
      </section>
      {footer !== undefined && footer}
    </ReactModal>
  )
);
