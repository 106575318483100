import React from 'react';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { HDVideoThumbnail } from '../HDThumbnails/HDVideoThumbnail';
import { HDPlaylistThumbnail } from '../HDThumbnails/HDPlaylistThumbnail';
import getPlaylistItemURL from '../../lib/getPlaylistItemURL';
import { isRelativeURL } from '../../lib/isRelativeUrl';
import config from '../../config';
import { reportPlaylistOpenedAnalytics } from '../../analytics/reportPlaylistOpenedAnalytics';
import { ESVideoPlaylistItem } from '../../schema/playlist/ESVideoPlaylistItem';
import {
  RelatedContentItem,
  RelatedContentPlaylistItem
} from '../../schema/playlist/RelatedContentItem';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

const { cdnUrl } = config;

interface HDPlaylistItemsProps {
  items: (
    | ESVideoPlaylistItem
    | RelatedContentPlaylistItem
    | RelatedContentItem
  )[];
  isChapterPage?: boolean;
  seeAllHref?: string;
  chapterShortname?: string;
  episodeShortname?: string;
  className?: string;
  trimmedUrl: string;
  isUrgentCare: boolean;
  isRelatedPlaylist?: boolean;
  isSuggestionList?: boolean;
  pageSpecialty?: string;
}

export function HDPlaylistItems({
  items,
  isChapterPage = false,
  seeAllHref,
  chapterShortname,
  episodeShortname,
  trimmedUrl,
  isUrgentCare,
  className,
  isRelatedPlaylist = false,
  isSuggestionList = false,
  pageSpecialty
}: HDPlaylistItemsProps) {
  const { modernizePlayerPage } = useFlags();
  const { pathname } = useLocation();

  const getEventSource = () => {
    if (isSuggestionList) {
      return 'suggestion list';
    }
    if (isChapterPage) {
      return 'related playlists';
    }
    return 'playlist index';
  };

  return (
    <div className="hd-playlist-items--wrapper">
      <div
        className={classnames(`hd-playlist-items ${className || ''}`, {
          'hd-playlist-items--chapter-page': isChapterPage,
          'hd-playlist-items--related-playlist': isRelatedPlaylist,
          'hd-playlist-items--suggestion-list': isSuggestionList
        })}
      >
        {items.map(item => {
          const path: string | null = get(
            item,
            ['thumbnail', '480', 'path'],
            null
          );
          const thumbnail = isRelativeURL(path)
            ? `${cdnUrl}/${path}` // relative url
            : path;

          const videoThumbnailUrl = modernizePlayerPage
            ? getPlaylistItemURL(
                item.url_path,
                isChapterPage,
                (isRelatedPlaylist || isSuggestionList) ?? false,
                isUrgentCare,
                pathname
              )
            : `${
                trimmedUrl.startsWith('/hd/episode')
                  ? trimmedUrl.slice(3)
                  : trimmedUrl
              }/${item.url_path}`;

          return item.type === 'episode' || item.type === 'playlist' ? (
            <HDPlaylistThumbnail
              key={`${item.url_path}${item.title}`}
              imageUrl=""
              className={item.type === 'playlist' ? item.graphic : undefined}
              videoCount={item.video_count}
              title={item.title}
              prefix={item.type === 'playlist' ? item.graphic : undefined}
              uploadDate={item.uploaded_at}
              to={
                modernizePlayerPage
                  ? getPlaylistItemURL(
                      item.url_path,
                      isChapterPage,
                      (isRelatedPlaylist || isSuggestionList) ?? false,
                      isUrgentCare,
                      pathname
                    )
                  : `${trimmedUrl}/${item.url_path}`
              }
              isChapterPage={isChapterPage}
              isRelatedPlaylist={isRelatedPlaylist}
              isSuggestionList={isSuggestionList}
              onClick={() =>
                reportPlaylistOpenedAnalytics({
                  playlist: item.title,
                  itemCount: item.video_count ?? 0,
                  pageSpecialty: isUrgentCare ? 'UC' : 'HD',
                  eventSource: getEventSource()
                })
              }
            />
          ) : (
            <HDVideoThumbnail
              key={`${item.url_path}${item.title}`}
              imageUrl={thumbnail}
              duration={item.duration}
              title={item.title}
              uploadDate={item.uploaded_at}
              href={videoThumbnailUrl}
              isChapterPage={isChapterPage}
              isSuggestionList={isSuggestionList}
              isSelected={videoThumbnailUrl.endsWith(
                `${episodeShortname}/${chapterShortname}`
              )}
              onClick={() => {
                reportLinkClickedAnalytics({
                  to: videoThumbnailUrl,
                  linkText: item.title,
                  pageSpecialty,
                  eventSource: getEventSource()
                });
              }}
            />
          );
        })}
        {seeAllHref && (
          <Link
            className="hd-episode-playlist__player-list-all hd-episode-playlist__player-list-all--mobile"
            to={seeAllHref}
          >
            See All »
          </Link>
        )}
      </div>
    </div>
  );
}
