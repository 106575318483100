import { CmeFaculty } from '../schema/cme/cmeFaculty';
import { EpisodeTypeShortname } from '../schema/webEpisode/episodeType';

const LATEST_COMMITTEE_UPDATE_DATE = new Date(2024, 5, 1);

type Credentials = {
  [Key in EpisodeTypeShortname]?: string;
};

interface Committee {
  [key: string]: {
    fullname: string;
    disclosure?: null;
    biography?: null;
    credentials: Credentials;
  };
}

const COMMITTEE: Committee = {
  Anand: {
    fullname: 'Anand Swaminathan',
    credentials: {
      ema: 'MD, FAAEM, Course Planner',
      full: 'MD, FAAEM, Course Planner'
    }
  },
  Gita: {
    fullname: 'Gita Pensa',
    credentials: {
      ucmaximus: 'MD, Editor'
    }
  },
  Heidi: {
    fullname: 'Heidi James',
    credentials: {
      rop: 'MD, Editor'
    }
  },
  Jan: {
    fullname: 'Jan Shoenberger',
    credentials: {
      full: 'MD, FACEP, Course Planner'
    }
  },
  Mary: {
    fullname: 'Mary Lanctot-Herbert',
    credentials: {
      ema: 'NP',
      rop: 'MSN, NP, CME Coordinator',
      full: 'NP, CME Coordinator',
      ucmaximus: 'MSN, NP, CME Coordinator'
    }
  },
  'Mel (Current)': {
    fullname: 'Mel Herbert',
    credentials: {
      ema: 'MD, MBBS, FACEP, FAAEM, Program Director',
      rop: 'MD, MBBS, FACEP, FAAEM, Program Director',
      full: 'MD, MBBS, FACEP, FAAEM, Program Director',
      ucmaximus: 'MD, MBBS, FACEP, FAAEM, Program Director'
    }
  },
  'Mel (Historic)': {
    fullname: 'Mel Herbert',
    credentials: {
      ema: 'MD',
      rop: 'MD, MBBS, FAAEM, FACEP, Course Director',
      full: 'MD, MBBS, FACEP, FAAEM, Program Director',
      ucmaximus: 'MD, MBBS, FAAEM, Course Director'
    }
  },
  MikeMenchine: {
    fullname: 'Mike Menchine',
    credentials: {
      ema: 'MD, Course Planner'
    }
  },
  MikeWeinstock: {
    fullname: 'Mike Weinstock',
    credentials: {
      ucmaximus: 'MD, Editor'
    }
  },
  Sanjay: {
    fullname: 'Sanjay Arora',
    credentials: {
      ema: 'MD, Course Planner'
    }
  },
  Scott: {
    fullname: 'Scott Kobner',
    credentials: {
      ema: 'MD, Program Director',
      rop: 'MD, Program Director',
      full: 'MD, Program Director',
      ucmaximus: 'MD, Program Director'
    }
  },
  Vanessa: {
    fullname: 'Vanessa Cardy',
    credentials: {
      rop: 'MD, Editor'
    }
  }
};

function getMembersByEpisodeType(
  members: (keyof typeof COMMITTEE)[],
  episodeType: EpisodeTypeShortname
): CmeFaculty[] {
  return members.map(name => {
    const member = COMMITTEE[name];

    const credentials = member.credentials[episodeType] || '';
    const fullnameWithCredentials = credentials
      ? `${member.fullname}, ${credentials}`
      : member.fullname;

    return {
      disclosure: member.disclosure || null,
      fullname: fullnameWithCredentials,
      biography: member.biography || null
    };
  });
}

function getCurrentPlanningCommittee(type: EpisodeTypeShortname): CmeFaculty[] {
  // if start date is >= June 1st 2024

  switch (type) {
    case 'full': // EM:RAP
      return getMembersByEpisodeType(['Mel (Current)', 'Jan', 'Anand'], 'full');
    case 'ema':
      return getMembersByEpisodeType(
        ['Mel (Current)', 'Sanjay', 'MikeMenchine'],
        'ema'
      );
    case 'ucmaximus':
      return getMembersByEpisodeType(
        ['Mel (Current)', 'Gita', 'MikeWeinstock'],
        'ucmaximus'
      );
    case 'rop':
      return getMembersByEpisodeType(
        ['Mel (Current)', 'Vanessa', 'Heidi', 'Mary'],
        'rop'
      );
    default:
      return [];
  }
}

function getHistoricPlanningCommittee(
  type: EpisodeTypeShortname,
  startDate: string
): CmeFaculty[] {
  if (new Date(startDate) < new Date('2024-01-01T00:00:00Z')) {
    switch (type) {
      case 'full': // EM:RAP
        return getMembersByEpisodeType(
          ['Mel (Historic)', 'Jan', 'Anand', 'Mary'],
          'full'
        );
      case 'ema':
        return [
          { disclosure: null, fullname: 'Sanjay Arora, MD', biography: null },
          { disclosure: null, fullname: 'Mel Herbert, MD', biography: null },
          {
            disclosure: null,
            fullname: 'Mary Lanctot-Herbert, NP',
            biography: null
          },
          { disclosure: null, fullname: 'Mike Menchine, MD', biography: null }
        ];
      case 'ucmaximus':
        return getMembersByEpisodeType(
          ['Mel (Historic)', 'Gita', 'Mary'],
          'ucmaximus'
        );
      case 'rop':
        return getMembersByEpisodeType(
          ['Mel (Historic)', 'Vanessa', 'Heidi', 'Mary'],
          'rop'
        );
      default:
        return [];
    }
  } else {
    switch (type) {
      case 'full': // EM:RAP
        return getMembersByEpisodeType(['Scott', 'Jan', 'Anand'], 'full');
      case 'ema':
        return getMembersByEpisodeType(
          ['Scott', 'Sanjay', 'MikeMenchine'],
          'ema'
        );
      case 'ucmaximus':
        return getMembersByEpisodeType(
          ['Scott', 'Gita', 'MikeWeinstock'],
          'ucmaximus'
        );
      case 'rop':
        return getMembersByEpisodeType(
          ['Mel (Historic)', 'Vanessa', 'Heidi', 'Mary'],
          'rop'
        );
      default:
        return [];
    }
  }
}

export function getPlanningCommittee(
  type: EpisodeTypeShortname,
  startDate: string
): CmeFaculty[] {
  const shouldUseLatestCommittee =
    new Date(startDate) >= LATEST_COMMITTEE_UPDATE_DATE;

  return shouldUseLatestCommittee
    ? getCurrentPlanningCommittee(type)
    : getHistoricPlanningCommittee(type, startDate);
}
