import { z } from 'zod';
import { c3ThumbnailSchema } from './thumbnail';

// ES transformer https://github.com/silverorange/emrap-search-tools/blob/master/src/transforms/c3PlaylistItemTransform.js
// SQL query https://github.com/silverorange/emrap-search-tools/blob/master/src/db/getC3PlaylistItems.js

export const ESC3PlaylistItemSchema = z.object({
  title: z.string(),
  // prefix: z.string().optional(), // its always missing in elastic search and is not used anymore here
  uploaded_at: z.string(),
  item_count: z.number(),
  url_path: z.string(),
  duration: z.number(), // Found one entry in the Media table with duration = null (maybe not a C3 media)
  thumbnail: c3ThumbnailSchema.optional()
});

export type ESC3PlaylistItem = z.infer<typeof ESC3PlaylistItemSchema>;
