import { track } from '@amplitude/analytics-browser';

export type CarouselEventSource = 'faculty slider' | 'podcast';

interface ReportCarouselSwipedAnalyticsParams {
  carouselEventSource?: CarouselEventSource;
  pageSpecialty?: string;
}

export function reportCarouselSwipedAnalytics({
  carouselEventSource,
  pageSpecialty
}: ReportCarouselSwipedAnalyticsParams) {
  track('Carousel_Swiped', {
    event_source: carouselEventSource,
    page_specialty: pageSpecialty,
    path: window.location.pathname
  });
}
