import React from 'react';
import cn from 'classnames';
import { FacultyModalHeadshot } from './FacultyModalHeadshot';
import { SecondaryButton } from './SecondaryButton';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import './FacultyTooltipModal.scss';

interface Props {
  title: string;
  text: string;
  onClick: () => void;
  imgSrc: string;
  flipped?: boolean;
  className?: string;
}

export function FacultyTooltipModal({
  title,
  text,
  onClick,
  imgSrc,
  flipped,
  className
}: Props) {
  return (
    <div
      className={cn('uc-faculty-tooltip-modal', className, {
        'uc-faculty-tooltip-modal--flipped': flipped
      })}
    >
      <div className="uc-faculty-tooltip-modal__left">
        <div>
          <span className="uc-faculty-tooltip-modal__title">{title}</span>
          <p className="uc-faculty-tooltip-modal__text">{text}</p>
        </div>
        <SecondaryButton
          icon="arrow-right"
          text="Read More"
          onClick={onClick}
          report={() =>
            reportButtonClickedAnalytics({
              buttonType: 'faculty',
              eventSource: title
            })
          }
          className="uc-faculty-tooltip-modal__button"
        />
      </div>
      <div className="uc-faculty-tooltip-modal__right">
        <FacultyModalHeadshot imgSrc={imgSrc} imgAlt={title} />
      </div>
    </div>
  );
}
