import React from 'react';
import cn from 'classnames';
import { Icon, IconName } from './Icon';

import './SecondaryButton.scss';

interface SecondaryButtonProps {
  icon: IconName;
  text: string;
  onClick: () => void;
  className?: string;
  report: () => void;
}

export function SecondaryButton({
  icon,
  text,
  onClick,
  report,
  className
}: SecondaryButtonProps) {
  return (
    <button
      type="button"
      className={cn('uc-secondary-button', className)}
      onClick={() => {
        onClick();
        report();
      }}
    >
      <Icon name={icon} className="uc-secondary-button__icon" />
      <span className="uc-secondary-button__text">{text}</span>
    </button>
  );
}
