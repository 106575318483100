import React from 'react';
import classnames from 'classnames';

import './ConferenceFacultyAvatar.scss';

interface Props {
  name: string;
  img: string;
  className?: string;
}

const ConferenceFacultyAvatar = ({ name, img, className }: Props) => (
  <div
    className={classnames('conference-faculty-avatar__container', className)}
  >
    <img src={img} alt={name} className="conference-faculty-avatar__photo" />
  </div>
);

export default ConferenceFacultyAvatar;

ConferenceFacultyAvatar.defaultProps = {
  className: undefined
};
