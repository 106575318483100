export const SET_DATERANGE = 'SET_DATERANGE';
export const SET_CURENT_SEARCH = 'SET_CURENT_SEARCH';
export const SET_CURRENT_TAGS = 'SET_CURRENT_TAGS';
export const SET_CURRENT_FACULTY = 'SET_CURRENT_FACULTY';
export const SET_CURRENT_EPISODE_TYPE = 'SET_CURRENT_EPISODE_TYPE';
export const SET_CURRENT_DOCUMENT_TYPE = 'SET_CURRENT_DOCUMENT_TYPE';
export const SET_CURRENT_IS_FREE_ONLY = 'SET_CURRENT_IS_FREE_ONLY';
export const SET_CITATION_TYPE = 'SET_CITATION_TYPE';
export const SET_EPISODE_SORT = 'SET_EPISODE_SORT';
export const SET_SEARCH_SORT = 'SET_SEARCH_SORT';
export const SET_EPISODE_SORT_OPEN = 'SET_EPISODE_SORT_OPEN';
export const SET_SEARCH_SORT_OPEN = 'SET_SEARCH_SORT_OPEN';
export const SET_AUDIENCE = 'SET_AUDIENCE';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const TOGGLE_EPISODE_SORT = 'TOGGLE_EPISODE_SORT';
export const TOGGLE_SEARCH_SORT = 'TOGGLE_SEARCH_SORT';
export const TOGGLE_AUDIENCE_SELECT = 'TOGGLE_AUDIENCE_SELECT';
export const SET_AUDIENCE_OPEN = 'SET_AUDIENCE_OPEN';
export const SET_MENUS_CLOSED = 'SET_MENUS_CLOSED';

export const ALL_CITATIONS = 'all citations';
export const NON_ABSTRACTED_CITATIONS = 'non-abstracted citations';
export const ABSTRACT_CITATIONS = 'abstract citations';

export function setDateRange(range) {
  const [start, end] = range;
  return {
    type: SET_DATERANGE,
    start,
    end
  };
}

export function setCurrentSearch(currentSearch) {
  return {
    type: SET_CURENT_SEARCH,
    currentSearch
  };
}

export function setCurrentTags(tags) {
  return {
    type: SET_CURRENT_TAGS,
    tags
  };
}

export function setCurrentFaculty(faculty) {
  return {
    type: SET_CURRENT_FACULTY,
    faculty
  };
}

export function setCurrentEpisodeType(episodeType) {
  return {
    type: SET_CURRENT_EPISODE_TYPE,
    episodeType
  };
}

export function setCurrentDocumentType(documentType) {
  return {
    type: SET_CURRENT_DOCUMENT_TYPE,
    documentType
  };
}

export function setCurrentIsFreeOnly(isFreeOnly) {
  return {
    type: SET_CURRENT_IS_FREE_ONLY,
    isFreeOnly
  };
}

export function setCitationType(citationType) {
  return {
    type: SET_CITATION_TYPE,
    citationType
  };
}

export function setEpisodeSort(episodeSort) {
  return {
    type: SET_EPISODE_SORT,
    episodeSort
  };
}

export function setSearchSort(searchSort) {
  return {
    type: SET_SEARCH_SORT,
    searchSort
  };
}

export function setEpisodeSortOpen(episodeSortOpen) {
  return {
    type: SET_EPISODE_SORT_OPEN,
    episodeSortOpen
  };
}

export function setSearchSortOpen(searchSortOpen) {
  return {
    type: SET_SEARCH_SORT_OPEN,
    searchSortOpen
  };
}

export function setAudience(audience) {
  return {
    type: SET_AUDIENCE,
    audience
  };
}

export function toggleFilters() {
  return {
    type: TOGGLE_FILTERS
  };
}

export function toggleEpisodeSort() {
  return {
    type: TOGGLE_EPISODE_SORT
  };
}

export function toggleSearchSort() {
  return {
    type: TOGGLE_SEARCH_SORT
  };
}

export function toggleAudienceSelect() {
  return {
    type: TOGGLE_AUDIENCE_SELECT
  };
}

export function setAudienceOpen(audienceSelectOpen) {
  return {
    type: SET_AUDIENCE_OPEN,
    audienceSelectOpen
  };
}

export function setMenusClosed() {
  return {
    type: SET_MENUS_CLOSED,
    audienceSelectOpen: false,
    episodeSortOpen: false,
    searchSortOpen: false
  };
}
