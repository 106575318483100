import React from 'react';
import cn from 'classnames';
import { FacultyHeadshot } from './FacultyHeadshot';

import './FacultyModalHeadshot.scss';

interface FacultyModalHeadshotProps {
  imgSrc: string;
  imgAlt: string;
  className?: string;
}

export function FacultyModalHeadshot({
  imgSrc,
  imgAlt,
  className
}: FacultyModalHeadshotProps) {
  return (
    <FacultyHeadshot
      imgSrc={imgSrc}
      imgAlt={imgAlt}
      onClick={() => null}
      disabled
      className={cn('uc-faculty-modal-headshot', className)}
    />
  );
}
