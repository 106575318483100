import React from 'react';
import ColumnHeader from './ColumnHeader';
import CrunchTimeFMDoctors from '../../images/episode-guide/doctors-crunchtime-fm.svg';
import CrunchTimeFMLogo from '../../images/episode-guide/logo-crunchtime-fm.svg';

import './PageHeader.scss';
import './CrunchTimeFMHeader.scss';

export const CrunchTimeFMHeader = () => {
  const title = 'EM:RAP’s Crunch Time FM';
  const description = `EM:RAP’s Crunch Time FM. Family Medicine board review made simple,
  focused, and accessible in the EM:RAP tradition. Perfect for the
  entire spectrum of FM exams.
`;

  return (
    <ColumnHeader
      className="crunchtime-fm-header"
      title={title}
      description={description}
      doctors={CrunchTimeFMDoctors}
      logo={CrunchTimeFMLogo}
    />
  );
};

export default CrunchTimeFMHeader;
