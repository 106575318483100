import { useDispatch } from 'react-redux';
import { fetchApi } from '../lib/fetchApi';
import { commentsSlice } from '../store/comments';
import { WebCommentStatus } from '../schema/webComment/webCommentStatus';
import { reportError } from '../lib/reportError';

export const editorActions = ['hide', 'publish'] as const;
export type EditorActionType = (typeof editorActions)[number];

export default function useEditorActions(): {
  editCommentStatus: (
    commentId: number,
    chapterId: number,
    action: EditorActionType
  ) => Promise<void>;
} {
  const dispatch = useDispatch();
  const editCommentStatus = async (
    commentId: number,
    chapterId: number,
    action: EditorActionType
  ) => {
    const newStatus =
      action === 'publish'
        ? WebCommentStatus.PUBLISHED
        : WebCommentStatus.UNPUBLISHED;

    const requestBody = {
      commentStatus: newStatus
    };

    try {
      const response = await fetchApi(
        `chapters/${chapterId}/comments/${commentId}`,
        {
          method: 'PUT',
          body: JSON.stringify(requestBody)
        }
      );

      if (response.ok) {
        if (newStatus === WebCommentStatus.PUBLISHED) {
          dispatch(commentsSlice.actions.publish(commentId));
        } else dispatch(commentsSlice.actions.hide(commentId));
      }
    } catch (error) {
      reportError(error);
    }
  };

  return {
    editCommentStatus
  };
}
