import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import CreateSnippet from '../CreateSnippet';
import { resultDescription } from '../../lib/highlightedElements';
import config from '../../config';
import gravatarImg from '../../images/icons/gravatar80.png';

import './CommentResult.scss';

const escapedDefaultGravatar = encodeURIComponent(config.defaultGravatar);

interface CommentResultProps {
  media: {
    mobile: boolean;
    xs: boolean;
  };
  data: {
    highlight: {
      title: string;
      description: string;
    };
    episode: {
      title: string;
    };
    chapter: {
      resource: string;
    };
    comment: {
      bodytext: string;
      createdate: string;
      author: string;
      gravatar: string;
    };
  };
}

export function CommentResult({ media, data }: CommentResultProps) {
  const {
    episode: { title: episodeTitle },
    chapter: { resource: chapterResource },
    comment: { createdate: createDate, author, gravatar, bodytext: bodyText },
    highlight
  } = data;

  const renderSnippetOrDescription = () => {
    if (media.xs) {
      if (highlight.description) {
        return <CreateSnippet text={highlight.description} />;
      }
      return resultDescription(bodyText, highlight, true);
    }
    return resultDescription(bodyText, highlight, false);
  };

  const gravatarSrc = gravatar
    ? `https://secure.gravatar.com/avatar/${gravatar}?s=80&d=${escapedDefaultGravatar}`
    : gravatarImg;

  const chapterResourceUrl = chapterResource.replace(
    /^http(s?):\/\/www.emrap.org/,
    ''
  );
  const commentsLink = `${chapterResourceUrl}#comments`;
  return (
    <Link className="result-item comment" to={commentsLink}>
      <img src={gravatarSrc} alt="avatar" />
      <div className="content">
        <div className="title">Comment by {author}</div>
        <div className="comment-info">
          Posted {moment(createDate).format('MMM d, YYYY')} on{' '}
          <em>{episodeTitle}</em>
        </div>
        {renderSnippetOrDescription()}
      </div>
    </Link>
  );
}
