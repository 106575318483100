import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as UCLogoWhite } from '../../images/urgent-care/logo/uc-logo--icon-white-v2.svg';
import { ReactComponent as UCLogoHorizontalWhite } from '../../images/urgent-care/logo/uc-logo--horizontal-white-v2.svg';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './HDUCMaximusLogo.scss';

interface Props {
  to: string;
  className?: string;
}

export function HDUCMaximusLogo({ to, className }: Props) {
  return (
    <Link
      to={to}
      className={classnames('uc-maximus-logo__link', className)}
      onClick={() =>
        reportLinkClickedAnalytics({
          to,
          linkText: 'by UC Maximus',
          pageSpecialty: 'UC'
        })
      }
    >
      <strong className="uc-maximus-logo__text">
        by
        <span className="visually-hidden">&nbsp;UC Max</span>
        <UCLogoWhite aria-hidden="true" className="uc-maximus-logo" />
        <UCLogoHorizontalWhite
          aria-hidden="true"
          className="uc-maximus-logo uc-maximus-logo--horizontal"
        />
      </strong>
    </Link>
  );
}
