import React from 'react';
import ColumnHeader from './ColumnHeader';
import EmaDoctors from '../../images/episode-guide/doctors-ema.svg';
import EmaLogo from '../../images/episode-guide/logo-ema.svg';

import './PageHeader.scss';
import './EmaHeader.scss';

interface EmaHeaderProps {
  year?: string;
}

export const EmaHeader = ({ year = '' }: EmaHeaderProps) => {
  const title = year
    ? `Emergency Medical Abstracts from ${year}`
    : 'Emergency Medical Abstracts';
  const description =
    'We review over 600 medical journals each month so you don’t have to.';

  return (
    <ColumnHeader
      className="ema-header"
      title={title}
      description={description}
      doctors={EmaDoctors}
      logo={EmaLogo}
    />
  );
};

export default EmaHeader;
