import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { reportError } from '../lib/reportError';
import Icon from './Icons/Icon';

import './NotFound.scss';

interface NotFoundProps {
  sourcePath: string;
  component: string;
  reason?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra?: Record<string, any>;
}

function NotFound({ sourcePath, component, reason, extra }: NotFoundProps) {
  reportError(new Error('404 page not found'), {
    extra: {
      path: sourcePath,
      component,
      reason,
      ...extra
    }
  });

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className="not-found not-found--legacy global-background-gradient">
        <div className="not-found__text-container">
          <h1 id="skip_to_content" className="not-found__title">
            404
          </h1>
          <h2 className="not-found__heading">Page Not Found</h2>
          <p className="not-found__description-text">
            Looks like you&apos;re lost. Try double-checking your URL or
            visiting our homepage.
          </p>
          <Link to="/" className="not-found__home-link">
            <Icon name="arrow-right" />
            Back to Home
          </Link>
        </div>
      </main>
    </>
  );
}

export default NotFound;
