import { connect } from 'react-redux';
import { AppState } from '../../lib/stateTypes';
import { accountSlice } from '../../store/account';
import { StatusBar } from './StatusBar';

const mapStateToProps = (state: AppState) => ({
  account: state[accountSlice.name].account,
  isLoading: state[accountSlice.name].isLoading
});

export default connect(mapStateToProps)(StatusBar);
