import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from './types';
import { reportPlaylistOpenedAnalytics } from '../../analytics/reportPlaylistOpenedAnalytics';

import './HDPlaylistBreadcrumbs.scss';

export interface HDPlaylistBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  isUrgentCare: boolean;
}

export function HDPlaylistBreadcrumbs({
  breadcrumbs = [],
  isUrgentCare
}: HDPlaylistBreadcrumbsProps) {
  return (
    <div className="hd-playlist-page__breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => {
        const { playlist } = breadcrumb;

        return playlist !== null ? (
          <Fragment key={breadcrumb.url}>
            <Link
              className={
                index === breadcrumbs.length - 1
                  ? 'quick-link quick-link-teal text__link hd-playlist-page__breadcrumbs__last'
                  : 'quick-link text__link'
              }
              to={breadcrumb.url}
              onClick={() => {
                reportPlaylistOpenedAnalytics({
                  playlist: playlist.title,
                  itemCount: playlist.video_count,
                  pageSpecialty: isUrgentCare ? 'UC' : 'HD',
                  eventSource: 'breadcrumb link'
                });
              }}
            >
              {playlist.title}&nbsp;
            </Link>
            {index < breadcrumbs.length - 1 && ' / '}
          </Fragment>
        ) : null;
      })}
    </div>
  );
}

export default HDPlaylistBreadcrumbs;
