import { z } from 'zod';
import { editionSchema } from './edition';
import { eventSubscriptionSchema } from './eventSubscription';
import { specialtySchema } from './specialty';
import { subscriptionSchema } from './subscription';

export const accountSchema = z.object({
  id: z.number(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  suffix: z.string().nullable(),
  subscription: subscriptionSchema.nullable(),
  specialty: specialtySchema,
  edition: editionSchema.optional(),
  avatarUri: z.string(),
  subscriptionType: z.string().nullable(),
  isSpoofed: z.boolean(),
  events: z.array(eventSubscriptionSchema),
  isCurator: z.boolean()
});

export type Account = z.infer<typeof accountSchema>;
