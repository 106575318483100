import { z } from 'zod';
import { thumbnailSchema } from './thumbnail';

// ES transformer https://github.com/silverorange/emrap-search-tools/blob/master/src/transforms/videoPlaylistItemTransform.js
// SQL query https://github.com/silverorange/emrap-search-tools/blob/master/src/db/getVideoPlaylistItems.js

const ESArtwork = z.object({
  id: z.number()
});

const ESVideoPlaylistBaseItemSchema = z.object({
  title: z.string(),
  uploaded_at: z.string(),
  url_path: z.string()
});

const ESVideoPlaylistChapterItemSchema = ESVideoPlaylistBaseItemSchema.extend({
  type: z.literal('chapter'),
  thumbnail: thumbnailSchema,
  duration: z.number(),
  chapter_media_id: z.number(),
  currentpoint: z.number().optional()
});

export type ESVideoPlaylistChapterItem = z.infer<
  typeof ESVideoPlaylistChapterItemSchema
>;

const ESVideoPlaylistEpisodeItemSchema = ESVideoPlaylistBaseItemSchema.extend({
  type: z.literal('episode'),
  prefix: z.string().optional(),

  // It looks like video_count could be undefined if items is empty:
  // https://github.com/silverorange/emrap-search-tools/blob/master/src/utils/getVideoCount.js#L9-L50, but I
  // don't think we deal with playlists with no items
  video_count: z.number(),
  artwork: ESArtwork
});

export type ESVideoPlaylistEpisodeItem = z.infer<
  typeof ESVideoPlaylistEpisodeItemSchema
>;

const ESVideoPlaylistPlaylistItemSchema = ESVideoPlaylistBaseItemSchema.extend({
  type: z.literal('playlist'),
  prefix: z.string().optional(),

  // It looks like video_count could be undefined if the episode has no chapters:
  // https://github.com/silverorange/emrap-search-tools/blob/master/src/utils/getVideoCount.js#L9-L50, but I
  // don't think we deal with episode with no chapters
  video_count: z.number(),
  graphic: z.string().optional()
});

export type ESVideoPlaylistPlaylistItem = z.infer<
  typeof ESVideoPlaylistPlaylistItemSchema
>;

export const ESVideoPlaylistItemSchema = z.discriminatedUnion('type', [
  ESVideoPlaylistChapterItemSchema,
  ESVideoPlaylistEpisodeItemSchema,
  ESVideoPlaylistPlaylistItemSchema
]);

export type ESVideoPlaylistItem = z.infer<typeof ESVideoPlaylistItemSchema>;
