import React from 'react';
import cn from 'classnames';
import { Icon, IconName } from './Icon';

import './PrimaryButton.scss';

interface PrimaryButtonProps {
  icon: IconName;
  text: string;
  onClick: () => void;
  className?: string;
  iconClassName?: string;
}

export function PrimaryButton({
  icon,
  text,
  onClick,
  className,
  iconClassName
}: PrimaryButtonProps) {
  return (
    <button
      type="button"
      className={cn('uc-primary-button', className)}
      onClick={onClick}
    >
      <Icon
        name={icon}
        className={cn('uc-primary-button__icon', iconClassName)}
      />
      <span className="uc-primary-button__text">{text}</span>
    </button>
  );
}
