import moment from 'moment-timezone';
import { ChapterPageBreadcrumbLink } from '../components/ChapterPage/ChapterPageBreadcrumb';
import { EpisodeTypeShortname } from '../schema/webEpisode/episodeType';
import { WebEpisode } from '../schema/webEpisode/webEpisode';
import { ESC3Playlist } from '../schema/playlist/ESC3Playlist';
import { ESVideoPlaylist } from '../schema/playlist/ESVideoPlaylist';

export default function getBreadcrumbLinks(
  playlists: ESC3Playlist[] | ESVideoPlaylist[],
  episode: WebEpisode,
  basePath: string,
  episodeType: EpisodeTypeShortname
) {
  const links: ChapterPageBreadcrumbLink[] = [];
  if (playlists.length > 0) {
    playlists.forEach(
      (playlist: ESVideoPlaylist | ESC3Playlist, index: number) => {
        links.push({
          title: playlist.title,
          href:
            links.length === 0
              ? `/${episodeType}/playlist/${playlist.url_path}`
              : `${links[index - 1].href}/${playlist.url_path}`
        });
      }
    );
    if (basePath.includes('/episode')) {
      links.push({
        title: episode.title,
        href:
          episodeType !== 'c3'
            ? `${links[links.length - 1].href}/episode/${episode.shortname}`
            : undefined // c3 doesn't have an episode index
      });
    }
    return links;
  }
  links.push({
    title: episode.title,
    href: `/${episodeType}/episode/${episode.shortname}`,
    publishedDate: moment(episode.publishedDate.toDate())
      .format('MMM DD YYYY')
      .toLocaleUpperCase()
  });

  return links;
}
