import React from 'react';
import classnames from 'classnames';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';

import './PlaybackSlider.scss';

type PlaybackSliderProps = {
  secondsPlayed: number;
  setSecondsPlayed: (seconds: number) => void;
  duration: number;
  episodeType?: EpisodeTypeShortname;
  className?: string;
  isEnabled?: boolean;
};

function getSliderBackground(duration: number, secondsPlayed: number) {
  const backgroundWidth = (secondsPlayed * 100) / duration;
  return backgroundWidth < 50 // prevents alignment issues with the slider thumb and background colors
    ? `${backgroundWidth + 1}% 100%`
    : `${backgroundWidth - 1}% 100%`;
}

export const PlaybackSlider = ({
  episodeType,
  secondsPlayed,
  setSecondsPlayed,
  duration,
  isEnabled = true,
  className
}: PlaybackSliderProps) => (
  <>
    <label htmlFor="playback-slider__input" className="visually-hidden">
      Playback Slider
    </label>
    <input
      id="playback-slider__input"
      type="range"
      className={classnames(
        'playback-slider',
        className,
        episodeType && `playback-slider--${[episodeType]}`,
        {
          'playback-slider--disabled': !isEnabled
        }
      )}
      min={0}
      max={duration}
      value={secondsPlayed}
      onChange={e => setSecondsPlayed(parseInt(e.target.value, 10))}
      disabled={!isEnabled}
      style={{ backgroundSize: getSliderBackground(duration, secondsPlayed) }}
    />
  </>
);
