import { track } from '@amplitude/analytics-browser';

// episodeIds included for historical tracking purposes
type ReportCmePrintedParams = {
  creditIds: number[];
  episodeIds: number[];
};

export function reportCmePrintedAnalytics({
  creditIds,
  episodeIds
}: ReportCmePrintedParams) {
  track('CME_Printed', {
    credit_ids: creditIds,
    episode_ids: episodeIds,
    path: window.location.pathname
  });
}
