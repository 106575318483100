import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelect } from 'downshift';
import { useSelector } from '../../lib/hooks';
import { ReactComponent as C3Icon } from '../../images/icons/episode-types/icon-c3.svg';
import { ReactComponent as EMRAPIcon } from '../../images/icons/episode-types/icon-emrap.svg';
import { ReactComponent as EMAIcon } from '../../images/icons/episode-types/icon-ema.svg';
import { ReactComponent as CrunchTimeEMIcon } from '../../images/icons/episode-types/icon-crunchtime-em.svg';
import { ReactComponent as CrunchTimeFMIcon } from '../../images/icons/episode-types/icon-crunchtime-fm.svg';
import { ReactComponent as LLSAIcon } from '../../images/icons/episode-types/icon-llsa.svg';
import { ReactComponent as ROPIcon } from '../../images/icons/episode-types/icon-rop.svg';
import { ReactComponent as UCIcon } from '../../images/icons/episode-types/icon-uc.svg';
import { ReactComponent as AllIcon } from '../../images/icons/episode-types/icon-more.svg';
import Icon from '../Icons/Icon';
import { getEpisodeTypePath } from '../../lib/getEpisodeTypePath';
import { getEpisodeTypeDisplay } from '../../lib/getEpisodeTypeDisplay';
import { getEpisodeTypeLongDisplay } from '../../lib/getEpisodeTypeLongDisplay';
import { getEpisodeTypeClass } from '../../lib/getEpisodeTypeClass';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import {
  EMRAP,
  C3,
  EMA,
  CRUNCH_TIME_EM,
  LLSA,
  ROP,
  CRUNCH_TIME_FM,
  UC_MAX,
  NONE
} from '../../lib/episodeTypes';

import './EpisodeTypeSelect.scss';

function EpisodeTypeSelect({ episodeType }) {
  const history = useHistory();
  const specialtyShortname = useSelector(
    state => state.account.account?.specialty?.shortname
  );

  const items = [
    NONE,
    ...(specialtyShortname === 'uc' ? [UC_MAX] : []),
    EMRAP,
    EMA,
    C3,
    CRUNCH_TIME_EM,
    LLSA,
    CRUNCH_TIME_FM,
    ROP,
    ...(specialtyShortname !== 'uc' ? [UC_MAX] : [])
  ];

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getItemProps
  } = useSelect({
    items,
    initialSelectedItem: episodeType,
    itemToString: item => getEpisodeTypeLongDisplay(item),
    onSelectedItemChange: changes => {
      const selectedEpisodeType = getEpisodeTypeDisplay(changes.selectedItem);
      reportLinkClickedAnalytics({
        to: getEpisodeTypePath(changes.selectedItem),
        linkText:
          selectedEpisodeType === '' ? 'All Episodes' : selectedEpisodeType
      });
      history.push(getEpisodeTypePath(changes.selectedItem));
    }
  });

  // TODO: Do we need a label here as well? See getLabelProps
  return (
    <>
      <div className="header-bar__dropdown-button-container">
        <button
          className="header-bar__dropdown-button"
          type="button"
          {...getToggleButtonProps()}
        >
          {selectedItem === C3 && (
            <C3Icon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === EMA && (
            <EMAIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === EMRAP && (
            <EMRAPIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === CRUNCH_TIME_EM && (
            <CrunchTimeEMIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === CRUNCH_TIME_FM && (
            <CrunchTimeFMIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === LLSA && (
            <LLSAIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === ROP && (
            <ROPIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === UC_MAX && (
            <UCIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          {selectedItem === NONE && (
            <AllIcon
              className={classnames(
                getEpisodeTypeClass(selectedItem, 'dropdown-icon'),
                'dropdown-icon'
              )}
            />
          )}
          <span
            className={classnames(
              getEpisodeTypeClass(selectedItem, 'dropdown-title'),
              'dropdown-title'
            )}
          >
            {selectedItem === NONE
              ? 'All Episodes'
              : getEpisodeTypeDisplay(selectedItem)}
          </span>
          <Icon
            name="arrow-right"
            className={classnames(
              getEpisodeTypeClass(selectedItem, 'dropdown-icon-arrow-down'),
              'dropdown-icon-arrow-down'
            )}
          />
        </button>
      </div>
      <ul
        className="header-bar__dropdown-select"
        style={isOpen ? {} : { display: 'none' }}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              className={classnames(getEpisodeTypeClass(item, 'dropdown-item'))}
              key={item}
              {...getItemProps({ item, index })}
            >
              {item === UC_MAX && (
                <UCIcon className="dropdown-icon dropdown-icon-uc" />
              )}
              {item === C3 && (
                <C3Icon className="dropdown-icon dropdown-icon-c3" />
              )}
              {item === EMA && (
                <EMAIcon className="dropdown-icon dropdown-icon-ema" />
              )}
              {item === EMRAP && (
                <EMRAPIcon className="dropdown-icon dropdown-icon-emrap" />
              )}
              {item === CRUNCH_TIME_EM && (
                <CrunchTimeEMIcon className="dropdown-icon dropdown-icon-crunchtime-em" />
              )}
              {item === LLSA && (
                <LLSAIcon className="dropdown-icon dropdown-icon-llsa" />
              )}
              {item === CRUNCH_TIME_FM && (
                <CrunchTimeFMIcon className="dropdown-icon dropdown-icon-crunchtime-fm" />
              )}
              {item === ROP && (
                <ROPIcon className="dropdown-icon dropdown-icon-rop" />
              )}
              {item === NONE && (
                <AllIcon className="dropdown-icon dropdown-icon-all" />
              )}
              {item === NONE ? 'All Episodes' : getEpisodeTypeLongDisplay(item)}
            </li>
          ))}
      </ul>
    </>
  );
}

EpisodeTypeSelect.propTypes = {
  episodeType: PropTypes.number.isRequired
};

export default EpisodeTypeSelect;
