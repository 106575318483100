import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Provider } from '../schema/cme/provider';
import { ProvidersState } from '../lib/stateTypes';
import { reportError } from '../lib/reportError';
import isCacheValid from '../lib/isCacheValid';
import { fetchApi } from '../lib/fetchApi';
import { cmeProvidersResponseSchema } from '../schema/cme/cmeProvidersResponse';

const CACHE_LIFETIME_MS = 30000;

const initialState: ProvidersState = {
  providers: [],
  isLoading: false,
  hasError: false
};

export const loadProviders = createAsyncThunk<
  Provider[] | null,
  void,
  { state: ProvidersState; rejectValue: null }
>('providers/loadProviders', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const { providers } = state;

  if (
    providers &&
    providers.length > 0 &&
    isCacheValid(state.lastUpdate ?? null, CACHE_LIFETIME_MS)
  ) {
    return providers;
  }

  try {
    const response = await fetchApi('cme_providers');
    const data = cmeProvidersResponseSchema.parse(await response.json());
    return data.providers;
  } catch (error) {
    reportError(error);
    return null;
  }
});

export const providersSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    setProviders: (state, action: PayloadAction<Provider[]>) => {
      state.providers = action.payload;
      state.lastUpdate = new Date().getTime();
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadProviders.pending, state => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(loadProviders.fulfilled, (state, action) => {
        if (action.payload) state.providers = action.payload;
        state.isLoading = false;
        state.lastUpdate = new Date().getTime();
        state.hasError = false;
      })
      .addCase(loadProviders.rejected, state => {
        state.isLoading = false;
        state.hasError = true;
      });
  }
});

export const { reducer, actions } = providersSlice;
export const { setProviders } = actions;
