import React from 'react';
import { HDPlaylistItems } from './HDPlaylistItems';
import HDPlaylistHeader from './HDPlaylistHeader';
import { ESVideoPlaylist } from '../../schema/playlist/ESVideoPlaylist';
import { Breadcrumb } from './types';

import './HDPlaylistDisplay.scss';

interface HDPlaylistDisplayProps {
  playlist: ESVideoPlaylist;
  breadcrumbs: Breadcrumb[];
  isUrgentCare: boolean;
  trimmedUrl: string;
}

export function HDPlaylistDisplay({
  playlist,
  breadcrumbs = [],
  isUrgentCare = false,
  trimmedUrl
}: HDPlaylistDisplayProps) {
  return (
    <>
      <HDPlaylistHeader
        playlist={playlist}
        breadcrumbs={breadcrumbs}
        isUrgentCare={isUrgentCare}
      />
      <div className="hd-playlist__feed-container">
        <HDPlaylistItems
          items={playlist.items}
          trimmedUrl={trimmedUrl}
          isUrgentCare={isUrgentCare}
        />
      </div>
    </>
  );
}

export default HDPlaylistDisplay;
