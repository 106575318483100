import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './_style/lib/components/Heading.scss';

interface HeadingProps {
  variant: 'mega' | 'xl' | 'lg' | 'md' | 'sm';
  Tag: 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'strong';
  children: ReactNode;
  className?: string;
  id?: string;
}

export function Heading({
  variant,
  Tag,
  children,
  className,
  id
}: HeadingProps) {
  return (
    <Tag
      id={id}
      className={classnames('global-heading', className)}
      data-variant={`heading-${variant}`}
    >
      {children}
    </Tag>
  );
}
