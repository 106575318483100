import { z } from 'zod';

export const webTagSchema = z.object({
  id: z.number(),
  shortname: z.string(),
  title: z.string(),
  hasCme: z.boolean(),
  extra: z.string().nullable()
});

export type WebTag = z.infer<typeof webTagSchema>;
