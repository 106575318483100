/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { get } from 'lodash';
import { useLocation } from 'react-router';
import AudienceTag from './AudienceTag';
import { resultTitle, resultDescription } from '../../lib/highlightedElements';
import formatDuration from '../../lib/formatDuration';
import { pluralizeText } from '../../lib/pluralizeText';
import { getEpisodeTypeClass } from '../../lib/getEpisodeTypeClass';
import { getEpisodePath } from '../../lib/getEpisodePath';
import { LinkModernizer } from '../LinkModernizer';
import config from '../../config';
import {
  HD,
  LLSA,
  CRUNCH_TIME_EM,
  ROP,
  CRUNCH_TIME_FM,
  UC_MAX
} from '../../lib/episodeTypes';

import './EpisodeResult.scss';

interface EpisodeResultData {
  episode: {
    title: string;
    resource: string;
    description: string;
    chapter_count: string;
    episode_type: {
      id: number;
      display: string;
    };
    duration: string | number;
    shortname: string;
    publish_date: string;
    cme: {
      hours: number;
    };
  };
}

interface EpisodeResultProps {
  media: { xs: boolean };
  data: EpisodeResultData;
}

export function EpisodeResult({ data, media }: EpisodeResultProps) {
  const { pathname } = useLocation();
  const {
    episode: {
      shortname,
      title,
      description,
      chapter_count: chapterCount,
      episode_type: { id: episodeType },
      duration
    }
  } = data;
  let art;
  if (episodeType === HD) {
    const artId = get(data, 'episode.video_image', null);
    art = artId ? `${config.videoArtUrl}/${artId}.jpg` : '';
  } else {
    const artId = get(data, 'episode.artwork.id', null);
    art = artId ? `${config.episodeArtUrl}/${artId}.jpg` : '';
  }
  const isUrgentCare = episodeType === UC_MAX;
  const cmeHours = get(data, 'episode.cme.hours', null);

  const publishDate = moment(data.episode.publish_date)
    .format('MMM DD YYYY')
    .toUpperCase();

  const classes = classnames(
    'result-item episode',
    getEpisodeTypeClass(episodeType)
  );

  return (
    <LinkModernizer
      to={getEpisodePath(isUrgentCare, `/${shortname}`)}
      href={`/episode/${shortname}`}
      className={classes}
    >
      <img src={art} alt="Episode Cover Art" />
      <div className="content">
        {![LLSA, CRUNCH_TIME_EM, CRUNCH_TIME_FM].includes(episodeType) && (
          <div className="publish-date">
            {publishDate}
            {pathname === '/episode' && episodeType === ROP && (
              <AudienceTag episodeType={episodeType} />
            )}
          </div>
        )}

        {resultTitle(title, { title: '', description: '' }, false)}
        {resultDescription(description, null, media.xs, 100)}
        <div className="footer">
          <span className="duration">{formatDuration(Number(duration))}</span>
          <span className="bullet"> / </span>
          <span className="chapter-count">
            {pluralizeText(chapterCount, 'chapter')}
          </span>
          {cmeHours && (
            <>
              <span className="bullet"> / </span>
              <span className="cme-hours">
                {pluralizeText(cmeHours.toString(), 'CME Hour')}
              </span>
            </>
          )}
        </div>
      </div>
    </LinkModernizer>
  );
}
