export function sendMessage(
  message: string,
  extraArgs?: Record<string, string>
) {
  const data = Object.assign({}, extraArgs, {
    type: message,
    name: window.name,
    source: 'emrap-client'
  });

  window.parent.postMessage(data, '*');
}
