// deprecated: use global width-wrapper utility
import React, { ReactNode } from 'react';
import cn from 'classnames';

import './Wrapper.scss';

export interface WrapperProps {
  children?: ReactNode;
  className?: string;
}

export function Wrapper({ children, className }: WrapperProps) {
  return <div className={cn('wrapper', className)}>{children}</div>;
}
