import React, { useEffect, useRef, useState } from 'react';
import range from 'lodash/range';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import Cookies from 'js-cookie';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';

import './PlaybackSpeedControl.scss';

const CSS_TRANSITION_DURATION_IN_MS = 250;
const MIN_RATE = 0.5;
const MAX_RATE = 3;
const COOKIE_DURATION_IN_DAYS = 365;

function setPlaybackSpeedPreference(cookieValue: string) {
  Cookies.set('audioPlaybackSpeedPreference', cookieValue, {
    expires: COOKIE_DURATION_IN_DAYS
  });
}

interface PlaybackSpeedControlProps {
  speed: number;
  setSpeed: (speed: number) => void;
  handlePlaybackSpeedAnalytics: () => void;
  episodeType: EpisodeTypeShortname;
}

const PlaybackSpeedControl = ({
  speed,
  setSpeed,
  handlePlaybackSpeedAnalytics,
  episodeType
}: PlaybackSpeedControlProps) => {
  const [showSlider, setShowSlider] = useState(false);

  // Lodash range suffers from accumulated floating-point summation errors. We
  // map and round the range values to work around this issue:
  // https://github.com/lodash/lodash/issues/1539
  const ticks = range(0.5, 3.1, 0.1).map(num => Math.round(num * 10) / 10);

  const sliderRef = useRef<HTMLDivElement>(null);
  const episodeColorClassName = `playback-speed-control--${episodeType}`;

  function handleClick(e: MouseEvent) {
    const target = e.target as Node;
    if (sliderRef.current && !sliderRef.current.contains(target)) {
      setShowSlider(false);
    }
  }

  function handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape' || e.key === 'Enter') {
      setShowSlider(false);
      e.preventDefault();
    }
  }

  function handleSpeedChange(e: React.FormEvent<HTMLInputElement>) {
    setPlaybackSpeedPreference(e.currentTarget.value);
    setSpeed(Number(e.currentTarget.value));
  }

  useEffect(() => {
    if (showSlider) {
      window.addEventListener('click', handleClick);
      window.addEventListener('keydown', handleKeydown);
      return () => {
        window.removeEventListener('click', handleClick);
        window.removeEventListener('keydown', handleKeydown);
      };
    }
    return undefined;
  }, [showSlider]);

  return (
    <div
      className={classnames('playback-speed-control', episodeColorClassName)}
      ref={sliderRef}
    >
      <button
        type="button"
        className="playback-speed-control__button"
        onClick={() => setShowSlider(!showSlider)}
      >
        {speed}×
      </button>
      <CSSTransition
        in={showSlider}
        timeout={CSS_TRANSITION_DURATION_IN_MS}
        classNames="playback-speed-control__slider--fade"
        unmountOnExit
      >
        <div className="playback-speed-control__slider-container">
          <label
            htmlFor="play-speed-control__slider"
            className="playback-speed-control__title"
          >
            Playback Speed
          </label>
          <input
            id="play-speed-control__slider"
            type="range"
            className={classnames('playback-speed-control__slider', {
              'playback-speed-control__slider--visible': showSlider
            })}
            min={MIN_RATE}
            max={MAX_RATE}
            step="0.1"
            defaultValue={speed}
            onChange={handleSpeedChange}
            onMouseUp={handlePlaybackSpeedAnalytics}
            onKeyUp={handlePlaybackSpeedAnalytics}
          />
          <div className="playback-speed-control__slider-ticks-container">
            {ticks.map(tick => (
              <span
                className="playback-speed-control__slider-tick"
                key={tick}
              />
            ))}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default PlaybackSpeedControl;
