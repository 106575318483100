import { track } from '@amplitude/analytics-browser';

// this is legacy filter analytics, overly specific to the /search page
// new approach:
// - use generic reportFilterAddedAnalytics for new events
// - create custom events in Amplitude dashboard to provide specifics
type FilterType =
  | 'episode type'
  | 'publish date'
  | 'citation type'
  | 'availability'
  | 'faculty'
  | 'tags'
  | 'document type';

type ReportFilterAppliedAnalyticsParams = {
  filterType: FilterType;
} & (
  | {
      filterValue: string | string[];
      publishDateStart?: never;
      publishDateEnd?: never;
    }
  | { filterValue?: never; publishDateStart: string; publishDateEnd: string }
);

export function reportFilterAppliedAnalytics({
  filterType,
  filterValue,
  publishDateStart,
  publishDateEnd
}: ReportFilterAppliedAnalyticsParams) {
  const lowerCaseFilterValue = Array.isArray(filterValue)
    ? filterValue.map(value => value.toLowerCase())
    : filterValue?.toLowerCase();

  track('Filter_Applied', {
    filter_type: filterType,
    filter_value:
      Array.isArray(lowerCaseFilterValue) && lowerCaseFilterValue.length === 0
        ? 'all'
        : lowerCaseFilterValue,
    publish_date_start: publishDateStart,
    publish_date_end: publishDateEnd,
    path: window.location.pathname
  });
}
