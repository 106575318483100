import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './SidebarExtraSection.scss';

interface SidebarExtraSectionProps {
  children: ReactNode;
  className?: string;
}

const SidebarExtraSection = ({
  children,
  className
}: SidebarExtraSectionProps) => (
  <div className={classnames('sidebar-extra-section', className)}>
    {children}
  </div>
);

export default SidebarExtraSection;
