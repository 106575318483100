import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from '../../lib/hooks';
import { accountSlice } from '../../store/account';
import { firebaseSlice } from '../../store/firebase';
import { useLoadTopLevelVideoPlaylistsQuery } from '../../store/videoPlaylists';
import { HDVideoThumbnail } from '../HDThumbnails/HDVideoThumbnail';
import { HDPlaylistThumbnail } from '../HDThumbnails/HDPlaylistThumbnail';
import { HDVideoFeed } from './HDVideoFeed';
import QuickLinks from './QuickLinks';
import { HDHero } from './HDHero';
import { VideoHero } from './VideoHero';
import { ReactComponent as HeroBgShape } from '../../images/hd/hero-bg-shape.svg';
import Loader from '../Loader';
import { getHDPath } from '../../lib/getHDPath';
import { reportPlaylistOpenedAnalytics } from '../../analytics/reportPlaylistOpenedAnalytics';
import { useElementHeight } from '../../hooks/useElementHeight';
import { HDHeaderBar } from '../HDNav/HDHeaderBar';
import { getPlaybackData } from '../../lib/getPlaybackData';
import { ESVideoPlaylistChapterItem } from '../../schema/playlist/ESVideoPlaylistItem';
import { ESVideoPlaylist } from '../../schema/playlist/ESVideoPlaylist';

import './HDLandingPage.scss';

export function HDLandingPage() {
  const accountState = useSelector(state => state[accountSlice.name]);
  const firebaseToken = useSelector(state => state[firebaseSlice.name].token);
  const { ordering, openMenu } = useParams<{
    ordering: string;
    openMenu: string;
  }>();

  const { reskinVideoPage } = useFlags();

  const isUrgentCare = ordering === 'urgent-care';
  const defaultMenuState = openMenu === 'all-playlists' ? 'open' : 'closed';

  const navBarHeight = useElementHeight('.nav-bar');
  const miniBannerHeight = useElementHeight('.mini-banner');

  useEffect(() => {
    const topOffset = navBarHeight + miniBannerHeight;
    document.body.style.setProperty('--top-offset', `${topOffset}px`);
  }, [navBarHeight, miniBannerHeight]);

  const [arePlaylistsLoading, setArePlaylistsLoading] = useState(true);
  const { data: playlists } = useLoadTopLevelVideoPlaylistsQuery(
    isUrgentCare ? 'urgent-care' : 'default'
  );

  const [visiblePlaylists, setVisiblePlaylists] = useState<ESVideoPlaylist[]>(
    []
  );

  const featuredChapter = useMemo(() => {
    const path = isUrgentCare ? 'featured-uc' : 'featured';

    const chapter = playlists
      ?.find(playlist => playlist.url_path === path)
      ?.items.find(item => item.type === 'chapter');

    if (chapter?.type !== 'chapter') {
      return null;
    }

    if (isUrgentCare) {
      return {
        ...chapter,
        url_path: `/playlist/${path}/${chapter.url_path}`
      };
    }

    return chapter;
  }, [playlists, isUrgentCare]);

  useEffect(() => {
    async function formatPlaylistsWithPlaybackData() {
      if (!playlists || accountState.isLoading) {
        return;
      }

      const filteredPlaylists = playlists.filter(playlist => {
        if (['featured', 'featured-uc'].includes(playlist.url_path)) {
          return false;
        }
        if (reskinVideoPage && playlist.title === 'COVID-19') {
          return false;
        }
        return true;
      });

      if (accountState.isLoading === false && accountState.account === null) {
        setVisiblePlaylists(filteredPlaylists);
        setArePlaylistsLoading(false);
      }

      if (accountState.account === null) return;

      const mediaIds = filteredPlaylists.flatMap(playlist =>
        playlist.items
          .filter(item => item.type === 'chapter')
          .map(item => {
            const chapterItem = item as ESVideoPlaylistChapterItem;
            return chapterItem.chapter_media_id;
          })
          .filter(mediaId => mediaId !== undefined)
      );
      if (mediaIds.length === 0) {
        setVisiblePlaylists(filteredPlaylists);
        setArePlaylistsLoading(false);
        return;
      }

      const playbackData = await getPlaybackData(
        firebaseToken,
        mediaIds,
        accountState.account.id
      );
      const formattedPlaylists = filteredPlaylists.map(playlist => ({
        ...playlist,
        items: playlist.items.map(item => {
          if (item.type !== 'chapter') {
            return item;
          }

          const playlistItem = item as ESVideoPlaylistChapterItem;
          const playbackItem = playbackData.find(
            data => data.media === playlistItem.chapter_media_id
          );
          if (playbackItem) {
            return {
              ...item,
              currentpoint: playbackItem.currentpoint
            };
          }
          return item;
        })
      }));

      setVisiblePlaylists(formattedPlaylists);
      setArePlaylistsLoading(false);
    }

    formatPlaylistsWithPlaybackData();
  }, [playlists, reskinVideoPage, accountState]);

  return (
    <div
      className="hd-landing-page"
      data-theme={isUrgentCare ? 'uc-max' : null}
    >
      <Helmet>
        <title>HD | EM:RAP</title>
        <meta property="og:title" content="EM:RAP HD" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.emrap.org/hd" />
        <meta
          property="og:image"
          content="https://www.emrap.org/images/elements/hd-header-logo.png"
        />
      </Helmet>
      {reskinVideoPage ? (
        <section className="hd-landing-page__top-section">
          <HeroBgShape className="hd-landing-page__top-section-shape" />
          <div className="hd-landing-page__top-section-content global-is-width-wrapper">
            <HDHeaderBar isUrgentCare={isUrgentCare} />
            <VideoHero
              isUrgentCare={isUrgentCare}
              featuredChapter={featuredChapter}
            />
          </div>
        </section>
      ) : (
        <HDHero
          isLoading={arePlaylistsLoading}
          isUrgentCare={isUrgentCare}
          chapter={featuredChapter}
          defaultMenuState={defaultMenuState}
        />
      )}
      {arePlaylistsLoading ? (
        <Loader className="hd-loader__container" />
      ) : (
        <div className="hd-landing-page__video-feed-container">
          <section className="hd-landing-page__video-feed">
            {!reskinVideoPage && (
              <QuickLinks
                className="hd-landing-page__quick-links"
                links={visiblePlaylists.map(playlist => ({
                  name: playlist.title,
                  to: getHDPath(isUrgentCare, `/playlist/${playlist.url_path}`),
                  onClick: () =>
                    reportPlaylistOpenedAnalytics({
                      playlist: playlist.title,
                      itemCount: playlist.video_count,
                      pageSpecialty: isUrgentCare ? 'UC' : 'HD',
                      eventSource: 'quick links'
                    })
                }))}
              />
            )}

            {visiblePlaylists.map(playlist => (
              <HDVideoFeed
                key={playlist.url_path}
                title={playlist.title}
                count={playlist.video_count}
                to={getHDPath(isUrgentCare, `/playlist/${playlist.url_path}`)}
                isUrgentCare={
                  isUrgentCare ||
                  playlist.graphic === 'fundamentals' ||
                  playlist.graphic === 'key-topics'
                }
                onClick={() =>
                  reportPlaylistOpenedAnalytics({
                    playlist: playlist.title,
                    itemCount: playlist.video_count,
                    pageSpecialty: isUrgentCare ? 'UC' : 'HD',
                    eventSource: 'see all'
                  })
                }
              >
                {playlist.items.map(item => {
                  if (item.type === 'playlist' || item.type === 'episode') {
                    return (
                      <HDPlaylistThumbnail
                        isVideoReskin={reskinVideoPage}
                        key={item.url_path}
                        className={
                          item.type === 'playlist' ? item.graphic : undefined
                        }
                        videoCount={item.video_count}
                        title={item.title}
                        prefix={item.prefix ? item.prefix : undefined}
                        uploadDate={item.uploaded_at}
                        to={getHDPath(
                          isUrgentCare,
                          `/playlist/${playlist.url_path}/${item.url_path}`
                        )}
                        onClick={() => {
                          if (item.video_count) {
                            reportPlaylistOpenedAnalytics({
                              playlist: item.title,
                              itemCount: item.video_count,
                              pageSpecialty: isUrgentCare ? 'UC' : 'HD',
                              eventSource: 'playlist carousel'
                            });
                          }
                        }}
                      />
                    );
                  }

                  // item is a chapter
                  const thumbnail = `${process.env.REACT_APP_CDN_BASE}${item.thumbnail['480'].path}`;
                  return (
                    <HDVideoThumbnail
                      key={item.url_path}
                      imageUrl={thumbnail}
                      duration={item.duration}
                      secondsPlayed={
                        reskinVideoPage ? item.currentpoint : undefined
                      }
                      title={item.title}
                      uploadDate={item.uploaded_at}
                      href={getHDPath(
                        isUrgentCare,
                        `/playlist/${playlist.url_path}/${item.url_path}`
                      )}
                    />
                  );
                })}
              </HDVideoFeed>
            ))}
          </section>
        </div>
      )}
    </div>
  );
}
