import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EpisodeList } from './EpisodeList';
import EpisodeHeaderBar from '../EpisodeHeaderBar/EpisodeHeaderBar';
import EmaHeader from '../PageHeader/EmaHeader';
import EmrapHeader from '../PageHeader/EmrapHeader';
import EpisodeMobileDropdown from './EpisodeMobileDropdown';
import ArchiveSidebar from '../Sidebar/ArchiveSidebar';
import theme from '../../theme';
import { setSearchParams } from '../../lib/setSearchParams';
import { getArchiveEpisodeQuery } from '../../lib/getArchiveEpisodeQuery';
import { getEpisodeTypeDisplay } from '../../lib/getEpisodeTypeDisplay';
import { EMRAP, C3, EMA, NONE } from '../../lib/episodeTypes';

import config from '../../config';

import './EpisodeGuide.scss';

export function matchEpisodeType(type) {
  switch (type) {
    case 'emrap':
      return EMRAP;
    case 'c3':
      return C3;
    case 'ema':
      return EMA;
    default:
      return NONE;
  }
}

export function matchEpisodeHeader(type) {
  switch (type) {
    case 'emrap':
      return 'emrap-header-logo.png';
    case 'c3':
      return 'c3-header-logo.png';
    case 'ema':
      return 'ema-header-logo.png';
    default:
      return 'emrap-header-logo.png';
  }
}

export function ArchiveEpisodes({ media }) {
  const { episodeType, year } = useParams();

  const matchedType = matchEpisodeType(episodeType);

  const query = getArchiveEpisodeQuery([matchedType], year);

  const displayName = getEpisodeTypeDisplay(matchedType);

  return (
    <section className="episode-guide">
      <Helmet>
        <title>{displayName} Episode Archive | EM:RAP</title>
        <meta property="og:title" content={`${displayName} Episode Archive`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.emrap.org/${episodeType}/archive/${year}`}
        />
        <meta
          property="og:image"
          content={`https://www.emrap.org/images/elements/${matchEpisodeHeader(
            episodeType
          )}`}
        />
      </Helmet>
      <div className="app-container">
        <div className="results-container">
          <ReactiveBase
            app={config.episodeGuideApp}
            url={config.url}
            theme={theme}
            setSearchParams={setSearchParams}
          >
            <EpisodeHeaderBar episodeType={matchedType} displayLogo={false} />
            {matchedType === EMA && <EmaHeader year={year} />}
            {matchedType === EMRAP && <EmrapHeader year={year} />}
            {media.xs && <EpisodeMobileDropdown />}

            <div className="episode-list-container">
              <div className="episode-list-content">
                <EpisodeList
                  episodeTypes={[matchedType]}
                  media={media}
                  query={query}
                />
                <nav
                  className={classnames('search-sidebar', {
                    closed: true
                  })}
                >
                  <ArchiveSidebar />
                </nav>
              </div>
            </div>
          </ReactiveBase>
        </div>
      </div>
    </section>
  );
}

ArchiveEpisodes.propTypes = {
  media: PropTypes.shape({
    xs: PropTypes.bool,
    sm: PropTypes.bool
  }).isRequired
};

const mapStatetoProps = state => ({
  sort: state.filters.episodeSort
});

export default connect(mapStatetoProps)(ArchiveEpisodes);
