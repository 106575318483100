import { track } from '@amplitude/analytics-browser';
import { CurrentStep } from '../lib/cmeEvaluationSteps';

interface ReportCmeEvaluationFlowSteppedAnalyticsParams {
  actionStep: 'forward' | 'back' | 'close';
  actionText?: string;
  evaluationStep: CurrentStep;
}

export function reportCmeEvaluationFlowSteppedAnalytics({
  actionStep,
  actionText,
  evaluationStep
}: ReportCmeEvaluationFlowSteppedAnalyticsParams) {
  track('CME_Evaluation_Flow_Stepped', {
    action_step: actionStep,
    action_text: actionText,
    evaluation_step: evaluationStep,
    path: window.location.pathname
  });
}
