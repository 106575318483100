import React from 'react';
import classnames from 'classnames';

import './_style/lib/components/Button.scss';

export interface ButtonProps {
  variant: 'primary' | 'secondary' | 'tertiary';
  size?: 'default' | 'small';
  type?: 'reset' | 'submit' | 'button';
  disabled?: boolean;
  onClick?: () => void;
  label: string;
  IconStart?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  IconEnd?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  className?: string;
}

export const Button = ({
  variant,
  size,
  type = 'button',
  disabled = false,
  onClick,
  label,
  IconStart,
  IconEnd,
  className
}: ButtonProps) => (
  /* eslint-disable react/button-has-type */
  /* See https://github.com/yannickcr/eslint-plugin-react/issues/1555 */
  <button
    type={type}
    className={classnames('global-button', className)}
    data-variant={variant}
    data-size={size}
    disabled={disabled}
    onClick={onClick}
  >
    {IconStart !== undefined && (
      <IconStart
        aria-hidden="true"
        focusable="false"
        className="global-button__icon"
      />
    )}
    {label}
    {IconEnd !== undefined && (
      <IconEnd
        aria-hidden="true"
        focusable="false"
        className="global-button__icon"
      />
    )}
  </button>
);
