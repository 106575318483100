import React from 'react';
import cn from 'classnames';
import { FacultyHeadshot } from './FacultyHeadshot';
import { FacultyTooltipModal } from './FacultyTooltipModal';

import './FacultyTooltip.scss';

interface Props {
  title: string;
  text: string;
  onClick: () => void;
  imgSrc: string;
  facultyOnClick: () => void;
  flipped?: boolean;
  className?: string;
}

export function FacultyTooltip({
  title,
  text,
  onClick,
  facultyOnClick,
  imgSrc,
  flipped,
  className
}: Props) {
  return (
    <li
      className={cn('uc-faculty-tooltip', className, {
        'uc-faculty-tooltip--flipped': flipped
      })}
    >
      <FacultyHeadshot
        className="uc-faculty-tooltip__headshot"
        imgSrc={imgSrc}
        imgAlt={title}
        onClick={facultyOnClick}
      />
      <FacultyTooltipModal
        className="uc-faculty-tooltip__modal"
        title={title}
        text={text}
        onClick={onClick}
        imgSrc={imgSrc}
        flipped={flipped}
      />
    </li>
  );
}
