import React from 'react';
import cn from 'classnames';
import { IconName } from './Icon';
import { SecondaryButton } from './SecondaryButton';

import './FeatureBubble.scss';

interface Props {
  img: string;
  text: string;
  icon: IconName;
  buttonText: string;
  onClick: () => void;
  flipped?: boolean;
  className?: string;
}

export function FeatureBubble({
  img,
  text,
  icon,
  buttonText,
  flipped = false,
  onClick,
  className = ''
}: Props) {
  return (
    <li
      className={cn('uc-feature-bubble__container', className, {
        'uc-feature-bubble__container--flipped': flipped
      })}
      aria-label={icon}
    >
      <div className="uc-feature-bubble__content">
        <img src={img} alt={img} className="uc-feature-bubble__image" />
        <p className="uc-feature-bubble__text">{text}</p>
        <SecondaryButton
          icon={icon}
          text={buttonText}
          onClick={onClick}
          report={() => null}
          className="uc-feature-bubble__button"
        />
      </div>
    </li>
  );
}
