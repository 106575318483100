import {
  C3_PLAYLIST_LOAD_START,
  C3_PLAYLIST_LOAD_SUCCESS,
  C3_PLAYLIST_LOAD_FAILURE,
  ALL_C3_PLAYLISTS_LOAD_START,
  ALL_C3_PLAYLISTS_LOAD_SUCCESS,
  ALL_C3_PLAYLISTS_LOAD_FAILURE,
  ActionTypes
} from '../actions/c3Playlists';
import { C3PlaylistsState } from '../lib/stateTypes';

export const initialState: C3PlaylistsState = {
  playlists: {},
  allPlaylists: {
    isLoading: false,
    data: null,
    error: null,
    lastUpdate: null
  }
};

/**
 * @return {C3PlaylistsState}
 */
export default function c3PlaylistReducer(
  state = initialState,
  action: ActionTypes
) {
  switch (action.type) {
    case C3_PLAYLIST_LOAD_START: {
      const { urlPath } = action;

      const playlist = state.playlists[urlPath];

      // Playlist does not exist in redux store yet.
      // Set loading state
      if (playlist === undefined) {
        return {
          ...state,
          playlists: {
            ...state.playlists,
            [urlPath]: {
              isLoading: true,
              data: null,
              error: null,
              lastUpdate: null
            }
          }
        };
      }

      // Playlist exists, and is not currently being loaded.
      // Set loading and populate with existing data which will
      // be replaced when the load is successful.
      if (!playlist.isLoading) {
        return {
          ...state,
          playlists: {
            ...state.playlists,
            [urlPath]: {
              ...playlist,
              isLoading: true,
              error: null
            }
          }
        };
      }

      return state;
    }
    case C3_PLAYLIST_LOAD_SUCCESS: {
      const { urlPath, data } = action;

      return {
        ...state,
        playlists: {
          ...state.playlists,
          [urlPath]: {
            isLoading: false,
            data,
            error: null,
            lastUpdate: new Date().toISOString()
          }
        }
      };
    }
    case C3_PLAYLIST_LOAD_FAILURE: {
      const { urlPath, error } = action;

      return {
        ...state,
        playlists: {
          ...state.playlists,
          [urlPath]: {
            isLoading: false,
            data: null,
            error,
            lastUpdate: null
          }
        }
      };
    }
    case ALL_C3_PLAYLISTS_LOAD_START: {
      if (!state.allPlaylists.isLoading) {
        return {
          ...state,
          allPlaylists: {
            ...state.allPlaylists,
            isLoading: true,
            error: null
          }
        };
      }

      return state;
    }
    case ALL_C3_PLAYLISTS_LOAD_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        allPlaylists: {
          isLoading: false,
          data,
          error: null,
          lastUpdate: new Date().toISOString()
        }
      };
    }
    case ALL_C3_PLAYLISTS_LOAD_FAILURE: {
      const { error } = action;

      return {
        ...state,
        allPlaylists: {
          isLoading: false,
          data: null,
          error,
          lastUpdate: null
        }
      };
    }
    default:
      return state;
  }
}
