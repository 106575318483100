import React from 'react';
import parse from 'html-react-parser';
import { passMarks } from '../lib/passMarks';

const CreateSnippet = ({ text }: { text: string }) =>
  text ? (
    <div className="snippet">
      ...
      {parse(text, { replace: passMarks })}
      ...
    </div>
  ) : null;

export default CreateSnippet;
