import moment from 'moment-timezone';

export function getArchiveEpisodeQuery(episodeType: number, year: string) {
  const date = `${year}-01-01`;
  const start = moment(date).unix() * 1000;
  const end = moment(date).add(1, 'year').unix() * 1000;

  return () => ({
    query: {
      bool: {
        must: [
          { term: { type: 'episode' } },
          { exists: { field: 'episode.artwork.id' } },
          {
            terms: {
              'episode.episode_type.id': episodeType
            }
          },
          {
            range: {
              'episode.publish_date': {
                gte: start,
                lte: end
              }
            }
          },
          { exists: { field: 'episode.duration' } }
        ]
      }
    },

    sort: [
      {
        'episode.preview': { order: 'asc' }
      },
      { 'episode.publish_date': { order: 'asc' } }
    ]
  });
}
