import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch, useLocation, Switch, Route } from 'react-router';
import Loader from '../Loader';
import { useLoadVideoPlaylistQuery } from '../../store/videoPlaylists';
import HDEpisodePage from './HDEpisodePage';
import HDPlaylistDisplay from './HDPlaylistDisplay';
import NotFound from '../NotFound';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import { Breadcrumb } from './types';

import './HDPlaylistPage.scss';

interface HDPlaylistPageProps {
  breadcrumbs: Breadcrumb[];
}

const getPlaylistShortname = (url: string) => {
  const parts = url.split('/').filter(part => part !== '');
  return parts.length > 0 ? parts[parts.length - 1] : '';
};

export function HDPlaylistPage({ breadcrumbs = [] }: HDPlaylistPageProps) {
  const { url } = useRouteMatch();
  const location = useLocation();
  const trimmedUrl = url.replace(/\/$/g, '');

  const playlistShortname = getPlaylistShortname(location.pathname);

  const {
    data: playlist,
    isLoading,
    isError,
    error
  } = !location.pathname.includes('/episode/')
    ? useLoadVideoPlaylistQuery(playlistShortname)
    : { data: undefined, isLoading: false, isError: false, error: undefined };

  const isUrgentCare = url.includes('urgent-care');

  const title = playlist?.title ?? '';
  const pageTitle = title ? `${title} | ` : '';
  const ogTitle = title ? `EM:RAP HD - ${title}` : 'EM:RAP HD';

  const breadcrumb: Breadcrumb = {
    playlist: playlist ?? null,
    isLoading,
    url: trimmedUrl
  };

  const parent = breadcrumbs[breadcrumbs.length - 1];
  const showLoader = isLoading || (parent !== undefined && parent.isLoading);
  const showNotFound = !showLoader && isError;
  const showPlaylistDisplay = !showLoader && !showNotFound && playlist !== null;

  return (
    <Switch>
      <Route
        path={`${trimmedUrl}/episode/:shortname`}
        render={routeProps => (
          <HDEpisodePage
            {...routeProps}
            breadcrumbs={[...breadcrumbs, breadcrumb]}
          />
        )}
      />
      <Route
        path={`${trimmedUrl}/:shortname`}
        render={routeProps => (
          <HDPlaylistPage
            {...routeProps}
            breadcrumbs={[...breadcrumbs, breadcrumb]}
          />
        )}
      />
      <Route path="*">
        <ScrollToTopOnMount />
        <Helmet>
          <title>{pageTitle}HD | EM:RAP</title>
          <meta property="og:title" content={ogTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://www.emrap.org${url}`} />
          <meta
            property="og:image"
            content="https://www.emrap.org/images/elements/hd-header-logo.png"
          />
        </Helmet>
        <div className="hd-playlist-page-container">
          {showNotFound && (
            <NotFound
              sourcePath={location.pathname}
              component="HDPlaylistPage"
              reason={`HD Playlist with URL path “${playlist?.url_path}” not found.`}
              extra={error}
            />
          )}
          {showLoader && <Loader className="hd-loader__container" />}
          {showPlaylistDisplay && playlist && (
            <HDPlaylistDisplay
              playlist={playlist}
              breadcrumbs={breadcrumbs}
              trimmedUrl={trimmedUrl}
              isUrgentCare={isUrgentCare}
            />
          )}
        </div>
      </Route>
    </Switch>
  );
}
