import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Results } from '../../Results';
import CitationSearchFilters from './CitationSearchFilters';
import PrintButtons from './PrintButtons';
import MobileDropdownContainer from '../../MobileComponents/MobileDropdownContainer';
import MobileDropdown from '../../MobileComponents/MobileDropdown';
import MobileFilterToggle from '../../MobileComponents/MobileFilterToggle';
import MobileAudienceSelect from '../../MobileComponents/MobileAudienceSelect';
import SearchedFor from '../SearchedFor';
import CitationSearchHeader from '../../PageHeader/CitationSearchHeader';
import { SearchSortSelect } from '../../SortSelect';
import AudienceSelect from '../../AudienceSelect';
import SearchSortList from '../SearchSortList';
import { setSearchParams } from '../../../lib/setSearchParams';

import '../../App.scss';
import '../Search.scss';
import theme from '../../../theme';
import config from '../../../config';

import { setCurrentSearch as setCurrentSearchAction } from '../../../actions/filters';

const { searchSortOptions } = config;

export const CitationSearch = ({
  media,
  setCurrentSearch,
  currentSearch,
  currentAudience,
  filtersOpen
}) => (
  <section className="emrap-search-container">
    <Helmet>
      <title>Citation Search | EM:RAP</title>
      <meta property="og:title" content="Search EM:RAP Citations" />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.emrap.org/search/citations"
      />
      <meta
        property="og:image"
        content="https://www.emrap.org/images/elements/emrap-header-logo.png"
      />
    </Helmet>
    <ReactiveBase
      app={config.citationSearchApp}
      url={config.url}
      theme={theme}
      setSearchParams={setSearchParams}
    >
      <div className="app-container">
        <div className="search-results-container">
          <div className="search-results">
            <CitationSearchHeader />
            <DataSearch
              componentId="emrap-search-bar"
              filterLabel="Search"
              dataField={[
                'episode.title',
                'episode.description',
                'chapter.title',
                'chapter.description',
                'attachment.title',
                'citation.citation',
                'citation.abstract_xhtml',
                'chapter.tags',
                'chapter.faculty'
              ]}
              fieldWeights={[8, 5, 6, 5, 4, 4, 3, 2, 2]}
              placeholder="Search by title, citations, or abstract…"
              autosuggest={false}
              highlight
              fuzziness={0}
              iconPosition="right"
              URLParams
              debounce={250}
              className="data-search-container"
              innerClass={{
                input: 'search-input'
              }}
              onValueChange={setCurrentSearch}
              defaultValue={currentSearch}
              defaultQuery={() => ({
                query: {
                  bool: {
                    must: [
                      {
                        terms: {
                          'episode.episode_type.id': currentAudience.value
                        }
                      }
                    ]
                  }
                }
              })}
            />
            {media.sm && <SearchedFor />}
            {media.xs && (
              <MobileDropdownContainer>
                <MobileFilterToggle />
                <MobileDropdown buttonText={currentAudience.label}>
                  <MobileAudienceSelect />
                </MobileDropdown>
                <MobileDropdown buttonText="Sort" showClear={false}>
                  <SearchSortList options={searchSortOptions} />
                </MobileDropdown>
              </MobileDropdownContainer>
            )}
            <div className="filter-dropdown-container">
              {media.sm && <AudienceSelect />}
              {media.sm && <SearchSortSelect options={searchSortOptions} />}
            </div>

            <Results media={media}>
              <PrintButtons buttonText="Print Selected Citations" />
            </Results>
          </div>
          <nav
            className={classnames('search-sidebar citation-search', {
              closed: !filtersOpen
            })}
          >
            <CitationSearchFilters media={media} />
          </nav>
        </div>
      </div>
    </ReactiveBase>
  </section>
);

const mapStatetoProps = state => ({
  filtersOpen: state.filters.open,
  currentSearch: state.filters.currentSearch,
  currentAudience: state.filters.audience
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setCurrentSearch: setCurrentSearchAction
    },
    dispatch
  );

const CitationSearchContainer = connect(
  mapStatetoProps,
  mapDispatchtoProps
)(CitationSearch);

CitationSearch.propTypes = {
  media: PropTypes.shape({
    mobile: PropTypes.bool,
    desktop: PropTypes.bool,
    sm: PropTypes.bool,
    xs: PropTypes.bool
  }).isRequired,
  setCurrentSearch: PropTypes.func.isRequired,
  currentSearch: PropTypes.string.isRequired,
  currentAudience: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  filtersOpen: PropTypes.bool.isRequired
};

export default CitationSearchContainer;
