import React from 'react';
import classnames from 'classnames';
import { formatDateMonthYear } from '../../lib/formatDateMonthYear';

interface HDPlaylistTitleProps {
  title: string;
  prefix?: string;
  createdAt: string;
  updatedAt: string;
  videoCount: number;
}

export function HDPlaylistTitle({
  title = '',
  prefix = 'Playlist',
  createdAt,
  updatedAt,
  videoCount = 0
}: HDPlaylistTitleProps) {
  return (
    <div className="hd-playlist-header__details">
      <h1
        id="skip_to_content"
        className={classnames('hd-playlist-header__title', {
          'hd-playlist-header__title--short': title.length < 20,
          'hd-playlist-header__title--long': title.length > 50
        })}
      >
        {title}
      </h1>
      <div className="hd-playlist-header__subtitle">
        <span className="hd-playlist-header__subtitle-label">{prefix}: </span>
        {videoCount} {videoCount === 1 ? 'Video' : 'Videos'} | Created{' '}
        {formatDateMonthYear(createdAt)} | Last Updated{' '}
        {formatDateMonthYear(updatedAt)}
      </div>
    </div>
  );
}

export default HDPlaylistTitle;
