import { debounce } from 'lodash';
import { reportPlayerSeekStartedAnalytics } from '../analytics/reportPlayerSeekStartedAnalytics';
import { reportPlayerSeekEndedAnalytics } from '../analytics/reportPlayerSeekEndedAnalytics';
import { MediaReport } from './types';
import { getPageSpecialty } from './getPageSpecialty';
import { EpisodeTypeShortname } from '../schema/webEpisode/episodeType';

interface SeekParams {
  currentPoint: number;
  episodeType: EpisodeTypeShortname;
  mediaReport: MediaReport;
}

const handleSeekStartAnalytics = ({
  currentPoint,
  episodeType,
  mediaReport
}: SeekParams) => {
  reportPlayerSeekStartedAnalytics({
    ...mediaReport,
    currentPoint: Math.round(currentPoint),
    pageSpecialty: getPageSpecialty(episodeType)
  });
};

const handleSeekEndAnalytics = ({
  currentPoint,
  episodeType,
  mediaReport
}: SeekParams) => {
  reportPlayerSeekEndedAnalytics({
    ...mediaReport,
    currentPoint: Math.round(currentPoint),
    pageSpecialty: getPageSpecialty(episodeType)
  });
};

export const debouncedHandleSeekStartAnalytics = debounce(
  handleSeekStartAnalytics,
  500,
  { leading: true, trailing: false }
);

export const debouncedHandleSeekEndAnalytics = debounce(
  handleSeekEndAnalytics,
  500
);
