const theme = {
  typography: {
    fontFamily: '"proximasoft", "Helvetica Neue", Helvetica, sans-serif',
    fontSize: '16px'
  },

  colors: {
    textColor: '#555',
    primaryTextColor: '#fff',
    primaryColor: '#ff6c2c',
    secondaryColor: '#bf5121',
    titleColor: '#3c4557',
    alertColor: '#d9534f',
    borderColor: '#fff'
  }
};

export default theme;
