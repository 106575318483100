import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUrgentCare } from '../../hooks/useUrgentCare';
import { WebChapterWithIndex } from '../../lib/formatPlaylistChapterPage';
import getPlaylistItemURL from '../../lib/getPlaylistItemURL';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import PlaylistItem from './PlaylistItem';

export const EpisodeChapter = ({
  chapter,
  currentChapterId,
  showThumbnail,
  episodeType,
  isAbstract
}: {
  chapter: WebChapterWithIndex;
  showThumbnail: boolean;
  currentChapterId: number;
  episodeType: EpisodeTypeShortname;
  isAbstract: boolean;
}) => {
  const isUrgentCare = useUrgentCare() || episodeType === 'ucmaximus';
  const { pathname } = useLocation();
  return chapter.id === currentChapterId ? (
    <div
      className="episode-playlist__chapter episode-playlist__chapter--selected"
      key={chapter.id}
    >
      <PlaylistItem
        chapter={chapter}
        isSelected
        chapterNumber={chapter.index}
        showThumbnail={showThumbnail}
        episodeType={episodeType}
        isAbstract={isAbstract}
      />
    </div>
  ) : (
    <Link
      to={getPlaylistItemURL(
        chapter.shortname,
        true,
        false,
        isUrgentCare,
        pathname
      )}
      className="episode-playlist__chapter"
      key={chapter.id}
    >
      <PlaylistItem
        chapter={chapter}
        chapterNumber={chapter.index}
        showThumbnail={showThumbnail}
        episodeType={episodeType}
        isAbstract={isAbstract}
      />
    </Link>
  );
};
