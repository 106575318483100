import { SET_PAGE_DATE_RANGE } from '../actions/page';

import config from '../config';

const { dateSliderStart, dateSliderEnd } = config;

const initialState = {
  dateRange: {
    start: dateSliderStart,
    end: dateSliderEnd
  }
};

export default function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          start: action.start || dateSliderStart,
          end: action.end || dateSliderEnd
        }
      };
    default:
      return state;
  }
}
