import React from 'react';
import PropTypes from 'prop-types';
import { SelectedFilters } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MainSearchLink from './MainSearchLink';
import CitationTypePicker from './CitationTypePicker';
import DateFilter from '../../DateFilter';
import SearchFilterItem from '../SearchFilterItem';
import SidebarSection from '../../Sidebar/SidebarSection';
import {
  setDateRange as setDateRangeAction,
  setCurrentTags as setCurrentTagsAction,
  toggleFilters as toggleFiltersAction
} from '../../../actions/filters';

import '../SearchFilters.scss';

const CitationSearchFilters = ({
  media,
  filtersOpen,
  toggleFilters,
  dateRange,
  setDateRange
}) => (
  <div className="filters-container">
    {media.xs && filtersOpen && (
      <button type="button" className="backdrop" onClick={toggleFilters} />
    )}
    <SidebarSection className="sidebar-section--main-search-link">
      <MainSearchLink />
    </SidebarSection>
    <SidebarSection title="Citation Type">
      <CitationTypePicker />
    </SidebarSection>
    <SidebarSection isTransparent className="sidebar-section--selected-filters">
      <SelectedFilters clearAllLabel="Clear All" render={SearchFilterItem} />
    </SidebarSection>
    <SidebarSection>
      <DateFilter
        componentId="publish-date"
        dataField="episode.publish_date"
        placeholder="Date Range"
        title="Published Date"
        filterLabel="Published Date"
        showHistogram
        snap
        dateRange={dateRange}
        setDateRange={setDateRange}
        URLParams
        react={{
          and: ['emrap-search-bar']
        }}
      />
    </SidebarSection>
  </div>
);

const mapStateToProps = state => ({
  filtersOpen: state.filters.open,
  currentTags: state.filters.tags,
  dateRange: state.filters.dateRange
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setCurrentTags: setCurrentTagsAction,
      setDateRange: setDateRangeAction,
      toggleFilters: toggleFiltersAction
    },
    dispatch
  );

const CitationSearchFiltersContainer = connect(
  mapStateToProps,
  mapDispatchtoProps
)(CitationSearchFilters);

CitationSearchFilters.propTypes = {
  media: PropTypes.shape({
    xs: PropTypes.bool
  }).isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  }).isRequired,
  setDateRange: PropTypes.func.isRequired
};

export default CitationSearchFiltersContainer;
