import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLoadTopLevelVideoPlaylistsQuery } from '../../store/videoPlaylists';
import Loader from '../Loader';
import HDSearchBar from '../HDNav/HDSearchBar';
import { HDHomeLink } from '../HDNav/HDHomeLink';
import { VideoHomeLink } from '../HDNav/VideoHomeLink';
import SketchyButton from '../LandingPage/SketchyButton';
import Icon from '../Icons/Icon';
import { getHDPath } from '../../lib/getHDPath';
import { reportPlaylistOpenedAnalytics } from '../../analytics/reportPlaylistOpenedAnalytics';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import {
  ESVideoPlaylistItem,
  ESVideoPlaylistPlaylistItem,
  ESVideoPlaylistEpisodeItem
} from '../../schema/playlist/ESVideoPlaylistItem';

import './HDAllPlaylistsMenu.scss';

const animationDurationMs = 250;

// TODO: Replace the parent selector with an element
// that allows us to still show the banner/nav
const MODAL_PARENT_SELECTOR = '.react-app';

interface HDPlaylistsMenuProps {
  defaultState?: 'open' | 'closed';
  isUrgentCare: boolean;
}

function isEpisode(
  item: ESVideoPlaylistItem
): item is ESVideoPlaylistEpisodeItem {
  return item.type === 'episode';
}

function isPlaylist(
  item: ESVideoPlaylistItem
): item is ESVideoPlaylistPlaylistItem {
  return item.type === 'playlist';
}

export function HDAllPlaylistsMenu({
  defaultState = 'closed',
  isUrgentCare
}: HDPlaylistsMenuProps) {
  const [isOpen, setIsOpen] = useState(defaultState === 'open');

  const { reskinVideoPage } = useFlags();

  const closeMenu = () => {
    setIsOpen(false);
  };

  const { data: playlists, isLoading } = useLoadTopLevelVideoPlaylistsQuery(
    isUrgentCare ? 'urgent-care' : 'default'
  );

  const sortedPlaylists = useMemo(
    () =>
      playlists
        ? playlists
            // Remove video items from children.
            .map(playlist => ({
              ...playlist,
              items: playlist.items.filter(isPlaylist),
              key: playlist.title
            }))

            // Remove playlists that do not contain child playlists
            // and playlists that are unused in the Video reskin.
            .filter(playlist => {
              const hasChildPlaylists =
                playlist.items.length > 0 && playlist.url_path !== 'latest';
              const isNotExcludedTitle = reskinVideoPage
                ? playlist.title !== 'COVID-19' &&
                  playlist.title !== 'Newest UC Videos'
                : true;
              return hasChildPlaylists && isNotExcludedTitle;
            })

            // Sort by longest playlist in reverse order.
            .sort((a, b) => b.items.length - a.items.length)
        : [],

    [playlists]
  );

  return (
    <div
      data-theme={isUrgentCare ? 'uc-max' : ''}
      data-is-reskin={reskinVideoPage}
    >
      <SketchyButton
        className="hd-playlists-menu__toggle hd-header-bar__button"
        hasIcon
        iconName="playlist"
        isFilled
        color="hd-purple"
        onClick={() => {
          reportButtonClickedAnalytics({
            buttonType: 'open menu',
            buttonText: 'all playlists',
            pageSpecialty: isUrgentCare ? 'UC' : 'HD'
          });
          setIsOpen(!isOpen);
        }}
      >
        <span>
          <span className="hd-playlists-menu__open-all">All</span> Playlists
        </span>
        <Icon
          name="arrow-right"
          className="hd-playlists-menu__secondary-icon"
        />
      </SketchyButton>
      <Modal
        overlayClassName="hd-playlists-menu__overlay"
        className="hd-playlists-menu"
        isOpen={isOpen}
        onRequestClose={closeMenu}
        closeTimeoutMS={animationDurationMs}
        bodyOpenClassName="hd-playlists-menu__body--open"
        parentSelector={() =>
          document.body.querySelector(MODAL_PARENT_SELECTOR) ?? document.body
        }
        onAfterOpen={() => {
          // adjust top offset to accommodate any page scroll
          const modalParent = document.body.querySelector(
            MODAL_PARENT_SELECTOR
          );
          if (modalParent instanceof HTMLElement) {
            modalParent.style.setProperty(
              '--top-offset',
              `${modalParent.getBoundingClientRect().y}px`
            );
          }
        }}
      >
        <div
          className="hd-playlists-menu__content"
          data-is-reskin={reskinVideoPage}
        >
          <div className="hd-playlists-menu__header">
            <div className="hd-playlists-menu__search">
              {reskinVideoPage ? (
                <VideoHomeLink
                  className="hd-header-bar__home-link"
                  isUrgentCare={isUrgentCare}
                />
              ) : (
                <HDHomeLink
                  className="hd-header-bar__home-link"
                  isUrgentCare={isUrgentCare}
                />
              )}
              <HDSearchBar isUrgentCare={isUrgentCare} />
            </div>
            <SketchyButton
              className="hd-playlists-menu__close"
              hasIcon
              iconName="playlist"
              isFilled
              onClick={() => {
                reportButtonClickedAnalytics({
                  buttonType: 'close menu',
                  buttonText: 'all playlists',
                  pageSpecialty: isUrgentCare ? 'UC' : 'HD'
                });
                closeMenu();
              }}
              color="hd-purple"
            >
              <span>
                <span className="hd-playlists-menu__close-all">All</span>{' '}
                Playlists
              </span>
              <Icon
                name="close"
                className="hd-playlists-menu__secondary-icon"
              />
            </SketchyButton>
          </div>
          {isLoading && <Loader />}
          {!isLoading && playlists && (
            <ul className="hd-playlists-menu__playlists">
              {sortedPlaylists.map(playlist => (
                <li
                  className="hd-playlists-menu__playlist"
                  key={playlist.title}
                >
                  <div className="hd-playlists-menu__playlist-title-container">
                    <Link
                      className="hd-playlists-menu__playlist-title"
                      to={getHDPath(
                        isUrgentCare,
                        `/playlist/${playlist.url_path}`
                      )}
                      onClick={() => {
                        reportPlaylistOpenedAnalytics({
                          playlist: playlist.title,
                          itemCount: playlist.video_count,
                          pageSpecialty: isUrgentCare ? 'UC' : 'HD'
                        });
                        closeMenu();
                      }}
                    >
                      {playlist.title}
                    </Link>
                  </div>
                  <ul className="hd-playlists-menu__playlist-items">
                    {playlist.items.map(item => (
                      <li
                        className="hd-playlists-menu__playlist-item"
                        key={item.title}
                      >
                        <Link
                          className="hd-playlists-menu__playlist-item-title"
                          to={getHDPath(
                            isUrgentCare,
                            `/playlist/${playlist.url_path}/${item.url_path}`
                          )}
                          onClick={() => {
                            reportPlaylistOpenedAnalytics({
                              playlist: playlist.title,
                              itemCount: playlist.video_count,
                              pageSpecialty: isUrgentCare ? 'UC' : 'HD'
                            });
                            closeMenu();
                          }}
                        >
                          <span className="hd-playlists-menu__item-title">
                            {item.title}
                            {isEpisode(item) && (
                              <span className="hd-playlists-menu__item-cme">
                                CME
                              </span>
                            )}
                          </span>{' '}
                          {(isPlaylist(item) || isEpisode(item)) && (
                            <span className="hd-playlists-menu__item-count">
                              {item.video_count}{' '}
                              <span className="hd-playlists-menu__item-count-videos">
                                {item.video_count === 1 ? 'video' : 'videos'}
                              </span>
                            </span>
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Modal>
    </div>
  );
}
