import React from 'react';
import classNames from 'classnames';
import formatDuration from '../../lib/formatDuration';

import './HDThumbnailDuration.scss';

interface Props {
  className?: string;
  duration?: number;
}

export function HDThumbnailDuration({ className, duration = 0 }: Props) {
  return (
    <div className={classNames(className, 'hd-thumbnail-duration')}>
      {formatDuration(duration)}
    </div>
  );
}
