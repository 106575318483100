import React from 'react';
import classnames from 'classnames';

import './_style/lib/components/FormLabel.scss';

type Variant = 'uppercase' | 'question';
export type Tag = 'label' | 'legend' | 'span';

type FormLabelProps = {
  Tag?: Tag;
  labelText: string;
  id?: string;
  variant: Variant;
  isVisuallyHidden?: boolean;
  htmlFor?: string;
};

export function FormLabel({
  Tag = 'label',
  labelText,
  id,
  variant,
  htmlFor,
  isVisuallyHidden = false
}: FormLabelProps) {
  return (
    <Tag
      htmlFor={htmlFor}
      id={id}
      data-variant={variant}
      className={classnames('global-form-label', {
        'global-is-visually-hidden': isVisuallyHidden
      })}
    >
      {labelText}
    </Tag>
  );
}
