import { AccountState } from './stateTypes';

export function getAccountType(state: AccountState): string | null {
  if (!state.account) {
    return null;
  }
  if (state.account.subscription) {
    return state.account.subscription.type;
  }
  return state.account.subscriptionType ? state.account.subscriptionType : null;
}
