import React from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import Loader from '../Loader';
import { useLoadVideoPlaylistQuery } from '../../store/videoPlaylists';
import HDPlaylistDisplay from './HDPlaylistDisplay';
import NotFound from '../NotFound';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import { Breadcrumb } from './types';

import './HDPlaylistPage.scss';

interface HDEpisodePageProps {
  breadcrumbs: Breadcrumb[];
}

export function HDEpisodePage({ breadcrumbs = [] }: HDEpisodePageProps) {
  const { url } = useRouteMatch();
  const location = useLocation();
  const trimmedUrl = url.replace(/\/$/g, '');
  const episodeShortname = `episode/${
    url.split('/')[url.split('/').length - 1] ?? ''
  }`;

  const shortname = episodeShortname;
  const {
    data: playlist,
    isLoading,
    isError,
    error
  } = useLoadVideoPlaylistQuery(shortname);
  const isUrgentCare = url.includes('urgent-care');
  const parent = breadcrumbs[breadcrumbs.length - 1];
  const showLoader = isLoading || (parent !== undefined && parent.isLoading);
  const showNotFound = !showLoader && isError;
  const showPlaylistDisplay = !showLoader && !showNotFound && playlist !== null;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://www.emrap.org${url}`} />
      </Helmet>
      <ScrollToTopOnMount />
      <div className="hd-episode-page-container">
        {showLoader && <Loader className="hd-loader__container" />}
        {showNotFound && (
          <NotFound
            sourcePath={location.pathname}
            component="HDEpisodePage"
            reason={`HD Playlist with URL path “${playlist?.url_path}” not found.`}
            extra={error}
          />
        )}
        {showPlaylistDisplay && playlist && (
          <HDPlaylistDisplay
            playlist={playlist}
            breadcrumbs={breadcrumbs}
            trimmedUrl={trimmedUrl}
            isUrgentCare={isUrgentCare}
          />
        )}
      </div>
    </>
  );
}

export default HDEpisodePage;
