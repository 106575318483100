import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as EmrapOneLogo } from '../../../images/conferences/emrap-one-logo.svg';
import { ReactComponent as EmrapOneLogoSquare } from '../../../images/conferences/emrap-one-logo-square.svg';
import ConferenceMenu from '../ConferenceMenu';
import ConferenceMenuItem from '../ConferenceMenuItem';
import ConferenceMenuIcon from '../ConferenceMenuIcon';
import { WoopWoopEvent } from '../../../schema/woopWoopEvent/woopWoopEvent';
import { WoopWoopEventRoute } from '../../../schema/woopWoopEvent/woopWoopEventRoute';

import './LiveConferenceSubPageHeader.scss';

interface Props {
  event: WoopWoopEvent;
  route: WoopWoopEventRoute;
  url: string;
  className?: string;
}

const LiveConferenceSubPageHeader = ({
  event,
  route,
  url,
  className
}: Props) => (
  <div
    className={classNames(
      'live-conference-subpage-header__container',
      className
    )}
  >
    <div className="live-conference-subpage-header__nav">
      <Link to={url} className="live-conference-subpage-header__logo--mobile">
        <EmrapOneLogoSquare />
      </Link>
      <Link to={url} className="live-conference-subpage-header__logo--desktop">
        <EmrapOneLogo />
      </Link>
      <ConferenceMenu className="live-conference-subpage-header__menu--mobile">
        {event.routes
          .filter(r => r.shortname !== 'faq')
          .map(r => (
            <ConferenceMenuItem
              key={r.shortname}
              url={`${url}/${r.shortname}`}
              name={r.shortname}
              title={r.title}
            />
          ))}
      </ConferenceMenu>
    </div>
    <div className="live-conference-subpage-header__content">
      <div>
        <h1 className="live-conference-subpage-header__title">
          <ConferenceMenuIcon
            name={route.shortname}
            className="live-conference-subpage-header__title__icon"
          />{' '}
          {route.title === 'FAQ' ? (
            <>
              {route.title}
              <span>s</span>
            </>
          ) : (
            route.title
          )}
        </h1>
        <p className="live-conference-subpage-header__description">
          {route.description}
        </p>
      </div>
      <ConferenceMenu className="live-conference-subpage-header__menu--desktop">
        {event.routes
          .filter(r => r.shortname !== 'faq')
          .map(r => (
            <ConferenceMenuItem
              key={r.shortname}
              url={`${url}/${r.shortname}`}
              name={r.shortname}
              title={r.title}
            />
          ))}
      </ConferenceMenu>
    </div>
  </div>
);

export default LiveConferenceSubPageHeader;

LiveConferenceSubPageHeader.defaultProps = {
  className: undefined
};
