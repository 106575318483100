import React from 'react';
import { formatDatefromTimestamp } from '../lib/formatDatefromTimestamp';

export function DateWithWordbreak({ timestamp }: { timestamp: string }) {
  const dateParts = formatDatefromTimestamp(timestamp).split('/');
  const monthAndDay = `${dateParts[0]}/${dateParts[1]}/`;
  const year = dateParts[2];
  return (
    <>
      {monthAndDay}
      <wbr />
      {year}
    </>
  );
}
