import React from 'react';
import ColumnHeader from './ColumnHeader';
import CrunchTimeEMDoctors from '../../images/episode-guide/doctors-crunchtime-em.svg';
import CrunchTimeEMLogo from '../../images/episode-guide/logo-crunchtime-em.svg';

import './PageHeader.scss';
import './CrunchTimeEMHeader.scss';

export const CrunchTimeEMHeader = () => {
  const title = 'EM:RAP’s Crunch Time EM';
  const description = `EM:RAP’s Crunch Time EM. EM board review made simple, focused, and
  accessible in the EM:RAP tradition. Perfect for the entire spectrum of
  EM exams.`;

  return (
    <ColumnHeader
      className="crunchtime-em-header"
      title={title}
      description={description}
      doctors={CrunchTimeEMDoctors}
      logo={CrunchTimeEMLogo}
    />
  );
};

export default CrunchTimeEMHeader;
