import { getRandomAlphanumeric } from './getRandomSequence';

/**
 * Detects if the user can connect to the internet by checking
 * window.navigator.onLine (boolean) and making a HEAD request
 * to our own origin.
 * @returns {Promise<boolean>} - true if the user can connect to our server, false if the user cannot connect to the network or our server
 */
export async function isOnline(): Promise<boolean> {
  if (!window.navigator.onLine) {
    return false;
  }

  const url = new URL(window.location.origin);

  // random value to prevent cached responses
  url.searchParams.set('is-online', getRandomAlphanumeric());

  try {
    const response = await fetch(url.toString(), { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    return false;
  }
}
