import { getAccountType } from './getAccountType';
import { AccountState } from './stateTypes';

export function hasPaidAndActiveSubscription(state: AccountState): boolean {
  return Boolean(
    state.account &&
      state.account.subscription &&
      getAccountType(state) !== 'free'
  );
}
