import React from 'react';
import { ReactComponent as CameraIcon } from '../../images/icons/camera.svg';
import { Launchpad, LaunchpadLink } from './Launchpad';

import './VideoIntroduction.scss';

const LAUNCHPAD_LINKS: LaunchpadLink[] = [
  {
    id: 0,
    to: '/hd/playlist/procedures',
    label: 'Procedures'
  },
  {
    id: 1,
    to: '/hd/playlist/courses',
    label: 'Courses & Lectures'
  },
  {
    id: 2,
    to: '/hd/playlist/LiveEventsPL',
    label: 'Events'
  }
];

export function VideoIntroduction() {
  return (
    <div className="video-introduction">
      <h2 className="video-introduction__heading">
        <CameraIcon
          className="video-introduction__heading-logo"
          width="88.7"
          height="60"
          aria-hidden="true"
        />
        <span>Video</span>
      </h2>
      <p className="video-introduction__blurb">
        Master your clinical skills with the world’s experts—just press play.
      </p>
      <Launchpad links={LAUNCHPAD_LINKS} />
    </div>
  );
}
