import React from 'react';

import './ReplyingToLabel.scss';

interface ReplyingToLabelProps {
  name: string;
}

export function ReplyingToLabel({ name }: ReplyingToLabelProps) {
  return (
    <div className="replying-to-label" data-testid="replying-to-label">
      Replying to <span className="replying-to-label__name">{name}</span>
    </div>
  );
}
