import React from 'react';
import classnames from 'classnames';
import EpisodeSearchBar from './EpisodeSearchBar';
import AllTopicsMenu from './AllTopicsMenu';
import EpisodeHomeLink from './EpisodeHomeLink';
import EpisodeTypeSelect from './EpisodeTypeSelect';
import { C3, NONE } from '../../lib/episodeTypes';
import { getEpisodeTypePath } from '../../lib/getEpisodeTypePath';
import { getEpisodeTypeClass } from '../../lib/getEpisodeTypeClass';

import './EpisodeHeaderBar.scss';
import { EpisodeTypeNumber } from '../../lib/types';

interface EpisodeHeaderBarProps {
  className?: string;
  episodeType?: EpisodeTypeNumber;
  displayLogo?: boolean;
  displayDropDown?: boolean;
  defaultMenuState?: 'open' | 'closed';
}

export default function EpisodeHeaderBar({
  className,
  episodeType = NONE,
  displayLogo = true,
  defaultMenuState = 'closed',
  displayDropDown = true
}: EpisodeHeaderBarProps) {
  return (
    <div
      className={classnames(
        'header-bar',
        getEpisodeTypeClass(episodeType, 'header-bar'),
        className
      )}
    >
      {displayDropDown && (
        <div
          className={classnames(
            'header-bar__dropdown',
            getEpisodeTypeClass(episodeType, 'header-bar__dropdown'),
            className
          )}
        >
          <EpisodeTypeSelect episodeType={episodeType} />
        </div>
      )}
      {displayLogo && (
        <EpisodeHomeLink
          episodeType={episodeType}
          pathTo={getEpisodeTypePath(episodeType)}
          className="header-bar__home-link"
        />
      )}
      <div
        className={classnames(
          'header-bar__search',
          getEpisodeTypeClass(episodeType, 'header-bar__search')
        )}
      >
        <EpisodeSearchBar episodeType={episodeType} />
      </div>
      {episodeType === C3 && (
        <div className="header-bar__buttons">
          {!displayDropDown && (
            <EpisodeHomeLink
              episodeType={episodeType}
              pathTo={getEpisodeTypePath(episodeType)}
              className="header-bar__home-link--mobile"
            />
          )}
          <div className="header-bar__links">
            <AllTopicsMenu
              defaultState={defaultMenuState}
              displayLogo={displayLogo}
              displayDropDown={displayDropDown}
            />
          </div>
        </div>
      )}
    </div>
  );
}
