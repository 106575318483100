function getConnector(count: number) {
  if (count > 1) {
    return count > 2 ? ', and ' : ' and ';
  }
  return '';
}

export default function arrayToList(items: string[]) {
  return (
    items.slice(0, -1).join(', ') + getConnector(items.length) + items.slice(-1)
  );
}
