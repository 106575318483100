import striptags from 'striptags';

function stripOnlyMarkTags(text: string) {
  return text.replace(/<\/?mark>/g, '');
}

export function highlightFullText(
  text: string,
  highlights: string | Array<string>
) {
  if (typeof highlights === 'string') {
    highlights = [highlights];
  }
  highlights.forEach(highlight => {
    const strippedHighlight = stripOnlyMarkTags(highlight);
    text = text.replace(strippedHighlight, highlight);
  });
  return striptags(text, '<mark>');
}
