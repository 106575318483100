import { track } from '@amplitude/analytics-browser';
import { MediaReport } from '../lib/types';

type reportPlaybackResumedAnalyticsParams = MediaReport & {
  pageSpecialty?: string;
};

export function reportPlaybackResumedAnalytics({
  id,
  chapterTitle,
  episodeTitle,
  currentPoint,
  duration,
  mediaType,
  pageSpecialty
}: reportPlaybackResumedAnalyticsParams) {
  // keep legacy Amplitude event name
  track('Resume_Player', {
    'Media.id': id,
    'Media.chapter_title': chapterTitle,
    'Media.episode_title': episodeTitle,
    'Media.current_point': currentPoint,
    'Media.duration': duration,
    'Media.media_type': mediaType,
    page_specialty: pageSpecialty,
    path: window.location.pathname
  });
}
