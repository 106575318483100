import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { HDHeroLogo as HDHomeLinkLogo } from '../HDLandingPage/HDHeroLogo';
import { HDUCMaximusLogo } from '../HDLandingPage/HDUCMaximusLogo';
import { getHDPath } from '../../lib/getHDPath';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './HDHomeLink.scss';

interface Props {
  className?: string;
  isUrgentCare: boolean;
}

export function HDHomeLink({ className = '', isUrgentCare }: Props) {
  return (
    <div className={classnames('hd-home-link__container', className)}>
      <Link
        className="hd-home-link"
        to={getHDPath(isUrgentCare, '')}
        onClick={() =>
          reportLinkClickedAnalytics({
            to: getHDPath(isUrgentCare, ''),
            linkText: 'hd',
            pageSpecialty: isUrgentCare ? 'UC' : 'HD'
          })
        }
      >
        <HDHomeLinkLogo
          isUrgentCare={isUrgentCare}
          className="hd-home-link__logo"
        />
      </Link>
      {isUrgentCare && (
        <HDUCMaximusLogo to="/uc-dashboard" className="hd-home-link__uc-logo" />
      )}
    </div>
  );
}
