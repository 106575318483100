import React, { useState } from 'react';
import classnames from 'classnames';
import { RangeSlider, StateProvider } from '@appbaseio/reactivesearch';
import moment from 'moment-timezone';
import { get } from 'lodash';
import config from '../config';
import { reportFilterAppliedAnalytics } from '../analytics/reportFilterAppliedAnalytics';

// allows the date range to be set from the URL params on init
// and cleared when the clear filters button is clicked
import { SearchStateHelper } from './SearchStateHelper';

import './DateFilter.scss';

const { dateSliderStep, dateSliderStart, dateSliderEnd } = config;

interface DateFilterProps {
  className?: string;
  componentId: string;
  dataField: string;
  episodeClass?: string;
  title?: string;
  filterLabel?: string;
  showHistogram?: boolean;
  snap?: boolean;
  URLParams?: boolean;
  react?: { and: string[] };
  setDateRange: (range: [number, number]) => void;
  defaultSelected?: (
    min?: number,
    max?: number
  ) => { start: number; end: number };
}

const DateFilter = ({
  episodeClass = 'all',
  className = '',
  componentId,
  setDateRange,
  defaultSelected = (min = dateSliderStart, max = dateSliderEnd) => ({
    start: min,
    end: max
  }),
  ...props
}: DateFilterProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      className={classnames(
        'date-filter',
        `date-filter--${episodeClass}`,
        className
      )}
    >
      <SearchStateHelper
        onInit={searchState => {
          const selectedValue = get(searchState, `[${componentId}].value`, [
            dateSliderStart,
            dateSliderEnd
          ]);
          setDateRange(selectedValue);
          setIsLoading(false);
        }}
        watchState={componentId}
        onClearFilter={() => {
          setDateRange([dateSliderStart, dateSliderEnd]);
        }}
      />
      <RangeSlider
        componentId={componentId}
        range={{
          start: dateSliderStart,
          end: dateSliderEnd
        }}
        rangeLabels={{
          start: moment(dateSliderStart).format('YYYY'),
          end: moment(dateSliderEnd).format('YYYY')
        }}
        stepValue={dateSliderStep}
        interval={dateSliderStep}
        className="date-filter__range-slider"
        innerClass={{
          title: 'date-filter__title',
          slider: 'date-filter__slider',
          label: 'date-filter__label'
        }}
        onDrag={setDateRange}
        defaultValue={defaultSelected()}
        onValueChange={value => {
          if (isLoading) return;
          reportFilterAppliedAnalytics({
            filterType: 'publish date',
            publishDateStart: moment(value.start).format('YYYY'),
            publishDateEnd: moment(value.end).format('YYYY')
          });
        }}
        {...props}
      />
      <StateProvider
        render={({ searchState }) => {
          const value = get(searchState, `[${componentId}].value`);
          const [selectedRangeStart, selectedRangeEnd] = value || [
            dateSliderStart,
            dateSliderEnd
          ];
          return (
            <div className="date-filter__selected-dates">
              {selectedRangeStart !== null
                ? moment(selectedRangeStart).format('YYYY')
                : '????'}
              <span className="date-filter__to">to</span>
              {selectedRangeEnd !== null
                ? moment(selectedRangeEnd).format('YYYY')
                : '????'}
            </div>
          );
        }}
      />
    </div>
  );
};

export default DateFilter;
