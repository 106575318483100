import React from 'react';
import { useGetMarketingSpots } from '../../hooks/useGetMarketingSpots';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { isEmrapUrl } from '../../lib/isEmrapUrl';
import { reportMarketingSpotClickedAnalytics } from '../../analytics/reportMarketingSpotClickedAnalytics';
import { HeroBanner } from './HeroBanner';
import { MarketingSpotSection } from './MarketingSpotSection';
import { MarketingSpotModal } from './MarketingSpotModal';

interface HeroBannerSlotProps {
  slotId:
    | 'landingPageHeroBanner'
    | 'landingPageSection'
    | 'landingPageBucket'
    | 'modal';
}

export function MarketingSlot({ slotId }: HeroBannerSlotProps) {
  const slotSpots = useGetMarketingSpots(slotId);

  const isHeroBanner = slotId === 'landingPageHeroBanner';
  const isBucket = slotId === 'landingPageBucket';
  const isSection = slotId === 'landingPageSection';

  if (slotSpots.length === 0) {
    return null;
  }

  if (isHeroBanner) {
    return (
      <>
        {slotSpots.map(
          spot =>
            spot.headline && (
              <HeroBanner
                key={spot.id}
                headline={spot.headline}
                message={spot.message}
                imageSrc={spot.imageSource}
                campaignColor={spot.campaignColor}
                ctaText={spot.ctaText}
                href={
                  isEmrapUrl(spot.link) ? getPhpSitePath(spot.link) : spot.link
                }
                report={() =>
                  reportMarketingSpotClickedAnalytics({
                    marketingSpotType: 'hero-banner',
                    to: spot.link,
                    buttonText: spot.ctaText
                  })
                }
              />
            )
        )}
      </>
    );
  }

  if (isBucket || isSection) {
    return (
      <>
        {slotSpots.map(spot => (
          <MarketingSpotSection
            key={spot.id}
            isBucket={isBucket}
            campaignColor={spot.campaignColor}
            headline={spot.headline}
            message={spot.message}
            ctaText={spot.ctaText}
            href={isEmrapUrl(spot.link) ? getPhpSitePath(spot.link) : spot.link}
            imgSrc={spot.imageSource}
            report={() =>
              reportMarketingSpotClickedAnalytics({
                marketingSpotType: isBucket ? 'bucket' : 'section',
                to: spot.link,
                buttonText: spot.ctaText
              })
            }
          />
        ))}
      </>
    );
  }

  return (
    <MarketingSpotModal
      campaignColor={slotSpots[0].campaignColor}
      headline={slotSpots[0].headline}
      message={slotSpots[0].message}
      ctaText={slotSpots[0].ctaText}
      modalId={slotSpots[0].id}
      href={slotSpots[0].link}
      imgSrc={slotSpots[0].imageSource}
      report={() =>
        reportMarketingSpotClickedAnalytics({
          marketingSpotType: 'modal',
          to: slotSpots[0].link,
          buttonText: slotSpots[0].ctaText
        })
      }
    />
  );
}
