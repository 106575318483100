import React, { TextareaHTMLAttributes } from 'react';
import classnames from 'classnames';

import './Textarea.scss';

export default function Textarea(
  props: TextareaHTMLAttributes<HTMLTextAreaElement>
) {
  // eslint-disable-next-line react/destructuring-assignment
  const className = classnames('textarea', props.className);
  return <textarea {...props} className={className} />;
}
