import { CmeEvaluationResponse } from '../schema/cme/cmeEvaluationResponse';
import { CmeEvaluation } from '../schema/cme/cmeEvaluations';

export function getDependentQuestionIdsToRemove(
  evaluation: CmeEvaluation,
  evaluationResponse: CmeEvaluationResponse
) {
  const chosenOptionIds = Object.values(evaluationResponse.questionResponse)
    .map(response => response.evaluation_question_option_id)
    .flat();

  return evaluation.questions
    .filter(
      question =>
        // This questions depends on another and the dependent options is not selected
        question.dependent_question_option_id !== null &&
        !chosenOptionIds.includes(question.dependent_question_option_id) &&
        // This question has an answer
        (evaluationResponse.questionResponse[question.id].text_value !== null ||
          evaluationResponse.questionResponse[question.id]
            .evaluation_question_option_id.length > 0)
    )
    .map(question => question.id);
}
