import React from 'react';
import PropTypes from 'prop-types';
import listify from 'listify';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { StateProvider } from '@appbaseio/reactivesearch';
import { get } from 'lodash';
import { getEpisodeTypeDisplay } from '../../lib/getEpisodeTypeDisplay';

import './SearchedFor.scss';

export const SearchedFor = ({ dateRange }) => (
  <StateProvider
    render={({ searchState }) => {
      const searchBar = searchState['emrap-search-bar'];
      const typeSelect = get(searchState, '[episode-type-select].value', []);
      const citationType = searchState['citation-type'];
      const query = searchBar ? searchBar.value : null;
      const types = Array.isArray(typeSelect)
        ? typeSelect.map(id => getEpisodeTypeDisplay(Number.parseInt(id, 10)))
        : [];
      const citation = citationType ? citationType.value : null;
      const after = moment(dateRange.start).format('YYYY');
      const before = moment(dateRange.end).format('YYYY');

      if (query) {
        return (
          <div className="searched-for searched-for--query">
            Searched for <strong>{query}</strong>
            {types && types.length > 0 && (
              <span>
                {' '}
                in <strong>{listify(types)}</strong>
              </span>
            )}
            {citation && (
              <span>
                {' '}
                in <strong>{citation}</strong>
              </span>
            )}
            {after && (
              <span>
                {' '}
                after <strong>{after}</strong>
              </span>
            )}
            {before && (
              <span>
                {' '}
                and before <strong>{before}</strong>
              </span>
            )}
          </div>
        );
      }

      return <div className="searched-for searched-for--empty">{'\u00a0'}</div>;
    }}
  />
);

SearchedFor.propTypes = {
  dateRange: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  dateRange: state.filters.dateRange
});

export default connect(mapStateToProps)(SearchedFor);
