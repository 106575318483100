import React from 'react';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SketchyLink from '../LandingPage/SketchyLink';
import { VideoHomeLink } from './VideoHomeLink';
import HDSearchBar from './HDSearchBar';
import { HDAllPlaylistsMenu } from '../HDAllPlaylistsMenu/HDAllPlaylistsMenu';
import { HDHomeLink } from './HDHomeLink';
import { getHDPath } from '../../lib/getHDPath';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './HDHeaderBar.scss';

interface Props {
  className?: string;
  displayHDLogo?: boolean;
  defaultMenuState?: 'open' | 'closed';
  isUrgentCare?: boolean;
  isChapterPage?: boolean;
}

export function HDHeaderBar({
  className = '',
  displayHDLogo = true,
  defaultMenuState = 'closed',
  isUrgentCare = false,
  isChapterPage = false
}: Props) {
  const { reskinVideoPage } = useFlags();

  return (
    <div
      className={classnames('hd-header-bar', className, {
        'hd-header-bar--chapter-page': isChapterPage
      })}
      data-theme={isUrgentCare ? 'uc-max' : ''}
      data-is-reskin={reskinVideoPage}
    >
      <div className="hd-header-bar__search">
        {displayHDLogo &&
          (reskinVideoPage ? (
            <VideoHomeLink
              className="hd-header-bar__home-link"
              isUrgentCare={isUrgentCare}
            />
          ) : (
            <HDHomeLink
              className="hd-header-bar__home-link"
              isUrgentCare={isUrgentCare}
            />
          ))}
        <HDSearchBar isUrgentCare={isUrgentCare} />
      </div>
      <div className="hd-header-bar__buttons">
        {reskinVideoPage ? (
          <VideoHomeLink
            className="hd-header-bar__home-link--mobile"
            isUrgentCare={isUrgentCare}
          />
        ) : (
          <HDHomeLink
            className="hd-header-bar__home-link--mobile"
            isUrgentCare={isUrgentCare}
          />
        )}
        <div className="hd-header-bar__links">
          {!reskinVideoPage && (
            <SketchyLink
              to={getHDPath(isUrgentCare, '/playlist/smartcardsPL')}
              onClick={() =>
                reportLinkClickedAnalytics({
                  to: 'playlist/smartcardsPL',
                  linkText: 'smart cards',
                  pageSpecialty: isUrgentCare ? 'UC' : 'HD'
                })
              }
              className="hd-header-bar__button"
              color="hd-purple"
              isFilled
              hasIcon
              iconName="smart-card"
            >
              Smart Cards
            </SketchyLink>
          )}
          <HDAllPlaylistsMenu
            defaultState={defaultMenuState}
            isUrgentCare={isUrgentCare}
          />
        </div>
      </div>
    </div>
  );
}
