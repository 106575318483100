export function getCorependiumAppLink(operatingSystem?: string) {
  switch (operatingSystem) {
    case 'iOS':
      return 'https://apps.apple.com/tr/app/corependium/id1581291387';
    case 'Android':
      return 'https://play.google.com/store/apps/details?id=org.emrap.corependium.app&hl=en_CA&gl=US';
    default:
      return 'https://www.emrap.org/podcast';
  }
}
