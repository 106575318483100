import { z } from 'zod';

export const genericDisclosureSchema = z.object({
  provider: z.string(),
  content_html: z.string()
});

export type CmeGenericDisclosure = z.infer<typeof genericDisclosureSchema>;

export type CmeGenericDisclosurePayload = {
  disclosure: CmeGenericDisclosure;
  providerId: number;
};

export const temporaryDisclosureSchema = z.object({
  disclosurePreviewId: z.string(),
  content_html: z.string()
});

export type CmeTemporaryDisclosure = z.infer<typeof temporaryDisclosureSchema>;
