import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { EpisodeList } from './EpisodeList';
import EpisodeHeaderBar from '../EpisodeHeaderBar/EpisodeHeaderBar';
import RopHeader from '../PageHeader/RopHeader';
import EpisodeMobileDropdown from './EpisodeMobileDropdown';
import RopSidebar from '../Sidebar/RopSidebar';
import theme from '../../theme';
import { setSearchParams } from '../../lib/setSearchParams';
import { ROP } from '../../lib/episodeTypes';
import getEpisodeQuery from '../../lib/getEpisodeQuery';

import config from '../../config';

import './EpisodeGuide.scss';

const EPISODE_TYPES = [ROP];
const { episodeSortOptions } = config;
const episodeConfig = config.episodes.find(
  episode =>
    EPISODE_TYPES.every(type => episode.types.includes(type)) &&
    episode.types.every(type => EPISODE_TYPES.includes(type))
);

export function ROPEpisodes({ media, sort }) {
  const query = getEpisodeQuery(EPISODE_TYPES, sort);

  return (
    <section className="episode-guide">
      <Helmet>
        <title>ROP Episodes | EM:RAP</title>
        <meta property="og:title" content="EM:RAP ROP Episodes" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.emrap.org/rop" />
        <meta
          property="og:image"
          // TODO: missing ROP header
          content="https://www.emrap.org/images/elements/emrap-header-logo.png"
        />
      </Helmet>
      <div className="app-container">
        <div className="results-container">
          <ReactiveBase
            app={config.episodeGuideApp}
            url={config.url}
            theme={theme}
            setSearchParams={setSearchParams}
          >
            <EpisodeHeaderBar episodeType={ROP} displayLogo={false} />
            <RopHeader />
            {media.xs && (
              <EpisodeMobileDropdown sortOptions={episodeSortOptions} />
            )}

            <div className="episode-list-container">
              <div className="episode-list-content">
                <EpisodeList
                  episodeTypes={EPISODE_TYPES}
                  media={media}
                  query={query}
                />
                <RopSidebar media={media} episodeConfig={episodeConfig} />
              </div>
            </div>
          </ReactiveBase>
        </div>
      </div>
    </section>
  );
}

ROPEpisodes.propTypes = {
  media: PropTypes.shape({
    xs: PropTypes.bool,
    sm: PropTypes.bool
  }).isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    sortBy: PropTypes.string,
    dataField: PropTypes.string
  }).isRequired
};

const mapStatetoProps = state => ({
  sort: state.filters.episodeSort
});

export default connect(mapStatetoProps)(ROPEpisodes);
