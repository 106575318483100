import React, { useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import { useDispatch, useSelector } from '../../lib/hooks';
import { loadC3Playlist as loadPlaylistAction } from '../../actions/c3Playlists';
import EpisodePlaylistHeader from './EpisodePlaylistHeader';
import EpisodePlaylistItems from './EpisodePlaylistItems';
import { ESC3PlaylistSchema } from '../../schema/playlist/ESC3Playlist';
import NotFound from '../NotFound';
import Loader from '../Loader';

import './EpisodePlaylistPage.scss';

interface EpisodePlaylistPageProps {
  shortname: string;
}

export function EpisodePlaylistPage({ shortname }: EpisodePlaylistPageProps) {
  const { url } = useRouteMatch<{ shortname: string }>();
  const location = useLocation();
  const trimmedUrl = url.replace(/\/$/g, '');
  const dispatch = useDispatch();

  const { playlist, isLoading, hasError, errorMessage } = useSelector(state => {
    const playlistState = state.c3Playlists.playlists[shortname];
    const playlistData = playlistState?.data;
    const zodResult = ESC3PlaylistSchema.safeParse(playlistData);

    return {
      playlist: zodResult.success ? zodResult.data : null,
      // The playlist at playlists[shortname] is undefined if we haven't dispatched
      // the loadPlaylistAction yet in which case we should consider it loading.
      isLoading: playlistState?.isLoading ?? true,
      hasError: playlistState?.error ?? !zodResult.success,
      errorMessage:
        playlistState?.error ??
        (zodResult.success ? '' : zodResult.error.message)
    };
  });

  useEffect(() => {
    dispatch(loadPlaylistAction(shortname));
  }, [dispatch, loadPlaylistAction, shortname]);

  let content = null;

  if (isLoading) {
    content = <Loader className="playlist-loader__container" />;
  } else if (hasError) {
    content = (
      <NotFound
        sourcePath={location.pathname}
        component="EpisodePlaylistPage"
        reason={`error: ${errorMessage}`}
      />
    );
  } else if (playlist) {
    content = (
      <>
        <EpisodePlaylistHeader
          title={playlist.title}
          graphic={playlist.graphic}
          itemCount={playlist.items.length}
        />
        <div className="playlist__feed-container">
          <EpisodePlaylistItems
            items={playlist.items}
            trimmedUrl={trimmedUrl}
          />
        </div>
      </>
    );
  }

  return <div className="playlist-page-container">{content}</div>;
}

export default EpisodePlaylistPage;
