// deprecated: use RadioOption and Question or Fieldset container in new code
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './RadioList.scss';

const RadioList = ({ defaultValue, onChange, options }) => {
  const [selectedValue, setSelectedValue] = useState('');
  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = value => {
    onChange(value);
    setSelectedValue(value);
  };

  return (
    <ul className="radio-list">
      {options.map(option => (
        <li
          key={option.value}
          className={classnames('radio-list__option', {
            active: selectedValue === option.value
          })}
        >
          <button
            type="button"
            className="radio-list__button"
            onClick={() => {
              handleChange(option.value);
            }}
          >
            {option.title}
          </button>
        </li>
      ))}
    </ul>
  );
};

RadioList.propTypes = {
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  onChange: PropTypes.func
};

RadioList.defaultProps = {
  defaultValue: '',
  onChange: () => {},
  options: []
};

export default RadioList;
