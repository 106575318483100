export const SET_PAGE_DATE_RANGE = 'SET_PAGE_DATERANGE';

export function setPageDateRange(range) {
  const [start, end] = range;
  return {
    type: SET_PAGE_DATE_RANGE,
    start,
    end
  };
}
