import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import SidebarExtraSection from './SidebarExtraSection';
import { EpisodeTypeNumber } from '../../lib/types';
import { getEpisodeTypeClass } from '../../lib/getEpisodeTypeClass';

import './SidebarLink.scss';

type SidebarLinkProps = {
  className?: string;
  episodeType: EpisodeTypeNumber;
  isInverted?: boolean;
  title?: string;
  text: string;
  buttonText: string;
  onClick: () => void;
  icon?: ReactNode;
} & ({ to: string; href?: never } | { to?: never; href: string });

export function SidebarLink({
  className,
  isInverted = false,
  episodeType,
  to,
  href,
  title,
  text,
  buttonText,
  onClick,
  icon
}: SidebarLinkProps) {
  return (
    <SidebarExtraSection
      className={classnames(
        'sidebar-link',
        className,
        `sidebar-link--${getEpisodeTypeClass(episodeType)}`,
        {
          'sidebar-link--inverted': isInverted
        }
      )}
    >
      {to !== undefined ? (
        <Link className="sidebar-link__link" to={to} onClick={onClick}>
          {title && <h3 className="sidebar-link__header">{title}</h3>}
          <p className="sidebar-link__content">{text}</p>
          <div
            className={classnames(
              'sidebar-link__button',
              `sidebar-link__button--${getEpisodeTypeClass(episodeType)}`
            )}
          >
            <div className="sidebar-link__icon">{icon}</div>
            <span className="sidebar-link__button-text">{buttonText}</span>
          </div>
        </Link>
      ) : (
        <a className="sidebar-link__link" href={href} onClick={onClick}>
          {title && <h3 className="sidebar-link__header">{title}</h3>}
          <p className="sidebar-link__content">{text}</p>
          <div
            className={classnames(
              'sidebar-link__button',
              `sidebar-link__button--${getEpisodeTypeClass(episodeType)}`
            )}
          >
            <div className="sidebar-link__icon">{icon}</div>
            <span className="sidebar-link__button-text">{buttonText}</span>
          </div>
        </a>
      )}
    </SidebarExtraSection>
  );
}
