/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebEpisode } from '../schema/webEpisode/webEpisode';

export default function formatChapterBlocks(data: any): WebEpisode {
  data.chapters.forEach((chapter: any) => {
    if (!chapter.blocks) {
      chapter.blocks = {
        text: [],
        attachment: [],
        media: [],
        image: [],
        citation: ''
      };
    } else {
      chapter.blocks.attachment.forEach((element: any) => {
        element.type = 'attachment';
      });
      chapter.blocks.media.forEach((element: any) => {
        element.type = 'media';
      });
      chapter.blocks.image.forEach((element: any) => {
        element.type = 'image';
      });
      chapter.blocks.text.forEach((element: any) => {
        element.type = 'text';
      });
    }
  });

  return data;
}
