import React from 'react';
import classnames from 'classnames';

import './ConferenceLink.scss';

interface ConferenceLinkProps {
  href: string;
  children: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ConferenceLink = ({
  href,
  children,
  className,
  disabled,
  onClick
}: ConferenceLinkProps) =>
  disabled ? (
    <span
      className={classnames(
        'conference-link',
        'conference-link--disabled',
        className
      )}
    >
      <span className="conference-link__text">{children}</span>
    </span>
  ) : (
    <a
      href={href}
      className={classnames('conference-link', className)}
      onClick={onClick}
    >
      <span className="conference-link__text">{children}</span>
    </a>
  );

export default ConferenceLink;

ConferenceLink.defaultProps = {
  className: undefined,
  disabled: false
};
