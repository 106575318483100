import { delay } from './delay';
import { MaximumRetryError } from './MaximumRetryError';
/* eslint-disable no-await-in-loop */
/* the loop is intentionally sequential */

/**
 * Retry a function with exponential backoff, the first retry
 * is immediate after which it waits for initialDelay
 * @param fn the function to retry, returns immediately if successful
 * @param initialDelay the first delay in milliseconds
 * @param maxRetries will fail after retrying this many times
 * @param maxDelay the delay will not exceed this value
 */
export async function retryWithBackoff<T>(
  fn: () => Promise<T>,
  errHandler: (e: unknown) => void,
  initialDelay = 100,
  maxRetries = 10,
  maxDelayMs = 6000
): Promise<T> {
  let lastError: unknown;
  let retries = 0;
  while (retries < maxRetries) {
    try {
      const result = await fn();
      return result;
    } catch (e) {
      lastError = e;
      errHandler(e);
      // calculate delay in ms using exponential backoff formula
      // duration is doubled with each retry attempt until it reaches
      // the maximum delay
      const delayMs = Math.min(initialDelay * 2 ** retries, maxDelayMs);
      await delay(delayMs);
    } finally {
      retries += 1;
    }
  }
  throw new MaximumRetryError(
    'Maximum retry limit reached',
    retries,
    lastError as Error
  );
}
