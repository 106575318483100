import { track } from '@amplitude/analytics-browser';

interface ReportSearchSubmittedAnalyticsParams {
  query: string;
  results: number;
  isCitationSearch: boolean;
}

export function reportSearchSubmittedAnalytics({
  query,
  results,
  isCitationSearch
}: ReportSearchSubmittedAnalyticsParams) {
  track('Search_Submitted', {
    query,
    results,
    isCitationSearch,
    path: window.location.pathname
  });
}
