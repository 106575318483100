import React from 'react';
import ReactPlayer from 'react-player';
import { WebChapterBlock } from '../../schema/webEpisode/webChapterBlocks';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import ChapterDownloadItem from './ChapterDownloadItem';
import ChapterBlockMediaDetails from './ChapterBlockMediaDetails';
import config from '../../config';
import findImageByType from '../../lib/findImageByType';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './ChapterBlock.scss';

const { cdnVideoUrl, cdnVideoPostfix, cdnUrl } = config;

interface ChapterBlockProps {
  chapterBlock: WebChapterBlock;
  episodeType: EpisodeTypeShortname;
  canDownload?: boolean;
}

const ChapterBlock = ({
  chapterBlock,
  episodeType,
  canDownload = false
}: ChapterBlockProps) => {
  const pageSpecialty = getPageSpecialty(episodeType);

  return (
    <div className={`chapter-block chapter-block--${episodeType}`}>
      {chapterBlock.type === 'text' && (
        <div
          className="chapter-block__html-wrapper"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: chapterBlock.bodyText }} // HTML from EM:RAP admin
        />
      )}
      {chapterBlock.type === 'attachment' && (
        <ChapterDownloadItem
          downloadItem={chapterBlock}
          canDownload={canDownload}
          episodeType={episodeType}
          isFromSummary
        />
      )}
      {chapterBlock.type === 'media' && (
        <div className="chapter-block__video-wrapper">
          <div
            className="chapter-block__video-player"
            id={chapterBlock.imageUris[0]}
          >
            <ReactPlayer
              url={`${cdnVideoUrl}/${chapterBlock.videoKey}/${cdnVideoPostfix}`}
              controls
              playing
              width="100%"
              height="100%"
              light={`${cdnUrl}/images/${findImageByType(
                chapterBlock.imageUris,
                '/720'
              )}`}
              onStart={() =>
                reportButtonClickedAnalytics({
                  buttonType: 'view video',
                  eventSource: 'chapter block',
                  pageSpecialty
                })
              }
            />
          </div>
          {(!!chapterBlock.title || !!chapterBlock.description) && (
            <ChapterBlockMediaDetails
              title={chapterBlock.title}
              description={chapterBlock.description}
            />
          )}
        </div>
      )}
      {chapterBlock.type === 'image' && (
        <div
          id={chapterBlock.imageUris[0]}
          className="chapter-block__image-wrapper"
        >
          <a
            href={`${cdnUrl}/${findImageByType(
              chapterBlock.imageUris,
              '/original'
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="chapter-block__image-player"
            onClick={event =>
              reportLinkClickedAnalytics({
                to: `${cdnUrl}/${findImageByType(
                  chapterBlock.imageUris,
                  '/original'
                )}`,
                linkText: event.currentTarget.text,
                eventSource: 'chapter block',
                pageSpecialty
              })
            }
          >
            <img
              src={`${cdnUrl}/${findImageByType(
                chapterBlock.imageUris,
                '/720'
              )}`}
              alt={chapterBlock.title || chapterBlock.description || ''}
              className="chapter-block__image"
            />
            <span className="chapter-block__view-image-button">
              Full Size Image
            </span>
          </a>
          {(!!chapterBlock.title || !!chapterBlock.description) && (
            <ChapterBlockMediaDetails
              title={chapterBlock.title}
              description={chapterBlock.description}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChapterBlock;
