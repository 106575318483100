import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { getEpisodeTypeDisplay } from '../../lib/getEpisodeTypeDisplay';
import { ReactComponent as CloseIcon } from '../../images/icons/close-filters.svg';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import './SearchFilterItem.scss';

export const SearchFilterItem = props => {
  const { selectedValues, setValue, showClearAll, clearAllLabel } = props;

  const clearFilter = component => {
    setValue(component, null);
  };

  const clearAll = () => Object.keys(selectedValues).forEach(clearFilter);

  if (!selectedValues) {
    return null;
  }

  const filters = Object.keys(selectedValues)
    .map(component => {
      const { value, label, showFilter } = selectedValues[component];
      // Don't render if the filter should be hidden or if its value is null.
      // Also hide fake values used to work around
      // https://github.com/appbaseio/reactivesearch/issues/1402
      if (!showFilter || !value || value.isFake) {
        return null;
      }

      // render human readable dates for the publish date
      let displayedValue = value;
      if (component === 'publish-date') {
        displayedValue = value
          .map(time => moment(time).format('YYYY'))
          .join(' - ');
      } else if (component === 'episode-type-select') {
        displayedValue = value
          .map(id => getEpisodeTypeDisplay(Number.parseInt(id, 10)))
          .join(', ');
      } else if (Array.isArray(value)) {
        displayedValue = value.join(', ');
      }

      if (!displayedValue || !displayedValue.length) {
        return null;
      }

      return (
        <button
          className="search-filter-item__button"
          key={`search-filter-item-${component}`}
          onClick={() => clearFilter(component)}
          type="button"
        >
          <div className="search-filter-item__content">
            <span className="search-filter-item__title">{label}: </span>
            {displayedValue}
          </div>
          <span className="search-filter-item__clear">✕</span>
        </button>
      );
    })
    .filter(filter => !!filter);

  // no filters selected, don't render
  if (filters.length === 0) {
    return null;
  }

  return (
    <>
      <h2 className="search-filter-item__header">Search Filters:</h2>
      {showClearAll && (
        <button
          className="search-filter-item__clear-all"
          onClick={() => {
            reportButtonClickedAnalytics({
              buttonType: 'clear all'
            });
            clearAll();
          }}
          type="button"
        >
          <span className="search-filter-item__clear-all-text">
            {clearAllLabel}
          </span>
          <CloseIcon className="search-filter-item__clear-all-icon" />
        </button>
      )}
      <div className="search-filter-item__list">{filters}</div>
    </>
  );
};

SearchFilterItem.propTypes = {
  selectedValues: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  showClearAll: PropTypes.bool,
  clearAllLabel: PropTypes.string
};

SearchFilterItem.defaultProps = {
  showClearAll: false,
  clearAllLabel: 'Clear All'
};

export default SearchFilterItem;
