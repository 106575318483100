import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { loadAllC3Playlists as loadAllC3PlaylistsAction } from '../../actions/c3Playlists';
import C3Hero from './C3Hero';
import Loader from '../Loader';
import { EpisodeCard } from '../EpisodeCard/EpisodeCard';
import { EpisodeFeed } from '../EpisodeFeed/EpisodeFeed';
import { reportPlaylistOpenedAnalytics } from '../../analytics/reportPlaylistOpenedAnalytics';
import { ESC3Playlist } from '../../schema/playlist/ESC3Playlist';
import { RootState } from '../../store';
import { useElementHeight } from '../../hooks/useElementHeight';

import './C3LandingPage.scss';

interface C3LandingPageProps {
  playlists: ESC3Playlist[] | null;
  isLoading: boolean;
  loadAllC3Playlists: typeof loadAllC3PlaylistsAction;
}

function C3LandingPage(props: C3LandingPageProps) {
  const { playlists, isLoading, loadAllC3Playlists } = props;
  const navBarHeight = useElementHeight('.nav-bar');
  const miniBannerHeight = useElementHeight('.mini-banner');

  useEffect(() => {
    const topOffset = navBarHeight + miniBannerHeight;
    document.body.style.setProperty('--top-offset', `${topOffset}px`);
  }, [navBarHeight, miniBannerHeight]);

  useEffect(() => {
    loadAllC3Playlists();
  }, [loadAllC3Playlists]);

  const visiblePlaylists = useMemo(
    () =>
      playlists
        ? playlists.filter(playlist => playlist.url_path !== 'featured-c3')
        : [],
    [playlists]
  );

  const featuredEpisode = useMemo(() => {
    const featuredPlaylist = playlists?.find(
      playlist => playlist.url_path === 'featured-c3'
    );

    if (!featuredPlaylist) {
      return null;
    }
    return featuredPlaylist.items[0] || null;
  }, [playlists]);

  return (
    <div className="c3-landing-page">
      <Helmet>
        <title>C3 | EM:RAP</title>
        <meta property="og:title" content="C3 Comprehensive Core Curriculum" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.emrap.org/c3" />
        <meta
          property="og:image"
          content="https://www.emrap.org/images/elements/c3-header-logo.png"
        />
      </Helmet>
      <C3Hero isLoading={isLoading} episode={featuredEpisode} />
      {isLoading ? (
        <Loader className="c3-loader__container" />
      ) : (
        <div className="c3-landing-page__episode-feed-container">
          <section className="c3-landing-page__episode-feed">
            {visiblePlaylists.map(playlist => (
              <EpisodeFeed
                episodeType="c3"
                key={playlist.url_path}
                title={playlist.title}
                count={playlist.items.length}
                to={`/c3/playlist/${playlist.url_path}`}
                onClick={() =>
                  reportPlaylistOpenedAnalytics({
                    playlist: playlist.title,
                    itemCount: playlist.item_count,
                    pageSpecialty: 'C3'
                  })
                }
                page="c3"
                link="See All"
              >
                {/* Do not add getPhpSitePath here */}
                {/* EpisodeCard passes the href to LinkModernizer */}
                {/* LinkModernizer handles path */}
                {playlist.items.map(item => (
                  <EpisodeCard
                    key={item.url_path}
                    imageUrl={
                      item.thumbnail
                        ? `${process.env.REACT_APP_CDN_BASE}${item.thumbnail['episode-card'].path}`
                        : ''
                    }
                    itemCount={item.item_count}
                    title={item.title}
                    href={`/c3/playlist/${playlist.url_path}/${item.url_path}`}
                    duration={item.duration}
                    page="c3"
                  />
                ))}
              </EpisodeFeed>
            ))}
          </section>
        </div>
      )}
    </div>
  );
}

C3LandingPage.propTypes = {
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      item_count: PropTypes.number.isRequired,
      url_path: PropTypes.string.isRequired,
      graphic: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          prefix: PropTypes.string,
          item_count: PropTypes.number,
          duration: PropTypes.number,
          url_path: PropTypes.string.isRequired,
          thumbnail: PropTypes.shape({
            'episode-card': PropTypes.shape({
              path: PropTypes.string
            })
          })
        })
      )
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  loadAllC3Playlists: PropTypes.func.isRequired
};

C3LandingPage.defaultProps = {
  playlists: null
};

function mapStateToProps(state: RootState) {
  return {
    playlists: state.c3Playlists.allPlaylists.data,
    isLoading: state.c3Playlists.allPlaylists.isLoading
  };
}

function mapDispatchtoProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadAllC3Playlists: loadAllC3PlaylistsAction
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchtoProps)(C3LandingPage);
