// deprecated: use Link or ButtonLink in new code

import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from '../Icons/Icon';

import './TextLink.scss';

interface TextLinkProps {
  href?: string;
  className?: string;
  iconName?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const TextLink = ({
  href = '/',
  className,
  children,
  iconName,
  onClick
}: TextLinkProps) => (
  <Link
    to={href}
    className={classNames(className, 'text__link')}
    onClick={onClick}
  >
    <span>{children}</span>
    {iconName && <Icon name={iconName} />}
  </Link>
);

export default TextLink;
