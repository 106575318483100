import { useEffect, useState } from 'react';

export const useDismissAfterTime = (
  shouldStart: boolean,
  timeoutMS: number
) => {
  const [isDismissed, setIsDismissed] = useState(true);
  useEffect(() => {
    if (shouldStart) {
      setIsDismissed(false);
      const timeout = setTimeout(() => {
        setIsDismissed(true);
      }, timeoutMS);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [shouldStart]);
  return isDismissed;
};
