import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { EpisodeList } from './EpisodeList';
import EpisodeHeaderBar from '../EpisodeHeaderBar/EpisodeHeaderBar';
import CrunchTimeFMHeader from '../PageHeader/CrunchTimeFMHeader';
import EpisodeMobileDropdown from './EpisodeMobileDropdown';
import CrunchTimeFmSidebar from '../Sidebar/CrunchTimeFmSidebar';
import theme from '../../theme';
import { setSearchParams } from '../../lib/setSearchParams';
import getEpisodeQuery from '../../lib/getEpisodeQuery';
import { CRUNCH_TIME_FM } from '../../lib/episodeTypes';

import config from '../../config';

import './EpisodeGuide.scss';

const EPISODE_TYPES = [CRUNCH_TIME_FM];

export default function CrunchTimeFMEpisodes({ media }) {
  const sort = { dataField: 'episode.displayorder', sortBy: 'asc' };
  const query = getEpisodeQuery(EPISODE_TYPES, sort, false, false);

  return (
    <section className="episode-guide">
      <Helmet>
        <title>CrunchTime FM Episodes | EM:RAP</title>
        <meta property="og:title" content="EM:RAP CrunchTime FM Episodes" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.emrap.org/crunchtimefm" />
        <meta
          property="og:image"
          // TODO: Missing crunchtime fm header
          content="https://www.emrap.org/images/elements/emrap-header-logo.png"
        />
      </Helmet>
      <div className="app-container">
        <div className="results-container">
          <ReactiveBase
            app={config.episodeGuideApp}
            url={config.url}
            theme={theme}
            setSearchParams={setSearchParams}
          >
            <EpisodeHeaderBar
              episodeType={CRUNCH_TIME_FM}
              displayLogo={false}
            />
            <CrunchTimeFMHeader />
            {media.xs && <EpisodeMobileDropdown />}

            <div className="episode-list-container">
              <div className="episode-list-content">
                <EpisodeList
                  episodeTypes={EPISODE_TYPES}
                  media={media}
                  query={query}
                />
                <CrunchTimeFmSidebar />
              </div>
            </div>
          </ReactiveBase>
        </div>
      </div>
    </section>
  );
}

CrunchTimeFMEpisodes.propTypes = {
  media: PropTypes.shape({
    xs: PropTypes.bool,
    sm: PropTypes.bool
  }).isRequired
};
