import React from 'react';
import classNames from 'classnames';

import './ConferenceMenuIcon.scss';

import { ReactComponent as IconWorkshops } from '../../images/conferences/menu/icon-workshops.svg';
import { ReactComponent as IconSpeakers } from '../../images/conferences/menu/icon-speakers.svg';
import { ReactComponent as IconLocation } from '../../images/conferences/menu/icon-location.svg';
import { ReactComponent as IconSchedule } from '../../images/conferences/menu/icon-schedule.svg';

interface Props {
  name: string;
  className?: string;
}

function getIconComponent(name: string) {
  switch (name) {
    case 'workshops':
      return IconWorkshops;
    case 'faculty':
      return IconSpeakers;
    case 'location':
      return IconLocation;
    case 'schedule':
      return IconSchedule;
    case 'faq':
    default:
      return IconSchedule;
  }
}

export const ConferenceMenuIcon = ({ className, name }: Props) => {
  const IconComponent = getIconComponent(name);

  return (
    <IconComponent
      className={classNames(
        className,
        `conference-menu-icon conference-menu-icon--${name}`
      )}
    />
  );
};

ConferenceMenuIcon.defaultProps = {
  className: undefined
};

export default ConferenceMenuIcon;
