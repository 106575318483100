import React, { ReactNode } from 'react';
import { LinkModernizer } from '../LinkModernizer';
import Loader from '../Loader';
import { HDThumbnailDuration } from '../HDThumbnails/HDThumbnailDuration';
import { reportFeaturedContentClickedAnalytics } from '../../analytics/reportFeaturedContentClickedAnalytics';
import { getHDPath } from '../../lib/getHDPath';
import { ESVideoPlaylistChapterItem } from '../../schema/playlist/ESVideoPlaylistItem';

import './FeaturedVideo.scss';

interface FeaturedVideoWrapperProps {
  chapter: ESVideoPlaylistChapterItem | null;
  url?: string;
  children: ReactNode;
}

interface FeaturedVideoProps {
  chapter: ESVideoPlaylistChapterItem | null;
  isUrgentCare: boolean;
}

function FeaturedVideoWrapper({
  chapter,
  url,
  children
}: FeaturedVideoWrapperProps) {
  const className = 'featured-video';

  return chapter && url ? (
    <LinkModernizer
      className={className}
      to={url}
      href={url}
      onClick={() =>
        reportFeaturedContentClickedAnalytics({ episodeTitle: chapter.title })
      }
    >
      {children}
    </LinkModernizer>
  ) : (
    <div className={className}>{children}</div>
  );
}

export function FeaturedVideo({ chapter, isUrgentCare }: FeaturedVideoProps) {
  let url: string | undefined;
  if (chapter) {
    url = isUrgentCare
      ? getHDPath(isUrgentCare, chapter?.url_path)
      : `/episode/${chapter?.url_path.slice('chapter/'.length)}`;
  }
  const thumbnail = chapter?.thumbnail['720'].path
    ? `${process.env.REACT_APP_CDN_BASE}${chapter.thumbnail['720'].path}`
    : undefined;

  return (
    <FeaturedVideoWrapper chapter={chapter} url={url}>
      <div className="featured-video__details">
        <h3 className="featured-video__headline">Featured Video</h3>
        <h4 className="featured-video__title">{chapter?.title}</h4>
      </div>
      {thumbnail ? (
        <div className="featured-video__thumbnail-container">
          <img className="featured-video__thumbnail" src={thumbnail} alt="" />
          <HDThumbnailDuration
            className="featured-video__thumbnail-duration"
            duration={chapter?.duration}
          />
        </div>
      ) : (
        <Loader className="featured-video__thumbnail-loader" />
      )}
    </FeaturedVideoWrapper>
  );
}
