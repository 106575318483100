import React, { useState } from 'react';
import classnames from 'classnames';
import { VideoPlayer } from './VideoPlayer';
import { PrimaryLinkButton } from './PrimaryLinkButton';
import { Wrapper } from '../Wrapper';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import './DashboardUCFundamentalsSection.scss';

interface Props {
  videoUrl: string;
  posterFrame?: string;
  className?: string;
}

export function DashboardUCFundamentalsSection({
  videoUrl,
  posterFrame,
  className
}: Props) {
  const [playing, setPlaying] = useState<boolean>(false);

  return (
    <section
      className={classnames(
        'uc-dashboard-uc-fundamentals-section__container',
        className
      )}
    >
      <Wrapper className="uc-dashboard-uc-fundamentals-section__wrapper">
        <div className="uc-dashboard-uc-fundamentals-section">
          <div className="uc-dashboard-uc-fundamentals-section__content">
            <h2 className="uc-dashboard-uc-fundamentals-section__title">
              UC Fundamentals
            </h2>
            <p className="uc-dashboard-uc-fundamentals-section__text">
              Your go-to video series for mastering urgent care scenarios with
              confidence.
            </p>
            <PrimaryLinkButton
              icon="arrow-right"
              text="Watch now"
              to="/hd/playlist/UCFundamentals"
              className="uc-dashboard-uc-fundamentals-section__button"
              iconClassName="uc-dashboard-uc-fundamentals-section__button-icon"
              onClick={() =>
                reportLinkClickedAnalytics({
                  to: 'hd/playlist/UCFundamentals',
                  linkText: 'Watch now',
                  pageSpecialty: 'UC'
                })
              }
            />
          </div>
          <div className="uc-dashboard-uc-fundamentals-section__video-player">
            <VideoPlayer
              url={videoUrl}
              posterFrame={posterFrame}
              playing={playing}
              onPlayClick={() => setPlaying(true)}
              onStart={() =>
                reportButtonClickedAnalytics({
                  buttonType: 'view video',
                  eventSource: 'uc fundamentals preview'
                })
              }
            />
          </div>
        </div>
      </Wrapper>
    </section>
  );
}
