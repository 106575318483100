import { z } from 'zod';
import { timestampSchema } from '../timestamp';
import { webChapterBlocksSchema } from './webChapterBlocks';
import { webCorePendiumChapterSchema } from './webCorePendiumChapter';
import { webMediaSchema } from './webMedia';
import { webTagSchema } from './webTag';
import {
  RelatedContentItemSchema,
  RelatedContentPlaylistItemSchema
} from '../playlist/RelatedContentItem';

export enum ChapterCommentStatus {
  OPEN,
  MODERATED,
  LOCKED,
  CLOSED
}

export const chapterCommentStatusSchema = z.nativeEnum(ChapterCommentStatus);

export const webChapterSchema = z.object({
  id: z.number(),
  shortname: z.string(),
  title: z.string(),
  description: z.string(),
  thumbnail: z.string().nullable(),
  image: z.string().nullable(),
  isFree: z.boolean(),
  media: z.array(webMediaSchema),
  extras: z.array(z.string()),
  tags: z.array(webTagSchema),
  blocks: webChapterBlocksSchema,
  corependiumChapters: z.array(webCorePendiumChapterSchema),
  commentStatus: chapterCommentStatusSchema,
  uploaded_at: timestampSchema,
  relatedContent: z.array(RelatedContentItemSchema).optional(),
  relatedPlaylist: z.array(RelatedContentPlaylistItemSchema).optional()
});

export type WebChapter = z.infer<typeof webChapterSchema>;
