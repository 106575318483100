import React from 'react';
import cn from 'classnames';
import { Icon, IconName } from './Icon';

import './IconButton.scss';

interface IconButtonProps {
  icon: IconName;
  onClick: () => void;
  report?: () => void;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
}

export function IconButton({
  icon,
  onClick,
  report,
  className,
  iconClassName,
  disabled
}: IconButtonProps) {
  return (
    <button
      type="button"
      className={cn('uc-icon-button', className)}
      onClick={() => {
        onClick();
        if (report) report();
      }}
      disabled={disabled}
    >
      <Icon name={icon} className={cn('uc-icon-button__icon', iconClassName)} />
    </button>
  );
}
