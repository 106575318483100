export function pluralizeText(
  number: string,
  singularText: string,
  pluralText = `${singularText}s`
) {
  const numberAsFloat = Number.parseFloat(number);
  return numberAsFloat === 1
    ? `${numberAsFloat} ${singularText}`
    : `${numberAsFloat} ${pluralText}`;
}
