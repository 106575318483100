export default function distanceToTop(element: HTMLElement) {
  let px = element.offsetTop;
  let parent = element.offsetParent;
  while (parent && parent instanceof HTMLElement) {
    px += parent.offsetTop;
    parent = parent.offsetParent;
  }

  return px >= 0 ? px : 0;
}
