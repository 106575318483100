import React from 'react';
import classnames from 'classnames';
import { PrimaryLinkButton } from './PrimaryLinkButton';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './UCNewsletterCallout.scss';

interface Props {
  className?: string;
}

const MAILCHIMP_URL =
  'https://mailchi.mp/a6834ef803e6/urgent-care-paper-clips-newsletter';

export function UCNewsletterCallout({ className }: Props) {
  return (
    <section className={classnames('uc-newsletter-callout', className)}>
      <p className="uc-newsletter-callout__text">
        UC Max Newsletter: Sign up for our free newsletter that brings the best
        urgent care education to your inbox.
      </p>
      <PrimaryLinkButton
        href={MAILCHIMP_URL}
        text="Sign Up for the Newsletter"
        icon="arrow-right"
        onClick={() =>
          reportLinkClickedAnalytics({
            to: MAILCHIMP_URL,
            linkText: 'Sign Up for the Newsletter',
            pageSpecialty: 'UC',
            isExternal: true,
            eventSource: 'UC Newsletter Callout'
          })
        }
      />
    </section>
  );
}
