import React, { useState } from 'react';
import classNames from 'classnames';
import EpisodeDuration from './EpisodeDuration';
import { LinkModernizer } from '../LinkModernizer';

import './EpisodeCard.scss';

// heading can be different levels depending on location
// default to 3, add support for more as needed
function Heading({
  headingLevel,
  children,
  className
}: {
  headingLevel?: number;
  children: React.ReactNode;
  className: string;
}) {
  if (headingLevel === 2) {
    return <h2 className={className}>{children}</h2>;
  }
  return <h3 className={className}>{children}</h3>;
}

interface EpisodeCardProps {
  title: string;
  imageUrl: string;
  href: string;
  itemCount: number;
  duration: number;
  headingLevel?: number;
  page: 'c3' | 'uc-audio';
}

export function EpisodeCard({
  title,
  itemCount,
  href,
  imageUrl,
  duration,
  headingLevel,
  page
}: EpisodeCardProps) {
  const [isDragging, setIsDragging] = useState(false);
  const hasShortTitle = title.length < 16 && page !== 'uc-audio';

  return (
    <LinkModernizer
      href={href}
      to={href}
      className={classNames('episode-card')}
      onMouseDown={() => {
        setIsDragging(false);
      }}
      onDragStart={e => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onClick={e => {
        if (isDragging) {
          e.preventDefault();
        }
      }}
    >
      <div
        className={classNames(
          ' episode-card__container',
          `episode-card__container--${page}`
        )}
      >
        <div className="episode-card__bg-container">
          <div className="episode-card__posterframe">
            <img className="episode-thumb" src={imageUrl} alt={title} />
            <EpisodeDuration
              className="episode-card__duration"
              duration={duration}
            />
          </div>
          <div className="episode-card__details">
            <Heading
              className={classNames(
                'episode-card__title',
                `episode-card__title--${hasShortTitle ? 'short' : 'long'}`
              )}
              headingLevel={headingLevel}
            >
              <span>{title}</span>
            </Heading>
            <p className="episode-card__chapter-count">
              {itemCount} {itemCount === 1 ? 'Chapter' : 'Chapters'}
            </p>
          </div>
        </div>
      </div>
    </LinkModernizer>
  );
}
