import { track, flush } from '@amplitude/analytics-browser';

type MarketingSpotType =
  | 'hero-banner'
  | 'mini-banner'
  | 'section'
  | 'bucket'
  | 'modal';

export function reportMarketingSpotClickedAnalytics({
  marketingSpotType,
  to,
  buttonText,
  eventSource,
  modalId
}: {
  marketingSpotType: MarketingSpotType;
  to?: string;
  buttonText: string;
  eventSource?: string;
  modalId?: string;
}) {
  track('Messaging_Spot_Clicked', {
    to: to?.replace(/^\/(.+)/, '$1').replace('https://www.emrap.org/', ''),
    button_text: buttonText.toLowerCase(),
    messaging_spot_type: marketingSpotType,
    event_source: eventSource,
    path: window.location.pathname,
    modal_id: modalId
  });
  flush();
}
