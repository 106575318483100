import React from 'react';
import { Heading } from '../Heading';
import { LinkButton } from '../LinkButton';

import './MarketingSpotSection.scss';

export type MarketingSpotSectionProps = {
  campaignColor: string;
  headline?: string;
  message: string;
  ctaText: string;
  href: string;
  imgSrc: string;
  report: () => void;
  isBucket?: boolean;
};

export function MarketingSpotSection({
  campaignColor,
  headline,
  message,
  ctaText,
  href,
  imgSrc,
  report,
  isBucket
}: MarketingSpotSectionProps) {
  return (
    <section
      data-testid="marketing-spot-section"
      style={{
        '--firestore--background--color': campaignColor
      }}
      className="marketing-spot-section global-is-stack"
      data-is-bucket={isBucket}
    >
      {headline && (
        <Heading
          Tag="h2"
          className="marketing-spot-section__headline"
          variant={isBucket ? 'lg' : 'xl'}
        >
          {headline}
        </Heading>
      )}
      <p className="marketing-spot-section__message">{message}</p>
      <LinkButton
        href={href}
        className="marketing-spot-section__cta"
        variant="tertiary"
        size={isBucket ? 'small' : 'default'}
        onClick={() => {
          report();
        }}
        label={ctaText}
      />

      <img src={imgSrc} alt="" className="marketing-spot-section__image" />
    </section>
  );
}
