import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import ReactJWPlayer from 'react-jw-player';
import { WebChapter } from '../../schema/webEpisode/webChapter';
import { WebEpisode } from '../../schema/webEpisode/webEpisode';
import { useVerticalVideoPreference } from '../../hooks/useVerticalVideoPreference';
import Icon from '../Icons/Icon';
import SubscribeContent from '../ChapterPage/SubscribeContent';
import { useFirebasePlayback } from '../../hooks/useFirebasePlayback';
import { reportPlaybackBeginAnalytics } from '../../analytics/reportPlaybackBeginAnalytics';
import { reportEndPlayerAnalytics } from '../../analytics/reportEndPlayerAnalytics';
import { reportPlayerPausedAnalytics } from '../../analytics/reportPlayerPausedAnalytics';
import { reportPlaybackResumedAnalytics } from '../../analytics/reportPlaybackResumedAnalytics';
import { reportPlaybackSpeedChangedAnalytics } from '../../analytics/reportPlaybackSpeedChangedAnalytics';
import { MediaReport } from '../../lib/types';
import { getPageSpecialty } from '../../lib/getPageSpecialty';
import {
  debouncedHandleSeekStartAnalytics,
  debouncedHandleSeekEndAnalytics
} from '../../lib/handleSeekingAnalytics';

import './HDVideoContent.scss';

export interface HDVideoContentProps {
  chapter: WebChapter;
  episode: WebEpisode;
  isDesktop: boolean;
  isEnabled: boolean;
  isSignedIn: boolean;
  accountId?: number | null;
}

function HDVideoContent({
  chapter,
  episode,
  isDesktop,
  isEnabled,
  isSignedIn,
  accountId
}: HDVideoContentProps) {
  const [previousPlaybackSpeed, setPreviousPlaybackSpeed] = useState(1);
  const media = chapter.media[0];
  const pageSpecialty = getPageSpecialty(episode.type);
  const verticalUri = media.verticalVideo?.uri;
  const { uri: horizontalUri } = media;
  const { verticalVideoPreference, setVerticalVideoPreference } =
    useVerticalVideoPreference(isDesktop, verticalUri);
  const videoTimeRef = useRef<{ [chapterShortname: string]: number } | null>(
    null
  );
  const [playerSeek, setPlayerSeek] = useState<
    ((seconds: number) => void) | undefined
  >(undefined);
  const currentPlayerRef = useRef<jwplayer.JWPlayer | null>(null);
  const uriToUse =
    !horizontalUri || (verticalUri && verticalVideoPreference)
      ? verticalUri
      : horizontalUri;
  const hasRotatebutton =
    verticalUri && horizontalUri && verticalUri !== horizontalUri;
  const { synchronizeFirebasePlayback, secondsPlayed } = useFirebasePlayback(
    media,
    accountId,
    playerSeek
  );
  const getCurrentPoint = () => {
    const position = currentPlayerRef.current?.getPosition();
    return position ? Math.round(position) : 0;
  };
  const mediaReport: MediaReport = {
    id: media.id,
    chapterTitle: chapter.title,
    episodeTitle: episode.title,
    currentPoint: Math.round(secondsPlayed),
    duration: Math.round(media.duration),
    mediaType: 'video'
  };

  return (
    <div className="hd-video-content">
      {isEnabled ? (
        <>
          {uriToUse && (
            <ReactJWPlayer
              key={uriToUse} // prevents Picture-in-Picture freezing on the screen
              file={uriToUse}
              playerId="jw-player"
              playerScript="https://www.emrap.org/packages/jwplayer/jwplayer.js?202208251112"
              image={
                uriToUse === verticalUri
                  ? media.verticalVideo?.image
                  : media.image || chapter.image || undefined
              }
              licenseKey="B2OQP7VQbt3av5lyhOlryFRewS8Y9G1PLRU5YCDVidw7evlT"
              customProps={{
                skin: { name: 'seven' },
                playbackRateControls: [0.5, 1, 1.25, 1.5, 2]
              }}
              className={classnames('hd-video-content__video-container', {
                'hd-video-content__video-container-vertical-video':
                  uriToUse === verticalUri
              })}
              onReady={() => {
                const player = window.jwplayer('jw-player');
                currentPlayerRef.current = player;
                if (player) {
                  if (
                    videoTimeRef.current &&
                    videoTimeRef.current[chapter.shortname]
                  ) {
                    setPlayerSeek(undefined);
                    player.seek(videoTimeRef.current[chapter.shortname]);
                  } else {
                    // playerSeek is only used to make the player go to the playback time saved in firebase. If we have a rotated video time stored in videoTimeRef, we use that instead.
                    setPlayerSeek(() => (seconds: number) => {
                      player.seek(seconds);
                      player.pause(); // Prevents the player from starting automatically
                    });
                  }
                }
              }}
              onOneHundredPercent={() => {
                synchronizeFirebasePlayback(0, true, true, false);
                reportEndPlayerAnalytics({
                  ...mediaReport,
                  currentPoint: getCurrentPoint(),
                  pageSpecialty
                });
              }}
              onPlay={() => {
                reportPlaybackBeginAnalytics({
                  ...mediaReport,
                  currentPoint: getCurrentPoint(),
                  pageSpecialty
                });
              }}
              onResume={() => {
                reportPlaybackResumedAnalytics({
                  ...mediaReport,
                  currentPoint: getCurrentPoint(),
                  pageSpecialty
                });
              }}
              onPause={({ pauseReason }) => {
                // ignore external onReady pause
                if (pauseReason === 'interaction') {
                  reportPlayerPausedAnalytics({
                    ...mediaReport,
                    currentPoint: getCurrentPoint(),
                    pageSpecialty
                  });
                }
                // exact position saved for Firebase
                const position = currentPlayerRef.current?.getPosition();
                if (position) {
                  synchronizeFirebasePlayback(position, false, true, false);
                }
              }}
              onSeek={({ position, offset }) => {
                // ignore onReady setting position to 0
                if (position !== offset) {
                  debouncedHandleSeekStartAnalytics({
                    currentPoint: position,
                    episodeType: episode.type,
                    mediaReport
                  });

                  debouncedHandleSeekEndAnalytics({
                    currentPoint: offset,
                    episodeType: episode.type,
                    mediaReport
                  });
                }
              }}
              onPlaybackRateChanged={({ playbackRate }) => {
                if (previousPlaybackSpeed !== playbackRate) {
                  reportPlaybackSpeedChangedAnalytics({
                    ...mediaReport,
                    currentPoint: getCurrentPoint(),
                    previousSpeed: previousPlaybackSpeed,
                    selectedSpeed: playbackRate,
                    pageSpecialty
                  });

                  // prepare for next speed change
                  setPreviousPlaybackSpeed(playbackRate);
                }
              }}
              onTime={event => {
                synchronizeFirebasePlayback(
                  event.currentTime,
                  false,
                  false,
                  false
                );
              }}
            />
          )}
          {hasRotatebutton && (
            <div className="hd-video-content__button-container">
              <button
                type="button"
                className="hd-video-content__button"
                onClick={() => {
                  setVerticalVideoPreference(!verticalVideoPreference);
                  videoTimeRef.current = {
                    [chapter.shortname]:
                      currentPlayerRef.current?.getPosition() || 0
                  };
                }}
              >
                <Icon
                  name="refresh"
                  className="hd-video-content__refresh-icon"
                />
                Rotate Video
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="hd-video-content__disabled-content">
          {chapter.image !== null && Boolean(chapter.image) && (
            <img
              className="hd-video-content__image"
              src={chapter.image}
              alt=""
            />
          )}
          <div className="hd-video-content__subscription-message">
            <SubscribeContent
              isSignedIn={isSignedIn}
              isHDVideo
              className="hd-video-content__subscription-message-links"
              path={window.location.pathname}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default HDVideoContent;
