import React from 'react';
import classnames from 'classnames';
import './ConferenceSectionTitle.scss';

interface Props {
  title: string;
  subTitle: string;
  className?: string;
}

const ConferenceSectionTitle = ({ title, subTitle, className }: Props) => (
  <div className={classnames('conference-section-title__container', className)}>
    <h2 className="conference-section-title__title">{title}</h2>
    <h3 className="conference-section-title__sub-title">{subTitle}</h3>
  </div>
);

export default ConferenceSectionTitle;

ConferenceSectionTitle.defaultProps = {
  className: undefined
};
