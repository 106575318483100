import { useEffect, useState } from 'react';
import { fetchRelatedContent } from '../lib/fetchRelatedContent';
import { removeListItemByShortname } from '../lib/removeListItemByShortname';
import {
  RelatedContentItem,
  RelatedContentPlaylistItem
} from '../schema/playlist/RelatedContentItem';

export function useRelatedContent(
  chapterId: number,
  playlistShortname: string
) {
  const [relatedContent, setRelatedContent] = useState<RelatedContentItem[]>(
    []
  );
  const [relatedPlaylist, setRelatedPlaylist] = useState<
    RelatedContentPlaylistItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadContent = async () => {
      setIsLoading(true);
      const { relatedContent: apiContent, relatedPlaylist: apiPlaylist } =
        await fetchRelatedContent(chapterId);

      setRelatedContent(
        removeListItemByShortname(apiContent, playlistShortname)
      );
      setRelatedPlaylist(
        removeListItemByShortname(apiPlaylist, playlistShortname)
      );
      setIsLoading(false);
    };

    loadContent();
  }, [chapterId, playlistShortname]);

  return { relatedContent, relatedPlaylist, isLoading };
}
