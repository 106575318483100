import { z } from 'zod';
import { ESVideoPlaylistItemSchema } from './ESVideoPlaylistItem';

// ES transformer https://github.com/silverorange/emrap-search-tools/blob/master/src/transforms/videoPlaylistTransform.js
// SQL query https://github.com/silverorange/emrap-search-tools/blob/master/src/db/getVideoPlaylists.js

export const ESVideoPlaylistSchema = z.object({
  title: z.string(),

  // It looks like video_count could be undefined if items is empty:
  // https://github.com/silverorange/emrap-search-tools/blob/master/src/utils/getVideoCount.js#L9-L50, but I
  // don't think we deal with playlists with no items
  video_count: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  url_path: z.string(),
  graphic: z.string().optional(),
  is_top_level: z.boolean(),
  display_order: z.number().optional(),
  display_order_uc: z.number().optional(),
  has_cme: z.boolean().optional(), // Some playlists in elastic search seem to be missing this, but no Playlist have has_cme = null in postgres
  items: z.array(ESVideoPlaylistItemSchema)
});

export const ESVideoPlaylistApiResponseSchema = z.object({
  hits: z.object({
    total: z.object({
      value: z.number().positive()
    }),
    hits: z.array(
      z.object({
        _source: ESVideoPlaylistSchema
      })
    )
  })
});

export type ESVideoPlaylist = z.infer<typeof ESVideoPlaylistSchema>;
