import classnames from 'classnames';
import React from 'react';
import { WebComment } from '../../schema/webComment/webComment';
import { ChapterTab } from '../../lib/types';
import { getPageSpecialty } from '../../lib/getPageSpecialty';
import { reportPlayerTabOpenedAnalytics } from '../../analytics/reportPlayerTabOpenedAnalytics';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import { ReactComponent as SpeechBubbleIcon } from '../../images/icons/icon-speech-bubble.svg';
import { ReactComponent as DocumentIcon } from '../../images/icons/icon-document.svg';
import { ReactComponent as SmartCardsIcon } from '../../images/icons/icon-smart-cards.svg';
import { ReactComponent as CrunchyQIcon } from '../../images/icons/icon-crunchy-q.svg';
import { ReactComponent as CmeIcon } from '../../images/icons/cme/icon-cme.svg';

import './ChapterTabs.scss';

interface ChapterTabsProps {
  episodeType: EpisodeTypeShortname;
  selectedTab: ChapterTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<ChapterTab>>;
  hasCrunchyQs?: boolean;
  chapterComments: WebComment[];
  smartCardsCount: number;
  cmeCreditsHours: number;
  isDesktop?: boolean;
}

interface Tab {
  label: string;
  icon: JSX.Element;
  key: ChapterTab;
}

const ChapterTabs = ({
  episodeType,
  selectedTab,
  setSelectedTab,
  hasCrunchyQs,
  smartCardsCount,
  chapterComments,
  cmeCreditsHours,
  isDesktop
}: ChapterTabsProps) => {
  const tabs: Tab[] = [];

  const smartCardsTab: Tab | null = smartCardsCount
    ? {
        label: smartCardsCount > 1 ? 'Smart Cards' : 'Smart Card',
        icon: <SmartCardsIcon aria-hidden="true" />,
        key: 'Smart Cards'
      }
    : null;

  if (episodeType === 'hd' && smartCardsTab) {
    tabs.push(smartCardsTab);
  }

  tabs.push({
    label: 'Summary',
    icon: <DocumentIcon aria-hidden="true" />,
    key: 'Summary'
  });

  if (episodeType !== 'hd' && smartCardsTab) {
    tabs.push(smartCardsTab);
  }

  tabs.push({
    label: chapterComments.length
      ? `Conversation (${chapterComments.length})`
      : 'Conversation',
    icon: <SpeechBubbleIcon aria-hidden="true" />,
    key: 'Conversation'
  });

  if (hasCrunchyQs) {
    tabs.push({
      label: 'Crunchy Qs',
      icon: <CrunchyQIcon aria-hidden="true" />,
      key: 'Crunchy Qs'
    });
  }

  if (cmeCreditsHours > 0) {
    tabs.push({
      label: `CME Credits (${cmeCreditsHours})`,
      icon: <CmeIcon aria-hidden="true" />,
      key: 'CME Credits'
    });
  }

  return (
    <div className={`chapter-tabs chapter-tabs--${episodeType}`}>
      <div className="chapter-tabs__container">
        {tabs.map(tab => (
          <button
            className={classnames('chapter-tabs__tab', {
              'chapter-tabs__tab--selected': tab.key === selectedTab
            })}
            type="button"
            onClick={() => {
              reportPlayerTabOpenedAnalytics({
                tab: tab.key,
                pageSpecialty: getPageSpecialty(episodeType)
              });
              setSelectedTab(tab.key);
            }}
            key={tab.key}
          >
            {(!isDesktop || episodeType !== 'hd') && tab.icon}
            <span className="chapter-tabs__label">{tab.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ChapterTabs;
