import { keyBy } from 'lodash';
import { WebChapter } from '../schema/webEpisode/webChapter';
import { WebChapterListItem } from '../schema/webEpisode/webChapterListItem';

export interface WebChapterWithIndex extends WebChapter {
  index: number;
}

type FormattedChapterListItem =
  | {
      type: 'chapter-section';
      chapter: WebChapterWithIndex;
    }
  | {
      type: 'abstract-section';
      abstractLists: {
        chapters: WebChapterWithIndex[];
        title?: string | null;
        subtitle?: string | null;
      }[];
    };

export default function formatPlaylistChapterPage(
  episodeChapterList: WebChapterListItem[],
  chapters: WebChapter[]
): FormattedChapterListItem[] {
  const formattedList: FormattedChapterListItem[] = [];

  const chapterByChapterId = keyBy(chapters, 'id');
  let regularChapterIndex = 0;
  let abstractChapterIndex = 0;
  episodeChapterList.forEach(chapterList => {
    if (chapterList.type === 'chapter-section') {
      chapterList.ids.forEach(id => {
        regularChapterIndex += 1;
        formattedList.push({
          type: 'chapter-section',
          chapter: {
            ...chapterByChapterId[id],
            index: regularChapterIndex
          }
        });
      });
    } else {
      let abstractList: FormattedChapterListItem = {
        abstractLists: [],
        type: 'abstract-section'
      };
      const lastList = formattedList.length
        ? formattedList[formattedList.length - 1]
        : null;
      if (lastList && lastList.type === 'abstract-section') {
        abstractList = lastList;
      } else {
        // start new abstract list
        formattedList.push(abstractList);
        abstractChapterIndex = 0;
      }
      abstractList.abstractLists.push({
        chapters: chapterList.ids.map(id => {
          abstractChapterIndex += 1;
          return {
            ...chapterByChapterId[id],
            index: abstractChapterIndex
          };
        }),
        title: chapterList.title,
        subtitle: chapterList.subtitle
      });
    }
  });
  return formattedList;
}
