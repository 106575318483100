import moment from 'moment-timezone';
import {
  EMRAP,
  C3,
  EMA,
  HD,
  CRUNCH_TIME_EM,
  LLSA,
  ROP,
  CRUNCH_TIME_FM,
  UC_MAX
} from './lib/episodeTypes';

export default {
  url: `${process.env.REACT_APP_ES_URI}`,
  episodeGuideApp: 'emrap.guide.episode',
  mainSearchApp: 'emrap.search.main',
  citationSearchApp: 'emrap.search.citation',
  videoPlaylistApp: 'emrap.hd.video-playlist',
  c3PlaylistApp: 'emrap.c3.playlist',
  mediaQueries: {
    sm: '(min-width: 48rem)',
    lg: '(min-width: 75rem)',
    lgMax: '(max-width: 67.5rem)'
  },
  pages: [
    {
      key: 'search',
      path: '/search',
      types: [],
      title: 'Search',
      intro: '',
      class: '',
      linkText: 'Search'
    },
    {
      key: 'citation-search',
      path: '/search/citations',
      types: [],
      title: 'Citation Search',
      intro: '',
      class: '',
      linkText: 'Citation Search'
    }
  ],
  episodes: [
    {
      key: 'episode',
      path: '/episode',
      types: [EMRAP, EMA, C3, HD, ROP, LLSA],
      class: '',
      linkText: 'All'
    },
    {
      key: 'emrap',
      path: '/emrap',
      types: [EMRAP],
      class: 'emrap',
      linkText: 'EM:RAP'
    },
    {
      key: 'ema',
      path: '/ema',
      types: [EMA],
      class: 'ema',
      linkText: 'EMA'
    },
    {
      key: 'c3',
      path: '/c3',
      types: [C3],
      class: 'c3',
      linkText: 'C3'
    },
    {
      key: 'crunchtime',
      path: '/crunchtime',
      types: [CRUNCH_TIME_EM],
      class: 'crunch-time',
      linkText: 'Crunch Time EM'
    },
    {
      key: 'llsa',
      path: '/llsa',
      types: [LLSA],
      class: 'llsa',
      linkText: 'LLSA'
    },
    {
      key: 'rop',
      path: '/rop',
      types: [ROP],
      class: 'rop',
      linkText: 'Right on Prime'
    },
    {
      key: 'crunchtimefm',
      path: '/crunchtimefm',
      types: [CRUNCH_TIME_FM],
      class: 'crunch-time-fm',
      linkText: 'Crunch Time FM'
    }
  ],
  episodeSortOptions: [
    {
      key: 'sort-by-newest',
      label: 'Newest',
      dataField: 'episode.publish_date',
      sortBy: 'desc'
    },
    {
      key: 'sort-by-oldest',
      label: 'Oldest',
      dataField: 'episode.publish_date',
      sortBy: 'asc'
    }
  ],
  searchSortOptions: [
    {
      key: 'sort-by-relevance',
      label: 'Most Relevant',
      dataField: '_score',
      sortBy: 'desc'
    },
    {
      key: 'sort-by-newest',
      label: 'Newest',
      dataField: 'episode.publish_date',
      sortBy: 'desc'
    },
    {
      key: 'sort-by-oldest',
      label: 'Oldest',
      dataField: 'episode.publish_date',
      sortBy: 'asc'
    }
  ],
  audienceOptions: [
    {
      key: 'all-clinicians',
      label: 'All Clinicians',
      dataField: 'episode.episode_type',
      value: [EMRAP, EMA, C3, ROP, LLSA, UC_MAX]
    },
    {
      key: 'em-clinicians',
      label: 'EM Clinicians',
      dataField: 'episode.episode_type',
      value: [EMRAP, EMA, HD, C3, LLSA]
    },
    {
      key: 'fm-clinicians',
      label: 'PC Clinicians',
      dataField: 'episode.episode_type',
      value: [ROP, HD]
    }
  ],
  dateSliderStep: 3.154e10,
  dateSliderStart: moment(
    process.env.REACT_APP_EMRAP_EPISODE_START_DATE,
    'YYYY-MM-DD'
  ).valueOf(),
  dateSliderEnd: moment().seconds(0).milliseconds(0).valueOf(),
  defaultGravatar: 'https://www.emrap.org/images/elements/gravatar80.png',
  chapterArtUrl:
    'https://d140vvwqovffrf.cloudfront.net/images/chapters/@2x-artwork',
  chapterMediaUrl:
    'https://d140vvwqovffrf.cloudfront.net/media/chapter/original',
  episodeArtUrl:
    'https://d140vvwqovffrf.cloudfront.net/images/episodes/@2x-artwork',
  videoArtUrl: 'https://d140vvwqovffrf.cloudfront.net/images/videos/480',
  cdnVideoUrl: 'https://d140vvwqovffrf.cloudfront.net/media',
  cdnVideoPostfix: 'hls/index.m3u8',
  cdnUrl: 'https://d140vvwqovffrf.cloudfront.net'
};
