import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { ApiError } from './ApiError';

export function reportError(error: unknown, context?: CaptureContext) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error, context);
  }

  if (error instanceof ApiError && error.status === 401) {
    return;
  }

  Sentry.captureException(error, context);
}
