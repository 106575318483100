import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from '../../lib/hooks';
import Icon from '../Icons/Icon';
import { HD } from '../../lib/episodeTypes';
import {
  setCurrentSearch as setCurrentSearchAction,
  setCurrentEpisodeType as setCurrentEpisodeTypeAction
} from '../../actions/filters';

import './HDSearchBar.scss';

interface Props {
  isUrgentCare?: boolean;
}

export function HDSearchBar({ isUrgentCare = false }: Props) {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { reskinVideoPage } = useFlags();
  const labelText = reskinVideoPage ? `Search Videos` : `Search HD Videos`;

  const searchHDVideos = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setCurrentSearchAction(searchText));
    dispatch(setCurrentEpisodeTypeAction([HD]));
    history.push('/search');
  };

  return (
    <form
      className="hd-search-bar"
      onSubmit={searchHDVideos}
      data-is-urgent-care={isUrgentCare}
      data-is-reskin={reskinVideoPage}
      data-theme={isUrgentCare ? 'uc-max' : ''}
    >
      <label htmlFor="hd-search-input" className="visually-hidden">
        {labelText}
      </label>
      <input
        id="hd-search-input"
        className="hd-search-bar__input"
        placeholder={labelText}
        type="text"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
      />
      <button className="hd-search-bar__button" type="submit">
        <Icon isInteractive className="hd-search-bar__icon" name="search" />
      </button>
    </form>
  );
}

export default HDSearchBar;
