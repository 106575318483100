import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './_style/lib/components/Prose.scss';

type ProseProps = {
  className?: string;
  children?: ReactNode;
  htmlString?: string | TrustedHTML; // only use with trusted source
};

export function Prose({ className, children, htmlString }: ProseProps) {
  if (htmlString !== undefined) {
    return (
      <div
        data-testid="global-prose"
        className={classnames('global-prose global-is-stack', className)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    );
  }

  return (
    <div
      data-testid="global-prose"
      className={classnames('global-prose global-is-stack', className)}
    >
      {children}
    </div>
  );
}
