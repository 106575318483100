import React, { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { getPhpSitePath } from '../lib/getPhpSitePath';

interface PropTypes {
  href: string;
  to: string;
  className: string;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  onDragStart?: React.DragEventHandler<HTMLAnchorElement>;
  onMouseDown?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function LinkModernizer({ href, children, to, ...props }: PropTypes) {
  const { modernizePlayerPage } = useFlags();

  return modernizePlayerPage ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <a href={getPhpSitePath(href)} {...props}>
      {children}
    </a>
  );
}
