import { z } from 'zod';

export const webChapterListItemSchema = z.object({
  type: z.union([z.literal('chapter-section'), z.literal('abstract-section')]),
  ids: z.array(z.number()),
  title: z.string().nullable(),
  subtitle: z.string().nullable()
});

export type WebChapterListItem = z.infer<typeof webChapterListItemSchema>;
