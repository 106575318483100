import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Results } from '../../Results';
import MainSearchFilters from './MainSearchFilters';
import MobileDropdownContainer from '../../MobileComponents/MobileDropdownContainer';
import MobileDropdown from '../../MobileComponents/MobileDropdown';
import MobileFilterToggle from '../../MobileComponents/MobileFilterToggle';
import SearchSortList from '../SearchSortList';
import SearchedFor from '../SearchedFor';
import SearchHeader from '../../PageHeader/SearchHeader';
import { SearchSortSelect } from '../../SortSelect';
import { setSearchParams } from '../../../lib/setSearchParams';

import '../../App.scss';
import '../Search.scss';
import theme from '../../../theme';
import config from '../../../config';

import { setCurrentSearch as setCurrentSearchAction } from '../../../actions/filters';

const { searchSortOptions } = config;

export const MainSearch = ({
  media,
  setCurrentSearch,
  filtersOpen,
  currentSearch
}) => (
  <section className="emrap-search-container">
    <ReactiveBase
      app={config.mainSearchApp}
      url={config.url}
      theme={theme}
      setSearchParams={setSearchParams}
    >
      <Helmet>
        <title>Search | EM:RAP</title>
        <meta property="og:title" content="Search EM:RAP" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.emrap.org/search" />
        <meta
          property="og:image"
          content="https://www.emrap.org/images/elements/emrap-header-logo.png"
        />
      </Helmet>
      <div className="app-container">
        <div className="search-results-container">
          <div className="search-results">
            <SearchHeader />
            <DataSearch
              componentId="emrap-search-bar"
              filterLabel="Search"
              dataField={[
                'episode.title',
                'episode.description',
                'chapter.title',
                'chapter.description',
                'attachment.title',
                'attachment.bodytext',
                'chapter.tags',
                'chapter.faculty',
                'chapter.written_summary',
                'chapter.citations',
                'comment.bodytext'
              ]}
              fieldWeights={[8, 5, 5, 5, 4, 4, 3, 3, 2, 2, 2]}
              placeholder="Search by title, summary, or comments…"
              autosuggest={false}
              highlight
              fuzziness={0}
              debounce={500}
              iconPosition="right"
              URLParams
              className="data-search-container"
              innerClass={{
                input: 'search-input'
              }}
              onValueChange={value => {
                setCurrentSearch(value);
              }}
              defaultValue={currentSearch}
            />
            {media.xs && (
              <MobileDropdownContainer>
                <MobileFilterToggle />
                <MobileDropdown buttonText="Sort" showClear>
                  <SearchSortList options={searchSortOptions} />
                </MobileDropdown>
              </MobileDropdownContainer>
            )}
            {media.sm && <SearchedFor />}
            {media.sm && <SearchSortSelect options={searchSortOptions} />}
            <Results media={media} />
          </div>
          <nav
            className={classnames('search-sidebar', {
              closed: !filtersOpen
            })}
          >
            <MainSearchFilters media={media} currentSearch={currentSearch} />
          </nav>
        </div>
      </div>
    </ReactiveBase>
  </section>
);

const mapStatetoProps = state => ({
  filtersOpen: state.filters.open,
  currentSearch: state.filters.currentSearch
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators({ setCurrentSearch: setCurrentSearchAction }, dispatch);

const MainSearchContainer = connect(
  mapStatetoProps,
  mapDispatchtoProps
)(MainSearch);

MainSearch.propTypes = {
  media: PropTypes.shape({
    mobile: PropTypes.bool,
    desktop: PropTypes.bool,
    sm: PropTypes.bool,
    xs: PropTypes.bool
  }).isRequired,
  setCurrentSearch: PropTypes.func.isRequired,
  currentSearch: PropTypes.string.isRequired,
  filtersOpen: PropTypes.bool.isRequired
};

export default MainSearchContainer;
