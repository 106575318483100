import React, { useState } from 'react';
import classnames from 'classnames';
import formatPlaylistChapterPage from '../../lib/formatPlaylistChapterPage';
import { WebChapter } from '../../schema/webEpisode/webChapter';
import { WebChapterListItem } from '../../schema/webEpisode/webChapterListItem';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import DropdownButton from '../DropdownButton';
import { EpisodeChapter } from './EpisodeChapter';
import { useUrgentCare } from '../../hooks/useUrgentCare';

import './EpisodePlaylist.scss';

interface EpisodePlaylistProps {
  chapters: WebChapter[];
  currentChapterId: number;
  episodeTitle: string;
  episodeType: EpisodeTypeShortname;
  episodeChapterList: WebChapterListItem[];
}

const EpisodePlaylist = ({
  chapters,
  currentChapterId,
  episodeTitle,
  episodeType,
  episodeChapterList
}: EpisodePlaylistProps) => {
  const isUrgentCare = useUrgentCare() || episodeType === 'ucmaximus';
  const chapterCount = chapters.length;
  const showThumbnail: boolean =
    episodeType === 'c3' || episodeType === 'full' || isUrgentCare;
  const formattedPlaylistChapter = formatPlaylistChapterPage(
    episodeChapterList,
    chapters
  );

  const initialOpenIndex = formattedPlaylistChapter.findIndex(
    element =>
      element.type === 'abstract-section' &&
      element.abstractLists.find(abstractList =>
        abstractList.chapters.find(chapter => chapter.id === currentChapterId)
      )
  );

  const [isOpen, setIsOpen] = useState<{ [index: number]: boolean }>(
    initialOpenIndex !== -1 ? { [initialOpenIndex]: true } : {}
  );

  return (
    <div className={`episode-playlist episode-playlist--${episodeType}`}>
      <div className="episode-playlist__header">
        <span className="episode-playlist__title">{episodeTitle}</span>
        <span className="episode-playlist__chapter-count">{`${chapterCount} ${
          chapterCount === 1 ? 'Chapter' : 'Chapters'
        }`}</span>
      </div>
      <div className="episode-playlist__chapter-list">
        {formattedPlaylistChapter.map((element, playlistIndex) =>
          element.type === 'abstract-section' ? (
            <div key={element.abstractLists[0].chapters[0].shortname}>
              <DropdownButton
                handleClick={() =>
                  setIsOpen({
                    ...isOpen,
                    [playlistIndex]: !isOpen[playlistIndex]
                  })
                }
                text={`${
                  episodeType === 'rop'
                    ? 'Primary Care Medical Abstracts'
                    : 'Abstracts'
                } (${element.abstractLists.reduce(
                  (chapterAbstractCount, abstractList) =>
                    chapterAbstractCount + abstractList.chapters.length,
                  0
                )})`}
                displayCollapseLabel
                isOpen={isOpen[playlistIndex]}
                className="episode-playlist__dropdown-button"
              />
              {isOpen[playlistIndex] &&
                element.abstractLists.map((abstractList, index) => (
                  <div key={abstractList.chapters[0].id}>
                    <div className="episode-playlist__chapter-group">
                      {(abstractList.title || abstractList.subtitle) && (
                        <div
                          className={classnames(
                            'episode-playlist__header-container',
                            {
                              'episode-playlist__header-container--first':
                                index === 0
                            }
                          )}
                        >
                          {abstractList.title && (
                            <div className="episode-playlist__chapter-group-title">
                              {abstractList.title}
                            </div>
                          )}
                          {abstractList.subtitle && (
                            <div className="episode-playlist__chapter-group-subtitle">
                              {abstractList.subtitle}
                            </div>
                          )}
                        </div>
                      )}
                      {abstractList.chapters.map(chapter => (
                        <EpisodeChapter
                          key={chapter.shortname}
                          chapter={chapter}
                          currentChapterId={currentChapterId}
                          showThumbnail={showThumbnail}
                          episodeType={episodeType}
                          isAbstract
                        />
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <EpisodeChapter
              key={element.chapter.shortname}
              chapter={element.chapter}
              currentChapterId={currentChapterId}
              showThumbnail={showThumbnail}
              episodeType={episodeType}
              isAbstract={false}
            />
          )
        )}
      </div>
    </div>
  );
};

export default EpisodePlaylist;
