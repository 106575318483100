import { doc, getDocFromServer } from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { db } from '../firebase';
import collections from '../firebase/collections';

/**
 * Detects if the user can connect to Firestore by forcing
 * a request with getDocFromServer that will fail if the user is offline.
 * @returns {Promise<boolean>} - true if the user can connect to Firestore, false if the user is offline
 */
export async function isFirebaseOnline(): Promise<boolean> {
  try {
    const docRef = doc(db, collections.webConfig, 'ping');

    await getDocFromServer(docRef);

    return true;
  } catch (error) {
    if (error instanceof FirebaseError) {
      if (
        error.message ===
        'Failed to get document because the client is offline.'
      ) {
        return false;
      }
    }

    // unexpected error
    throw error;
  }
}
