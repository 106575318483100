import React from 'react';
import { Modal, ModalStickyFooter } from '../Modal';
import { Prose } from '../Prose';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import './CmeGenericDisclosureModal.scss';

interface CmeGenericDisclosureModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  disclosureContent: string;
  isLoading: boolean;
  isPreview?: boolean;
}

export function CmeGenericDisclosureModal({
  isModalOpen,
  handleClose,
  disclosureContent,
  isLoading,
  isPreview = false
}: CmeGenericDisclosureModalProps) {
  return (
    <Modal
      isPreview={isPreview}
      isOpen={isModalOpen}
      handleClose={handleClose}
      title={
        !isPreview ? 'CME Information' : 'Preview Disclosure: CME Information'
      }
      modalClassName="cme-generic-disclosure-modal"
      footer={
        isPreview ? undefined : (
          <ModalStickyFooter
            primaryAction={
              <button
                type="button"
                className="global-button"
                data-variant="primary"
                onClick={() => {
                  reportButtonClickedAnalytics({
                    buttonType: 'confirm',
                    buttonText: 'I have read the CME information',
                    eventSource: 'cme generic disclosure'
                  });
                  handleClose();
                }}
              >
                I have read the CME information
              </button>
            }
          />
        )
      }
    >
      {isLoading ? (
        <p className="global-is-width-wrapper">Loading...</p>
      ) : (
        <Prose
          className="cme-generic-disclosure-modal__section-container global-is-width-wrapper global-is-stack"
          htmlString={disclosureContent}
        />
      )}
    </Modal>
  );
}
