import { z } from 'zod';

export const marketingSlotIdSchema = z.union([
  z.literal('landingPageHeroBanner'),
  z.literal('landingPageSection'),
  z.literal('landingPageBucket'),
  z.literal('modal'),
  z.literal('emrapMiniBanner'),
  z.literal('corependiumMiniBanner')
]);

export type MarketingSlotId = z.infer<typeof marketingSlotIdSchema>;
