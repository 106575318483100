import React, { FunctionComponent, SVGProps } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowDown } from '../../images/urgent-care/icons/uc-icon__arrow--down.svg';
import { ReactComponent as ArrowLeft } from '../../images/urgent-care/icons/uc-icon__arrow--left.svg';
import { ReactComponent as ArrowRight } from '../../images/urgent-care/icons/uc-icon__arrow--right.svg';
import { ReactComponent as ArrowUp } from '../../images/urgent-care/icons/uc-icon__arrow--up.svg';
import { ReactComponent as ContentTypeAudio } from '../../images/urgent-care/icons/uc-icon__content-types--audio.svg';
import { ReactComponent as ContentTypeConferences } from '../../images/urgent-care/icons/uc-icon__content-types--conferences.svg';
import { ReactComponent as ContentTypeFoundations } from '../../images/urgent-care/icons/uc-icon__content-types--foundations.svg';
import { ReactComponent as ContentTypeVideo } from '../../images/urgent-care/icons/uc-icon__content-types--video.svg';
import { ReactComponent as FeaturesAudio } from '../../images/urgent-care/icons/uc-icon__features--audio.svg';
import { ReactComponent as FeaturesLiteratureReview } from '../../images/urgent-care/icons/uc-icon__features--literature-review.svg';
import { ReactComponent as FeaturesTextbook } from '../../images/urgent-care/icons/uc-icon__features--textbook.svg';
import { ReactComponent as FeaturesVideo } from '../../images/urgent-care/icons/uc-icon__features--video.svg';
import { ReactComponent as MediaControlsFastForward } from '../../images/urgent-care/icons/uc-icon__media-controls--fast-forward.svg';
import { ReactComponent as MediaControlsFullScreen } from '../../images/urgent-care/icons/uc-icon__media-controls--full-screen.svg';
import { ReactComponent as MediaControlsPause } from '../../images/urgent-care/icons/uc-icon__media-controls--pause.svg';
import { ReactComponent as MediaControlsPlay } from '../../images/urgent-care/icons/uc-icon__media-controls--play.svg';
import { ReactComponent as MediaControlsReplay } from '../../images/urgent-care/icons/uc-icon__media-controls--replay.svg';
import { ReactComponent as MediaControlsRewind } from '../../images/urgent-care/icons/uc-icon__media-controls--rewind.svg';
import { ReactComponent as MediaControlsSkipBack } from '../../images/urgent-care/icons/uc-icon__media-controls--skip-back.svg';
import { ReactComponent as MediaControlsSkipForward } from '../../images/urgent-care/icons/uc-icon__media-controls--skip-forward.svg';
import { ReactComponent as MediaControlsWindow } from '../../images/urgent-care/icons/uc-icon__media-controls--window.svg';
import { ReactComponent as StatusHelp } from '../../images/urgent-care/icons/uc-icon__status--help.svg';
import { ReactComponent as StatusInfo } from '../../images/urgent-care/icons/uc-icon__status--info.svg';
import { ReactComponent as StatusWarning } from '../../images/urgent-care/icons/uc-icon__status--warning.svg';
import { ReactComponent as VolumeControlsHigh } from '../../images/urgent-care/icons/uc-icon__volume-controls--high.svg';
import { ReactComponent as VolumeControlsLow } from '../../images/urgent-care/icons/uc-icon__volume-controls--low.svg';
import { ReactComponent as VolumeControlsMid } from '../../images/urgent-care/icons/uc-icon__volume-controls--mid.svg';
import { ReactComponent as VolumeControlsMute } from '../../images/urgent-care/icons/uc-icon__volume-controls--mute.svg';
import { ReactComponent as Chat } from '../../images/urgent-care/icons/uc-icon--chat.svg';
import { ReactComponent as Check } from '../../images/urgent-care/icons/uc-icon--check.svg';
import { ReactComponent as Close } from '../../images/urgent-care/icons/uc-icon--close.svg';
import { ReactComponent as Home } from '../../images/urgent-care/icons/uc-icon--home.svg';
import { ReactComponent as Menu } from '../../images/urgent-care/icons/uc-icon--menu.svg';
import { ReactComponent as Minus } from '../../images/urgent-care/icons/uc-icon--minus.svg';
import { ReactComponent as Notification } from '../../images/urgent-care/icons/uc-icon--notification.svg';
import { ReactComponent as Plus } from '../../images/urgent-care/icons/uc-icon--plus.svg';
import { ReactComponent as Search } from '../../images/urgent-care/icons/uc-icon--search.svg';
import { ReactComponent as Settings } from '../../images/urgent-care/icons/uc-icon--settings.svg';
import { ReactComponent as User } from '../../images/urgent-care/icons/uc-icon--user.svg';
import './Icon.scss';

export type IconName =
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'content-type-audio'
  | 'content-type-conferences'
  | 'content-type-foundations'
  | 'content-type-video'
  | 'features-audio'
  | 'features-literature-review'
  | 'features-textbook'
  | 'features-video'
  | 'media-controls-fast-forward'
  | 'media-controls-full-screen'
  | 'media-controls-pause'
  | 'media-controls-play'
  | 'media-controls-replay'
  | 'media-controls-rewind'
  | 'media-controls-skip-back'
  | 'media-controls-skip-forward'
  | 'media-controls-window'
  | 'status-help'
  | 'status-info'
  | 'status-warning'
  | 'volume-control-high'
  | 'volume-control-low'
  | 'volume-control-mid'
  | 'volume-control-mute'
  | 'chat'
  | 'check'
  | 'close'
  | 'home'
  | 'menu'
  | 'minus'
  | 'notification'
  | 'plus'
  | 'search'
  | 'settings'
  | 'user';

interface IconData {
  component: FunctionComponent<SVGProps<SVGSVGElement>>;
}

type IconDataMap = { [key in IconName]: IconData };

export const iconDataMap: IconDataMap = {
  'arrow-down': {
    component: ArrowDown
  },
  'arrow-left': {
    component: ArrowLeft
  },
  'arrow-right': {
    component: ArrowRight
  },
  'arrow-up': {
    component: ArrowUp
  },
  'content-type-audio': {
    component: ContentTypeAudio
  },
  'content-type-conferences': {
    component: ContentTypeConferences
  },
  'content-type-foundations': {
    component: ContentTypeFoundations
  },
  'content-type-video': {
    component: ContentTypeVideo
  },
  'features-audio': {
    component: FeaturesAudio
  },
  'features-literature-review': {
    component: FeaturesLiteratureReview
  },
  'features-textbook': {
    component: FeaturesTextbook
  },
  'features-video': {
    component: FeaturesVideo
  },
  'media-controls-fast-forward': {
    component: MediaControlsFastForward
  },
  'media-controls-full-screen': {
    component: MediaControlsFullScreen
  },
  'media-controls-pause': {
    component: MediaControlsPause
  },
  'media-controls-play': {
    component: MediaControlsPlay
  },
  'media-controls-replay': {
    component: MediaControlsReplay
  },
  'media-controls-rewind': {
    component: MediaControlsRewind
  },
  'media-controls-skip-back': {
    component: MediaControlsSkipBack
  },
  'media-controls-skip-forward': {
    component: MediaControlsSkipForward
  },
  'media-controls-window': {
    component: MediaControlsWindow
  },
  'status-help': {
    component: StatusHelp
  },
  'status-info': {
    component: StatusInfo
  },
  'status-warning': {
    component: StatusWarning
  },
  'volume-control-high': {
    component: VolumeControlsHigh
  },
  'volume-control-low': {
    component: VolumeControlsLow
  },
  'volume-control-mid': {
    component: VolumeControlsMid
  },
  'volume-control-mute': {
    component: VolumeControlsMute
  },
  chat: {
    component: Chat
  },
  check: {
    component: Check
  },
  close: {
    component: Close
  },
  home: {
    component: Home
  },
  menu: {
    component: Menu
  },
  minus: {
    component: Minus
  },
  notification: {
    component: Notification
  },
  plus: {
    component: Plus
  },
  search: {
    component: Search
  },
  settings: {
    component: Settings
  },
  user: {
    component: User
  }
};

interface Props {
  name: IconName;
  type?: 'primary' | 'secondary' | 'disabled';
  className?: string;
}

export function Icon({ name, type = 'primary', className = '' }: Props) {
  const { component } = iconDataMap[name];

  return (
    <div
      aria-label={name}
      className={cn('uc-icon__container', className, {
        'uc-icon__container--secondary': type === 'secondary',
        'uc-icon__container--disabled': type === 'disabled'
      })}
    >
      {React.createElement(component, { className: 'uc-icon' })}
    </div>
  );
}
