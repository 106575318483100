import {
  EMRAP,
  C3,
  EMA,
  HD,
  CRUNCH_TIME_EM,
  LLSA,
  ROP,
  CRUNCH_TIME_FM,
  UC_MAX,
  NONE
} from './episodeTypes';

export function getEpisodeTypeClass(episodeType = NONE, suffix = '') {
  let prefix = '';
  switch (episodeType) {
    case EMRAP:
      prefix = 'emrap';
      break;
    case EMA:
      prefix = 'ema';
      break;
    case C3:
      prefix = 'c3';
      break;
    case HD:
      prefix = 'hd';
      break;
    case CRUNCH_TIME_EM:
      prefix = 'crunch-time';
      break;
    case LLSA:
      prefix = 'llsa';
      break;
    case ROP:
      prefix = 'rop';
      break;
    case CRUNCH_TIME_FM:
      prefix = 'crunch-time-fm';
      break;
    case UC_MAX:
      prefix = 'uc';
      break;
    default:
      prefix = '';
      break;
  }

  return prefix && suffix ? `${prefix}-${suffix}` : `${prefix}${suffix}`;
}
