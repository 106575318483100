import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { getDateQuery } from '../../lib/getDateQuery';

export default function EpisodeDateQuery({ currentSearch, currentDateRange }) {
  return (
    <ReactiveComponent
      componentId="episode-date-query"
      currentSearch={currentSearch}
      render={({ setQuery }) => (
        <Observer
          setQuery={setQuery}
          currentSearch={currentSearch}
          currentDateRange={currentDateRange}
        />
      )}
    />
  );
}

function Observer({ currentSearch, currentDateRange, setQuery }) {
  useEffect(() => {
    setQuery(getDateQuery(currentSearch, currentDateRange));
  }, [currentSearch, setQuery, currentDateRange]);
  return null;
}

EpisodeDateQuery.propTypes = {
  currentSearch: PropTypes.string,
  currentDateRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  }).isRequired
};

EpisodeDateQuery.defaultProps = {
  currentSearch: ''
};
Observer.propTypes = {
  ...EpisodeDateQuery.propTypes,
  setQuery: PropTypes.func
};

Observer.defaultProps = {
  ...EpisodeDateQuery.defaultProps,
  setQuery: undefined
};
