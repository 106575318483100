import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import getSeeAllURL from '../../lib/getSeeAllURL';
import { ESVideoPlaylistItem } from '../../schema/playlist/ESVideoPlaylistItem';
import { HDPlaylistItems } from '../HDPlaylistPage/HDPlaylistItems';

import './HDEpisodePlaylist.scss';

interface HDEpisodePlaylistProps {
  items: ESVideoPlaylistItem[];
  title: string;
  videoCount: number;
  isSeeAllVisible: boolean;
  chapterShortname: string;
  episodeShortname: string;
  isUrgentCare: boolean;
}

const HDEpisodePlaylist = ({
  title,
  videoCount,
  items,
  isSeeAllVisible,
  chapterShortname,
  episodeShortname,
  isUrgentCare
}: HDEpisodePlaylistProps) => {
  const { pathname } = useLocation();
  return (
    <div className="hd-episode-playlist">
      <div className="hd-episode-playlist__header">
        <h3 className="hd-episode-playlist__header-container">
          <span className="hd-episode-playlist__header-label">Playlist: </span>{' '}
          <span className="hd-episode-playlist__header-title">{title}</span>
          <span className="hd-episode-playlist__header-count">
            {`${videoCount} Videos`}
          </span>
        </h3>
      </div>
      <HDPlaylistItems
        items={items}
        isChapterPage
        seeAllHref={isSeeAllVisible ? getSeeAllURL(pathname) : undefined}
        chapterShortname={chapterShortname}
        episodeShortname={episodeShortname}
        trimmedUrl=""
        isUrgentCare={isUrgentCare}
      />
      {isSeeAllVisible && (
        <Link
          className="hd-episode-playlist__player-list-all hd-episode-playlist__player-list-all--desktop"
          to={getSeeAllURL(pathname)}
        >
          See All »
        </Link>
      )}
    </div>
  );
};
export default HDEpisodePlaylist;
