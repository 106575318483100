export const EMRAP = 0;
export const C3 = 10;
export const EMA = 11;
export const HD = 2;
export const CRUNCH_TIME_EM = 12;
export const LLSA = 13;
export const ROP = 14;
export const CRUNCH_TIME_FM = 15;
export const UC_MAX = 16;
export const NONE = 100;
