import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import { resultTitle } from '../../lib/highlightedElements';
import CreateSnippet from '../CreateSnippet';
import pdfImg from '../../images/icons/attachment-pdf.png';

import './AttachmentResult.scss';

function AttachmentResult({ data, media }) {
  const {
    highlight,
    episode: { title: episodeTitle },
    attachment: { title, resource, file_size: fileSize }
  } = data;
  const sizeInKb = filesize(+fileSize, { round: 0 });
  return (
    <a href={resource} className="result-item attachment">
      <img className="icon" src={pdfImg} alt="pdf" />
      <div className="content">
        {resultTitle(title, highlight, media.xs)}
        <div className="episode-title">
          Attachment from <em>{episodeTitle}</em>
        </div>
        <div className="attachment-info-container">
          <div className="attachment-title">
            <CreateSnippet text={highlight.description} />
            <div className="file-size">{sizeInKb}</div>
          </div>
        </div>
      </div>
    </a>
  );
}

AttachmentResult.propTypes = {
  media: PropTypes.shape({
    mobile: PropTypes.bool,
    xs: PropTypes.bool
  }).isRequired,
  data: PropTypes.shape({
    highlight: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string
    }),
    episode: PropTypes.shape({
      title: PropTypes.string,
      resource: PropTypes.string
    }),
    attachment: PropTypes.shape({
      title: PropTypes.string,
      resource: PropTypes.string,
      file_size: PropTypes.string,
      bodytext: PropTypes.string
    })
  }).isRequired
};

export default AttachmentResult;
