import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSearchSort as setSortAction } from '../../actions/filters';

export const SearchSortList = ({ options, currentSort, setSort }) => (
  <div className="mobile-dropdown__list">
    <ul className="sort-options radio-list">
      {options.map(option => (
        <li
          key={`sort-select-${option.key}`}
          className={classnames('radio-list__option', {
            active: currentSort.key === option.key
          })}
        >
          <button
            type="button"
            className="radio-list__button"
            onClick={() => setSort(option)}
          >
            {option.label}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

SearchSortList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string
    })
  ).isRequired,
  currentSort: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  setSort: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  currentSort: state.filters.searchSort
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSort: setSortAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchSortList);
