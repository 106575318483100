import React from 'react';
import moment from 'moment';
import pimJaLogo from '../../images/cme/pim-ja-logo.png';
import aapaLogo from '../../images/cme/aapa-logo.png';
import pimLogo from '../../images/cme/pim-logo.png';

import './PimFrontMatter.scss';

const PIM_JA_LOGO_HTML = `<div class="pim-front-matter__pim-ja-logo-container"><img class="pim-front-matter__pim-ja-logo" src=${pimJaLogo} alt="Jointly Accredited Provider Logo"><div><p><strong>Joint Accreditation`;
const AAPA_HTML = `<div class="pim-front-matter__aapa-container"><img class="pim-front-matter__aapa-logo" src=${aapaLogo} alt="AAPA Category 1 CME Logo"><div><p><strong>Continuing Physician`;

// Adds the necessary images not included in the content from the PHP API
function addPimImages(html: string) {
  return html
    .replace('<strong>Joint Accreditation', PIM_JA_LOGO_HTML) // PIM Joint Accredited logo start
    .replace('healthcare team.</p>', 'healthcare team.</p></div></div>') // PIM Joint Accredited logo end
    .replace('<strong>Continuing Physician', AAPA_HTML) // AAPA logo start
    .replace(
      'their participation.</p>',
      'their participation.</p></div></div>'
    ); // AAPA logo end
}

interface Props {
  episodeTitle: string;
  releaseDate: string;
  expiryDate: string;
  contentXhtml: string;
}

export function PimFrontMatter({
  episodeTitle,
  releaseDate,
  expiryDate,
  contentXhtml
}: Props) {
  return (
    <div className="pim-front-matter">
      <h2 className="pim-front-matter__content-title">
        {episodeTitle} CME Information
      </h2>
      <p>
        <strong>Date of Original Release:</strong>{' '}
        {moment(releaseDate).format('MMMM D, YYYY')}
      </p>
      <p>
        <strong>Termination Date:</strong>{' '}
        {moment(expiryDate).format('MMMM D, YYYY')}
      </p>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          // Content from PHP API
          __html: addPimImages(contentXhtml)
        }}
      />
      <img
        className="pim-front-matter__pim-logo"
        src={pimLogo}
        alt="Postgraduate Institute for Medicine logo"
      />
      <p>
        Copyright © {new Date().getFullYear()} EM:RAP, Inc. All Rights Reserved
      </p>
    </div>
  );
}
