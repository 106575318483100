type AnyJson = boolean | number | string | null | Array<AnyJson> | JsonMap;
interface JsonMap {
  [key: string]: AnyJson;
}

export class ApiError<T extends AnyJson> extends Error {
  public readonly data: T;

  public readonly status: number;

  constructor(data: T, status: number) {
    const message = JSON.stringify(data);
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.data = data;
    this.status = status;
  }
}
