/**
 * Gets a path an Episode resource based on isUrgentCare
 *
 * @param {string} path the absolute path to prefix.
 *
 * @returns string
 */
export function getEpisodePath(isUrgentCare: boolean, path: string) {
  return isUrgentCare ? `/urgent-care/episode${path}` : `/episode${path}`;
}
