import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { RadioOption } from '../RadioOption';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';
import { Question } from '../Question';
import {
  CmeEvaluationQuestionOption,
  CmeEvaluation
} from '../../schema/cme/cmeEvaluations';
import { CmeEvaluationResponse } from '../../schema/cme/cmeEvaluationResponse';

// TODO remove each question type to a new component
function CmeQuestionTypeText({
  label,
  value,
  setValue,
  isRequired
}: {
  label: string | null;
  value: string;
  setValue: (value: string) => void;
  isRequired: boolean;
}) {
  if (label === null) return null;
  const [localValue, setLocalValue] = useState(value);
  const deboucedSetValue = useCallback(debounce(setValue, 500), []);
  const labelId = label.toLowerCase().replace(' ', '-');
  return (
    <Question
      question={label}
      hintText={isRequired ? undefined : 'optional'}
      htmlFor={labelId}
    >
      <Input
        id={labelId}
        value={localValue}
        onChange={e => {
          setLocalValue(e.target.value);
          deboucedSetValue(e.target.value);
        }}
      />
    </Question>
  );
}
function CmeQuestionCheckBoxList({
  label,
  options,
  value,
  onChange,
  isRequired
}: {
  label: string | null;
  options: CmeEvaluationQuestionOption[];
  value: number[];
  onChange: (newOptionIds: number[]) => void;
  isRequired: boolean;
}) {
  if (label === null) return null;
  return (
    <Question
      labelTag="legend"
      question={label}
      hintText={isRequired ? undefined : 'optional'}
      details="Please check all that apply."
    >
      {options.map(option => {
        const checked = value.includes(option.id);
        if (option.title === null) return null;
        return (
          <Checkbox
            key={option.id}
            label={option.title}
            id={`${option.id}_checkbox`}
            isChecked={checked}
            onChange={() => {
              if (checked) {
                onChange(value.filter(optionId => optionId !== option.id));
              } else {
                onChange([...value, option.id]);
              }
            }}
          />
        );
      })}
    </Question>
  );
}

function CmeQuestionRadioEntry({
  label,
  name,
  options,
  value,
  onChange,
  isRequired
}: {
  name: string;
  label: string | null;
  options: CmeEvaluationQuestionOption[];
  value: number | null;
  onChange: (newOptionId: number) => void;
  isRequired: boolean;
}) {
  if (label === null) return null;
  return (
    <Question
      labelTag="legend"
      question={label}
      hintText={isRequired ? undefined : 'optional'}
      isFlexLayout={options.length < 4}
    >
      {options.map(option => (
        <RadioOption
          key={option.id}
          id={option.id}
          name={name}
          value={option.id}
          title={option.title}
          isChecked={option.id === value}
          onChange={() => onChange(option.id)}
        />
      ))}
    </Question>
  );
}

export function CmeEvaluationsQuestions({
  evaluation,
  evaluationResponse,
  updateCmeEvaluationQuestionResponse
}: {
  evaluation: CmeEvaluation;
  evaluationResponse: CmeEvaluationResponse;
  updateCmeEvaluationQuestionResponse: (
    questionId: number,
    newOptionIds: number[],
    textValue: string | null
  ) => void;
}) {
  const chosenOptionIds = Object.values(evaluationResponse.questionResponse)
    .map(response => response.evaluation_question_option_id)
    .flat();

  return (
    <>
      {evaluation.questions.map(question => {
        if (
          question.dependent_question_option_id !== null &&
          !chosenOptionIds.includes(question.dependent_question_option_id)
        ) {
          return null;
        }

        if (question.question_type === 'TYPE_TEXT') {
          return (
            <CmeQuestionTypeText
              key={question.id}
              label={question.body_text}
              value={
                evaluationResponse.questionResponse[question.id].text_value ??
                ''
              }
              setValue={value =>
                updateCmeEvaluationQuestionResponse(question.id, [], value)
              }
              isRequired={question.required}
            />
          );
        }
        if (question.question_type === 'TYPE_CHECKBOX') {
          return (
            <CmeQuestionCheckBoxList
              key={question.id}
              label={question.body_text}
              options={question.question_options}
              value={
                evaluationResponse.questionResponse[question.id]
                  .evaluation_question_option_id
              }
              onChange={newOptionIds =>
                updateCmeEvaluationQuestionResponse(
                  question.id,
                  newOptionIds,
                  null
                )
              }
              isRequired={question.required}
            />
          );
        }
        if (question.question_type === 'TYPE_RADIO') {
          return (
            <CmeQuestionRadioEntry
              key={question.id}
              label={question.body_text}
              options={question.question_options}
              name={`radio_${question.id}`}
              value={
                evaluationResponse.questionResponse[question.id]
                  .evaluation_question_option_id[0] ?? null
              }
              onChange={newOptionId =>
                updateCmeEvaluationQuestionResponse(
                  question.id,
                  newOptionId === null ? [] : [newOptionId],
                  null
                )
              }
              isRequired={question.required}
            />
          );
        }
        return null;
      })}
    </>
  );
}
