import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { SearchStateHelper } from '../../SearchStateHelper';
import { reportFilterAppliedAnalytics } from '../../../analytics/reportFilterAppliedAnalytics';

import RadioList from '../../RadioList';
import {
  setCitationType as setCitationTypeAction,
  ALL_CITATIONS,
  NON_ABSTRACTED_CITATIONS,
  ABSTRACT_CITATIONS
} from '../../../actions/filters';

import './CitationTypePicker.scss';

const CitationTypeOptions = [
  { title: 'All citations', value: ALL_CITATIONS },
  { title: 'Citations with abstracts', value: ABSTRACT_CITATIONS },
  {
    title: 'Non-abstracted citations',
    value: NON_ABSTRACTED_CITATIONS
  }
];

const getCitationQuery = type => {
  switch (type) {
    case ABSTRACT_CITATIONS:
      return {
        query: {
          bool: {
            must: [
              {
                term: {
                  type: 'citation'
                }
              },
              {
                exists: {
                  field: 'chapter.id'
                }
              }
            ]
          }
        },
        value: ABSTRACT_CITATIONS
      };

    case NON_ABSTRACTED_CITATIONS:
      return {
        query: {
          bool: {
            must: [
              {
                term: {
                  type: 'citation'
                }
              },
              {
                bool: {
                  must_not: {
                    exists: {
                      field: 'chapter.id'
                    }
                  }
                }
              }
            ]
          }
        },
        value: NON_ABSTRACTED_CITATIONS
      };

    case ALL_CITATIONS:
    default:
      return {
        query: {
          term: {
            type: 'citation'
          }
        },

        // Set value to false to make default state not appear in selected
        // filter list.
        value: false
      };
  }
};

export function CitationTypePicker({ citationType, setCitationType }) {
  return (
    <ReactiveComponent
      componentId="citation-type"
      defaultValue={citationType}
      customQuery={() => ({
        query: {
          term: {
            type: 'citation'
          }
        }
      })}
      URLParams
      render={({ setQuery }) => (
        <div className="citation-type-picker">
          <RadioList
            options={CitationTypeOptions}
            defaultValue={citationType}
            onChange={changedValue => {
              reportFilterAppliedAnalytics({
                filterType: 'citation type',
                filterValue: changedValue
              });
              setQuery(getCitationQuery(changedValue));
              setCitationType(changedValue);
            }}
          />
          <SearchStateHelper
            onInit={searchState => {
              if (!citationType) {
                const urlCitationType = get(
                  searchState,
                  '[citation-type].value',
                  ALL_CITATIONS
                );
                setQuery(getCitationQuery(urlCitationType));
                setCitationType(urlCitationType);
              } else {
                setQuery(getCitationQuery(citationType));
              }
            }}
            onClearFilter={() => {
              setQuery(getCitationQuery(ALL_CITATIONS));
              setCitationType(ALL_CITATIONS);
            }}
          />
        </div>
      )}
    />
  );
}

CitationTypePicker.propTypes = {
  citationType: PropTypes.string,
  setCitationType: PropTypes.func.isRequired
};

CitationTypePicker.defaultProps = {
  citationType: null
};

const mapStateToProps = state => ({
  citationType: state.filters.citationType
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setCitationType: setCitationTypeAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchtoProps)(CitationTypePicker);
