import React from 'react';
import { useDispatch, useSelector } from '../../lib/hooks';
import MobileDropdownList from './MobileDropdownList';
import config from '../../config';
import { setAudience as setAudienceAction } from '../../actions/filters';
import { AudienceOption } from '../../lib/types';

const { audienceOptions } = config;

const MobileAudienceSelect = () => {
  const dispatch = useDispatch();
  const currentAudience = useSelector(state => state.filters.audience);

  const setAudience = (audience: AudienceOption) => {
    dispatch(setAudienceAction(audience));
  };

  return (
    <MobileDropdownList
      options={audienceOptions}
      setOption={setAudience}
      currentOption={currentAudience}
    />
  );
};

export default MobileAudienceSelect;
