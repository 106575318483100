import { z } from 'zod';
import { metaSchema } from '../api/meta';

const cmeCreditSchema = z.object({
  credit: z.object({
    id: z.number(),
    hours: z.number(),
    expiry_date: z.string()
  }),
  front_matter_id: z.number().optional(),
  providers: z.array(
    z.object({
      title: z.string(),
      credit_title: z.string(),
      credit_title_plural: z.string()
    })
  ),
  has_attested: z.boolean().optional(),
  is_quiz_complete: z.boolean().optional(),
  is_evaluation_complete: z.boolean().optional(),
  is_credit_earned: z.boolean().optional(),
  has_quiz: z.boolean()
});

export type CmeCredit = z.infer<typeof cmeCreditSchema>;

export const episodeCmeCreditResponseSchema = z.object({
  cmecredit: z.array(z.never()).or(z.record(cmeCreditSchema)),
  meta: metaSchema
});
