import { fetchApi } from '../../lib/fetchApi';
import { reportError } from '../../lib/reportError';
import { FrontMatter, frontMatterSchema } from '../../schema/cme/frontMatter';

export async function getFrontMatterContent(
  episodeId: number,
  setFrontMatterContent: React.Dispatch<
    React.SetStateAction<FrontMatter | undefined>
  >,
  setHasError: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    const response = await fetchApi(`episodes/${episodeId}/front_matters`);

    const results = await response.json();

    // some episodes do not have front matter e.g. HD
    if (!results.front_matters || results.front_matters.length === 0) {
      return;
    }

    const validatedFrontMatter = frontMatterSchema.parse(
      results.front_matters[0]
    );

    setFrontMatterContent(validatedFrontMatter);
  } catch (error) {
    reportError(error);
    setHasError(true);
  }
}
