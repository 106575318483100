import { Dispatch } from 'redux';
import isCacheValid from '../lib/isCacheValid';
import config from '../config';
import {
  ESC3Playlist,
  ESC3PlaylistSchema
} from '../schema/playlist/ESC3Playlist';
import { reportError } from '../lib/reportError';
import { C3PlaylistsState } from '../lib/stateTypes';
import { formatErrorMessage } from '../lib/formatErrorMessage';

export const C3_PLAYLIST_LOAD_START = 'C3_PLAYLIST_LOAD_START';
export const C3_PLAYLIST_LOAD_SUCCESS = 'C3_PLAYLIST_LOAD_SUCCESS';
export const C3_PLAYLIST_LOAD_FAILURE = 'C3_PLAYLIST_LOAD_FAILURE';

export const ALL_C3_PLAYLISTS_LOAD_START = 'ALL_C3_PLAYLISTS_LOAD_START';
export const ALL_C3_PLAYLISTS_LOAD_SUCCESS = 'ALL_C3_PLAYLISTS_LOAD_SUCCESS';

export const ALL_C3_PLAYLISTS_LOAD_FAILURE = 'ALL_C3_PLAYLISTS_LOAD_FAILURE';

const CACHE_LIFETIME_MS = 30000;

const { url: elasticsearchBase, c3PlaylistApp } = config;

function loadC3PlaylistStart(urlPath: string): {
  type: 'C3_PLAYLIST_LOAD_START';
  urlPath: string;
} {
  return {
    type: C3_PLAYLIST_LOAD_START,
    urlPath
  };
}

function loadC3PlaylistSuccess(
  urlPath: string,
  data: ESC3Playlist
): {
  type: 'C3_PLAYLIST_LOAD_SUCCESS';
  urlPath: string;
  data: ESC3Playlist;
} {
  return {
    type: C3_PLAYLIST_LOAD_SUCCESS,
    urlPath,
    data
  };
}

function loadC3PlaylistFailure(
  urlPath: string,
  error: string
): {
  type: 'C3_PLAYLIST_LOAD_FAILURE';
  urlPath: string;
  error: string;
} {
  return {
    type: C3_PLAYLIST_LOAD_FAILURE,
    urlPath,
    error
  };
}

/**
 * @param {string} urlPath
 */
export function loadC3Playlist(urlPath: string) {
  return async (
    dispatch: Dispatch,
    getState: () => { c3Playlists: C3PlaylistsState }
  ) => {
    const {
      c3Playlists: { playlists }
    } = getState();

    const playlist = playlists[urlPath];

    if (playlist !== undefined) {
      // If cache is valid, or we are already loading data, don't re-load
      // playlist data.
      if (
        playlist.isLoading ||
        isCacheValid(playlist.lastUpdate, CACHE_LIFETIME_MS)
      ) {
        return;
      }
    }

    dispatch(loadC3PlaylistStart(urlPath));
    try {
      const response = await fetch(
        `${elasticsearchBase}/${c3PlaylistApp}/_search`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            query: {
              term: {
                url_path: urlPath
              }
            },
            size: 1
          })
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.hits.total === 0) {
          // TODO: this is for debugging, remove when we stop reporting 404s
          reportError(`C3 Playlist with URL path “${urlPath}” not found.`);
          dispatch(
            loadC3PlaylistFailure(
              urlPath,
              `Playlist with URL path “${urlPath}” not found.`
            )
          );
        } else {
          // eslint-disable-next-line no-underscore-dangle
          const rawPlaylist = data?.hits?.hits[0]?._source;
          try {
            const validatedPlaylist = ESC3PlaylistSchema.parse(rawPlaylist);
            dispatch(loadC3PlaylistSuccess(urlPath, validatedPlaylist));
          } catch (error) {
            reportError(error);
            dispatch(loadC3PlaylistSuccess(urlPath, rawPlaylist));
          }
        }
      } else {
        reportError(data.error.reason);
        dispatch(loadC3PlaylistFailure(urlPath, data.error.reason));
      }
    } catch (e) {
      reportError(e);
      dispatch(loadC3PlaylistFailure(urlPath, formatErrorMessage(e)));
    }
  };
}

function loadAllC3PlaylistsStart(): { type: 'ALL_C3_PLAYLISTS_LOAD_START' } {
  return {
    type: ALL_C3_PLAYLISTS_LOAD_START
  };
}

function loadAllC3PlaylistsSuccess(data: ESC3Playlist[]): {
  type: 'ALL_C3_PLAYLISTS_LOAD_SUCCESS';
  data: ESC3Playlist[];
} {
  return {
    type: ALL_C3_PLAYLISTS_LOAD_SUCCESS,
    data
  };
}

function loadAllC3PlaylistsFailure(error: string): {
  type: 'ALL_C3_PLAYLISTS_LOAD_FAILURE';
  error: string;
} {
  return {
    type: ALL_C3_PLAYLISTS_LOAD_FAILURE,
    error
  };
}

export function loadAllC3Playlists() {
  return async (
    dispatch: Dispatch,
    getState: () => { c3Playlists: C3PlaylistsState }
  ) => {
    const {
      c3Playlists: { allPlaylists }
    } = getState();

    // If cache is valid, or we are already loading data, don't re-load
    // playlist data.
    if (
      allPlaylists.isLoading ||
      isCacheValid(allPlaylists.lastUpdate, CACHE_LIFETIME_MS)
    ) {
      return;
    }
    dispatch(loadAllC3PlaylistsStart());
    try {
      const response = await fetch(
        `${elasticsearchBase}/${c3PlaylistApp}/_search`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            sort: [{ display_order: 'asc' }],
            query: {
              range: {
                item_count: {
                  gt: 0
                }
              }
            },
            size: 100
          })
        }
      );

      const data = await response.json();

      if (response.ok) {
        const rawPlaylists = data.hits.hits.map(
          // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
          (hit: { _source: any }) => hit._source
        );
        try {
          const validatedPlaylists = rawPlaylists.map(ESC3PlaylistSchema.parse);
          dispatch(loadAllC3PlaylistsSuccess(validatedPlaylists));
        } catch (error) {
          reportError(error);
          dispatch(loadAllC3PlaylistsSuccess(rawPlaylists));
        }
      } else {
        reportError(data.error.reason);
        dispatch(loadAllC3PlaylistsFailure(data.error.reason));
      }
    } catch (e) {
      reportError(e);
      dispatch(loadAllC3PlaylistsFailure(formatErrorMessage(e)));
    }
  };
}

export type ActionTypes =
  | ReturnType<typeof loadC3PlaylistStart>
  | ReturnType<typeof loadC3PlaylistSuccess>
  | ReturnType<typeof loadC3PlaylistFailure>
  | ReturnType<typeof loadAllC3PlaylistsStart>
  | ReturnType<typeof loadAllC3PlaylistsSuccess>
  | ReturnType<typeof loadAllC3PlaylistsFailure>;
