import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { getEpisodeTypeClass } from '../../lib/getEpisodeTypeClass';
import Icon from '../Icons/Icon';
import { EpisodeTypeNumber } from '../../lib/types';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getEpisodeTypeDisplay } from '../../lib/getEpisodeTypeDisplay';

import './EpisodeHomeLink.scss';

interface EpisodeHomeLinkProps {
  className?: string;
  episodeType: EpisodeTypeNumber;
  pathTo: string;
}

export default function EpisodeHomeLink({
  className,
  episodeType,
  pathTo
}: EpisodeHomeLinkProps) {
  return typeof episodeType === 'number' &&
    episodeType !== EpisodeTypeNumber.NONE ? (
    <Link
      className={classnames(
        getEpisodeTypeClass(episodeType, 'episode-home-link'),
        'episode-home-link',
        className
      )}
      to={pathTo}
      onClick={() => {
        reportLinkClickedAnalytics({
          to: pathTo,
          linkText: getEpisodeTypeDisplay(episodeType)
        });
      }}
    >
      <Icon
        isInteractive
        name={getEpisodeTypeClass(episodeType)}
        className={getEpisodeTypeClass(episodeType, 'search-bar__icon')}
      />
    </Link>
  ) : null;
}
