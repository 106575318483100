import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import momentTimeZone from 'moment-timezone';
import { ConferencePhase } from '../lib/types';
import { WoopWoopEvent } from '../schema/woopWoopEvent/woopWoopEvent';

export function getPhase(now: number, start: number, end: number) {
  if (now < start) {
    return ConferencePhase.BEFORE;
  }

  if (now > end) {
    return ConferencePhase.AFTER;
  }

  return ConferencePhase.DURING;
}

export interface WoopWoopEventState {
  isLoading: boolean;
  time: number;
  timeZone: string;
  phase: ConferencePhase;
  event: WoopWoopEvent | undefined;
  paused: boolean;
}

const initialState: WoopWoopEventState = {
  isLoading: false,
  time: Math.floor(Date.now() / 1000),
  timeZone: momentTimeZone.tz.guess(),
  phase: ConferencePhase.BEFORE,
  event: undefined,
  paused: false
};

export const eventSlice = createSlice({
  name: 'firebase/woop-woop-event',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTimeZone: (state, action: PayloadAction<string>) => {
      state.timeZone = action.payload;
    },
    update: (state, action: PayloadAction<WoopWoopEvent>) => {
      state.event = action.payload;
      state.phase = getPhase(
        state.time,
        state.event.start_date,
        state.event.end_date
      );
    },
    remove: state => {
      state.event = initialState.event;
      state.phase = initialState.phase;
    },
    pause: (state, action: PayloadAction<boolean>) => {
      state.paused = action.payload;
    },
    tick: (state, action: PayloadAction<number>) => {
      state.time = state.paused ? state.time : action.payload;

      if (state.event !== undefined) {
        state.phase = getPhase(
          state.time,
          state.event.start_date,
          state.event.end_date
        );
      }
    }
  }
});
