import { z } from 'zod';

export const thumbnailImageSchema = z.object({
  width: z.number(),
  height: z.number(),
  path: z.string()
});

export type ThumbnailImage = z.infer<typeof thumbnailImageSchema>;

export const thumbnailSchema = z.object({
  480: thumbnailImageSchema,
  720: thumbnailImageSchema
});

export type Thumbnail = z.infer<typeof thumbnailSchema>;

export const c3ThumbnailSchema = z.object({
  'episode-card': thumbnailImageSchema,
  'featured-episode-card': thumbnailImageSchema
});

export type C3Thumbnail = z.infer<typeof c3ThumbnailSchema>;
