import { CmeEvaluationResponse } from '../schema/cme/cmeEvaluationResponse';
import { CmeEvaluation } from '../schema/cme/cmeEvaluations';
import { getDependentQuestionIdsToRemove } from './getDependentQuestionIdsToRemove';

// This function will mutate cmeCurrentResponses
export function updateCmeEvaluationResponse(
  cmeEvaluation: CmeEvaluation,
  cmeCurrentResponses: CmeEvaluationResponse,
  update: {
    questionId: number;
    newOptionIds: number[];
    textValue: string | null;
  }
): void {
  const { questionId, newOptionIds, textValue } = update;

  // Remove answers for dependent questions if user unselected the dependent option
  let dependentQuestionIdsToRemove = getDependentQuestionIdsToRemove(
    cmeEvaluation,
    cmeCurrentResponses
  );
  while (dependentQuestionIdsToRemove.length > 0) {
    dependentQuestionIdsToRemove.forEach(dependentQuestionId => {
      cmeCurrentResponses.questionResponse[
        dependentQuestionId
      ].evaluation_question_option_id = [];
      cmeCurrentResponses.questionResponse[dependentQuestionId].text_value =
        null;
    });

    // Check if we don't need to clear another question
    dependentQuestionIdsToRemove = getDependentQuestionIdsToRemove(
      cmeEvaluation,
      cmeCurrentResponses
    );
  }

  // Update the response for the current question
  cmeCurrentResponses.questionResponse[
    questionId
  ].evaluation_question_option_id = newOptionIds;
  cmeCurrentResponses.questionResponse[questionId].text_value = textValue;
}
