import React from 'react';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { Account } from '../../schema/account/account';

export function Renewal({ account }: { account: Account | null }) {
  const { trialSubscriptionMessaging } = useFlags();

  if (!account?.subscription?.expiryDate) {
    return null;
  }
  const now = Date.now();
  const isExpired = new Date(account.subscription.expiryDate).getTime() < now;
  const formattedExpiry = moment(account.subscription.expiryDate).format(
    'MMMM D, YYYY'
  );

  return (
    <div className="status-bar status-bar--warning">
      <div className="status-bar__container">
        <p className="status-bar__text">
          {isExpired ? (
            `Your subscription expired on ${formattedExpiry}.`
          ) : (
            <>
              Your subscription could expire on {formattedExpiry}.{' '}
              <a
                className="automatic-renewal-link"
                href={getPhpSitePath('/account/subscription/payment-method')}
              >
                Turn on automatic renewals
              </a>{' '}
              so you never miss a single episode!
            </>
          )}
        </p>
        <a
          href={getPhpSitePath(
            trialSubscriptionMessaging ? '/account/payment' : '/subscribe'
          )}
          className="status-bar__action-button"
        >
          Renew Now
        </a>
      </div>
    </div>
  );
}
