import { z } from 'zod';
import {
  RelatedContentItemSchema,
  RelatedContentPlaylistItemSchema
} from '../playlist/RelatedContentItem';
import { thumbnailSchema } from '../playlist/thumbnail';
import { metaSchema } from './meta';

export const relatedContentSchema = z.array(
  z.object({
    id: z.number(),
    shortname: z.string(),
    title: z.string(),
    type: z.literal('chapter'),
    duration: z.number(),
    thumbnail: z.array(thumbnailSchema),
    uploaded_at: z.string(),
    url_path: z.string()
  })
);

export const relatedContentApiResponseSchema = z.object({
  chapter_related_content: z.object({
    in_playlists: z.array(RelatedContentPlaylistItemSchema),
    related_content: z.array(RelatedContentItemSchema)
  }),
  meta: metaSchema
});
