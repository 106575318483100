import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { reportError } from '../lib/reportError';
import { CommentState } from '../lib/stateTypes';
import { fetchApi } from '../lib/fetchApi';
import { getCommentsApiResponseSchema } from '../schema/api/getComments';
import { WebComment } from '../schema/webComment/webComment';
import { WebCommentStatus } from '../schema/webComment/webCommentStatus';

const initialState: CommentState = {
  comments: [],
  isChapterEditor: false
};

export const loadComments = createAsyncThunk(
  'comments/loadComments',
  async (chapterId: number) => {
    try {
      const results = await fetchApi(`chapters/${chapterId}/comments`, {
        method: 'GET'
      });
      const data = getCommentsApiResponseSchema.parse(await results.json());
      return {
        comments: data.comments,
        isChapterEditor: data.isEditor
      };
    } catch (error) {
      reportError(error);
      return { comments: [], isChapterEditor: false };
    }
  }
);

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<WebComment>) => {
      const newComment = action.payload;
      state.comments = [...state.comments, newComment];
    },
    publish: (state, action: PayloadAction<number>) => {
      const editedCommentId = action.payload;
      const editedCommentIndex = state.comments.findIndex(
        comment => comment.id === editedCommentId
      );

      if (editedCommentIndex !== -1) {
        state.comments[editedCommentIndex].status = WebCommentStatus.PUBLISHED;
      }
    },
    hide: (state, action: PayloadAction<number>) => {
      const editedCommentId = action.payload;

      state.comments = state.comments.filter(
        comment => comment.id !== editedCommentId
      );
    }
  },
  extraReducers: builder => {
    builder.addCase(loadComments.fulfilled, (state, action) => {
      const { comments, isChapterEditor } = action.payload;
      state.comments = comments;
      state.isChapterEditor = isChapterEditor;
    });
  }
});
