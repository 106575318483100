import querystring from 'query-string';

export function quoteURLParams() {
  if (window.history.replaceState && window.location.href.includes('?')) {
    const [base, paramString] = window.location.href.split('?');
    const parsedString = querystring.parse(paramString);
    if (parsedString['emrap-search-bar']) {
      const searchTerm = parsedString['emrap-search-bar'] as string;
      if (
        searchTerm.charAt(0) !== '"' &&
        searchTerm.charAt(searchTerm.length - 1) !== '"'
      ) {
        parsedString['emrap-search-bar'] = `"${searchTerm}"`;
      }
    }
    const quotedParamString = querystring.stringify(parsedString);

    const newurl = `${base}?${quotedParamString}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }
}
