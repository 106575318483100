import React from 'react';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { EpisodeEmbeddedQuiz } from '../../schema/webEpisode/episodeEmbeddedQuiz';
import SubscriptionStatus from './SubscriptionStatus';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './ChapterCrunchyQs.scss';

interface ChapterCrunchyQsProps {
  crunchyQsLinks: EpisodeEmbeddedQuiz[];
  isSignedIn: boolean;
  isPaidAndActive: boolean;
}

const ChapterCrunchyQs = ({
  crunchyQsLinks,
  isSignedIn,
  isPaidAndActive
}: ChapterCrunchyQsProps) => {
  const hasPaidQuiz = crunchyQsLinks.some(link => link.isFree === false);
  const canAccessAllQuizzes = isSignedIn && isPaidAndActive;
  const viewAllQuizzesText = 'View All Crunchy Qs';

  return (
    <div className="chapter-crunchy-qs">
      {hasPaidQuiz && (
        <SubscriptionStatus
          tabName="crunchyQs"
          isSignedIn={isSignedIn}
          isPaidAndActive={isPaidAndActive}
          episodeType="crunchtime"
        />
      )}
      <div className="chapter-crunchy-qs__practice-quizzes">
        <h4 className="chapter-crunchy-qs__title">Choose your Quiz!</h4>
        <ul className="chapter-crunchy-qs__quizzes-links">
          {crunchyQsLinks.map(link => {
            if (link.isFree || canAccessAllQuizzes) {
              return (
                <li
                  key={link.shortname}
                  className="chapter-crunchy-qs__quiz-link-container"
                >
                  <a
                    href={getPhpSitePath(`/${link.uri}`)}
                    className="chapter-crunchy-qs__quiz-link"
                    onClick={() =>
                      reportLinkClickedAnalytics({
                        to: link.uri,
                        linkText: link.title,
                        eventSource: 'crunchy qs'
                      })
                    }
                  >
                    {link.title}
                  </a>
                </li>
              );
            }
            return (
              <li
                key={link.shortname}
                className="chapter-crunchy-qs__quiz-link-container chapter-crunchy-qs__quiz-link-container--disabled"
              >
                <div className="chapter-crunchy-qs__quiz-link">
                  {link.title}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="chapter-crunchy-qs__more-quizzes">
          More quizzes coming soon.
        </div>
      </div>
      {canAccessAllQuizzes ? (
        <a
          className="chapter-crunchy-qs__more-quizzes-link"
          href={getPhpSitePath('/crunchtime/quiz')}
          onClick={() =>
            reportLinkClickedAnalytics({
              to: 'crunchtime/quiz',
              linkText: viewAllQuizzesText,
              eventSource: 'crunchy qs'
            })
          }
        >
          {viewAllQuizzesText}
        </a>
      ) : (
        <button
          type="button"
          disabled
          className="chapter-crunchy-qs__more-quizzes-link"
        >
          {viewAllQuizzesText}
        </button>
      )}
    </div>
  );
};

export default ChapterCrunchyQs;
