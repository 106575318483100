import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from '../lib/hooks';

export default function LDReduxIdentify() {
  const ldClient = useLDClient();
  const account = useSelector(state => state.account);

  useEffect(() => {
    if (ldClient && account.account) {
      ldClient.identify({
        key: account.account.id.toString(),
        avatar: account.account.avatarUri,
        email: account.account.email,
        name: `${account.account.firstName} ${account.account.lastName}`,
        firstName: account.account.firstName,
        lastName: account.account.lastName,
        custom: {
          isAdmin: account.account.isCurator
        }
      });
    }
  }, [ldClient, account]);
  return null;
}
