import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateFilter from '../DateFilter';
import SidebarSection from './SidebarSection';
import { setPageDateRange as setPageDateRangeAction } from '../../actions/page';

import './EpisodeGuideSidebar.scss';

function DefaultSidebar({
  media,
  episodeConfig,
  pageDateRange,
  setPageDateRange
}) {
  return (
    <nav className="episode-guide-sidebar filters-container">
      {media.sm && (
        <SidebarSection>
          <DateFilter
            episodeClass={episodeConfig.class}
            componentId="episode-guide-publish-date"
            dataField="episode.publish_date"
            placeholder="Date Range"
            title="Published Date:"
            filterLabel="Published Date"
            showHistogram
            snap
            dateRange={pageDateRange}
            setDateRange={setPageDateRange}
            URLParams
            react={{
              or: ['all-episodes-query', 'playlist-query']
            }}
          />
        </SidebarSection>
      )}
    </nav>
  );
}

DefaultSidebar.propTypes = {
  media: PropTypes.shape({
    xs: PropTypes.bool,
    sm: PropTypes.bool
  }).isRequired,
  episodeConfig: PropTypes.shape({
    class: PropTypes.string.isRequired
  }).isRequired,
  pageDateRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  }).isRequired,
  setPageDateRange: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  pageDateRange: state.page.dateRange,
  filtersOpen: state.filters.open
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setPageDateRange: setPageDateRangeAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchtoProps)(DefaultSidebar);
