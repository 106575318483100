import { z } from 'zod';
import { cmeCreditStatusSchema } from './cmeCreditStatus';
import { cmeEpisodeSchema } from './cmeEpisode';

export const accountCmeCreditSchema = z.object({
  id: z.number(),
  overallCreditHours: z.number(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  expiryDate: z.string(),
  releaseDate: z.string(),
  lastReviewDate: z.string().nullable(),
  status: cmeCreditStatusSchema,
  episode: cmeEpisodeSchema,
  objectives: z.string().nullable()
});

// TODO: Check if need
// provider: {
//   id: number;
//   shortname: string;
// };

export type AccountCmeCredit = z.infer<typeof accountCmeCreditSchema>;
