import { z } from 'zod';
import { metaSchema } from '../api/meta';
import { accountCmeCreditSchema } from './accountCmeCredit';

const cmeEpisodeCreditSchema = accountCmeCreditSchema
  .merge(z.object({ disclosure: z.string().nullable() }))
  .merge(
    z.object({
      provider: z.object({
        id: z.number(),
        shortname: z.string(),
        title: z.string(),
        creditTitle: z.string(),
        creditTitlePlural: z.string()
      })
    })
  );

export const cmeEpisodeCreditResponseSchema = z.object({
  status: z.literal('success'),
  data: z.object({
    credit: cmeEpisodeCreditSchema.nullable()
  }),
  meta: metaSchema
});

export type CmeEpisodeCredit = z.infer<typeof cmeEpisodeCreditSchema>;
