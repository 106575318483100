import React from 'react';
import { SidebarLink } from './SidebarLink';
import Icon from '../Icons/Icon';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { EMA } from '../../lib/episodeTypes';

const MAILCHIMP_URL = 'https://mailchi.mp/emrap/ema-daily-newsletter';

export function EMANewsletterCallout() {
  return (
    <SidebarLink
      href={MAILCHIMP_URL}
      buttonText="Sign Up for the Newsletter"
      text="Sign up to receive a daily fix of emergency medicine abstracts straight to your inbox!"
      episodeType={EMA}
      isInverted
      icon={<Icon name="ema" />}
      onClick={() =>
        reportLinkClickedAnalytics({
          to: MAILCHIMP_URL,
          linkText: 'Sign Up for the Newsletter',
          isExternal: true,
          eventSource: 'EMA Newsletter Callout'
        })
      }
    />
  );
}
