import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export function PrintWindow(props) {
  const { handleClose, children } = props;
  const printable = document.createElement('div');

  useEffect(() => {
    const externalWindow = window.open(
      '',
      'Print',
      'status=no, toolbar=no, scrollbars=yes',
      'false'
    );
    const style = document.createElement('style');
    style.appendChild(
      document.createTextNode('@page { size: auto; margin: 5mm; }')
    );
    externalWindow.document.head.appendChild(style);
    externalWindow.document.body.appendChild(printable);
    externalWindow.print();
    externalWindow.close();
    return handleClose();
  }, []); // eslint-disable-line

  return ReactDOM.createPortal(children, printable); // eslint-disable-line react/prop-types
}

PrintWindow.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired
};

PrintWindow.defaultProps = {
  children: null
};

export default PrintWindow;
