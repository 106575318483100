import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Modal from 'react-modal';
import EpisodePlaylist from './EpisodePlaylist';
import { WebEpisode } from '../../schema/webEpisode/webEpisode';
import { WebChapter } from '../../schema/webEpisode/webChapter';
import EpisodePlayer from './EpisodePlayer';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

import './EpisodePlayerGroup.scss';

interface EpisodePlayerGroupProps {
  episode: WebEpisode;
  chapter: WebChapter;
  isDesktop: boolean;
  isSubscribedToNursesEdition: boolean;
  isSignedIn: boolean;
  isPaidAndActive: boolean;
  accountId?: number | null;
}

const EpisodePlayerGroup = ({
  episode,
  chapter,
  isDesktop,
  isSubscribedToNursesEdition,
  isSignedIn,
  isPaidAndActive,
  accountId
}: EpisodePlayerGroupProps) => {
  const chapterCount = episode.chapters.length;
  const nursesEdition = chapter.media.find(item => item.edition === 'rn');
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const shouldDisplayNurseEditionPlayer =
    nursesEdition &&
    ((isSubscribedToNursesEdition && (isPaidAndActive || chapter.isFree)) ||
      !isSignedIn);
  useEffect(() => {
    setShowPlaylistModal(false);
  }, [isDesktop, chapter]);

  return (
    <div
      className={classnames('episode-player-group', {
        'episode-player-group--two-players': shouldDisplayNurseEditionPlayer,
        'episode-player-group--open-modal': showPlaylistModal,
        'episode-player-group--player-and-nurse-message':
          nursesEdition && !shouldDisplayNurseEditionPlayer
      })}
    >
      <div className="episode-player-group__player-container">
        <EpisodePlayer
          chapter={chapter}
          episode={episode}
          autoPlay
          isEnabled={chapter.isFree || (isSignedIn && isPaidAndActive)}
          isSignedIn={isSignedIn}
          accountId={accountId}
        />
        {shouldDisplayNurseEditionPlayer && (
          <div
            className={classnames('episode-player-group__nurses-edition', {
              'episode-player-group__nurses-edition--disabled':
                !chapter.isFree && !isSignedIn
            })}
          >
            <EpisodePlayer
              chapter={chapter}
              episode={episode}
              nursesEdition
              autoPlay={false}
              isEnabled={chapter.isFree || isSignedIn}
              accountId={accountId}
            />
          </div>
        )}
        {nursesEdition && !shouldDisplayNurseEditionPlayer && (
          <p className="episode-player-group__subscribe-nurse-edition">
            Nurses Edition Commentary is available for this chapter.{' '}
            <a
              className="episode-player-group__subscribe-nurse-edition-link"
              href={getPhpSitePath('account/content')}
            >
              Update your content settings
            </a>{' '}
            to view extra commentary
          </p>
        )}
      </div>
      {chapterCount > 1 &&
        (isDesktop ? (
          <div className="episode-player-group__playlist-container">
            <EpisodePlaylist
              chapters={episode.chapters}
              currentChapterId={chapter.id}
              episodeTitle={episode.title}
              episodeType={episode.type}
              episodeChapterList={episode.chapterList}
            />
          </div>
        ) : (
          <>
            <div className="episode-player-group__playlist-button-container">
              <button
                type="button"
                onClick={() => {
                  setShowPlaylistModal(!showPlaylistModal);
                }}
                className="episode-player-group__playlist-button"
              >
                <span className="episode-player-group__title">
                  {episode.title}
                </span>
                <span className="episode-player-group__chapter-count">{`${chapterCount} ${
                  chapterCount === 1 ? 'Chapter' : 'Chapters'
                }`}</span>
              </button>
            </div>
            {showPlaylistModal && (
              <Modal
                isOpen={showPlaylistModal}
                className="episode-player-group__playlist-modal"
                overlayClassName="episode-player-group__modal-overlay"
              >
                <EpisodePlaylist
                  chapters={episode.chapters}
                  currentChapterId={chapter.id}
                  episodeTitle={episode.title}
                  episodeType={episode.type}
                  episodeChapterList={episode.chapterList}
                />
                <button
                  type="button"
                  className={`episode-player-group__modal-close-button episode-player-group__modal-close-button--${episode.type}`}
                  onClick={() => {
                    setShowPlaylistModal(false);
                  }}
                >
                  Close
                </button>
              </Modal>
            )}
          </>
        ))}
    </div>
  );
};

export default EpisodePlayerGroup;
