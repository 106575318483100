import React from 'react';
import SkipEpisode from './SkipEpisode';
import PlaybackSpeedControl from './PlaybackSpeedControl';
import { PlaybackSlider } from '../PlaybackSlider/PlaybackSlider';
import formatDuration from '../../lib/formatDuration';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';

import './PlayerControlBar.scss';

interface PlayerControlBarProps {
  secondsPlayed: number;
  setSecondsPlayed: (seconds: number) => void;
  duration: number;
  speed: number;
  setSpeed: React.Dispatch<React.SetStateAction<number>>;
  handlePlaybackSpeedAnalytics: () => void;
  episodeShortname: string;
  episodeType: EpisodeTypeShortname;
  prevChapterShortname?: string;
  nextChapterShortname?: string;
  isEnabled?: boolean;
  onSkip?: (nextChapterShortname: string, icon: 'next' | 'previous') => void;
}

const PlayerControlBar = ({
  episodeShortname,
  episodeType,
  prevChapterShortname,
  nextChapterShortname,
  secondsPlayed,
  setSecondsPlayed,
  duration,
  speed,
  setSpeed,
  handlePlaybackSpeedAnalytics,
  onSkip,
  isEnabled = true
}: PlayerControlBarProps) => (
  <div className="player-control-bar">
    <SkipEpisode
      icon="previous"
      episodeShortname={episodeShortname}
      chapterShortname={prevChapterShortname}
      episodeType={episodeType}
      onSkip={onSkip}
    />
    <span className="player-control-bar__elapsed">
      {formatDuration(secondsPlayed)}
    </span>
    <PlaybackSlider
      episodeType={episodeType}
      secondsPlayed={secondsPlayed}
      setSecondsPlayed={setSecondsPlayed}
      duration={duration}
      isEnabled={isEnabled}
    />
    <span className="player-control-bar__duration">
      {formatDuration(duration)}
    </span>
    <SkipEpisode
      icon="next"
      episodeShortname={episodeShortname}
      chapterShortname={nextChapterShortname}
      episodeType={episodeType}
      onSkip={onSkip}
    />
    {isEnabled && (
      <PlaybackSpeedControl
        speed={speed}
        setSpeed={setSpeed}
        handlePlaybackSpeedAnalytics={handlePlaybackSpeedAnalytics}
        episodeType={episodeType}
      />
    )}
  </div>
);
export default PlayerControlBar;
