import { z } from 'zod';

export const relatedEpisodeSchema = z.object({
  title: z.string(),
  shortname: z.string(),
  artworkUri: z.string(),
  chapterCount: z.number(),
  duration: z.number()
});

export type RelatedEpisode = z.infer<typeof relatedEpisodeSchema>;
