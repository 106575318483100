/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { LinkModernizer } from '../LinkModernizer';
import { resultTitle, resultDescription } from '../../lib/highlightedElements';
import formatDuration from '../../lib/formatDuration';
import config from '../../config';

interface MediaEdition {
  duration: number | null;
  image: number | null;
  filename: string | null;
  edition: number | null;
  faculty?: string[];
}

interface ChapterResultProps {
  media: {
    mobile: boolean;
    xs: boolean;
  };
  data: {
    highlight: {
      title: string;
      description: string;
    };
    episode: {
      title: string;
      resource: string;
      publish_date: string;
    };
    chapter: {
      resource: string;
      title: string;
      description: string;
      written_summary: string;
      duration?: number;
      faculty: string[];
      tags?: string[];
      citations: string | null;
      media: {
        md: MediaEdition;
        video: {
          duration: number | null;
          image: number | null;
        };
        rn: MediaEdition;
      };
    };
  };
}

export function ChapterResult({ data, media }: ChapterResultProps) {
  const {
    highlight,
    chapter: {
      resource,
      title,
      description: chapterDescription,
      written_summary: writtenSummary,
      citations,
      media: {
        md: { duration: mdDuration, faculty: mdFaculty = [] },
        video: { duration: videoDuration }
      }
    },
    episode: { title: episodeTitle }
  } = data;

  const artId = get(data, 'chapter.artwork.id', null);
  const art = artId ? `${config.chapterArtUrl}/${artId}.jpg` : '';

  const publishDate = moment(data.episode.publish_date).format('MMMM YYYY');
  const description = chapterDescription || writtenSummary || citations;

  const separator = mdFaculty.length ? <span className="bullet"> • </span> : '';
  const resourceUrl = resource.replace(/^http(s?):\/\/www.emrap.org/, '');

  const mediaDuration = mdDuration || videoDuration;

  return (
    <LinkModernizer to={resourceUrl} href={resourceUrl} className="result-item">
      <img src={art} alt="Chapter Cover Art" />
      <div className="content">
        {resultTitle(title, highlight, media.xs)}
        <div className="publish-date">
          From {publishDate}, <em>{episodeTitle}</em>
        </div>
        {description ? resultDescription(description, highlight, true) : null}
        <div className="footer">
          {mediaDuration && (
            <span className="duration">{formatDuration(mediaDuration)}</span>
          )}
          {separator}
          <span className="faculty">{mdFaculty.join(', ')}</span>
        </div>
      </div>
    </LinkModernizer>
  );
}
