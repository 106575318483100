import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import EpisodeHeaderBar from '../EpisodeHeaderBar/EpisodeHeaderBar';
import { ChapterTab } from '../../lib/types';
import { WebEpisode } from '../../schema/webEpisode/webEpisode';
import {
  ChapterCommentStatus,
  WebChapter
} from '../../schema/webEpisode/webChapter';
import getEpisodeNumber from '../../lib/getEpisodeNumber';
import EpisodePlayerGroup from './EpisodePlayerGroup';
import ChapterTabs from './ChapterTabs';
import ChapterPageFooter from './ChapterPageFooter';
import ChapterSmartCards from './ChapterSmartCards';
import ChapterSummary from './ChapterSummary';
import { hasPaidAndActiveSubscription } from '../../lib/hasPaidAndActiveSubscription';
import ChapterCrunchyQs from './ChapterCrunchyQs';
import Conversation from '../Conversation/Conversation';
import { commentsSlice, loadComments } from '../../store/comments';
import { accountSlice } from '../../store/account';
import { useDispatch, useSelector } from '../../lib/hooks';
import getSmartCards from '../../lib/getSmartCards';
import useScrollToHash from '../../hooks/useScrollToHash';
import { NavBar } from '../UrgentCare/NavBar';
import { useSearch } from '../../hooks/useSearch';
import { UC_MAX } from '../../lib/episodeTypes';
import { useUrgentCare } from '../../hooks/useUrgentCare';
import Loader from '../Loader';
import { loadC3Playlist } from '../../actions/c3Playlists';
import { ChapterPageBreadcrumb } from './ChapterPageBreadcrumb';
import getBreadcrumbLinks from '../../lib/getBreadcrumbLinks';
import { CmeCreditsTab } from '../CmeCreditsTab/CmeCreditsTab';
import { ChapterCme } from '../Cme/ChapterCme';
import { FrontMatterModal } from '../Cme/FrontMatterModal';
import { FeedbackBar } from '../FeedbackBar';
import { useCmeCredit } from '../../hooks/useCmeCredit';
import { getAccountType } from '../../lib/getAccountType';
import { getChapterHelmetTitle } from '../../lib/getChapterHelmetTitle';
import { ESC3Playlist } from '../../schema/playlist/ESC3Playlist';
import { cmeFacultiesSlice } from '../../store/cmeFaculties';
import { cmeEpisodesCreditSlice } from '../../store/cmeEpisodesCredit';
import { FeedbackFreeTrialStatus } from '../FeedbackFreeTrialStatus';

import './ChapterPage.scss';

interface ChapterPageProps {
  episode: WebEpisode;
  chapter: WebChapter;
  isDesktop: boolean;
  playlistShortnames: string[];
}

export const ChapterPage = ({
  chapter,
  episode,
  isDesktop,
  playlistShortnames
}: ChapterPageProps) => {
  const { modernizeCmeCredits } = useFlags();
  const isUrgentCare = useUrgentCare() || episode.type === 'ucmaximus';
  const { hash } = useLocation();

  const [selectedTab, setSelectedTab] = useState<ChapterTab>(
    hash.endsWith('-comment') ? 'Conversation' : 'Summary'
  );

  useScrollToHash();

  const dispatch = useDispatch();
  const accountState = useSelector(state => state[accountSlice.name]);
  const chapterCount = episode.chapters.length;
  const comments = useSelector(state => state[commentsSlice.name].comments);
  const c3PlaylistsState = useSelector(state => state.c3Playlists);
  const isSignedIn = accountState.account !== null;
  const isPaidAndActive = hasPaidAndActiveSubscription(accountState);
  const isSubscribedToNursesEdition =
    accountState.account?.edition?.shortname === 'rn';
  const isFreeAccount = getAccountType(accountState) === 'free';
  const isTrial = getAccountType(accountState) === 'trial';
  const { pathname } = useLocation();
  const smartCards = isPaidAndActive ? getSmartCards(episode) : [];
  const isExtraChapter = chapter.extras.length > 0;
  const hasCrunchyQs = episode.embeddedQuizzes.length > 0;
  const { searchValue, onSearchChange, onSearchSubmit } = useSearch({
    episodeType: UC_MAX
  });
  const helmetTitle = getChapterHelmetTitle(episode, chapter);

  // Current CME credit API
  const {
    cmeCredit,
    shouldShowFrontMatter,
    shouldShowCmeLinks,
    handleAttestCme,
    hasCmeError
  } = useCmeCredit(
    episode,
    isSignedIn,
    getAccountType(accountState),
    isPaidAndActive
  );
  const cmeCreditsHours =
    !isExtraChapter && cmeCredit ? Number(cmeCredit.credit.hours) : 0;

  // New CME credit API
  const chapterFaculty =
    useSelector(
      state => state[cmeFacultiesSlice.name].faculties?.[chapter.id]
    ) ?? [];
  const newCmeCredit = useSelector(
    state => state[cmeEpisodesCreditSlice.name].credits?.[episode.id]
  );
  const newCmeCreditsHours =
    !isExtraChapter && newCmeCredit !== undefined
      ? newCmeCredit.overallCreditHours
      : 0;

  useEffect(() => {
    dispatch(loadComments(chapter.id));
  }, [chapter.id]);

  useEffect(() => {
    playlistShortnames.forEach(playlistShortname => {
      dispatch(loadC3Playlist(playlistShortname));
    });
  }, [loadC3Playlist, playlistShortnames, dispatch]);

  const playlists: ESC3Playlist[] = [];
  for (let i = 0; i < playlistShortnames.length; i += 1) {
    const playlist =
      c3PlaylistsState.playlists[playlistShortnames[i]]?.data || null;
    if (playlist === null) {
      return <Loader />;
    }
    playlists.push(playlist);
  }

  return (
    <div className={`chapter-page chapter-page--${episode.type}`}>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      {!modernizeCmeCredits && (
        <FrontMatterModal
          isOpen={shouldShowFrontMatter}
          handleAttestCme={handleAttestCme}
          episodeId={episode.id}
          hasCmeError={hasCmeError}
        />
      )}
      <div className="chapter-page__top-container--background">
        <div className="chapter-page__top-container">
          {isUrgentCare ? (
            <NavBar
              searchValue={searchValue}
              onSearchChange={onSearchChange}
              onSearchSubmit={onSearchSubmit}
              isFancy={false}
              className="chapter-page__uc-navbar"
            />
          ) : (
            <EpisodeHeaderBar
              episodeType={getEpisodeNumber(episode.type)}
              displayLogo
              displayDropDown={false}
            />
          )}
          <div className="chapter-page__main-content">
            <FeedbackFreeTrialStatus
              className="chapter-page__trial-feedback"
              account={accountState.account}
            />
            <ChapterPageBreadcrumb
              indexLinks={getBreadcrumbLinks(
                playlists,
                episode,
                pathname,
                episode.type
              )}
              episodeType={episode.type}
              isEpisodeTitle={
                playlists.length === 0 ||
                playlists[playlists.length - 1].items.length === 0
              }
            />
            {episode.type !== 'full' && (
              <div className="chapter-page__publish-date">
                {episode.publishedDate.toDate().toLocaleDateString(undefined, {
                  month: 'long',
                  year: 'numeric'
                })}
              </div>
            )}
            <h1 id="skip_to_content" className="chapter-page__chapter-title">
              {chapter.title}
            </h1>
            <EpisodePlayerGroup
              episode={episode}
              chapter={chapter}
              isDesktop={isDesktop}
              isSubscribedToNursesEdition={isSubscribedToNursesEdition}
              isSignedIn={isSignedIn}
              isPaidAndActive={isPaidAndActive}
              accountId={accountState.account?.id}
            />
          </div>
        </div>
      </div>
      <div className="chapter-page__bottom-container">
        <ChapterTabs
          episodeType={episode.type}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          hasCrunchyQs={hasCrunchyQs}
          chapterComments={comments || []}
          smartCardsCount={smartCards.length}
          cmeCreditsHours={
            modernizeCmeCredits ? newCmeCreditsHours : cmeCreditsHours
          }
        />
        <div className="chapter-page__tab-content">
          <div
            className={classnames(
              'chapter-page__tab-content-container',
              `chapter-page__tab-content-container--${selectedTab}`
            )}
          >
            {selectedTab === 'Summary' && (
              <ChapterSummary
                episodeType={episode.type}
                relatedEpisodes={episode.relatedEpisodes}
                chapter={chapter}
                isPaidAndActive={isPaidAndActive}
                isSignedIn={isSignedIn}
                isDesktop={isDesktop}
                isUrgentCare={isUrgentCare}
                isSingleChapterEpisode={chapterCount < 2}
              />
            )}
            {smartCards.length > 0 && selectedTab === 'Smart Cards' && (
              <ChapterSmartCards
                smartCards={smartCards}
                episodeType={episode.type}
              />
            )}
            {selectedTab === 'Conversation' &&
              chapter.commentStatus !== ChapterCommentStatus.CLOSED && (
                <Conversation
                  episodeId={episode.id}
                  chapterId={chapter.id}
                  chapterCommentStatus={chapter.commentStatus}
                  comments={comments || []}
                  isLoading={false}
                  episodeType={episode.type}
                  isPaidAndActive={isPaidAndActive}
                  isSignedIn={isSignedIn}
                  isFreeAccount={isFreeAccount}
                  isTrial={isTrial}
                />
              )}
            {!modernizeCmeCredits &&
              selectedTab === 'CME Credits' &&
              !isExtraChapter &&
              (hasCmeError ? (
                <FeedbackBar
                  variant="negative"
                  message="There seems to be an error loading data."
                  actionText="Try refreshing again."
                  onClick={() => window.location.reload()}
                />
              ) : (
                cmeCredit !== undefined && (
                  <ChapterCme
                    cmeCredit={cmeCredit}
                    episodeShortname={episode.shortname}
                    isPaidAndActive={isPaidAndActive}
                    isSignedIn={isSignedIn}
                    episodeType={episode.type}
                    shouldShowCmeLinks={shouldShowCmeLinks}
                  />
                )
              ))}
            {modernizeCmeCredits &&
              selectedTab === 'CME Credits' &&
              !isExtraChapter &&
              newCmeCredit !== undefined && (
                <CmeCreditsTab
                  cmeFaculty={chapterFaculty}
                  isPaidAndActive={isPaidAndActive}
                  isSignedIn={isSignedIn}
                  episodeType={episode.type}
                  cmeEpisodeCredit={newCmeCredit}
                  isTrial={isTrial}
                />
              )}
            {hasCrunchyQs && selectedTab === 'Crunchy Qs' && (
              <ChapterCrunchyQs
                isPaidAndActive={isPaidAndActive}
                isSignedIn={isSignedIn}
                crunchyQsLinks={episode.embeddedQuizzes}
              />
            )}
            {selectedTab !== 'Summary' && chapterCount > 1 && (
              <div className="chapter-page__tab-content-right-empty-filler" />
            )}
          </div>
        </div>
      </div>
      {isDesktop && <ChapterPageFooter />}
    </div>
  );
};

export default ChapterPage;
