import { ESCitationResult } from '../schema/citation/ESCitationResult';

export const TOGGLE_CITATION_TO_PRINT = 'TOGGLE_CITATION_TO_PRINT';
export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';
export const CLEAR_CITATIONS = 'CLEAR_CITATIONS';

export function toggleCitationToPrint(
  id: number,
  data: ESCitationResult,
  selectAll: boolean
) {
  return {
    type: TOGGLE_CITATION_TO_PRINT,
    id,
    data,
    selectAll
  };
}

export function toggleSelectAll() {
  return {
    type: TOGGLE_SELECT_ALL
  };
}

export function clearSelectedCitations() {
  return {
    type: CLEAR_CITATIONS
  };
}
