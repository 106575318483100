import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '../SearchIcon';

import './SearchBarGroup.scss';

const SearchBarGroup = ({ handleSubmit, setInputRef }) => (
  <form
    className="search-bar-group"
    onSubmit={event => {
      event.preventDefault();
      handleSubmit();
    }}
  >
    <input
      type="text"
      className="search-bar-group__text-input"
      ref={setInputRef}
      placeholder="Keywords…"
    />
    <button className="search-bar-group__button" type="submit">
      <SearchIcon
        className="search-bar-group__button-icon"
        stroke="#adb2b9"
        strokeWidth="3"
        width="18"
        height="18"
      />
      <span className="search-bar-group__button-text">Go</span>
    </button>
  </form>
);

SearchBarGroup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default SearchBarGroup;
