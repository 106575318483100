import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Icon, IconName } from './Icon';

import './PrimaryLinkButton.scss';

type Props = {
  icon: IconName;
  text: string;
  className?: string;
  iconClassName?: string;
  onClick?: () => void;
} & ({ to: string; href?: never } | { to?: never; href: string });

export function PrimaryLinkButton({
  icon,
  text,
  className,
  iconClassName,
  to,
  href,
  onClick
}: Props) {
  return to ? (
    <Link
      to={to}
      className={cn('uc-primary-link-button', className)}
      onClick={onClick}
    >
      <Icon
        name={icon}
        className={cn('uc-primary-link-button__icon', iconClassName)}
      />
      <span className="uc-primary-link-button__text">{text}</span>
    </Link>
  ) : (
    <a
      href={href}
      className={cn('uc-primary-link-button', className)}
      onClick={onClick}
    >
      <Icon
        name={icon}
        className={cn('uc-primary-link-button__icon', iconClassName)}
      />
      <span className="uc-primary-link-button__text">{text}</span>
    </a>
  );
}
