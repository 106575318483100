import React from 'react';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

export function AutomaticRenewalPastDue() {
  return (
    <div className="status-bar status-bar--warning">
      <div className="status-bar__container">
        <p className="status-bar__text">
          Payment was declined for your automatic subscription renewal. Please{' '}
          <a href={getPhpSitePath('/account/subscription/payment-method')}>
            update your payment details
          </a>{' '}
          so your subscription does not expire.
        </p>
      </div>
    </div>
  );
}
