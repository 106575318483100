import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from '../Icons/Icon';

import './SketchyLink.scss';

type SketchyColor =
  | 'default'
  | 'black'
  | 'orange'
  | 'orange-inverse'
  | 'teal-dark'
  | 'teal-dark-inverse'
  | 'teal-light'
  | 'ema-red'
  | 'crunch-red'
  | 'llsa-red'
  | 'llsa-blue'
  | 'c3-green'
  | 'c3-red'
  | 'gray'
  | 'gray-inverse'
  | 'hd-purple';

interface Props {
  children?: React.ReactNode;
  href?: string;
  to?: string;
  isSkinny?: boolean;
  color?: SketchyColor;
  className?: string;
  onClick?: () => void;
  isFilled?: boolean;
  hasIcon?: boolean;
  iconName?: string;
}

const SketchyLink = ({
  children,
  href,
  className,
  color = 'default',
  isSkinny = false,
  to,
  onClick,
  isFilled = false,
  hasIcon = false,
  iconName
}: Props) => {
  const colorClass = classNames(className, 'sketchy-link', {
    'sketchy-link--black': color === 'black',
    'sketchy-link--teal-dark': color === 'teal-dark',
    'sketchy-link--teal-dark-inverse': color === 'teal-dark-inverse',
    'sketchy-link--teal-light': color === 'teal-light',
    'sketchy-link--orange': color === 'orange',
    'sketchy-link--orange-inverse': color === 'orange-inverse',
    'sketchy-link--ema-red': color === 'ema-red',
    'sketchy-link--crunch-red': color === 'crunch-red',
    'sketchy-link--llsa-blue': color === 'llsa-blue',
    'sketchy-link--c3-green': color === 'c3-green',
    'sketchy-link--gray': color === 'gray',
    'sketchy-link--gray-inverse': color === 'gray-inverse',
    'sketchy-link--skinny': isSkinny,
    'sketchy-link--hd-purple': color === 'hd-purple',
    'sketchy-link--filled': isFilled,
    'sketchy-link--has-icon': hasIcon
  });

  if (to) {
    return (
      <Link to={to} className={colorClass} onClick={onClick}>
        {hasIcon && <Icon name={iconName} />}
        {children}
      </Link>
    );
  }

  return (
    <a href={href} className={colorClass} onClick={onClick}>
      {children}
    </a>
  );
};

export default SketchyLink;
