import React, { useRef, useState } from 'react';
import FilePlayer from 'react-player';
import ReactModal from 'react-modal';
import cn from 'classnames';
import range from 'lodash/range';
import formatDuration from '../../lib/formatDuration';
import { IconButton } from './IconButton';

import './AudioModalPlayer.scss';

interface AudioModalPlayerProps {
  url: string;
  artwork: string;
  className?: string;
  prevOnClick: () => void;
  nextOnClick: () => void;
}

const MIN_PLAYBACK_SPEED_RATE = 0.5;
const MAX_PLAYBACK_SPEED_RATE = 3;

function setSliderBackground(slider: HTMLInputElement) {
  const { max: duration, value: secondsPlayed } = slider;
  const backgroundSize = `${(
    (Number(secondsPlayed) * 100) /
    Number(duration)
  ).toString()}% 100%`;

  slider.style.backgroundSize = backgroundSize;
}

export const AudioModalPlayer = ({
  url,
  artwork,
  className,
  prevOnClick,
  nextOnClick
}: AudioModalPlayerProps) => {
  const [playing, setPlaying] = useState(false);
  const [secondsPlayed, setSecondsPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [showPlaybackSpeedSlider, setShowPlaybackSpeedSlider] = useState(false);
  const playerRef = useRef<FilePlayer>(null);

  // Lodash range suffers from accumulated floating-point summation errors. We
  // map and round the range values to work around this issue:
  // https://github.com/lodash/lodash/issues/1539
  const ticks = range(0.5, 3.1, 0.1).map(num => Math.round(num * 10) / 10);

  function handleSpeedChange(e: React.FormEvent<HTMLInputElement>) {
    setPlaybackSpeed(Number(e.currentTarget.value));
  }

  return (
    <div className={cn('uc-audio-modal-player', className)}>
      <div className="uc-audio-modal-player__player-container">
        <div className="uc-audio-modal-player__background">
          <img
            className="uc-audio-modal-player__background-image"
            src={artwork}
            alt=""
            aria-label="audio artwork"
          />
        </div>
        <img className="uc-audio-modal-player__artwork" src={artwork} alt="" />
        <IconButton
          icon={playing ? 'media-controls-pause' : 'media-controls-play'}
          onClick={() => setPlaying(!playing)}
          className="uc-audio-modal-player__button"
          iconClassName="uc-audio-modal-player__button-icon"
        />
        <FilePlayer
          url={url}
          ref={playerRef}
          className="uc-audio-modal-player__player"
          playing={playing}
          onProgress={({ playedSeconds }) => {
            setSecondsPlayed(playedSeconds);
          }}
          onDuration={seconds => {
            setDuration(seconds);
          }}
          playbackRate={playbackSpeed}
          aria-label="audio player"
        />
      </div>
      <div className="uc-audio-modal-player__control-bar">
        <IconButton
          icon="media-controls-skip-back"
          className="uc-audio-modal-player__skip-button"
          iconClassName="uc-audio-modal-player__skip-button-icon"
          onClick={prevOnClick}
        />
        <span className="uc-audio-modal-player__elapsed">
          {formatDuration(secondsPlayed)}
        </span>
        <input
          type="range"
          id="uc-audio-modal-player-duration-slider"
          className="uc-audio-modal-player__duration-slider"
          value={secondsPlayed}
          max={duration}
          onChange={e => {
            if (playerRef.current) {
              playerRef.current.seekTo(parseInt(e.target.value, 10));
            }
            setSecondsPlayed(parseInt(e.target.value, 10));
            setSliderBackground(e.target);
          }}
        />
        <span className="uc-audio-modal-player__duration">
          {formatDuration(duration)}
        </span>
        <IconButton
          icon="media-controls-skip-forward"
          className="uc-audio-modal-player__skip-button"
          iconClassName="uc-audio-modal-player__skip-button-icon"
          onClick={nextOnClick}
        />
        <div className="uc-audio-modal-player__playback-speed-container">
          <button
            type="button"
            className="uc-audio-modal-player__playback-speed-button"
            onClick={() => setShowPlaybackSpeedSlider(!showPlaybackSpeedSlider)}
          >
            {playbackSpeed}×
          </button>
          <ReactModal
            isOpen={showPlaybackSpeedSlider}
            onRequestClose={() =>
              setShowPlaybackSpeedSlider(!showPlaybackSpeedSlider)
            }
            overlayClassName="uc-audio-modal-player__playback-speed-modal-overlay"
            className="uc-audio-modal-player__playback-speed-modal-content"
          >
            <div className="uc-audio-modal-player__playback-speed-slider-container">
              <label
                htmlFor="uc-audio-modal-player-speed-slider"
                className="uc-audio-modal-player__playback-speed-title"
              >
                Playback Speed
              </label>
              <input
                id="uc-audio-modal-player-speed-slider"
                type="range"
                className="uc-audio-modal-player__playback-speed-slider"
                min={MIN_PLAYBACK_SPEED_RATE}
                max={MAX_PLAYBACK_SPEED_RATE}
                step="0.1"
                value={playbackSpeed}
                onChange={handleSpeedChange}
              />
              <div className="uc-audio-modal-player__playback-speed-slider-ticks-container">
                {ticks.map(tick => (
                  <span
                    className="uc-audio-modal-player__playback-speed-slider-tick"
                    key={tick}
                  />
                ))}
              </div>
            </div>
          </ReactModal>
        </div>
      </div>
    </div>
  );
};
