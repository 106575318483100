import { WebChapter } from '../schema/webEpisode/webChapter';
import { WebEpisode } from '../schema/webEpisode/webEpisode';
import { getEpisodeTypeDisplay } from './getEpisodeTypeDisplay';

export function getChapterHelmetTitle(
  episode: WebEpisode,
  chapter: WebChapter
) {
  return `${getEpisodeTypeDisplay(episode.type)} - ${chapter.title}${
    episode.chapters.length > 1 && ` - ${episode.title}`
  } | EM:RAP`;
}
