import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import firebaseMiddleware from '../middleware/firebase';
import conference from '../reducers/conference';
import filters from '../reducers/filters';
import print from '../reducers/print';
import page from '../reducers/page';
import { videoPlaylistsApi } from './videoPlaylists';
import c3Playlists from '../reducers/c3Playlists';
import { accountSlice } from './account';
import { episodesSlice } from './episodes';
import { firebaseSlice } from './firebase';
import { eventSlice } from './events';
import { allEventsSlice } from './all-events';
import { flagsSlice } from './flags';
import { commentsSlice } from './comments';
import { marketingSpotsSlice } from './marketingSpots';
import { providersSlice } from './providers';
import { cmeCreditsSlice } from './cmeCredits';
import { cmeFiltersOptionsSlice } from './cmeFiltersOptions';
import { cmeEvaluationsSlice } from './cmeEvaluations';
import { cmeGenericDisclosureSlice } from './cmeGenericDisclosures';
import { cmeFacultiesSlice } from './cmeFaculties';
import { cmeEpisodesCreditSlice } from './cmeEpisodesCredit';

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME
});

export const rootReducer = combineReducers({
  conference,
  filters,
  print,
  page,
  [episodesSlice.name]: episodesSlice.reducer,
  [commentsSlice.name]: commentsSlice.reducer,
  [firebaseSlice.name]: firebaseSlice.reducer,
  [eventSlice.name]: eventSlice.reducer,
  [allEventsSlice.name]: allEventsSlice.reducer,
  [flagsSlice.name]: flagsSlice.reducer,
  [accountSlice.name]: accountSlice.reducer,
  [marketingSpotsSlice.name]: marketingSpotsSlice.reducer,
  [providersSlice.name]: providersSlice.reducer,
  [cmeCreditsSlice.name]: cmeCreditsSlice.reducer,
  [cmeFiltersOptionsSlice.name]: cmeFiltersOptionsSlice.reducer,
  [cmeEvaluationsSlice.name]: cmeEvaluationsSlice.reducer,
  [cmeGenericDisclosureSlice.name]: cmeGenericDisclosureSlice.reducer,
  [cmeFacultiesSlice.name]: cmeFacultiesSlice.reducer,
  [cmeEpisodesCreditSlice.name]: cmeEpisodesCreditSlice.reducer,
  router: connectRouter(history),
  [videoPlaylistsApi.reducerPath]: videoPlaylistsApi.reducer,
  c3Playlists
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['firebase.unsubscribe'],
        ignoredActions: ['firebase/setUnsubscribe']
      }
    })
      .concat(routerMiddleware(history))
      .concat(firebaseMiddleware())
      .concat(videoPlaylistsApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
