import React from 'react';
import cn from 'classnames';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';
import { ModalButton } from './ModalButton';
import { IconButton } from './IconButton';
import { Icon, IconName } from './Icon';
import { AudioModalPlayer } from './AudioModalPlayer';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';

import './MediaModal.scss';

interface MediaModalProps {
  isOpen: boolean;
  closeOnClick: () => void;
  featureOnClick: (index: number) => void;
  icons: IconName[];
  activeIcon: IconName;
  artwork: string;
  title: string;
  subtitle: string;
  mediaUrl: string;
  isVideo: boolean;
  prevOnClick: () => void;
  nextOnClick: () => void;
  className?: string;
}

export function MediaModal({
  isOpen,
  closeOnClick,
  featureOnClick,
  icons,
  activeIcon,
  artwork,
  title,
  subtitle,
  mediaUrl,
  isVideo,
  prevOnClick,
  nextOnClick,
  className
}: MediaModalProps) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeOnClick}
      overlayClassName={cn('uc-media-modal__modal-container', className)}
      className="uc-media-modal__content"
    >
      <div className="uc-media-modal__container">
        <ModalButton
          icon="close"
          size="small"
          onClick={closeOnClick}
          report={() =>
            reportButtonClickedAnalytics({
              buttonType: 'close menu',
              eventSource: 'media slider'
            })
          }
          className="uc-media-modal__close-button"
        />
        <Icon name={activeIcon} className="uc-media-modal__header-icon" />
        <h3 className="uc-media-modal__title">
          <span className="uc-media-modal__title--blue">{title}: </span>
          {subtitle}
        </h3>
        <div className="uc-media-modal__player-container">
          {isVideo ? (
            <ReactPlayer
              url={mediaUrl}
              playing
              controls
              width="100%"
              height="100%"
            />
          ) : (
            <AudioModalPlayer
              url={mediaUrl}
              artwork={artwork}
              prevOnClick={prevOnClick}
              nextOnClick={nextOnClick}
            />
          )}
        </div>
        <div className="uc-media-modal__menu">
          <ModalButton
            icon="arrow-left"
            onClick={prevOnClick}
            report={() =>
              reportButtonClickedAnalytics({
                buttonType: 'carousel arrow',
                eventSource: 'media slider'
              })
            }
            className="uc-media-modal__skip-button"
          />
          <div className="uc-media-modal__menu_icons">
            {icons.map((icon, index) => (
              <IconButton
                key={icon}
                icon={icon}
                onClick={() => featureOnClick(index)}
                report={() =>
                  reportButtonClickedAnalytics({
                    buttonText: title,
                    buttonType: 'carousel dot',
                    pageSpecialty: 'UC'
                  })
                }
                className="uc-media-modal__icon-button"
                iconClassName={cn({
                  'uc-media-modal__icon-button--inactive': icon !== activeIcon
                })}
              />
            ))}
          </div>
          <ModalButton
            icon="arrow-right"
            onClick={nextOnClick}
            report={() =>
              reportButtonClickedAnalytics({
                buttonType: 'carousel arrow',
                eventSource: 'media slider'
              })
            }
            className="uc-media-modal__skip-button"
          />
        </div>
      </div>
    </ReactModal>
  );
}
