import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './ConferenceMenu.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export const ConferenceMenu = ({ className, children }: Props) => (
  <nav className={classNames(className, `conference-menu`)}>
    <ul className="conference-menu__list">{children}</ul>
  </nav>
);

ConferenceMenu.defaultProps = {
  className: undefined
};

export default ConferenceMenu;
