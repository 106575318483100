import React from 'react';
import ColumnHeader from './ColumnHeader';
import EmrapDoctors from '../../images/episode-guide/doctors-emrap.svg';
import EmrapLogo from '../../images/episode-guide/logo-emrap.svg';

import './PageHeader.scss';
import './EmrapHeader.scss';

interface EmrapHeaderProps {
  year?: string;
}

export const EmrapHeader = ({ year = '' }: EmrapHeaderProps) => {
  const title = year
    ? `Emergency Medicine: Reviews and Perspectives from ${year}`
    : 'Emergency Medicine: Reviews and Perspectives';
  const description =
    'A monthly emergency medicine audio series full of CME and the latest reviews and perspectives.';

  return (
    <ColumnHeader
      className="emrap-header"
      title={title}
      description={description}
      logo={EmrapLogo}
      doctors={EmrapDoctors}
    />
  );
};

export default EmrapHeader;
