import { z } from 'zod';
import { cmeChapterSchema } from './cmeChapter';
import { cmeEpisodeTagSchema } from './cmeEpisodeTag';
import { episodeTypeShortnameSchema } from '../webEpisode/episodeType';

export const cmeEpisodeSchema = z.object({
  id: z.number(),
  title: z.string(),
  tags: z.array(cmeEpisodeTagSchema),
  episodeType: episodeTypeShortnameSchema,
  chapters: z.array(cmeChapterSchema)
});
