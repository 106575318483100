import React, { useState } from 'react';
import classnames from 'classnames';
import ClearFiltersButton from './ClearFiltersButton';
import DropdownButton from '../DropdownButton';

import './MobileDropdown.scss';
import '../RadioList.scss';

interface MobileDropdownProps {
  buttonText: string;
  children: React.ReactNode;
  showClear?: boolean;
}

const MobileDropdown = ({
  buttonText,
  children,
  showClear = false
}: MobileDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const height = `${document.body.scrollHeight}px`;

  const closeModal = () => {
    setDropdownOpen(false);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div className="mobile-dropdown">
      <DropdownButton
        handleClick={toggleDropdown}
        text={buttonText}
        isOpen={dropdownOpen}
      />
      {showClear && <ClearFiltersButton />}
      <div
        className={classnames('mobile-dropdown__modal-fader', {
          open: dropdownOpen
        })}
        style={{ height }}
        onClick={closeModal}
        role="presentation"
      >
        {dropdownOpen && children}
      </div>
    </div>
  );
};

export default MobileDropdown;
