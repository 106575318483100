import { z } from 'zod';
import { WebCommentStatusSchema } from './webCommentStatus';

export const webCommentSchema = z.object({
  id: z.number(),
  account: z.number(),
  bodyText: z.string(),
  status: WebCommentStatusSchema,
  createDate: z.string(),
  parent: z.number().nullable(),
  chapter: z.number(),
  episode: z.number(),
  firstName: z.string(),
  lastInitial: z.string(),
  gravatarEmail: z.string().nullable(),
  suffix: z.string().nullable()
});

export type WebComment = z.infer<typeof webCommentSchema>;
