/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { Link } from 'react-router-dom';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

import './ChapterPageFooter.scss';

const ChapterPageFooter = () => (
  <footer className="chapter-page-footer">
    <div className="chapter-page-footer__slogan">What you do matters.</div>
    <div className="chapter-page-footer__links">
      <Link
        to={getPhpSitePath('/contact')}
        className="chapter-page-footer__link"
      >
        {'Help & Contact'}
      </Link>
      <Link to={getPhpSitePath('/about')} className="chapter-page-footer__link">
        About
      </Link>
      <Link
        to={getPhpSitePath('/podcast')}
        className="chapter-page-footer__link"
      >
        {'Podcast & App Setup'}
      </Link>
      <Link to={getPhpSitePath('/merch')} className="chapter-page-footer__link">
        Merch Store
      </Link>
      <Link to={getPhpSitePath('/coins')} className="chapter-page-footer__link">
        Challenge Coins
      </Link>
      <Link to={getPhpSitePath('/beta')} className="chapter-page-footer__link">
        Beta Signup
      </Link>
      <Link
        to={getPhpSitePath('/invite')}
        className="chapter-page-footer__link"
      >
        Invite Friends
      </Link>
      <Link
        to={getPhpSitePath('/privacy')}
        className="chapter-page-footer__link"
      >
        Privacy
      </Link>
      <Link to={getPhpSitePath('/terms')} className="chapter-page-footer__link">
        Terms
      </Link>
    </div>
  </footer>
);

export default ChapterPageFooter;
