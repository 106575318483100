import React from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';

const citationStyle = {
  fontFamily: 'Arial, Helvetica, sans-serif',
  color: 'black',
  border: 'none',
  boxShadow: 'none',
  marginTop: 40
};
const citationTitleStyle = {
  fontSize: 12,
  fontWeight: 400,
  color: 'black'
};
const titleStyle = {
  fontWeight: 'lighter',
  fontSize: 16
};
const abstractTitleStyle = {
  fontSize: 12,
  fontWeight: 'bold'
};
const descriptionStyle = {
  fontSize: 11,
  lineHeight: 1.4
};

const citationAbstractTitle = (title, url) => {
  if (title) {
    const matched = title.match(/(abstract)([1-9]+)/);
    if (!matched) {
      return null;
    }
    const [, , second] = matched;
    return (
      <h2 className="abstract-title" style={abstractTitleStyle}>
        Abstract {second} {url}
      </h2>
    );
  }
  return null;
};

export const CitationPrint = ({
  data: {
    episode: { title, shortname: episodeShortname },
    chapter: { shortname: chapterShortname },
    citation: { citation, abstract_xhtml: abstractXhtml }
  }
}) => {
  const episodeUrl = `(episode/${episodeShortname})`;
  const chapterUrl = `(episode/${episodeShortname}/${chapterShortname})`;

  return (
    <div className="result-item citation print" style={citationStyle}>
      <div className="content">
        {citationAbstractTitle(chapterShortname, chapterUrl)}
        <h2 className="citation-title" style={citationTitleStyle}>
          {title} {episodeUrl}
        </h2>
        <h2 className="title" style={titleStyle}>
          {citation}
        </h2>
        <p className="description" style={descriptionStyle}>
          {striptags(abstractXhtml)}
        </p>
      </div>
    </div>
  );
};

CitationPrint.propTypes = {
  data: PropTypes.shape({
    episode: PropTypes.shape({
      title: PropTypes.string,
      shortname: PropTypes.string
    }),
    chapter: PropTypes.shape({
      shortname: PropTypes.string
    }),
    citation: PropTypes.shape({
      citation: PropTypes.string,
      abstract_xhtml: PropTypes.string
    })
  }).isRequired
};

export default CitationPrint;
