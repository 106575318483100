import React from 'react';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

export function AutomaticRenewalExpired() {
  return (
    <div className="status-bar status-bar--warning">
      <div className="status-bar__container">
        <p className="status-bar__text">
          Your automatic subscription renewal expired.{' '}
          <a href={getPhpSitePath('/account/subscription/payment-method')}>
            Turn on automatic renewal
          </a>{' '}
          again to never miss an episode.
        </p>
      </div>
    </div>
  );
}
