import { useState, useEffect } from 'react';

export const useElementHeight = (selector: string) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const element = document.querySelector(selector) as HTMLElement;
      if (element) {
        setHeight(element.clientHeight);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  });

  return height;
};
