import {
  EMRAP,
  C3,
  EMA,
  HD,
  CRUNCH_TIME_EM,
  LLSA,
  ROP,
  CRUNCH_TIME_FM,
  UC_MAX,
  NONE
} from './episodeTypes';

export function getEpisodeTypePath(episodeType = NONE) {
  switch (episodeType) {
    case NONE:
      return '/episode';
    case EMRAP:
      return '/emrap';
    case EMA:
      return '/ema';
    case C3:
      return '/c3';
    case HD:
      return '/hd';
    case CRUNCH_TIME_EM:
      return '/crunchtime';
    case LLSA:
      return '/llsa';
    case ROP:
      return '/rop';
    case CRUNCH_TIME_FM:
      return '/crunchtimefm';
    case UC_MAX:
      return '/uc-dashboard';
    default:
      return '';
  }
}
