import React from 'react';

import './CmeCreditLabel.scss';

interface CmeCreditLabelProps {
  totalCredits: number;
  creditTitle: string;
  creditTitlePlural: string;
}

export function CmeCreditLabel({
  totalCredits,
  creditTitle,
  creditTitlePlural
}: CmeCreditLabelProps) {
  const displayTitle = totalCredits === 1 ? creditTitle : creditTitlePlural;

  return (
    <>
      <strong>{totalCredits}</strong>{' '}
      <span
        className={
          displayTitle.startsWith('AMA PRA Category 1')
            ? 'cme-credit-label__credit-title--italicized'
            : ''
        }
      >
        {displayTitle}
      </span>
    </>
  );
}
