import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FeatureDetails, FeaturesSection } from './FeaturesSection';
import { SalesHero } from './SalesHero';
import { FacultySection } from './FacultySection';
import { Faculty } from './FacultyModal';
import { Footer } from '../LandingPage/Footer';
import { UCNewsletterCallout } from './UCNewsletterCallout';
import { useSearch } from '../../hooks/useSearch';
import { UC_MAX } from '../../lib/episodeTypes';
import { reportButtonClickedAnalytics } from '../../analytics/reportButtonClickedAnalytics';
import UCMaxPodcast from '../../images/urgent-care/features/uc-features--podcast.svg';
import UCFundamentals from '../../images/urgent-care/features/uc-features--fundamentals.svg';
import UCKeyTopics from '../../images/urgent-care/features/uc-features--key-topics.svg';
import Corependium from '../../images/urgent-care/features/uc-features--corependium.svg';
import brittGuest from '../../images/urgent-care/faculty/uc-faculty__britt-guest.jpg';
import georgeWillis from '../../images/urgent-care/faculty/uc-faculty__george-willis.jpg';
import gitaPensa from '../../images/urgent-care/faculty/uc-faculty__gita-pensa.jpg';
import ileneClaudius from '../../images/urgent-care/faculty/uc-faculty__ilene-claudius.jpg';
import janShoenberger from '../../images/urgent-care/faculty/uc-faculty__jan-shoenberger.jpg';
import jessMason from '../../images/urgent-care/faculty/uc-faculty__jess-mason.jpg';
import jessieWerner from '../../images/urgent-care/faculty/uc-faculty__jessie-werner.jpg';
import melHerbert from '../../images/urgent-care/faculty/uc-faculty__mel-herbert.jpg';
import mikePallaci from '../../images/urgent-care/faculty/uc-faculty__mike-pallaci.jpg';
import mikeWeinstock from '../../images/urgent-care/faculty/uc-faculty__mike-weinstock.jpg';
import seanNordt from '../../images/urgent-care/faculty/uc-faculty__sean-nordt.jpg';
import stuartSwadron from '../../images/urgent-care/faculty/uc-faculty__stuart-swadron.jpg';
import meganJones from '../../images/urgent-care/faculty/uc-faculty__megan-jones.jpg';
import mizuhoMorrison from '../../images/urgent-care/faculty/uc-faculty__mizuho-morrison.jpg';
import joshRussell from '../../images/urgent-care/faculty/uc-faculty__josh-russell.jpg';
import chrisMerritt from '../../images/urgent-care/faculty/uc-faculty__chris-merritt.jpg';
import bryanHayes from '../../images/urgent-care/faculty/uc-faculty__bryan-hayes.jpg';
import nehaRaukar from '../../images/urgent-care/faculty/uc-faculty__neha-raukar.jpg';
import gabbyAhlzadeh from '../../images/urgent-care/faculty/uc-faculty__gabby-ahlzadeh.jpg';
import brianLin from '../../images/urgent-care/faculty/uc-faculty__brian-lin.jpg';
import stevenLai from '../../images/urgent-care/faculty/uc-faculty__steven-lai.jpg';
import mattBaird from '../../images/urgent-care/faculty/uc-faculty__matt-baird.jpg';
import benCooper from '../../images/urgent-care/faculty/uc-faculty__ben-cooper.jpg';
import mockArtwork from '../../images/urgent-care/audio-player/mock-artwork.png';
import config from '../../config';
import { useElasticsearch } from '../../lib/hooks';
import { ESEmrapGuideChapterDoc } from '../../lib/types';

import './UCLandingPage.scss';

const { cdnVideoUrl, cdnVideoPostfix, chapterArtUrl, chapterMediaUrl } = config;

/** TODO: Replace with real faculty data 21 24 & 25 */
const FACULTY: Faculty[] = [
  {
    id: 0,
    title: 'Britt Guest',
    text: 'Dr. Guest completed her residency training at UCLA Ronald Regan/Olive View Emergency Medicine Residency Program. After her year as chief resident, Britt became the first UCLA/EM:RAP Access and Innovation in Medical Education (AIME) Fellow. As the AIME fellow, Britt worked with international academic leaders to help develop innovative teaching tools and delivery approaches in order to expand access to engaging educational content for EM residency training programs throughout the world. After finishing her fellowship, she continues on as faculty at UCLA. In her role as CMO of WoopWoop Media, Britt has organized and developed multiple interactive, high-quality virtual and live conferences that make education not only fun but easily accessible both locally and internationally.',
    imgSrc: brittGuest
  },
  {
    id: 1,
    title: 'George Willis',
    text: "George Willis is an Associate Professor and Associate Program Director at the University of Texas Health Science Center at San Antonio. After completing his residency at the University of Maryland in Baltimore, he completed a Faculty Development Fellowship. He has developed an academic niche in endocrine emergencies, vascular emergencies, procedural education, and medical education and has lectured nationally and internationally in each of these areas. He has won numerous teaching awards including ACEP’s National Junior Faculty Teaching Award and EMRA’s Top 25 Under 45 Influencers in Emergency Medicine. He also serves as the Section Editor for the Endocrine Section of EM:RAP's online textbook, Corependium.",
    imgSrc: georgeWillis
  },
  {
    id: 2,
    title: 'Gita Pensa',
    text: 'Dr. Pensa has been an emergency physician for over twenty years. She is an alumna of the University of Pennsylvania School of Medicine and the George Washington University EM Residency Program, where she was Chief Resident. She has practiced in tertiary care, community care, urgent care, and student health settings, and she prides herself on being a skilled clinician and creative educator. Dr. Pensa is also regarded as an expert on malpractice litigation preparation, lecturing widely and podcasting on the topic, and is a performance coach for medical defendants. She is a member of the AOA Honor Society and was named the 2018 National Faculty Mentor of the Year by EMRA (Emergency Medicine Residents’ Association). She received the Dean’s Excellence in Teaching award in 2020, and also was the recipient of a 2019 Rhode Island ACEP Special Service Recognition Award for "courageous public advocacy of RI Emergency Medicine colleagues."',
    imgSrc: gitaPensa
  },
  {
    id: 3,
    title: 'Ilene Claudius',
    text: 'Ilene Claudius trained at UCLA for medical school and a combined residency in Pediatrics and Emergency Medicine. She is currently an Associate Professor in the Department of Emergency Medicine at the David Geffen School of Medicine at UCLA, and director of Quality Improvement for the Emergency Department at Harbor-UCLA.',
    imgSrc: ileneClaudius
  },
  {
    id: 4,
    title: 'Jan Shoenberger',
    text: 'Dr. Shoenberger is a Professor of Emergency Medicine at the Keck School of Medicine at USC.  She is the current Chief of Service for the Department of Emergency Medicine at the Los Angeles County + USC Medical Center. She is also the Vice-Chair of Operations and Clinical Education for the Department of Emergency Medicine at the Keck School of Medicine of USC.  Prior to these positions, she was the residency director at LAC+USC for 10 years. In addition to being board-certified in emergency medicine, she is also board-certified in Hospice and Palliative Medicine. She is the co-editor and co-host of EM:RAP.',
    imgSrc: janShoenberger
  },
  {
    id: 5,
    title: 'Jess Mason',
    text: 'Jessica Mason earned her medical degree at the University of Southern California Keck School of Medicine and completed her emergency medicine residency training at MetroHealth Medical Center and The Cleveland Clinic in Cleveland, Ohio. She completed a fellowship in Medical Education at UCSF Fresno where she served as the Fellowship Program Director and an Assistant Clinical Professor of Emergency Medicine. She is currently the Medical Education Fellowship Director at John Peter Smith Hospital in Fort Worth, Texas. She is fortunate to work with Emergency Medicine: Reviews and Perspectives (EM:RAP) managing the Comprehensive Core Curriculum (C3) podcast, HD video productions, the Crunch Time board review course, and serving as a Deputy Editor for the monthly EM:RAP podcast. She was recently named among EMRA’s 45 Under 45, was honored with California ACEP’s Education Award, and is proud to serve as the conference chair of California ACEP’s AdvancED Conference.',
    imgSrc: jessMason
  },
  {
    id: 6,
    title: 'Jessie Werner',
    text: 'Dr. Jessie Werner, MD, is a Clinical Instructor of Emergency Medicine at the University of California San Francisco and works at the Fresno campus. She also serves as the Medical Education in Emergency Medicine Fellowship Director and the medical student Clerkship Director. In addition to her university positions, Dr. Werner is also a founding member of EMRACast, the podcast for the Emergency Medicine Residents’ Association (EMRA), Associate Editor for EMRAP’s EM Fundamentals Series, the board review series, CrunchTime, and Editor of EMRAP’s CorePendium Spotlight Interviews.',
    imgSrc: jessieWerner
  },
  {
    id: 7,
    title: 'Mel Herbert',
    text: 'Professor Herbert is a board-certified ER doc and faculty member of the UCLA School of Medicine. The winner of multiple national education awards and honors, he is an internationally recognized leader in education. His innovations have made EM:RAP the leader in Emergency Medicine education across the world.',
    imgSrc: melHerbert
  },
  {
    id: 8,
    title: 'Mike Pallaci',
    text: 'Dr. Pallaci is a 1998 graduate of NYCOM, and is Professor of Emergency Medicine at NEOMED as well as Adjunct Clinical Professor of Emergency Medicine at OUHCOM. He trained at Palmetto Richland Memorial Hospital in Columbia, SC. In addition to nine years of private practice in Columbus, OH, he has served as Residency Program Director for two EM programs in Ohio, and currently is core faculty for the EM residency at Summa Health System in Akron where he also serves as Medical Director for the simulation lab. He has taught at the ACOEP Scientific Assembly, the Adena Chest Pain Summit, EM:RAP, UC:RAP and other venues. He and his wife Denise live in Westerville, OH with their three children, with whom they spend whatever free time they can find.',
    imgSrc: mikePallaci
  },
  {
    id: 9,
    title: 'Mike Weinstock',
    text: 'Mike is an emergency medicine physician as well as the Director of Research and CME and the IRB chair at the Adena Medical Center and Professor of Emergency Medicine, Adjunct at the Wexner Medical Center at The Ohio State University. He is the risk management section editor for EM RAP, and the editor in chief for the new podcast UC MAX (part of the EM RAP family of products) and has contributed to EmCrit, and Risk Management Monthly.',
    imgSrc: mikeWeinstock
  },
  {
    id: 11,
    title: 'Sean Nordt',
    text: 'Dr. Sean Patrick Nordt, MD, PharmD, DABAT, FAACT, FAAEM, FACMT, FASAM is a Professor of Emergency Medicine at the Loma Linda University, School of Medicine and is an Attending Physician and Medical Toxicologist at the Loma Linda University Medical Center. Dr. Nordt is also an Attending Physician and Toxicologist at the LAC+USC Medical Center. Dr. Nordt is the Editor-in-Chief for Pharmacology and Toxicology for EMRAP and the Associate Editor of the CorePendium Textbook of Emergency Medicine. Dr. Nordt is board-certified in Emergency Medicine, Medical Toxicology, Clinical Toxicology, Addiction Medicine, and a Palliative Medicine physician',
    imgSrc: seanNordt
  },
  {
    id: 12,
    title: 'Stuart Swadron',
    text: 'A graduate of the University of Toronto Faculty of Medicine, Dr. Swadron began his career in general medical practice in British Columbia. He is a former program director for the Los Angeles County-USC residency in emergency medicine. He also served as a Vice-Chair in the Department of Emergency Medicine and as an Assistant Dean at the Keck School of Medicine. He has been recognized with the Master Teacher and Leonard Tow Humanism in Medicine Awards at USC and as Program Director of the Year by the American Academy of Emergency Medicine.',
    imgSrc: stuartSwadron
  },
  {
    id: 14,
    title: 'Megan Jones',
    text: 'Dr. Jones has a passion for education and takes pride in being involved with multiple medical student and residency programs in the various locations she has practiced. As a Maternal-Fetal Medicine physician, she cares for complicated pregnancies where patient education is also at her forefront. Taking time with each family to ensure they understand every detail of their pregnancy, Dr. Jones feels extremely lucky to be a part of so many growing families. Growing up in the midwest, she is an alumna of University of Kansas School of Medicine and completed her Ob/Gyn residency at Banner University Medical Center in Phoenix, AZ and MFM fellowship at The Ohio State University. California recently became her home, and she just settled in San Luis Obispo with her husband and little Cavapoo pup. You can find them playing fetch on a beach, hiking, brewery-hopping and enjoying everything there is to do outdoors!',
    imgSrc: meganJones
  },
  {
    id: 15,
    title: 'Mizuho Morrison',
    text: 'Dr. Mizuho Morrison is a board-certified Emergency Medicine physician who works clinically in Southern California. She is one of the first female podcasters in her specialty having started her educational career at EM:RAP. She went on to launch many more medical education podcasts not just in Emergency Medicine, but pioneered the first in Urgent care medicine (UC:RAP) and Pediatrics (PEDS:RAP) serving as Editor-in-Chief of both. Mizuho is a well known voice in the medical education space on various platforms, especially in Urgent Care medicine. Dr.Morrison is a national lecturer and educator helping to develop other physicians. However her work does not stop with her fellow peers, but she also feels it is important to spread accurate and unbiased medical information to the lay public serving as a regular guest medical expert on various media platforms such as: CNN, BBC, and others.',
    imgSrc: mizuhoMorrison
  },
  {
    id: 16,
    title: 'Josh Russell',
    text: 'Dr. Russell is an Emergency and Urgent Care Physician at NorthShore University Health System and has served as a Medical Director for Go-Health Urgent Care. He has been featured on multiple medical education podcasts, including EM:RAP.  He has also completed fellowship training in Hospice and Palliative Medicine. He is the current editor-and-chief of the Journal of Urgent Care Medicine, which is the only peer-reviewed Urgent Care journal worldwide.',
    imgSrc: joshRussell
  },
  {
    id: 17,
    title: 'Chris Merritt',
    text: 'Chris Merritt is Associate Professor of Emergency Medicine and Pediatrics at the Alpert Medical School of Brown University in Providence, RI, where he cares for children in the emergency department at Hasbro Children’s Hospital. His professional interests include medical education and faculty development to improve the care of sick and injured children by preparing physicians to provide excellent pediatric urgent and emergency care. He directs the BrownEM Medical Education Fellowship.',
    imgSrc: chrisMerritt
  },
  {
    id: 18,
    title: 'Bryan Hayes',
    text: 'Dr. Hayes is an Associate Professor of Emergency Medicine in the Division of Medical Toxicology at Harvard Medical School and an Attending Pharmacist at Massachusetts General Hospital. He is board-certified in Clinical Toxicology. Dr. Hayes is a regular speaker at national EM and pharmacy conferences including the American Academy of Emergency Medicine’s Scientific Assembly, American College of Emergency Physician’s Scientific Assembly, and Essentials of Emergency Medicine. He has more than 100 peer-reviewed publications and book chapters, contributes to the Academic Life in EM blog, and is featured on several podcasts including EM:RAP and UC:Max. He is the Immediate Past President of the American Board of Applied Toxicology. He has been honored with Outstanding Teaching Awards from the University of Maryland and Massachusetts General Hospital Departments of EM, Preceptor of the Year Awards from the University of Maryland Medical Center&#39;s pharmacy residency program and the Maryland Society of Health-System Pharmacy, Allied Health Professional of the Year from the Harvard Affiliated Emergency Medicine Residency program, and fellowship status in both the American Academy of Clinical Toxicology and the American Society of Health-System Pharmacists. He resides in Braintree, MA with his wife, Melissa Hayes, PhD, and their two beautiful daughters.',
    imgSrc: bryanHayes
  },
  {
    id: 19,
    title: 'Neha Raukar',
    text: 'Dr. Neha Raukar joined the Department of Emergency Medicine at the Mayo Clinic after 12 years at Brown University in 2018. The combination of residency training in emergency medicine with further fellowship training in Primary Care Sports Medicine offers a unique perspective towards education, clinical medicine, legislation, and research. Her sideline experiences include high school, college, professional, Olympic and extreme athletes. She is dedicated to educating fellow physicians and the public as it relates to injuries and life-threatening diseases in athletes including sudden cardiac death, heat illness, and head injury and gave a TEDx talk on overuse injuries in athletes.',
    imgSrc: nehaRaukar
  },
  {
    id: 20,
    title: 'Gabby Ahlzadeh',
    text: 'Gabrielle Ahlzadeh, MD, CAQ-SM earned her doctor of medicine degree and completed her residency training at Northwestern University Feinberg School of Medicine, serving as chief resident during her final year. After residency, she completed a Primary Care Sports Medicine Fellowship at the University of Utah, where she worked with Division I, professional, and Olympic athletes. Dr. Ahlzadeh currently serves as faculty at the University of Southern California’s emergency medicine department. Dr. Ahlzadeh has lectured across the country and has contributed to multiple resources on orthopedic topics within emergency medicine, including EM:RAP’s CorePendium, ALiEM’s SplintER series, and various textbooks.',
    imgSrc: gabbyAhlzadeh
  },
  {
    id: 21,
    title: 'Brian Lin',
    text: 'Brian Lin is an ABEM-certified Emergency Physician.  He graduated AOA from Mount Sinai School of Medicine in 2005.  He completed Emergency Medicine residency at the Stanford/Kaiser program in 2008, after which he served for one year as the Academic & Administrative Fellow (formerly Chief Resident).  He is currently in active clinical practice at Kaiser Permanente, San Francisco.  He is a volunteer Assistant Professor at UCSF and regularly lectures to emergency medicine residents at the UCSF/SFGH and Stanford/Kaiser programs.',
    imgSrc: brianLin
  },
  {
    id: 22,
    title: 'Steven Lai',
    text: 'Dr. Lai graduated from the University of California, San Diego and completed his residency in emergency medicine at the Ronald Reagan UCLA | Olive View UCLA Emergency Medicine Program. He subsequently completed a Medical Education and Simulation Fellowship at UCLA and is an alumni of the ACEP Teaching Fellowship. His academic areas of interest include educational innovation and curriculum development.',
    imgSrc: stevenLai
  },
  {
    id: 23,
    title: 'Matt Baird',
    text: 'Matt is an Emergency Medicine and Sports Medicine physician at Prisma Health in Greenville, SC. He attended medical school at Dartmouth, Emergency Medicine residency at University of Virginia, and Sports Medicine fellowship at University of Arizona. He practices both disciplines clinically, is an Associate Clinical Professor, Associate Fellowship Director, and Team Physician. He lives in Greenville with his wife, Sara, and two young children, Caroline and Whitt.',
    imgSrc: mattBaird
  },
  // {
  //   id: 24,
  //   title: 'Justin Morgenstern',
  //   text:
  //     'TBD - Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi minima in fugiat quaerat debitis animi, id quas reprehenderit rerum quod saepe consequatur rem earum tempore! Perspiciatis necessitatibus soluta, veritatis accusantium quaerat omnis illum tempore inventore distinctio magni. Porro, impedit? Culpa earum mollitia quidem voluptas, accusantium reprehenderit dicta maiores corrupti facilis.',
  //   imgSrc: melHerbert
  // },
  {
    id: 25,
    title: 'Ben Cooper',
    text: 'Ben is an associate professor and associate program director in the department of emergency medicine at UTHealth Houston, where he directs conference activities and nerds-out over electrocardiography. Outside of work, he’s a proud husband and father of two boys. He enjoys running (enjoys is used loosely), going to his sons’ baseball games, and of course…the Astros!',
    imgSrc: benCooper
  }
];

const audioLatestFreeChapterQuery = {
  query: {
    bool: {
      must: [
        {
          term: {
            'episode.episode_type.id': UC_MAX
          }
        },
        {
          term: {
            type: 'chapter'
          }
        },
        {
          term: {
            'chapter.is_free': true
          }
        }
      ]
    }
  },
  sort: [
    {
      'episode.publish_date': {
        order: 'desc'
      }
    }
  ],
  size: 1
};

export const FEATURES: FeatureDetails[] = [
  {
    id: 0,
    icon: 'content-type-audio',
    img: UCMaxPodcast,
    artwork: `${chapterArtUrl}/52055.jpg`,
    bubbleFlipped: false,
    bubbleText: 'Cutting edge podcast to keep you on top of your UC practice.',
    bubbleButtonText: 'Listen Now',
    mediaModalTitle: 'UC Max Podcast',
    mediaModalSubtitle: 'October Introduction',
    mediaUrl: `${process.env.REACT_APP_CDN_BASE}media/chapter/original/a4aa0ed180534ad3c771b7b5a0c807a8297dfb1c.mp3`,
    isVideo: false
  },
  {
    id: 1,
    icon: 'content-type-foundations',
    img: UCFundamentals,
    artwork: mockArtwork,
    bubbleFlipped: true,
    bubbleText:
      'Video masterclass series covering the most common Urgent Care presentations.',
    bubbleButtonText: 'Watch Snippet',
    mediaModalTitle: 'UC Fundamentals',
    mediaModalSubtitle: 'A reel',
    mediaUrl: `${cdnVideoUrl}/6331f38743948/${cdnVideoPostfix}`,
    isVideo: true
  },
  {
    id: 2,
    icon: 'content-type-conferences',
    img: UCKeyTopics,
    artwork: mockArtwork,
    bubbleFlipped: true,
    bubbleText: 'Virtual UC conference by the best and brightest in the field.',
    bubbleButtonText: 'Watch Trailer',
    mediaModalTitle: 'Key Topics in Urgent Care',
    mediaModalSubtitle: 'Trailer',
    mediaUrl: `${cdnVideoUrl}/62a0fab87d5a5/${cdnVideoPostfix}`,
    isVideo: true
  },
  {
    id: 3,
    icon: 'features-textbook',
    img: Corependium,
    artwork: mockArtwork,
    bubbleFlipped: false,
    bubbleText:
      'The revolutionary digital clinical reference tool. Searchable, continuously updated, life-saving information. ',
    bubbleButtonText: 'Sneak Peek',
    mediaModalTitle: 'CorePendium',
    mediaModalSubtitle: 'A universe of information!',
    mediaUrl: `${cdnVideoUrl}/6346b6cf33ba5/${cdnVideoPostfix}`,
    isVideo: true
  }
];

interface SectionState {
  isOpen: boolean;
  selected: number;
}

export function UCLandingPage() {
  let features = FEATURES;
  const {
    data: audioEpisodesData,
    loading: isAudioEpisodesLoading,
    error: audioEpisodesError
  } = useElasticsearch<ESEmrapGuideChapterDoc>(
    'emrap.guide.episode',
    audioLatestFreeChapterQuery
  );

  if (
    !isAudioEpisodesLoading &&
    !audioEpisodesError &&
    audioEpisodesData &&
    audioEpisodesData.length
  ) {
    const updatedAudioFeature = {
      ...features[0],
      artwork: `${chapterArtUrl}/${audioEpisodesData[0].chapter.artwork.id}.jpg`,
      mediaUrl: `${chapterMediaUrl}/${audioEpisodesData[0].chapter.media.md?.filename}.mp3`,
      mediaModalSubtitle: audioEpisodesData?.[0].chapter.title || ''
    };
    features = [...features];
    features[0] = updatedAudioFeature;
  }

  const { searchValue, onSearchChange, onSearchSubmit } = useSearch({
    episodeType: UC_MAX
  });
  const [facultyState, setFacultyState] = useState<SectionState>({
    isOpen: false,
    selected: 0
  });
  const [featureState, setFeatureState] = useState<SectionState>({
    isOpen: false,
    selected: 0
  });

  const handleFacultyOnClick = (facultyIndex: number) => {
    if (facultyIndex < 0) {
      facultyIndex = FACULTY.length - 1;
    }

    if (facultyIndex >= FACULTY.length) {
      facultyIndex = 0;
    }

    setFacultyState({ isOpen: true, selected: facultyIndex });
  };

  const handleFeatureOnClick = (featureIndex: number) => {
    if (featureIndex < 0) {
      featureIndex = FEATURES.length - 1;
    }

    if (featureIndex >= FEATURES.length) {
      featureIndex = 0;
    }

    reportButtonClickedAnalytics({
      buttonType: 'feature',
      buttonText: FEATURES[featureIndex].bubbleButtonText,
      pageSpecialty: 'UC'
    });
    setFeatureState({
      isOpen: true,
      selected: featureIndex
    });
  };

  return (
    <>
      <Helmet>
        <title>UC Max | EM:RAP</title>
      </Helmet>
      <div className="uc-landing">
        <SalesHero
          className="uc-landing__hero"
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          onSearchSubmit={onSearchSubmit}
        />
        <FeaturesSection
          isOpen={featureState.isOpen}
          onModalClose={() =>
            setFeatureState({ ...featureState, isOpen: false })
          }
          featureOnClick={featureIndex => handleFeatureOnClick(featureIndex)}
          features={features}
          featureSelected={featureState.selected}
          className="uc-landing__feature-section"
        />
        <FacultySection
          isOpen={facultyState.isOpen}
          onModalClose={() =>
            setFacultyState({ ...facultyState, isOpen: false })
          }
          faculty={FACULTY}
          onClick={handleFacultyOnClick}
          facultySelected={facultyState.selected}
          facultyOnClick={handleFacultyOnClick}
          className="uc-landing__faculty-section"
        />
        <UCNewsletterCallout />
        <Footer className="uc-landing__footer" />
      </div>
    </>
  );
}
