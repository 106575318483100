import React from 'react';
import { Heading } from '../Heading';
import { LinkButton } from '../LinkButton';

import './HeroBanner.scss';

type HeroBannerProps = {
  headline: string;
  message: string;
  campaignColor: string;
  imageSrc: string;
  onClick?: () => void;
  report: () => void;
  ctaText: string;
  href: string;
};

export function HeroBanner({
  headline,
  message,
  campaignColor,
  imageSrc,
  onClick = () => null,
  report,
  ctaText,
  href
}: HeroBannerProps) {
  return (
    <aside
      data-testid="hero-banner"
      className="hero-banner"
      style={{
        '--firestore--background--color': campaignColor
      }}
    >
      <div className="hero-banner__gradient" />
      <Heading Tag="h3" className="hero-banner__headline" variant="lg">
        {headline}
      </Heading>
      <p className="hero-banner__message">{message}</p>
      <LinkButton
        onClick={() => {
          onClick();
          report();
        }}
        href={href}
        className="hero-banner__cta"
        variant="tertiary"
        label={ctaText}
      />
      <img src={imageSrc} alt="" className="hero-banner__image" />
    </aside>
  );
}
