import React, { useEffect } from 'react';
import { useDispatch } from '../../lib/hooks';
import { Footer } from '../LandingPage/Footer';
import { AudioHeroSection } from './AudioHeroSection';
import { AudioEpisodeSection } from './AudioEpisodeSection';
import { useSearch } from '../../hooks/useSearch';
import { UC_MAX } from '../../lib/episodeTypes';
import { loadAllC3Playlists } from '../../actions/c3Playlists';
import { NavBar } from './NavBar';

import './UCAudioPage.scss';

export function UCAudioPage(): JSX.Element {
  const dispatch = useDispatch();
  const { searchValue, onSearchChange, onSearchSubmit } = useSearch({
    episodeType: UC_MAX
  });

  useEffect(() => {
    dispatch(loadAllC3Playlists());
  }, [loadAllC3Playlists, dispatch]);

  return (
    <div className="uc-audio-page">
      <div className="uc-star-background">
        <header>
          <NavBar
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            onSearchSubmit={onSearchSubmit}
            isFancy={false}
          />
        </header>
        <div className="uc-swoosh-background">
          <AudioHeroSection className="uc-section" />
          <AudioEpisodeSection className="uc-section" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
