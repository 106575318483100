import React from 'react';
import { Account } from '../../schema/account/account';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

export function AutomaticRenewalCardExpiring({
  account
}: {
  account: Account | null;
}) {
  const cardExpiryDate = account?.subscription?.cardExpiryDate;
  if (!cardExpiryDate) {
    return null;
  }

  const isExpired = new Date(cardExpiryDate).getTime() < Date.now();

  return (
    <div className="status-bar status-bar--warning">
      <div className="status-bar__container">
        <p className="status-bar__text">
          {isExpired ? (
            <>
              The credit card used for your subscription renewal is expired.{' '}
              <a href={getPhpSitePath('/account/subscription/payment-method')}>
                Let’s get that fixed!
              </a>
            </>
          ) : (
            <>
              The credit card used for your subscription renewal expires soon.{' '}
              <a href={getPhpSitePath('/account/subscription/payment-method')}>
                Let’s get that fixed!
              </a>
            </>
          )}
        </p>
      </div>
    </div>
  );
}
