import React from 'react';
import classnames from 'classnames';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import { getPageSpecialty } from '../../lib/getPageSpecialty';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';

import './ChapterCmeLink.scss';

interface ChapterCmeLinkProps {
  href: string;
  isActive?: boolean;
  activeImg: string;
  inactiveImg: string;
  alt: string;
  text: string;
  episodeType: EpisodeTypeShortname;
}

export const ChapterCmeLink = ({
  href,
  isActive = false,
  activeImg,
  inactiveImg,
  alt,
  text,
  episodeType
}: ChapterCmeLinkProps) =>
  isActive ? (
    <a
      className={classnames('chapter-cme-link', {
        'chapter-cme-link--active': isActive
      })}
      href={href}
      onClick={() =>
        reportLinkClickedAnalytics({
          to: href,
          linkText: text,
          pageSpecialty: getPageSpecialty(episodeType),
          eventSource: 'cme'
        })
      }
    >
      <img className="chapter-cme-link__icon" src={activeImg} alt={alt} />
      {text}
    </a>
  ) : (
    <div className="chapter-cme-link">
      <img className="chapter-cme-link__icon" src={inactiveImg} alt={alt} />
      {text}
    </div>
  );
