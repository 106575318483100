import React from 'react';
import ColumnHeader from './ColumnHeader';
import LLSADoctors from '../../images/episode-guide/doctors-llsa.svg';
import LLSALogo from '../../images/episode-guide/logo-llsa.svg';

import './PageHeader.scss';
import './LLSAHeader.scss';

export const LLSAHeader = () => {
  const title = 'Lifelong Learning and Self‑Assessment';
  const description = `Reviews of LLSA papers that cut to the chase. Focused, short and
educational, EM:RAP’s LLSA takes the time and angst out of LLSA prep.`;

  return (
    <ColumnHeader
      className="llsa-header"
      title={title}
      description={description}
      logo={LLSALogo}
      doctors={LLSADoctors}
    />
  );
};

export default LLSAHeader;
