import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EpisodeDuration from './EpisodeDuration';

import './FeaturedEpisodeCard.scss';

export function FeaturedEpisodeCard({
  className,
  title,
  itemCount,
  href,
  imageUrl,
  duration,
  onClick
}) {
  return (
    <a
      href={href}
      className={classNames(className, 'featured-episode-card')}
      onClick={onClick}
    >
      <div className="featured-content-container">
        <div className="featured-posterframe">
          <img className="featured-thumb" src={imageUrl} alt={title} />
          <EpisodeDuration className="featured-duration" duration={duration} />
        </div>
        <div className="featured-details">
          <h2 className="featured-label">Latest Episode</h2>
          <h3
            className={classNames('featured-title featured-title--long', {
              'featured-title--short': title < 20,
              'featured-title--long': title > 25
            })}
          >
            <span>{title}</span>
          </h3>
          <p className="featured-chapter-count">
            {itemCount} {itemCount === 1 ? 'Chapter' : 'Chapters'}
          </p>
        </div>
      </div>
    </a>
  );
}
FeaturedEpisodeCard.defaultProps = {
  className: null
};

FeaturedEpisodeCard.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  itemCount: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FeaturedEpisodeCard;
