import React from 'react';
import cn from 'classnames';
import { Icon } from './Icon';
import './SearchBar.scss';

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

export function SearchBar({
  value,
  onChange,
  onSubmit,
  placeholder = '',
  disabled = false,
  className = ''
}: Props) {
  return (
    <form
      aria-label="form"
      onSubmit={onSubmit}
      className={cn('uc-searchbar__container', className, {
        'uc-searchbar__disabled': disabled
      })}
    >
      <input
        className="uc-searchbar__input"
        value={value}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      <button
        className="uc-searchbar__button"
        type="submit"
        disabled={disabled}
        tabIndex={-1}
      >
        <Icon className="uc-searchbar__icon" name="search" />
      </button>
    </form>
  );
}
