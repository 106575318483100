// From emrap-corependium https://github.com/silverorange/emrap-corependium/blob/master/client/src/hooks/useMobileOrientation.tsx
import { useEffect } from 'react';

export const useMobileOrientation = (
  isDesktop: boolean,
  setVerticalVideo: React.Dispatch<React.SetStateAction<boolean>>,
  verticalUri?: string
) => {
  useEffect(() => {
    if (!isDesktop && verticalUri) {
      const updateOrientation = () => {
        if (window.innerWidth <= 768) {
          setVerticalVideo(window.innerHeight > window.innerWidth);
        }
      };
      updateOrientation();
      window.addEventListener('resize', updateOrientation);
      return () => window.removeEventListener('resize', updateOrientation);
    }

    return undefined;
  }, [isDesktop, setVerticalVideo, verticalUri]);
};
