// deprecated: use FeedbackErrorPage or FeedbackErrorInline in new code

import React from 'react';
import classnames from 'classnames';
import { ReactComponent as WarningIcon } from '../images/icons/warning.svg';

import './LoadingError.scss';

interface LoadingErrorProps {
  className?: string;
  errorMessage?: string;
}

export function LoadingError({
  className,
  errorMessage = 'Woops! There seems to be an error loading data! Try refreshing again!'
}: LoadingErrorProps) {
  return (
    <div className={classnames('loading-error', className)}>
      <WarningIcon aria-label="warning icon" className="loading-error__icon" />
      <p className="loading-error__message">{errorMessage}</p>
    </div>
  );
}
