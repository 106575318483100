import React from 'react';
import classNames from 'classnames';
import { ReactComponent as HDHeaderFeatureBackground } from '../../images/hd/header/feature-background-polygon.svg';
import HDPlaylistTitle from './HDPlaylistTitle';
import HDPlaylistBreadcrumbs from './HDPlaylistBreadcrumbs';
import { HDHeaderBar } from '../HDNav/HDHeaderBar';
import { Breadcrumb } from './types';
import { ESVideoPlaylist } from '../../schema/playlist/ESVideoPlaylist';
import {
  ESVideoPlaylistEpisodeItem,
  ESVideoPlaylistPlaylistItem
} from '../../schema/playlist/ESVideoPlaylistItem';

import './HDPlaylistDisplay.scss';
import './HDPlaylistHeader.scss';

interface HDPlaylistHeaderProps {
  playlist: ESVideoPlaylist | null;
  breadcrumbs: Breadcrumb[];
  isUrgentCare: boolean;
}

export function HDPlaylistHeader({
  playlist,
  breadcrumbs,
  isUrgentCare
}: HDPlaylistHeaderProps) {
  const prefix = breadcrumbs[breadcrumbs.length - 1]?.playlist?.items.find(
    (item): item is ESVideoPlaylistPlaylistItem | ESVideoPlaylistEpisodeItem =>
      item.type === 'playlist' || item.type === 'episode'
  )?.prefix;

  return (
    <div
      className={classNames(
        {
          [`hd-playlist-header__container--${playlist?.graphic}`]:
            playlist?.graphic !== undefined
        },
        'hd-playlist-header__container'
      )}
    >
      <section className="hd-playlist-header">
        <HDHeaderFeatureBackground className="hd-playlist-header__background" />
        <HDHeaderBar
          className="hd-playlist-header__header-bar"
          isUrgentCare={isUrgentCare}
        />
        {playlist && (
          <div className="hd-playlist-header__content">
            <HDPlaylistBreadcrumbs
              breadcrumbs={breadcrumbs}
              isUrgentCare={isUrgentCare}
            />
            <HDPlaylistTitle
              title={playlist.title}
              prefix={prefix}
              videoCount={playlist.video_count}
              createdAt={playlist.created_at}
              updatedAt={playlist.updated_at}
            />
          </div>
        )}
      </section>
    </div>
  );
}

export default HDPlaylistHeader;
