// this function will either return the link to the app store if the OS is Mac OS or iOS, to Google Play store if it's Android and undefined otherwise
export function getEmrapAppLinkForNonWebContent(operatingSystem: string) {
  if (operatingSystem === 'Mac OS' || operatingSystem === 'iOS') {
    return 'https://apps.apple.com/us/app/emrap-for-emergency-medicine/id804248260';
  }

  if (operatingSystem === 'Android') {
    return 'https://play.google.com/store/apps/details?id=com.hippoed.rap.em.app&hl=en_CA&gl=US';
  }

  return undefined;
}
