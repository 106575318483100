import React from 'react';
import PropTypes from 'prop-types';

import { ROP, EMA } from '../../lib/episodeTypes';

import './AudienceTag.scss';

const episodeTypes = {
  [ROP]: 'Primary Care',
  [EMA]: 'Emergency Medicine'
};

const AudienceTag = ({ episodeType }) => (
  <span className="audience-tag">{episodeTypes[episodeType]}</span>
);

AudienceTag.propTypes = {
  episodeType: PropTypes.number.isRequired
};

export default AudienceTag;
