// From emrap-corependium https://github.com/silverorange/emrap-corependium/blob/master/client/src/hooks/useVerticalVideoPreference.tsx
import { useState } from 'react';
import cookie from 'js-cookie';
import { useMobileOrientation } from './useMobileOrientation';

export const verticalVideoPreferenceCookieName =
  'verticalVideoPreferenceCookie';

function getVerticalVideoPreferenceCookie(): boolean | undefined {
  const verticalVideoCookieValue = cookie.get(
    verticalVideoPreferenceCookieName
  );
  if (verticalVideoCookieValue === undefined) {
    return undefined;
  }
  return verticalVideoCookieValue === 'true';
}

function setVerticalVideoPreferenceCookie(cookieValue: boolean) {
  cookie.set(verticalVideoPreferenceCookieName, cookieValue.toString(), {
    expires: 365
  });
}

export const useVerticalVideoPreference = (
  isDesktop: boolean,
  verticalUri?: string
) => {
  const [defaultVerticalVideoPreference, setDefaultVerticalPreferenceVideo] =
    useState<boolean>(Boolean(verticalUri && !isDesktop));

  // we keep a local state intitialized with the cookie value because
  // just setting the new cookie value does not trigger a re-render.
  const [userVerticalVideoPreference, setUserVerticalVideoPreference] =
    useState<boolean | undefined>(getVerticalVideoPreferenceCookie);

  useMobileOrientation(
    isDesktop,
    setDefaultVerticalPreferenceVideo,
    verticalUri
  );

  const setVerticalVideoPreference = (value: boolean) => {
    setVerticalVideoPreferenceCookie(value);
    setUserVerticalVideoPreference(value);
  };

  let verticalVideoPreference = false;
  if (verticalUri) {
    verticalVideoPreference =
      userVerticalVideoPreference === undefined
        ? defaultVerticalVideoPreference
        : userVerticalVideoPreference;
  }

  return { verticalVideoPreference, setVerticalVideoPreference };
};
