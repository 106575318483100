import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './SidebarSection.scss';

export default function SidebarSection({ children, className, isTransparent }) {
  return (
    <div
      className={classnames('sidebar-section', className, {
        'sidebar-section--transparent': isTransparent
      })}
    >
      {children}
    </div>
  );
}

SidebarSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isTransparent: PropTypes.bool
};

SidebarSection.defaultProps = {
  className: '',
  isTransparent: false
};
