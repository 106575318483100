import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

type LinkButtonProps = {
  variant: 'primary' | 'secondary' | 'tertiary';
  size?: 'default' | 'small';
  onClick?: () => void;
  label: string;
  className?: string;
} & ({ to: string; href?: never } | { to?: never; href: string });

export function LinkButton({
  variant,
  size = 'default',
  onClick,
  label,
  to,
  href,
  className
}: LinkButtonProps) {
  const commonProps = {
    className: classnames('global-button', className),
    'data-variant': variant,
    'data-size': size,
    onClick
  };

  return to ? (
    <Link to={to} {...commonProps}>
      {label}
    </Link>
  ) : (
    <a href={href} {...commonProps}>
      {label}
    </a>
  );
}
