import React from 'react';
import cn from 'classnames';
import { Wrapper } from '../Wrapper';

import './AudioHeroSection.scss';

export interface AudioHeroSectionProps {
  className?: string;
}

export function AudioHeroSection({ className }: AudioHeroSectionProps) {
  return (
    <Wrapper className={cn('uc-audio-page-hero', className)}>
      <h1 className="uc-audio-page-hero__title">UC Audio</h1>
      <p className="uc-audio-page-hero__description">
        Your membership unlocks exclusive access to a comprehensive suite of UC
        educational products plus everything else EM:RAP has to offer!
      </p>
    </Wrapper>
  );
}
