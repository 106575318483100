import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useLocation, useParams } from 'react-router';
import { fetchApi } from '../../lib/fetchApi';
import NotFound from '../NotFound';
import { CmeEvaluationsQuestions } from '../CmeCreditsPage/CmeEvaluationsQuestions';
import {
  CmeEvaluation,
  cmeEvaluationSchema,
  cmeEvaluationsSchema
} from '../../schema/cme/cmeEvaluations';
import { getInitialEvaluationResponse } from '../../lib/getInitialEvaluationResponse';
import { CmeEvaluationResponse } from '../../schema/cme/cmeEvaluationResponse';
import { updateCmeEvaluationResponse } from '../../lib/updateCmeEvaluationResponse';
import Loader from '../Loader';
import { Modal } from '../Modal';

export function PreviewEvaluationPage() {
  const { id, idType } = useParams<{
    id: string;
    idType: 'provider-id' | 'evaluation-preview-id';
  }>();
  const location = useLocation();

  const [state, setState] = useState<{
    evaluation: CmeEvaluation;
    responses: CmeEvaluationResponse;
  } | null>(null);
  const [previewError, setPreviewError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (id) {
      fetchApi(`preview_cme_evaluation/${idType}/${id}`)
        .then(response => response.json())
        .then(json => {
          const parsedData =
            idType === 'provider-id'
              ? cmeEvaluationsSchema.parse(json.evaluation)[0]
              : cmeEvaluationSchema.parse(json.data);

          // Sort questions and options by displayOrder
          parsedData.questions = parsedData.questions.sort(
            (a, b) => a.display_order - b.display_order
          );
          parsedData.questions.forEach(question => {
            question.question_options.sort(
              (a, b) => a.display_order - b.display_order
            );
          });

          if (isMounted) {
            setState({
              evaluation: parsedData,
              responses: getInitialEvaluationResponse(parsedData)
            });
            setIsLoading(false);
          }
        })
        .catch(() => {
          if (isMounted) {
            setPreviewError(true);
            setIsLoading(false);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [idType, id]);

  if (isLoading) {
    return <Loader />;
  }

  if (previewError || state === null) {
    return (
      <NotFound
        sourcePath={location.pathname}
        component="Preview Evaluation"
        reason="Couldn't find resource"
      />
    );
  }

  return (
    <Modal
      isPreview
      isOpen
      handleClose={() => null}
      modalClassName="evaluation-flow-modal"
      title="Evaluation Preview"
    >
      <div className="evaluation-flow-modal__questions-container  global-is-stack global-is-width-wrapper">
        <CmeEvaluationsQuestions
          evaluation={state.evaluation}
          evaluationResponse={state.responses}
          updateCmeEvaluationQuestionResponse={(
            questionId,
            newOptionIds,
            textValue
          ) =>
            setState(currentState => {
              if (currentState === null) {
                return currentState;
              }
              const newResponse = cloneDeep(currentState.responses);
              updateCmeEvaluationResponse(
                currentState.evaluation,
                newResponse,
                {
                  questionId,
                  newOptionIds,
                  textValue
                }
              );
              return {
                evaluation: currentState.evaluation,
                responses: newResponse
              };
            })
          }
        />
      </div>
    </Modal>
  );
}
