import React, { ReactNode } from 'react';
import { ReactComponent as IconToggleArrow } from '../images/icons/icon-toggle-arrow.svg';
import { Heading } from './Heading';

import './_style/lib/components/ToggleLink.scss';
import './_style/lib/components/ToggleHeading.scss';

interface ToggleHeading {
  headingText: string;
  nounText: string;
  children: ReactNode;
}

export function ToggleHeading({
  headingText,
  nounText,
  children
}: ToggleHeading) {
  return (
    <details className="global-toggle-heading">
      <summary className="global-toggle-heading__summary">
        <Heading Tag="span" variant="md">
          {headingText}
        </Heading>{' '}
        <span
          className="global-toggle-heading__summary-action global-toggle-link"
          data-variant="heading"
        >
          <span className="global-toggle-link__action-text">{nounText}</span>
          <IconToggleArrow
            aria-hidden="true"
            className="global-toggle-link__action-arrow"
          />
        </span>
      </summary>
      {children}
    </details>
  );
}
