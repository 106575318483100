import React from 'react';
import classnames from 'classnames';
import IconWorld from '../../../images/conferences/features/features-world.svg';
import IconReward from '../../../images/conferences/features/features-reward.svg';
import IconAlien from '../../../images/conferences/features/features-alien.svg';
import IconIpad from '../../../images/conferences/features/features-ipad.svg';
import IconGradHat from '../../../images/conferences/features/features-grad-hat.svg';
import IconSurprise from '../../../images/conferences/features/features-surprise.svg';
import ConferenceFeatureCard from '../ConferenceFeatureCard';
import ConferenceSectionTitle from '../ConferenceSectionTitle';
import LiveConferenceCme from './LiveConferenceCme';

import './LiveConferenceFeaturesSection.scss';

interface Props {
  className?: string;
  episodePath: string | null;
}

const features = [
  {
    icon: IconReward,
    title: 'Best instructors in the biz',
    description:
      'Learn from the expert faculty you already know and trust, and meet the next generation of EM:RAP superstars!'
  },
  {
    icon: IconAlien,
    title: 'Cutting-edge technology',
    description:
      'From a custom app to software simulations and beyond, our team is delivering a tech experience found nowhere else in medicine.'
  },
  {
    icon: IconIpad,
    title: 'On-demand viewing',
    description:
      'The main conference will be streamed free for all EM:RAP subscribers and available for on demand viewing afterwards.'
  },
  {
    icon: IconGradHat,
    title: 'Edu-tainment, reimagined!',
    description:
      'We will have all manner of prizes, games, intrigue, treasure hunts — oh, and maybe a fire juggler or three!'
  },
  {
    icon: IconWorld,
    title: 'Global perspectives',
    description:
      'As part of its ongoing efforts to foster a global EM community, EM:RAP GO welcomes physicians from across the globe — both in person and virtually.'
  },
  {
    icon: IconSurprise,
    title: 'A very special surprise!',
    description:
      'There’s always something Bundt-Cake-Big brewing in EM:RAPlandia. We’re going to knock your socks off with this big reveal!'
  }
];

export default function LiveConferenceFeaturesSection({
  className,
  episodePath
}: Props) {
  return (
    <section className="live-conference-page__section live-conference-page__section-features">
      <div
        className={classnames(
          'live-conference__section-container-features live-conference-page__section-features-background',
          className
        )}
      >
        <div className="container live-conference-page__section-container-features ">
          <ConferenceSectionTitle
            title="why attend em:rap one?"
            subTitle="here are a few reasons why"
          />
          <div className="live-conference__section-content">
            {features.map(feature => (
              <ConferenceFeatureCard
                key={feature.icon}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
          <LiveConferenceCme episodePath={episodePath} />
        </div>
      </div>
    </section>
  );
}

LiveConferenceFeaturesSection.defaultProps = {
  className: ''
};
