import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import config from '../../config';
import { ReactComponent as ArrowDownIcon } from '../../images/icons/icon-arrow--down.svg';

import './AllTopicsSection.scss';

interface AllTopicsSectionProps {
  sectionTitle: string;
  sectionLink: string;
  children: ReactNode;
  closeMenu: () => void;
  onClick: () => void;
}

const AllTopicsSection = ({
  sectionTitle,
  sectionLink,
  children,
  closeMenu,
  onClick
}: AllTopicsSectionProps) => {
  const isMobile = !useMediaQuery({ query: config.mediaQueries.sm });
  const [showContent, setShowContent] = useState(false);

  return (
    <div className="all-topics-section">
      <div className="all-topics-section__header">
        <Link
          className="all-topics-section__section-title"
          to={sectionLink}
          onClick={() => {
            onClick();
            closeMenu();
          }}
        >
          {sectionTitle}
        </Link>
        {isMobile && (
          <button
            type="button"
            className="all-topics-section__button"
            onClick={() => setShowContent(!showContent)}
          >
            {/* TODO: eventually replace this with the Icon from Icons/Icon.js for improved accessibility once added to the map */}
            <ArrowDownIcon
              className={`all-topics-section__icon--${
                showContent ? 'up' : 'down'
              }`}
            />
          </button>
        )}
      </div>
      <div
        className={classnames(
          'all-topics-section__content',
          showContent || !isMobile
            ? 'all-topics-section__content--visible'
            : 'all-topics-section__content--hidden'
        )}
      >
        {(showContent || !isMobile) && children}
      </div>
    </div>
  );
};

export default AllTopicsSection;
