import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState, CmeFacultiesState } from '../lib/stateTypes';
import { reportError } from '../lib/reportError';
import { fetchApi } from '../lib/fetchApi';
import { CmeFaculty, cmeFacultyResponseSchema } from '../schema/cme/cmeFaculty';

const initialState: CmeFacultiesState = {
  faculties: null,
  isLoading: false
};

export const loadCmeFaculty = createAsyncThunk<
  { chapterId: number; faculties: CmeFaculty[] },
  number,
  { state: AppState; rejectValue: null }
>('cmeFaculty/loadCmeFaculty', async (chapterId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState();

    if (
      state.cmeFaculties.faculties !== null &&
      chapterId in state.cmeFaculties.faculties
    ) {
      return { chapterId, faculties: state.cmeFaculties.faculties[chapterId] };
    }
    const response = await fetchApi(`cme_chapter_faculty/${chapterId}`);
    const data = await response.json();

    const parsedResponse = cmeFacultyResponseSchema.parse(data);
    return { chapterId, faculties: parsedResponse.data.faculties };
  } catch (error) {
    reportError(error);
    return thunkAPI.rejectWithValue(null);
  }
});

export const cmeFacultiesSlice = createSlice({
  name: 'cmeFaculties',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadCmeFaculty.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadCmeFaculty.fulfilled, (state, action) => {
        if (action.payload) {
          state.faculties = {
            ...state.faculties,
            [action.payload.chapterId]: action.payload.faculties
          };
        }
        state.isLoading = false;
      })
      .addCase(loadCmeFaculty.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const { reducer } = cmeFacultiesSlice;
