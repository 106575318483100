import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as IconPlaylist } from '../../images/icons/playlist.svg';
import { ReactComponent as IconTriangleRight } from '../../images/icons/triangle-right.svg';
import { ReactComponent as IconArrowRight } from '../../images/icons/legacy-arrow-right.svg';
import { ReactComponent as IconArrowHollowRight } from '../../images/icons/icon-arrow--hollow-right.svg';
import { ReactComponent as IconArrowHollowLeft } from '../../images/icons/icon-arrow--hollow-left.svg';
import { ReactComponent as IconSearch } from '../../images/icons/search.svg';
import { ReactComponent as IconSmartCard } from '../../images/icons/smart-card.svg';
import { ReactComponent as IconFavorite } from '../../images/icons/favorite.svg';
import { ReactComponent as IconClose } from '../../images/icons/close.svg';
import { ReactComponent as IconEMRAP } from '../../images/icons/episode-icons/icon-emrap.svg';
import { ReactComponent as IconC3Search } from '../../images/icons/episode-icons/icon-c3-search.svg';
import { ReactComponent as IconEMA } from '../../images/icons/episode-icons/icon-ema.svg';
import { ReactComponent as IconHD } from '../../images/icons/episode-icons/icon-hd.svg';
import { ReactComponent as IconLLSA } from '../../images/icons/episode-icons/icon-llsa.svg';
import { ReactComponent as IconROP } from '../../images/icons/episode-icons/icon-rop.svg';
import { ReactComponent as IconCrunchEM } from '../../images/icons/episode-icons/icon-crunchtime-em.svg';
import { ReactComponent as IconCrunchFM } from '../../images/icons/episode-icons/icon-crunchtime-fm.svg';
import { ReactComponent as IconCrunchyQ } from '../../images/icons/episode-icons/icon-crunchyq.svg';
import { ReactComponent as IconDOC } from '../../images/icons/download-types/icon-doc.svg';
import { ReactComponent as IconJPG } from '../../images/icons/download-types/icon-jpg.svg';
import { ReactComponent as IconM4A } from '../../images/icons/download-types/icon-m4a.svg';
import { ReactComponent as IconMP3 } from '../../images/icons/download-types/icon-mp3.svg';
import { ReactComponent as IconMP4 } from '../../images/icons/download-types/icon-mp4.svg';
import { ReactComponent as IconPDF } from '../../images/icons/download-types/icon-pdf.svg';
import { ReactComponent as IconPNG } from '../../images/icons/download-types/icon-png.svg';
import { ReactComponent as IconXLS } from '../../images/icons/download-types/icon-xls.svg';
import { ReactComponent as IconZIP } from '../../images/icons/download-types/icon-zip.svg';
import { ReactComponent as IconDefaultExtension } from '../../images/icons/download-types/icon-default-extension.svg';
import { ReactComponent as Refresh } from '../../images/icons/icon-refresh.svg';
import { ReactComponent as PlayIcon } from '../../images/icons/icon-play.svg';
import { ReactComponent as PauseIcon } from '../../images/icons/icon-pause.svg';
import { ReactComponent as SkipIcon } from '../../images/icons/player-skip-icon.svg';

import './Icon.scss';

export const iconMap = {
  refresh: {
    component: Refresh,
    label: 'Refresh'
  },
  close: {
    component: IconClose,
    label: 'Close'
  },
  playlist: {
    component: IconPlaylist,
    label: 'Playlist'
  },
  'triangle-right': {
    component: IconTriangleRight,
    label: 'Triangle Right'
  },
  'arrow-right': {
    component: IconArrowRight,
    label: 'Arrow Right'
  },
  'arrow-hollow-right': {
    component: IconArrowHollowRight,
    label: 'Arrow Hollow Right'
  },
  'arrow-hollow-left': {
    component: IconArrowHollowLeft,
    label: 'Arrow Hollow Left'
  },
  search: {
    component: IconSearch,
    label: 'Search'
  },
  'smart-card': {
    component: IconSmartCard,
    label: 'Smart Card'
  },
  favorite: {
    component: IconFavorite,
    label: 'Favorite'
  },
  emrap: {
    component: IconEMRAP,
    label: 'EM:RAP'
  },
  ema: {
    component: IconEMA,
    label: 'EMA'
  },
  c3: {
    component: IconC3Search,
    label: 'C3 Search'
  },
  'c3-chapter': {
    component: IconC3Search,
    label: 'C3 Chapter'
  },
  hd: {
    component: IconHD,
    label: 'HD'
  },
  llsa: {
    component: IconLLSA,
    label: 'LLSA'
  },
  rop: {
    component: IconROP,
    label: 'Right on Prime'
  },
  'crunch-time-fm': {
    component: IconCrunchFM,
    label: 'Crunch Time Family Medicine'
  },
  'crunch-time': {
    component: IconCrunchEM,
    label: 'Crunch Time Emergency Medicine'
  },
  'crunchy-q': {
    component: IconCrunchyQ,
    label: 'Crunchy Qs'
  },
  doc: {
    component: IconDOC,
    label: 'Doc'
  },
  jpg: {
    component: IconJPG,
    label: 'Jpeg'
  },
  m4a: {
    component: IconM4A,
    label: 'M4A'
  },
  mp3: {
    component: IconMP3,
    label: 'MP3'
  },
  mp4: {
    component: IconMP4,
    label: 'MP4'
  },
  pdf: {
    component: IconPDF,
    label: 'PDF'
  },
  png: {
    component: IconPNG,
    label: 'PNG'
  },
  xls: {
    component: IconXLS,
    label: 'XLS'
  },
  zip: {
    component: IconZIP,
    label: 'Zip'
  },
  html: {
    component: IconDefaultExtension,
    label: 'HTML'
  },
  'default-extension': {
    component: IconDefaultExtension,
    label: 'Default Extension'
  },
  play: {
    component: PlayIcon,
    label: 'Play'
  },
  pause: {
    component: PauseIcon,
    label: 'Pause'
  },
  skip: {
    component: SkipIcon,
    label: 'Skip'
  }
};

export const Icon = props => {
  const { className, name, customLabel, isInteractive } = props;
  const { component, label } = iconMap[name];

  const IconComponent = component;
  // By default, we assume the icon is decorative and hide it from accessible DOM
  // If an icon is interactive, it should have a label
  return (
    <>
      <IconComponent
        data-testid="icon"
        aria-hidden="true"
        className={classNames(className, `icon icon--${name}`)}
      />
      {isInteractive && (
        <span className="visually-hidden">{customLabel ?? label}</span>
      )}
    </>
  );
};

Icon.defaultProps = {
  className: null,
  name: 'playlist',
  customLabel: null,
  isInteractive: false
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  customLabel: PropTypes.string,
  isInteractive: PropTypes.bool
};

export default Icon;
