import React from 'react';
import './ConferenceFeatureCard.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ConferenceFeatureCard = props => {
  const { icon, title, description, className } = props;
  return (
    <div
      className={classnames('conference-feature-card__container', className)}
    >
      <img
        src={icon}
        alt={`${title} icon`}
        className="conference-feature-card__icon"
      />
      <h3 className="conference-feature-card__title">{title}</h3>
      <p className="conference-feature-card__description">{description}</p>
    </div>
  );
};

export default ConferenceFeatureCard;

ConferenceFeatureCard.defaultProps = {
  icon: null,
  title: null,
  description: null,
  className: null
};

ConferenceFeatureCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string
};
