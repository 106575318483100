export function getEmrapAppLink(operatingSystem: string) {
  switch (operatingSystem) {
    case 'iOS':
      return 'https://apps.apple.com/us/app/emrap-for-emergency-medicine/id804248260';
    case 'Android':
      return 'https://play.google.com/store/apps/details?id=com.hippoed.rap.em.app&hl=en_CA&gl=US';
    default:
      return 'https://www.emrap.org/podcast';
  }
}
