import React from 'react';
import classNames from 'classnames';

interface PlaylistTitleProps {
  title: string;
  itemCount: number;
}

export function PlaylistTitle({ title, itemCount }: PlaylistTitleProps) {
  return (
    <div className="playlist-header__details">
      <h1
        className={classNames('playlist-header__title', {
          'playlist-header__title--short': title.length < 20,
          'playlist-header__title--long': title.length > 50
        })}
      >
        {title}
      </h1>
      <div className="playlist-header__subtitle">
        {itemCount} {itemCount === 1 ? 'Episode' : 'Episodes'}
      </div>
    </div>
  );
}

export default PlaylistTitle;
