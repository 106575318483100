/**
 * Gets a path to use in link hrefs to the PHP site
 *
 * The PHP site relies on base href="foo" to use relative linking. This React
 * application is using react-router which expects URLs to be absolute,
 * relative to the React app root.
 *
 * For correct linking behaviour in local, stage, and production environments
 * use this function. Do not use this function for <Link> components.
 *
 * @param {string} path the absolute path to the PHP site.
 *
 * @returns string
 */
export function getPhpSitePath(path: string) {
  const strippedBase = process.env.REACT_APP_BASE_NAME
    ? process.env.REACT_APP_BASE_NAME.replace(/\/$/, '')
    : '';

  const strippedPath = path.replace(/^\//, '');

  if (strippedPath || strippedBase === '') {
    return `${strippedBase}/${strippedPath}`;
  }

  return strippedBase;
}
