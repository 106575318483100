import {
  CONFERENCE_LOADING,
  CONFERENCE_SUCCESS,
  CONFERENCE_FAILURE
} from '../actions/conference';

const initialState = {
  isRegistered: false,
  isLoading: false
};

export default function conferenceReducer(state = initialState, action) {
  switch (action.type) {
    case CONFERENCE_LOADING: {
      if (!state.isLoading) {
        return {
          ...state,
          isLoading: true
        };
      }
      return state;
    }
    case CONFERENCE_SUCCESS: {
      return {
        ...state,
        isRegistered: action.isRegistered,
        isLoading: false
      };
    }
    case CONFERENCE_FAILURE: {
      return initialState;
    }
    default:
      return state;
  }
}
