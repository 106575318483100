/**
 * Gets a path an HD resource based on isUrgentCare
 *
 * @param {string} path the absolute path to prefix.
 *
 * @returns string
 */
export function getHDPath(isUrgentCare: boolean, path: string) {
  return isUrgentCare ? `/urgent-care/hd${path}` : `/hd${path}`;
}
