import { ApiError } from './ApiError';

interface FetchApiOptions extends RequestInit {
  baseUrl?: string;
}

export function getApiUrl(endpoint: string, options?: FetchApiOptions) {
  const baseUrl =
    options?.baseUrl ?? process.env.REACT_APP_NEXT_JS_API_URI ?? '';
  return `${baseUrl.replace(/\/+$/, '')}/${endpoint.replace(/^\/+/, '')}`;
}

export async function fetchApi(endpoint: string, options?: FetchApiOptions) {
  const developmentHeaders =
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_REFERENCE_TOKEN
      ? {
          Authorization: `Bearer ${process.env.REACT_APP_REFERENCE_TOKEN}`
        }
      : undefined;

  const defaultHeaders: HeadersInit = {
    Accept: 'application/vnd.rap.v3+json',
    ...developmentHeaders
  };

  const response = await fetch(getApiUrl(endpoint, options), {
    ...options,
    headers: { ...defaultHeaders, ...options?.headers },
    credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'omit'
  });

  if (!response.ok) {
    const body = await response.text();
    const data = (() => {
      try {
        return JSON.parse(body);
      } catch (e) {
        return body;
      }
    })();

    throw new ApiError(data, response.status);
  }

  return response;
}
