import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Icon, IconName } from './Icon';

import './MenuItem.scss';

interface Props {
  url: string;
  iconName: IconName;
  title: string;
  className?: string;
  onClick?: () => void;
}

export function MenuItem({ url, iconName, title, className, onClick }: Props) {
  return (
    <li className={classNames('uc-menu-item', className)}>
      <NavLink to={url} className="uc-menu-item__link" onClick={onClick}>
        <div className="uc-menu-item__icon-container">
          <Icon name={iconName} className="uc-menu-item__icon" />
        </div>
        <span className="uc-menu-item__title">{title}</span>
      </NavLink>
    </li>
  );
}
