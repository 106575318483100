import { EpisodeTypeShortname } from '../schema/webEpisode/episodeType';
import { EpisodeTypeNumber } from './types';

const getEpisodeNumber = (episodeLabel: EpisodeTypeShortname) => {
  switch (episodeLabel) {
    case 'full':
      return EpisodeTypeNumber.EMRAP;
    case 'ema':
      return EpisodeTypeNumber.EMA;
    case 'c3':
      return EpisodeTypeNumber.C3;
    case 'hd':
      return EpisodeTypeNumber.HD;
    case 'crunchtime':
      return EpisodeTypeNumber.CRUNCH_TIME_EM;
    case 'llsa':
      return EpisodeTypeNumber.LLSA;
    case 'rop':
      return EpisodeTypeNumber.ROP;
    case 'crunchtimefm':
      return EpisodeTypeNumber.CRUNCH_TIME_FM;
    default:
      return EpisodeTypeNumber.NONE;
  }
};

export default getEpisodeNumber;
