import React from 'react';
import { SidebarLink } from './SidebarLink';
import Icon from '../Icons/Icon';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { CRUNCH_TIME_FM } from '../../lib/episodeTypes';

const CrunchTimeFMCallout = () => (
  <SidebarLink
    to="crunchtimefm"
    buttonText="Start Reviewing CT:FM"
    title="Think you’re ready for the boards? "
    text="Family Medicine board review made simple, focused, and accessible in the EM:RAP tradition."
    episodeType={CRUNCH_TIME_FM}
    isInverted
    icon={<Icon name="crunch-time-fm" />}
    onClick={() =>
      reportLinkClickedAnalytics({
        to: 'crunchtimefm',
        linkText: 'Start Reviewing CT:FM',
        eventSource: 'Crunch Time FM Callout'
      })
    }
  />
);

export default CrunchTimeFMCallout;
