export class MaximumRetryError extends Error {
  public readonly retries: number;

  constructor(message: string, retries: number, error?: Error) {
    super(error?.message ?? message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, error?.constructor ?? this.constructor);
    } else {
      this.stack = error?.stack ?? new Error(message).stack;
    }
    this.retries = retries;
  }
}
