import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { useSelector } from '../../lib/hooks';
import { commentsSlice } from '../../store/comments';
import { WebCommentStatus } from '../../schema/webComment/webCommentStatus';
import { ChapterCommentStatus } from '../../schema/webEpisode/webChapter';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import ConversationAvatar from './ConversationAvatar';
import ConversationCommentForm from './ConversationCommentForm';
import { WebCommentWithReplies } from '../../lib/organizeComments';
import { ReplyingToLabel } from './ReplyingToLabel';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { useDismissAfterTime } from '../../hooks/useDismissAfterTime';
import SketchyButton from '../LandingPage/SketchyButton';
import useEditorActions, { editorActions } from '../../hooks/useEditorActions';

import './ConversationComment.scss';

interface ConversationCommentProps {
  comment: WebCommentWithReplies;
  chapterCommentStatus: ChapterCommentStatus;
  chapterId: number;
  episodeId: number;
  gravatarEmail: string | null;
  accountId?: number;
  episodeType: EpisodeTypeShortname;
  isPaidAndActive: boolean;
  replyingToName?: string;
}

function getCommentAuthorName(
  firstName: string,
  lastInitial: string,
  suffix: string | null
) {
  if (!suffix) {
    return `${firstName} ${lastInitial}.`;
  }

  return `${firstName} ${lastInitial}., ${suffix}`;
}

const ConversationComment = ({
  comment,
  chapterCommentStatus,
  chapterId,
  episodeId,
  gravatarEmail,
  accountId,
  episodeType,
  isPaidAndActive,
  replyingToName
}: ConversationCommentProps) => {
  const isNewCommentFromUser =
    comment.account === accountId &&
    new Date(comment.createDate).getTime() >= Date.now() - 5000;
  const hasReplyForm =
    comment.status !== WebCommentStatus.PENDING &&
    chapterCommentStatus !== ChapterCommentStatus.LOCKED &&
    isPaidAndActive;

  const commentAuthorName = getCommentAuthorName(
    comment.firstName,
    comment.lastInitial,
    comment.suffix
  );
  const pathname = getPhpSitePath(useLocation().pathname);
  const { editCommentStatus } = useEditorActions();
  const isChapterEditor = useSelector(
    state => state[commentsSlice.name].isChapterEditor
  );
  const isCommentPending = comment.status === WebCommentStatus.PENDING;
  const isDismissed = useDismissAfterTime(isNewCommentFromUser, 7000);

  return (
    <>
      <div
        className={classnames(
          'conversation-comment',
          `conversation-comment--${episodeType}`,
          {
            'conversation-comment--new': !isDismissed && isNewCommentFromUser
          },
          { 'conversation-comment--hidden': isDismissed },
          { 'conversation-comment--pending': isCommentPending }
        )}
        id={`${comment.id}-comment`}
      >
        {isCommentPending && (
          <span className="conversation-comment__comment-pending">
            {isChapterEditor
              ? 'Pending, only visible to editors'
              : 'Waiting for Review'}
          </span>
        )}
        <div className="conversation-comment__avatar-details--container">
          <ConversationAvatar
            gravatarEmail={comment.gravatarEmail ? comment.gravatarEmail : null}
          />
          <div className="conversation-comment__details-comment--container">
            <div className="conversation-comment__details">
              <div className="conversation-comment__about">
                <div className="conversation-comment__name">
                  {commentAuthorName}
                </div>
                <a
                  // Link was not used in this case, because it doesn't work correctly with a hashed link.
                  // For context, see https://github.com/remix-run/react-router/issues/394#issuecomment-220221604
                  href={`${pathname}#${comment.id}-comment`}
                  className="conversation-comment__date"
                >
                  {moment(comment.createDate).format('MMM D, YYYY [at] h:mm A')}
                </a>
              </div>
            </div>
            <div className="conversation-comment__comment">
              {replyingToName !== undefined && (
                <ReplyingToLabel name={replyingToName} />
              )}
              {comment.bodyText}
            </div>
          </div>
        </div>
        {isChapterEditor && isCommentPending && (
          <div className="conversation-comment__editor-actions-container">
            {editorActions.map(action => (
              <SketchyButton
                key={action}
                type="text"
                className="conversation-comment__editor-actions-button"
                color="gray"
                onClick={() => {
                  editCommentStatus(comment.id, chapterId, action);
                }}
              >
                {action.toUpperCase()}
              </SketchyButton>
            ))}
          </div>
        )}
        {hasReplyForm && (
          <ConversationCommentForm
            chapterId={chapterId}
            episodeId={episodeId}
            gravatarEmail={gravatarEmail || null}
            isReply
            parentCommentId={comment.id}
            replyingToName={commentAuthorName}
            episodeType={episodeType}
            isNewCommentSuccessful={isNewCommentFromUser}
          />
        )}
      </div>
      {comment.replies.length > 0 && (
        <div className="conversation-comment__replies">
          {comment.replies.map(reply => (
            <ConversationComment
              key={reply.id}
              comment={reply}
              chapterCommentStatus={chapterCommentStatus}
              chapterId={chapterId}
              episodeId={episodeId}
              gravatarEmail={gravatarEmail || null}
              accountId={accountId}
              episodeType={episodeType}
              isPaidAndActive={isPaidAndActive}
              replyingToName={commentAuthorName}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ConversationComment;
