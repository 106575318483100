export default function getEpisodeQuery(
  episodeTypes: number[],
  sort: {
    dataField: string;
    sortBy: string;
  },
  restrictRange = true,
  hasDuration = true
) {
  const { dataField, sortBy } = sort;

  const rangeQuery = { range: { 'episode.publish_date': { lte: 'now' } } };
  const durationQuery = { exists: { field: 'episode.duration' } };
  const sortQuery = { [dataField]: { order: sortBy } };

  // TODO: Improve Typings
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const episodeTypesClause: { [key: string]: any } = episodeTypes
    ? {
        bool: {
          must: [
            { term: { type: 'episode' } },
            { exists: { field: 'episode.artwork.id' } },
            {
              terms: {
                'episode.episode_type.id': episodeTypes
              }
            }
          ]
        }
      }
    : { match_all: {} };

  if (episodeTypes && hasDuration) {
    episodeTypesClause.bool?.must.push(durationQuery);
  }
  if (episodeTypes && restrictRange) {
    episodeTypesClause.bool?.must.push(rangeQuery);
  }

  return () => ({
    query: {
      ...episodeTypesClause
    },

    sort: [
      {
        'episode.preview': { order: 'asc' }
      },
      sortQuery
    ]
  });
}
