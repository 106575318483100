import { useDispatch } from 'react-redux';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash';
import { useSelector } from '../lib/hooks';
import { RootState } from '../store';
import { flagsSlice } from '../store/flags';

export default function LDReduxInit() {
  const dispatch = useDispatch();
  const flags = useFlags();
  const ldClient = useLDClient();
  const { initialized } = useSelector((state: RootState) => ({
    initialized: state[flagsSlice.name].initialized
  }));
  if (initialized) {
    return null;
  }

  if (!ldClient) {
    throw new Error('LDClient is not initialized');
  }

  dispatch(flagsSlice.actions.setFlags(flags));

  const allFlags = ldClient.allFlags();
  Object.keys(allFlags).forEach(key => {
    ldClient.on(`change:${key}`, current => {
      const newFlagValue = {
        [camelCase(key)]: current
      };
      dispatch(flagsSlice.actions.setFlags(newFlagValue));
    });
  });

  dispatch(flagsSlice.actions.setInitialized(true));

  return null;
}
