import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../firebase';
import collections from '../firebase/collections';
import {
  MarketingSpot,
  MarketingSpotSchema
} from '../schema/marketing/marketingSpot';
import { reportError } from '../lib/reportError';

export const loadMarketingSpots = createAsyncThunk(
  'marketingSpots/loadMarketingSpots',
  async () => {
    const querySnapshot = await getDocs(
      query(collection(db, collections.webMarketingSpots))
    );

    return querySnapshot.docs.reduce<MarketingSpot[]>((accumulator, doc) => {
      const spot = { ...doc.data(), id: doc.id };
      try {
        const validatedSpot = MarketingSpotSchema.parse(spot);

        return [...accumulator, validatedSpot];
      } catch (error) {
        reportError(error, {
          tags: { id: doc.id, collection: collections.webMarketingSpots }
        });
      }
      return accumulator;
    }, []);
  }
);

interface MarketingSpotsState {
  spots: MarketingSpot[];
  isLoading: boolean;
}

const initialState: MarketingSpotsState = {
  spots: [],
  isLoading: false
};

export const marketingSpotsSlice = createSlice({
  name: 'marketingSpots',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadMarketingSpots.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadMarketingSpots.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spots = action.payload;
      })
      .addCase(loadMarketingSpots.rejected, state => {
        state.isLoading = false;
      });
  }
});
