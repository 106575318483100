import { MarketingSpot } from '../schema/marketing/marketingSpot';
import { MarketingSlotId } from '../schema/marketing/marketingSlotId';

export function getSpotsBySlotId(
  spots: MarketingSpot[],
  slotId: MarketingSlotId
) {
  return spots
    .filter(spot => spot.slotIds.includes(slotId))
    .sort((spotA, spotB) => (spotA.order ?? 0) - (spotB.order ?? 0));
}
