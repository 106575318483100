import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MultiList as ReactiveSearchMultiList } from '@appbaseio/reactivesearch';

import './MultiList.scss';

export default function MultiList({ className, ...props }) {
  return (
    <ReactiveSearchMultiList
      {...props}
      className={classnames('multi-list', className)}
      showSearch={false}
      showCheckbox={false}
      URLParams
      innerClass={{
        title: 'multi-list__title',
        list: 'multi-list__list',
        checkbox: 'multi-list__checkbox',
        label: 'multi-list__label',
        count: 'multi-list__count'
      }}
    />
  );
}

MultiList.defaultProps = {
  className: ''
};

MultiList.propTypes = {
  className: PropTypes.string
};
