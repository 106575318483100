import { useEffect, useState } from 'react';
import { WebEpisode } from '../schema/webEpisode/webEpisode';
import {
  CmeCredit,
  episodeCmeCreditResponseSchema
} from '../schema/cme/episodeCmeCreditResponse';
import { postFrontMatterApiResponseSchema } from '../schema/api/postFrontMatter';
import { reportError } from '../lib/reportError';
import { fetchApi } from '../lib/fetchApi';

export function useCmeCredit(
  episode: WebEpisode | undefined,
  isSignedIn: boolean,
  accountType: string | null,
  isPaidAndActive: boolean
) {
  const [cmeCredit, setCmeCredit] = useState<CmeCredit | undefined>(undefined);
  const [shouldShowFrontMatter, setShouldShowFrontMatter] = useState(false);
  const [hasCmeError, setHasCmeError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (episode) {
      // get CME data
      (async () => {
        if (accountType === 'student') {
          return;
        }

        try {
          const results = await fetchApi(
            `episodes/${episode.id}/${
              isSignedIn ? 'cme_credits' : 'public_cme_credits'
            }`
          ).catch(error => {
            setHasCmeError(true);
            return error;
          }); // TODO this erases the type of results

          const data = episodeCmeCreditResponseSchema.parse(
            await results.json()
          );
          const validatedCmeCreditData = Object.values(data.cmecredit)[0];

          if (validatedCmeCreditData === undefined) {
            return;
          }

          const isCmeExpired =
            Date.now() >
            new Date(validatedCmeCreditData.credit.expiry_date).getTime();

          if (isMounted) {
            setCmeCredit(
              // Only show expired CME if it has been earned
              isCmeExpired && !validatedCmeCreditData.is_credit_earned
                ? undefined
                : validatedCmeCreditData
            );
          }
        } catch (error) {
          setHasCmeError(true);
          reportError(error);
        }
      })();
    }

    return () => {
      isMounted = false;
    };
  }, [episode]);

  useEffect(() => {
    if (!cmeCredit) return;
    setShouldShowFrontMatter(
      isSignedIn && accountType === 'physician' && isPaidAndActive
        ? !cmeCredit.has_attested
        : false
    );
  }, [cmeCredit]);

  async function handleAttestCme() {
    try {
      if (cmeCredit?.front_matter_id === undefined) {
        throw new Error('Front matter ID is undefined');
      }

      const response = await fetchApi('front_matter_attestation', {
        body: JSON.stringify({ front_matter: cmeCredit.front_matter_id }),
        method: 'POST'
      });

      const data = postFrontMatterApiResponseSchema.parse(
        await response.json()
      );

      if (data.status.code === 'ok') {
        setShouldShowFrontMatter(false);
      } else {
        setHasCmeError(true);
      }
    } catch (error) {
      setHasCmeError(true);
      reportError(error);
    }
  }

  return {
    cmeCredit,
    shouldShowFrontMatter,
    shouldShowCmeLinks: isSignedIn,
    handleAttestCme,
    hasCmeError
  };
}
