import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { useSelector } from '../../lib/hooks';
import { Footer } from '../LandingPage/Footer';
import { DashboardUCFundamentalsSection } from './DashboardUCFundamentalsSection';
import { DashboardHeroSection } from './DashboardHeroSection';
import { DashboardLatestSection } from './DashboardLatestSection';
import { UCNewsletterCallout } from './UCNewsletterCallout';
import { useSearch } from '../../hooks/useSearch';
import { UC_MAX } from '../../lib/episodeTypes';
import config from '../../config';
import { NavBar } from './NavBar';

import './UCDashboard.scss';

const videoUrl = `${config.cdnVideoUrl}/6499bd0d46e1c/${config.cdnVideoPostfix}`;
const posterFrame = `${config.videoArtUrl}/55778.jpg`;

export function UCDashboard() {
  const account = useSelector(state => state.account.account);
  const isAccountLoading = useSelector(state => state.account.isLoading);
  const { searchValue, onSearchChange, onSearchSubmit } = useSearch({
    episodeType: UC_MAX
  });

  if (isAccountLoading) {
    return null;
  }

  if (!account) {
    return <Redirect push={false} to="/uc-landing" />;
  }

  return (
    <>
      <Helmet>
        <title>UC Max | EM:RAP</title>
      </Helmet>
      <div className="uc-dashboard">
        <div className="uc-star-background">
          <header className="uc-hero-wrapper__header">
            <NavBar
              searchValue={searchValue}
              onSearchChange={onSearchChange}
              onSearchSubmit={onSearchSubmit}
              isFancy={false}
            />
          </header>
          <div className="uc-swoosh-background">
            <DashboardHeroSection accountName={account.firstName} />
            <DashboardLatestSection className="uc-section" />
          </div>
          <DashboardUCFundamentalsSection
            videoUrl={videoUrl}
            posterFrame={posterFrame}
          />
          <UCNewsletterCallout className="uc-dashboard__newsletter-callout" />
          <Footer />
        </div>
      </div>
    </>
  );
}
